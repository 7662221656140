import React, { useEffect, useState } from 'react';

import { Loader, Typography, Paper } from '@cision/rover-ui';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import prwebLogo from '../assets/registration/prweb-logo.png';
import { PackageFeature } from '../components/package-card/PackageCard';
import { PRWEB_PACKAGES } from '../constants';
import CacheService from '../utils/cache-service';

import styles from './account/partnersplash.module.css';

interface Props {
  config: PRWebConfig;
}

const formatUSD = (value: any) => {
  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const Welcome: React.FC<Props> = ({ config }: Props) => {
  const AUTH_CODE_PATH = '/authorizationcode';

  const prwebFrontEnd = config.prwebFrontEnd.url;
  const oktaUrl = config.prwebOkta.url;
  const oktaClientId = config.prwebOkta.clientId;

  const gotoLogin = () => {
    const loginUrl = `${oktaUrl}/oauth2/default/v1/authorize?&response_type=code&scope=openid profile email&state=state&redirect_uri=${encodeURI(
      `${prwebFrontEnd}${AUTH_CODE_PATH}`,
    )}&client_id=${oktaClientId}`;
    window.location.replace(loginUrl);
  };

  const getAdvancedPkgPoints = () => {
    return (
      <>
        <PackageFeature feature="Unlimited Media Assets" />
        <PackageFeature
          feature="Increased Search Visibility"
          description="Relevant content keyword tagging by our expert editorial team"
        />
        <PackageFeature
          feature="Increased Online Visibility"
          description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
        />
      </>
    );
  };

  const getStandardPkgPoints = () => {
    return (
      <>
        <PackageFeature feature="Unlimited Media Assets" />
        <PackageFeature
          feature="Increased Search Visibility"
          description="Relevant content keyword tagging by our expert editorial team"
        />
        <PackageFeature
          feature="Increased Online Visibility"
          description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
        />
        <PackageFeature feature="48-hour turn around time" />
      </>
    );
  };

  return (
    <div className={styles.partnersplashfont}>
      <nav className={styles.navbar}>
        <a
          className={styles.container}
          rel="index"
          title="Go to homepage"
          href="https://www.prweb.com"
        >
          <img src={prwebLogo} id="PartnerLogo" height="45" alt="logo" />
        </a>
      </nav>

      <section className={styles.bg_home}>
        <div className={styles.megaphoneContent}>
          <img
            src="/img/partner/PRMegaphone-recolored.png"
            alt="PRMegaphone"
            style={{ width: '350px' }}
          />
          <div className={styles.splashDescriptionContent}>
            <h1>Welcome to PRWeb</h1>
            <p>
              We&apos;re excited to announce that PRWeb has upgraded to a new
              platform!
            </p>
            <strong>What&apos;s New?</strong>
            <ul>
              <li>
                Stronger account security using Okta&apos;s multi-factor
                authentication.
              </li>
              <li>Easy-to-use, modern user interface.</li>
              <li>
                Create unlimited press release drafts to organize and plan ahead
                of your news distribution.
              </li>
              <li>Finalize your payment at the end of the transaction.</li>
            </ul>
            <p>
              <strong>Register for an account</strong> in the new platform today
              and begin creating your press release.
            </p>
          </div>
          <NavLink
            to={`/register`}
            id="welcomeRegisterBtn"
            className={styles.orangeBtn}
          >
            Register Here
          </NavLink>
          <br />
        </div>
        <Paper className={styles.splashPanel}>
          <img
            className={styles.socialNotifyIcon}
            src="/img/partner/SocialNotify.png"
            height="125"
            alt="Distribute"
          />
          <br />
          <div className={styles.rightPanelContent}>
            <div className={styles.socialNotifyIcon}>
              <p>
                If you have already registered in the new platform, please log
                in.
              </p>
              <button
                id="welcomeLoginBtn"
                className={styles.orangeBtn}
                onClick={() => gotoLogin()}
              >
                Login
              </button>
            </div>
          </div>
        </Paper>
      </section>

      <section className={styles.partnerPackageGrid}>
        <div id="partnerPriceBoxBasic" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/cloud.png"
            alt="Basic Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Basic</h2>
          <h4 id="partnerPriceBasic" style={{ color: '#008480' }}>
            {formatUSD(105)}
          </h4>
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Increased Search Engine Visibility
          </Typography>
          <br />
          <div className={styles.partnerPricingBullets}>
            <PackageFeature feature="Unlimited Media Assets" />
            <PackageFeature
              feature="Increased Search Visibility"
              description="Relevant content keyword tagging by our expert editorial team"
            />
            <PackageFeature feature="48-hour turn around time" />
          </div>
        </div>

        <div id="partnerPriceBoxStandard" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/cloud.png"
            alt="Basic Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Standard</h2>
          <h4 id="partnerPriceBasic" style={{ color: '#008480' }}>
            {formatUSD(220)}
          </h4>
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Increased Online Visibility
          </Typography>
          <br />
          <div className={styles.partnerPricingBullets}>
            {getStandardPkgPoints()}
          </div>
        </div>

        <div id="partnerPriceBoxAdvanced" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/cloud.png"
            alt="Advanced Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Advanced</h2>
          <h4 id="partnerPriceAdvanced" style={{ color: '#008480' }}>
            {formatUSD(325)}
          </h4>
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Increased Influencer Visibility
          </Typography>
          <br />

          <div className={styles.partnerPricingBullets}>
            {getAdvancedPkgPoints()}
            <PackageFeature
              feature="Email distribution to ONE of our industry-curated journalist lists"
              description="Proofreading from our expert editorial staff included"
            />
            <PackageFeature feature="24-hour turn around time" />
          </div>
        </div>

        <div id="partnerPriceBoxPremium" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/starcloud.png"
            alt="Premium Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Premium</h2>
          <h4 id="partnerPricePremium" style={{ color: '#008480' }}>
            {formatUSD(435)}
          </h4>
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Largest Influencer Visibility
          </Typography>
          <br />

          <div className={styles.partnerPricingBullets}>
            {getAdvancedPkgPoints()}
            <PackageFeature
              feature="Email distribution to TWO of our industry-curated journalist lists"
              description="Proofreading from our expert editorial staff included"
            />
            <PackageFeature
              feature="Distribution to Sovrn's content recommended network"
              description="Connects your message with influential bloggers"
            />
            <PackageFeature feature="24-hour turn around time" />
          </div>
        </div>
      </section>

      <section className={styles.footer}>
        <p>
          {new Date().getFullYear()} © PRWeb
          <br />
          Cision US
        </p>
        <p>
          866-640-6397
          <br />
          <a href="https://service.prweb.com/contact-us/?nav_location=footer">
            Contact Us
          </a>
        </p>
        <p>
          <a href="https://www.prweb.com/">www.prweb.com</a>
          <br />
          <a href="https://www.cision.com/">www.cision.com</a>
        </p>
        <p>
          12051 Indian Creek Ct
          <br />
          Beltsville, MD 20705
        </p>
      </section>
    </div>
  );
};

export default Welcome;
