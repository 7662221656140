import React, { FC, useEffect } from 'react';

import { Button } from '@cision/rover-ui';

import styles from './additions-priority-panel.module.css';

interface Props {
  hasPriority: boolean;
  onAddPriority: () => void;
  onRemovePriority: () => void;
}

const AdditionsPriorityPanel: FC<Props> = ({
  hasPriority,
  onAddPriority,
  onRemovePriority,
}: Props) => {
  const [showAddButton, setShowAddButton] = React.useState(true);
  const [showRemoveButton, setShowRemoveButton] = React.useState(false);

  const addPriority = (): void => {
    onAddPriority();
    setShowAddButton(false);
    setShowRemoveButton(true);
  };

  const removePriority = (): void => {
    onRemovePriority();
    setShowAddButton(true);
    setShowRemoveButton(false);
  };

  useEffect(() => {
    setShowAddButton(!hasPriority);
    setShowRemoveButton(hasPriority);
  }, [hasPriority]);

  return (
    <div className={styles.cjlContainer}>
      {showAddButton && (
        <Button
          level="secondary"
          id={'add-Priority-button'}
          onClick={addPriority}
        >
          + Add to Release
        </Button>
      )}
      {showRemoveButton && (
        <Button
          level="primary"
          id={'remove-Priority-button'}
          style={{
            backgroundColor: '#ed7d31',
            borderColor: '#ed7d31',
          }}
          onClick={removePriority}
        >
          - Remove from Release
        </Button>
      )}
    </div>
  );
};
export default AdditionsPriorityPanel;
