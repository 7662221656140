import React, { useState, useEffect } from 'react';

import {
  Button,
  Modal,
  Typography,
  Pill,
  EasyDropdown,
  Checkbox,
} from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as CloseSvg } from '../../../../assets/close.svg';
import { PRWEB_CJL } from '../../../../constants';
import CacheService from '../../../../utils/cache-service';

import styles from './cjlmodal.module.css';

interface Props {
  config: PRWebConfig;
  addCJLSelected: (arg0: Array<PRWebIndustryOutletCatgory>) => void;
  toggle: (arg0: boolean) => void;
  isOpen: boolean;
  availableCjlCount: number;
  cjlSelected?: Array<PRWebIndustryOutletCatgory>;
}

interface CJLOption {
  id: number;
  label: string;
  selected: boolean;
}

const CJLModal = ({
  config,
  addCJLSelected,
  toggle,
  isOpen,
  availableCjlCount,
  cjlSelected,
}: Props) => {
  const [currentCJLSelected, setCurrentCJLSelected] = useState(cjlSelected);
  const [cjlOptions, setCjlOptions] = useState<Array<CJLOption>>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;
  const addButtonLabel =
    cjlSelected && cjlSelected.length > 0 ? 'Update' : 'Add';

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const cancelDropdown = () => {
    setIsDropdownOpen(false);
    setCurrentCJLSelected(cjlSelected || []);
  };

  const changeSelection = (idx: number) => () => {
    const selected = cjlOptions[idx];
    const isAdded = (currentCJLSelected || [])?.findIndex(
      item => item.id === selected.id,
    );

    if (currentCJLSelected && isAdded !== undefined && isAdded >= 0) {
      const tempSelection =
        currentCJLSelected?.filter(cjl => cjl.id !== selected.id) || [];
      setCurrentCJLSelected(tempSelection);
    } else if (currentCJLSelected) {
      setCurrentCJLSelected((prev: any) => [
        ...prev,
        {
          id: selected.id,
          categoryName: selected.label,
          prWebBrandId: 1,
          isActive: true,
        },
      ]);
    } else {
      setCurrentCJLSelected([
        {
          id: selected.id,
          categoryName: selected.label,
          prWebBrandId: 1,
          isActive: true,
        },
      ]);
    }
  };

  const close = () => {
    toggle(false);
  };

  const addSelection = () => {
    addCJLSelected(currentCJLSelected || []);
    close();
  };

  const removeCJL = (cjlId: number) => () => {
    const tempSelection =
      currentCJLSelected?.filter(cjl => cjl.id !== cjlId) || [];
    setCurrentCJLSelected(tempSelection);
  };

  const clean = () => {
    setCurrentCJLSelected([]);
  };

  useEffect(() => {
    const getOptions = () => {
      if (CacheService.exists(PRWEB_CJL)) {
        const cacheCJLs = CacheService.get(PRWEB_CJL) as Array<CJLOption>;

        cacheCJLs.forEach(
          cacheCJL =>
            (cacheCJL.selected = currentCJLSelected
              ? currentCJLSelected.some(cjl => cjl.id === cacheCJL.id)
              : false),
        );

        setCjlOptions(cacheCJLs);
      } else {
        const getCJLUrl = `${prwebApi}/addition/cjl`;

        axios
          .get(getCJLUrl, {})
          .then(response => {
            if (
              response &&
              (response.status === 200 || response.status === 201)
            ) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const cjls = response.data.map((responseCJL: any) => ({
                id: responseCJL.cisionJournalistListId,
                label: responseCJL.categoryName,
                selected: currentCJLSelected
                  ? currentCJLSelected.some(
                      cjl => cjl.id === responseCJL.cisionJournalistListId,
                    )
                  : false,
              }));

              CacheService.set(PRWEB_CJL, cjls);

              setCjlOptions(cjls);
            } else {
              console.log(
                'invalid response loading cache data: ',
                response,
                PRWEB_CJL,
              );
            }
          })
          .catch(error => {
            console.log('loadcache error retrieving data: ', error, PRWEB_CJL);
          });
      }
    };

    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCJLSelected]);

  useEffect(() => {
    if (isOpen) {
      setCurrentCJLSelected(cjlSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} className={styles.modalOverflow}>
      <Modal.Header>
        <Typography size="lg">Cision Journalist Lists</Typography>
      </Modal.Header>
      <Modal.Body className={`${styles.modalBody} ${styles.modalOverflow}`}>
        <div className={styles.center}>
          <Typography size="lg">Share your release</Typography>
        </div>
        <div className={styles.center}>
          <Typography size="lg" weight="bold">
            With journalists looking for news about your industry
          </Typography>
        </div>
        <div className={styles.selectContainer}>
          <Typography color="black" size="lg" weight="bold" tag="p">
            Cision Journalist Lists (Up to {availableCjlCount})
          </Typography>
          <div id={'cjl-selection-container'}>
            <EasyDropdown
              className={styles.cjlDropdown}
              isOpen={isDropdownOpen}
              toggleProps={{
                className: styles.cjlDropdownToggle,
              }}
              menuProps={{
                className: styles.cjlDropdownPane,
              }}
              menuItems={[
                ...cjlOptions.map((item, idx) => ({
                  children: (
                    <div className={styles.cjlDropdownContent}>
                      <Checkbox checked={item.selected} readOnly />
                      <span>{item.label}</span>
                    </div>
                  ),
                  label: item.label,
                  onClick: changeSelection(idx),
                })),
              ]}
            >
              <div>
                <Button onClick={toggleDropdown} level="primary">
                  {isDropdownOpen
                    ? 'Apply Selection'
                    : 'Select Cision Journalist Lists'}
                </Button>
                {isDropdownOpen && (
                  <Button onClick={cancelDropdown}>Cancel</Button>
                )}
              </div>
            </EasyDropdown>
            {isOpen && currentCJLSelected && currentCJLSelected.length > 0 && (
              <div>
                <div>
                  {currentCJLSelected.map((cjl, i) => {
                    return (
                      <Pill
                        key={i}
                        className={styles.cjlPill}
                        id={cjl.id.toString()}
                        onClick={removeCJL(cjl.id)}
                      >
                        <div className={styles.flexCenterAlign}>
                          {cjl.categoryName}
                          <CloseSvg width={20} height={20} />
                        </div>
                      </Pill>
                    );
                  })}
                  <Button level="link" onClick={clean}>
                    CLEAR SELECTIONS
                  </Button>
                </div>
                {currentCJLSelected.length > availableCjlCount && (
                  <div className={styles.errorMessage}>
                    You may not select more than {availableCjlCount} Cision
                    Journalist Lists.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.modalBtnContainer}>
        <Button level="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          level="primary"
          onClick={addSelection}
          disabled={
            currentCJLSelected && currentCJLSelected.length > availableCjlCount
          }
        >
          {addButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CJLModal;
