import React, { useState, ReactElement, useEffect } from 'react';

import { Paper, Typography, Checkbox } from '@cision/rover-ui';

import { ReactComponent as AmexSvg } from '../../../../assets/third-party/amex-logo.svg';
import { ReactComponent as DiscoverSvg } from '../../../../assets/third-party/discover-logo.svg';
import { ReactComponent as MCSvg } from '../../../../assets/third-party/mc-logo.svg';
import { ReactComponent as PayPalSvg } from '../../../../assets/third-party/PayPal.svg';
import { ReactComponent as VisaSvg } from '../../../../assets/third-party/visa-logo.svg';

import TextInputField from '../../../../components/text-input-field';
import PaymentValidator, {
  PaymentErrors,
} from '../../../../validators/PaymentValidator';

import styles from './payment-step.module.css';

interface PaymentFormProps {
  disabled: boolean;
  isCreditCard?: boolean | null;
  payment: Payment;
  onChange: (fieldName: string, value?: string) => void;
  validationErrors?: PaymentErrors;
}

const PaymentForm = ({
  disabled,
  isCreditCard = null,
  payment,
  onChange,
  validationErrors,
}: PaymentFormProps) => {
  const [frontEndFormErrorsValue, setFrontEndFormErrorsValue] = useState<
    PaymentErrors
  >(validationErrors ?? {});

  const renderFieldValidationIssue = (fieldErrors: string[]): ReactElement => {
    return (
      <ul className={styles.errorMessage}>
        {fieldErrors.map((err, i) => (
          <li key={i}>{err}</li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (Object.values(validationErrors ?? {}).length > 0) {
      setFrontEndFormErrorsValue(prevState => ({
        ...prevState,
        ...validationErrors,
      }));
    }
  }, [validationErrors]);

  const updateDataFieldByValue = (key: string, value?: string): void => {
    onChange(key, value ?? '');
    if (disabled) {
      setFrontEndFormErrorsValue({});
    } else {
      let result: string[] = [];
      switch (key) {
        case 'creditCardNumber':
          result = PaymentValidator.validateCreditCardNumber(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            creditCardNumberErrors: result ?? [],
          }));
          break;
        case 'creditCardExpiration':
          result = PaymentValidator.validateCreditCardExpiration(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            creditCardExpirationErrors: result ?? [],
          }));
          break;
        case 'creditCardCvv':
          result = PaymentValidator.validateCreditCardCvv(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            creditCardCvvErrors: result ?? [],
          }));
          break;
        case 'cardholderFirstName':
          result = PaymentValidator.validateCardholderFirstName(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderFirstNameErrors: result ?? [],
          }));
          break;
        case 'cardholderLastName':
          result = PaymentValidator.validateCardholderLastName(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderLastNameErrors: result ?? [],
          }));
          break;
        case 'cardholderAddress1':
          result = PaymentValidator.validateCardholderAddress1(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderAddress1Errors: result ?? [],
          }));
          break;
        case 'cardholderAddress2':
          result = PaymentValidator.validateCardholderAddress2(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderAddress2Errors: result ?? [],
          }));
          break;
        case 'cardholderCity':
          result = PaymentValidator.validateCardholderCity(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderCityErrors: result ?? [],
          }));
          break;
        case 'cardholderState':
          result = PaymentValidator.validateCardholderState(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderStateErrors: result ?? [],
          }));
          break;
        case 'cardholderZip':
          result = PaymentValidator.validateCardholderZip(value);
          setFrontEndFormErrorsValue(prevState => ({
            ...prevState,
            cardholderZipErrors: result ?? [],
          }));
          break;
      }
    }
  };

  const handleCheckboxClick = () => {
    onChange('isCreditCard');
  };

  return (
    <>
      <Paper className={styles.creditCardContainer}>
        {isCreditCard !== null && (
          <span className={styles.spaceRight}>
            <Checkbox
              id={'creditcard-checkbox'}
              height="50"
              width="50"
              checked={isCreditCard}
              disabled={disabled}
              onClick={handleCheckboxClick}
            ></Checkbox>
          </span>
        )}
        <AmexSvg width="23" height="24" />
        <MCSvg width="39" height="24" />
        <VisaSvg width="43" height="24" />
        <DiscoverSvg width="38" height="24" />
        {(isCreditCard === null || isCreditCard) && (
          <div className={styles.spaceTop}>
            <div className={styles.sectionTitle}>
              <Typography weight="bold" color="black">
                Credit Card and Billing Information
              </Typography>
            </div>
            <div className={styles.columns}>
              <div className={`${styles.columnGrow} ${styles.spaceRight}`}>
                <div>
                  <TextInputField
                    id={'creditCardNumber'}
                    label={'Credit Card Number'}
                    tabIndex={1}
                    disabled={disabled}
                    value={disabled ? '' : payment.creditCardNumber}
                    onChangeValue={updateDataFieldByValue}
                    validate={PaymentValidator.validateCreditCardNumber}
                    issues={
                      frontEndFormErrorsValue.creditCardNumberErrors || []
                    }
                  ></TextInputField>
                </div>
              </div>

              <div className={styles.columnGrow}>
                <div className={styles.columns}>
                  <div className={`${styles.smallInput} ${styles.spaceRight}`}>
                    <TextInputField
                      id={'creditCardExpiration'}
                      label={'Expiration'}
                      placeholder={'mm/yy'}
                      tabIndex={2}
                      disabled={disabled}
                      value={disabled ? '' : payment.creditCardExpiration}
                      onChangeValue={updateDataFieldByValue}
                      validate={PaymentValidator.validateCreditCardExpiration}
                      issues={[]}
                    ></TextInputField>
                  </div>

                  <div className={styles.smallInput}>
                    <TextInputField
                      id={'creditCardCvv'}
                      label={'CVV'}
                      tabIndex={3}
                      disabled={disabled}
                      value={disabled ? '' : payment.creditCardCvv}
                      onChangeValue={updateDataFieldByValue}
                      validate={PaymentValidator.validateCreditCardCvv}
                      issues={[]}
                    ></TextInputField>
                  </div>
                </div>
                {!disabled &&
                  frontEndFormErrorsValue.creditCardExpirationErrors &&
                  renderFieldValidationIssue(
                    frontEndFormErrorsValue.creditCardExpirationErrors,
                  )}

                {!disabled &&
                  frontEndFormErrorsValue.creditCardCvvErrors &&
                  renderFieldValidationIssue(
                    frontEndFormErrorsValue.creditCardCvvErrors,
                  )}
              </div>
            </div>

            <div className={styles.columns}>
              <div className={`${styles.columnGrow} ${styles.spaceRight}`}>
                <TextInputField
                  id={'cardholderFirstName'}
                  label={'First Name'}
                  tabIndex={4}
                  disabled={disabled}
                  value={disabled ? '' : payment.cardholderFirstName}
                  onChangeValue={updateDataFieldByValue}
                  validate={PaymentValidator.validateCardholderFirstName}
                  issues={
                    frontEndFormErrorsValue.cardholderFirstNameErrors || []
                  }
                ></TextInputField>
              </div>
              <div className={styles.columnGrow}>
                <TextInputField
                  id={'cardholderLastName'}
                  label={'Last Name'}
                  tabIndex={5}
                  disabled={disabled}
                  value={disabled ? '' : payment.cardholderLastName}
                  onChangeValue={updateDataFieldByValue}
                  validate={PaymentValidator.validateCardholderLastName}
                  issues={
                    frontEndFormErrorsValue.cardholderLastNameErrors || []
                  }
                ></TextInputField>
              </div>
            </div>

            <div className={styles.columns}>
              <div className={`${styles.columnGrow} ${styles.spaceRight}`}>
                <TextInputField
                  id={'cardholderAddress1'}
                  label={'Address 1'}
                  tabIndex={6}
                  disabled={disabled}
                  value={disabled ? '' : payment.cardholderAddress1}
                  onChangeValue={updateDataFieldByValue}
                  validate={PaymentValidator.validateCardholderAddress1}
                  issues={
                    frontEndFormErrorsValue.cardholderAddress1Errors || []
                  }
                ></TextInputField>
              </div>
              <div className={styles.columnGrow}>
                <TextInputField
                  id={'cardholderAddress2'}
                  label={'Address 2'}
                  tabIndex={7}
                  disabled={disabled}
                  value={disabled ? '' : payment.cardholderAddress2}
                  onChangeValue={updateDataFieldByValue}
                  validate={PaymentValidator.validateCardholderAddress2}
                  issues={
                    frontEndFormErrorsValue.cardholderAddress2Errors || []
                  }
                ></TextInputField>
              </div>
            </div>

            <div className={styles.columns}>
              <div className={`${styles.columnGrow} ${styles.spaceRight}`}>
                <TextInputField
                  id={'cardholderCity'}
                  label={'City'}
                  tabIndex={8}
                  disabled={disabled}
                  value={disabled ? '' : payment.cardholderCity}
                  onChangeValue={updateDataFieldByValue}
                  validate={PaymentValidator.validateCardholderCity}
                  issues={frontEndFormErrorsValue.cardholderCityErrors || []}
                ></TextInputField>
              </div>
              <div>
                <div className={`${styles.columnGrow} ${styles.columns}`}>
                  <div className={`${styles.columnGrow} ${styles.spaceRight}`}>
                    <TextInputField
                      id={'cardholderState'}
                      label={'State'}
                      tabIndex={9}
                      disabled={disabled}
                      value={disabled ? '' : payment.cardholderState}
                      onChangeValue={updateDataFieldByValue}
                      validate={PaymentValidator.validateCardholderState}
                      issues={[]}
                    ></TextInputField>
                  </div>
                  <div className={styles.columnGrow}>
                    <TextInputField
                      id={'cardholderZip'}
                      label={'Zip'}
                      tabIndex={10}
                      disabled={disabled}
                      value={disabled ? '' : payment.cardholderZip}
                      onChangeValue={updateDataFieldByValue}
                      validate={PaymentValidator.validateCardholderZip}
                      issues={[]}
                    ></TextInputField>
                  </div>
                </div>
                {!disabled &&
                  frontEndFormErrorsValue.cardholderStateErrors &&
                  renderFieldValidationIssue(
                    frontEndFormErrorsValue.cardholderStateErrors,
                  )}
                {!disabled &&
                  frontEndFormErrorsValue.cardholderZipErrors &&
                  renderFieldValidationIssue(
                    frontEndFormErrorsValue.cardholderZipErrors,
                  )}
              </div>
            </div>
          </div>
        )}
      </Paper>
      {isCreditCard !== null && (
        <Paper className={styles.creditCardContainer}>
          <span className={styles.spaceRight}>
            <Checkbox
              id={'paypal-checkbox'}
              disabled={disabled}
              checked={!isCreditCard}
              onClick={handleCheckboxClick}
            ></Checkbox>
          </span>
          <PayPalSvg width="100" height="24" />
          {!isCreditCard && (
            <div className={styles.spaceTop}>
              {!disabled && (
                <Typography>
                  Click the &quot;PayPal button&quot; to be taken to PayPal to
                  complete your payment.
                </Typography>
              )}
            </div>
          )}
        </Paper>
      )}
    </>
  );
};
export default PaymentForm;
