import * as React from 'react';

import cookie from 'js-cookie';

import {
  USER_GROUP_COOKIE,
  ADMIN_GROUP,
  FINANCE_GROUP,
  VPO_GROUP,
  EDITOR_GROUP,
} from '../../constants';
import PRWebFeatures from '../../utils/PRWebFeatures';
import Menu from '../menu';

import styles from './header.module.css';

interface Props {
  prwebApiUrl?: string;
}

const Header: React.FC<Props> = (args?: Props) => {
  const prwebApiUrl = args?.prwebApiUrl;

  const links: Array<{ name: string; to: string }> = [
    { name: 'My Releases', to: '/' },
    { name: 'Store', to: '/store' },
    { name: 'Settings', to: '/settings/account' },
  ];

  if (!PRWebFeatures.enableBundleStorePage()) {
    links.splice(1, 1);
  }

  const adminTabCookie = cookie.get(USER_GROUP_COOKIE);
  if (adminTabCookie === ADMIN_GROUP || adminTabCookie === EDITOR_GROUP) {
    links.push({ name: 'Admin', to: '/admin/users' });
  } else if (adminTabCookie === FINANCE_GROUP) {
    links.push({ name: 'Admin', to: '/admin/orders' });
  } else if (adminTabCookie === VPO_GROUP) {
    links.push({ name: 'Admin', to: '/admin/partners' });
  }

  const newProps = {
    links,
    prwebApiUrl,
  };

  return (
    <div className={styles.navbarContainer}>
      <Menu {...newProps} />
    </div>
  );
};

export default Header;
