import React, { useEffect, useContext, useCallback, useState } from 'react';

import { Typography, Button } from '@cision/rover-ui';

import Confirm from '../../../components/confirm';
import PackageCard from '../../../components/package-card';
import WizardContext from '../../../components/wizard/wizard-context';
import {
  BASIC_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
} from '../../../constants';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import DistributionWizardContext from '../DistributionWizardContext';
import PRWebPackages from '../PRWebPackages';

import styles from './styles/select-package-step.module.css';
import './styles/SelectPackageStep.scss';

const SelectPackageStep: React.FC = () => {
  const { distributionData, updateDistributionData } = useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [prevPackage, setPrevPackage] = useState('');
  const REMOVE_MESSAGE = `Changing the package may affect: <ul style="text-align: left">%MESSAGES%</ul> Do you wish to proceed?`;
  const VIDEO_REMOVE_MESSAGE = `<li style="list-style-position: inside;">Video</li>`;
  const NEWS_IMAGE_REMOVE_MESSAGE = `<li style="list-style-position: inside;">News Image</li>`;
  const SELECTED_CJL_REMOVE_MESSAGE = `<li style="list-style-position: inside;">Selected Cision Journalist Lists</li>`;
  const TEXT_EDITOR_REMOVE_MESSAGE = `<li style="list-style-position: inside;">Advanced Text Editor</li>`;
  const PRIORITY_RELEASE_REMOVE_MESSAGE = `<li style="list-style-position: inside;">Priority to distribute release</li>`;

  const basicPackage = PRWebPackages.getPRWebPackage(
    BASIC_PACKAGE_ID,
  ) as PRWebPackage;
  const standardPackage = PRWebPackages.getPRWebPackage(
    STANDARD_PACKAGE_ID,
  ) as PRWebPackage;
  const advancedPackage = PRWebPackages.getPRWebPackage(
    ADVANCED_PACKAGE_ID,
  ) as PRWebPackage;
  const premiumPackage = PRWebPackages.getPRWebPackage(
    PREMIUM_PACKAGE_ID,
  ) as PRWebPackage;

  const setPRWebPackage = (prwebPackage: string) => {
    const prevPackage = distributionData.package;
    const releaseDate =
      distributionData.distributionId !== undefined &&
      distributionData.distributionId === 0 &&
      (prevPackage !== distributionData.package || prevPackage === '')
        ? PRWebPackages.getPackageBasedMinTurnaroundDate(prwebPackage)
            .add(1, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss')
        : distributionData.releaseDate;

    updateDistributionData({
      ...distributionData,
      package: prwebPackage,
      releaseDate,
    });
    resetDistribution(prevPackage, prwebPackage);
  };

  const resetDistribution = (prevPackage: string, newPackage: string) => {
    if (
      PRWebPackages.isHigherPackage(prevPackage) &&
      PRWebPackages.isLowerPackage(newPackage)
    ) {
      let message = REMOVE_MESSAGE;
      setPrevPackage(prevPackage);
      message = message.replace(
        '%MESSAGES%',
        VIDEO_REMOVE_MESSAGE +
          NEWS_IMAGE_REMOVE_MESSAGE +
          SELECTED_CJL_REMOVE_MESSAGE +
          TEXT_EDITOR_REMOVE_MESSAGE +
          PRIORITY_RELEASE_REMOVE_MESSAGE,
      );
      confirmResetDistribution(message);
    } else if (
      PRWebPackages.isPremiumPackage(prevPackage) &&
      PRWebPackages.isAdvancedPackage(newPackage)
    ) {
      let message = REMOVE_MESSAGE;
      setPrevPackage(prevPackage);
      message = message.replace(
        '%MESSAGES%',
        VIDEO_REMOVE_MESSAGE + SELECTED_CJL_REMOVE_MESSAGE,
      );
      confirmResetDistribution(message);
    }
  };

  const confirmResetDistribution = (message: string) => {
    setConfirmMessage(message);
    setToggleConfirm(true);
  };

  const onConfirmYes = () => {
    updateDistributionData({
      ...distributionData,
      newsImage: PRWebPackages.isLowerPackage(distributionData.package)
        ? undefined
        : distributionData.newsImage,
      cjlSelected: undefined,
      videoUrl: PRWebPackages.isPremiumPackage(distributionData.package)
        ? distributionData.videoUrl
        : '',
      hasPriorityRelease: false,
    });
    setToggleConfirm(false);
  };

  const onConfirmNo = () => {
    setToggleConfirm(false);
    updateDistributionData({ ...distributionData, package: prevPackage });
  };

  // Update progress
  useEffect(() => {
    if (!distributionData.package) {
      return;
    }

    const selectedPackage = PRWebPackages.getPRWebPackage(
      distributionData.package,
    );

    const stepValuesArray: string[] = selectedPackage
      ? [selectedPackage.name]
      : [];

    distributionData.order = {
      ...distributionData.order,
      package: selectedPackage?.packageId,
    };

    wizardContext.setProgressWhileEditing(stepValuesArray);

    wizardContext.setStepIsValid(distributionData.package ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionData.package]);

  const getBasicPkg = () => {
    if (basicPackage) {
      return (
        <Button
          id={'select-basic-button'}
          level="secondary"
          onClick={(): void => setPRWebPackage(basicPackage.packageId)}
          active={
            distributionData &&
            distributionData.package === basicPackage.packageId
          }
          disabled={distributionData && distributionData.hasPaid}
        >
          Select Basic
        </Button>
      );
    }
  };

  const getStandardPkg = () => {
    if (standardPackage) {
      return (
        <Button
          id={'select-standard-button'}
          level="secondary"
          onClick={(): void => setPRWebPackage(standardPackage.packageId)}
          active={
            distributionData &&
            distributionData.package === standardPackage.packageId
          }
          disabled={distributionData && distributionData.hasPaid}
        >
          Select Standard
        </Button>
      );
    }
  };

  return (
    <div>
      <div className={styles.stepContainer}>
        <div>
          <div className={styles.stepTitle}>
            <Typography size="xl2" color="black" weight="bold">
              Select Your Package
            </Typography>
          </div>
          <div className={styles.stepSubtitle}>
            <Typography className={styles.font16}>
              <span className={styles.font16}>
                Choose an enhanced package and boost your online visibility
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.packagesContainer} id={'packageContainer'}>
          <PackageCard>
            {{
              basic: getBasicPkg(),
              standard: getStandardPkg(),
              advanced: (
                <Button
                  id={'select-advanced-button'}
                  level="secondary"
                  disabled={distributionData && distributionData.hasPaid}
                  onClick={(): void =>
                    setPRWebPackage(advancedPackage.packageId)
                  }
                  active={
                    distributionData &&
                    distributionData.package === advancedPackage.packageId
                  }
                >
                  Select Advanced
                </Button>
              ),
              premium: (
                <Button
                  id={'select-premium-button'}
                  disabled={distributionData && distributionData.hasPaid}
                  className={styles.premiumPackageButton}
                  level="secondary"
                  onClick={(): void =>
                    setPRWebPackage(premiumPackage.packageId)
                  }
                  active={
                    distributionData &&
                    distributionData.package === premiumPackage.packageId
                  }
                >
                  Select Premium
                </Button>
              ),
            }}
          </PackageCard>
        </div>
      </div>
      <Confirm
        isOpen={toggleConfirm}
        text={confirmMessage}
        yes={onConfirmYes}
        no={onConfirmNo}
      ></Confirm>
      <ExitWizardPrompt />
    </div>
  );
};

export default SelectPackageStep;
