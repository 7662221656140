import React, { useState } from 'react';

import UpgradeWizardContext from './UpgradeWizardContext';

interface Props {
  children: React.ReactNode;
  distro?: Upgrade;
  onHold?: string[];
}

function UpgradeWizardProvider({ children, distro, onHold }: Props) {
  const [state, setState] = useState<Upgrade>({
    orderNumber: distro?.orderNumber ?? '',
    transactionId: distro?.transactionId ?? '',
    distributionId: distro?.distributionId ?? 0,
    prwebPressReleaseId: distro?.prwebPressReleaseId ?? 0,
    package: distro?.package ?? '',
    initialPackage: distro?.package ?? '',
    releaseDate: distro?.releaseDate ?? '',
    priority: distro?.priority ?? false,
    initialPriority: distro?.initialPriority ?? false,
  });
  // console.trace('default state.releaseDate', state.releaseDate);

  return (
    <UpgradeWizardContext.Provider
      value={{
        upgradeData: state,
        updateUpgradeData: updatedState => {
          setState((prevState: Upgrade) => {
            return { ...prevState, ...updatedState };
          });
        },
        // For situations when the app have a partial distribution object and only want to update certain attributes.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateUpgradeByField: (topLevelKey: string, value: any) => {
          setState((prevState: Upgrade) => {
            return {
              ...prevState,
              [topLevelKey]: value,
            };
          });
        },
      }}
    >
      {children}
    </UpgradeWizardContext.Provider>
  );
}

export default UpgradeWizardProvider;
