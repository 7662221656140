import Validator, { Validation } from './GenericValidator';

const InvalidMsg = {
  couponCodeValidation: {
    required: () => 'Please provide a universal coupon code',
    maxLength: max => `Your coupon code cannot exceed ${max} characters.`,
    noSpaces: () => 'Your coupon code cannot have spaces.',
    noHtml: () => 'Your coupon code cannot have html markup.',
  } as Validation,
  couponAmountValidation: {
    required: () => 'Please provide a coupon amount.',
    isNumeric: () => 'Your coupon amount must be in digits.',
    isBetween: () => `Your coupon amount must be greater than zero.`,
    twoDecimalPlaces: () =>
      `Your coupon amount must be up to two decimal places`,
  } as Validation,
  couponPercentValidation: {
    required: () => 'Please provide a coupon percent.',
    isNumeric: () => 'Your coupon percent must be in digits.',
    isBetween: (start: string, end: string) =>
      `Your coupon percent must be between ${start} and ${end} exclusive.`,
    twoDecimalPlaces: () =>
      `Your coupon amount must be up to two decimal places`,
  } as Validation,
  expirationDateValidaiton: {
    isAfterOrSame: () =>
      'Your coupon expiration date must be after start date.',
  } as Validation,
  notesValidation: {
    noHtml: () => 'Your coupon notes cannot have html markup.',
  } as Validation,
};

export interface CouponDetails {
  couponCode: string;
  couponAmount: string;
  couponIsPercent: boolean;
  couponStartDate: string;
  couponExpirationDate: string;
  couponNotes: string;
}

export interface CouponDetailsErrors {
  couponCodeErrors: Array<string>;
  couponAmountErrors: Array<string>;
  couponExpirationDateErrors: Array<string>;
  couponNotesErrors: Array<string>;
}

export const validateCouponCode = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.couponCodeValidation })
    .required()
    .noSpaces()
    .maxLength(36)
    .noHtml().results;

export const validateCouponAmount = (isPercent: boolean) => (
  value?: string,
): Array<string> => {
  if (isPercent) {
    return Validator({ value, invalidMsg: InvalidMsg.couponPercentValidation })
      .required()
      .isNumeric()
      .twoDecimalPlaces()
      .isBetween(0, 100).results;
  } else {
    return Validator({ value, invalidMsg: InvalidMsg.couponAmountValidation })
      .required()
      .isNumeric()
      .twoDecimalPlaces()
      .isBetween(0, Number.MAX_SAFE_INTEGER).results;
  }
};

export const validateExpirationDate = (
  value?: string,
  dateStr?: string,
): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.expirationDateValidaiton })
    .required()
    .isAfterOrSame(dateStr).results;

export const validateCouponNotes = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.notesValidation }).noHtml().results;

export const validateCouponDetails = (formDetails: CouponDetails) => {
  const couponCodeErrors = validateCouponCode(formDetails.couponCode);
  const couponAmountErrors = validateCouponAmount(formDetails.couponIsPercent)(
    formDetails.couponAmount,
  );
  const couponNotesErrors = validateCouponNotes(formDetails.couponNotes);
  const couponExpirationDateErrors = validateExpirationDate(
    formDetails.couponExpirationDate,
    formDetails.couponStartDate,
  );

  const numErrors =
    couponCodeErrors.length +
    couponAmountErrors.length +
    couponExpirationDateErrors.length +
    couponNotesErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    couponCodeErrors,
    couponAmountErrors,
    couponExpirationDateErrors,
    couponNotesErrors,
  };
};
