import React, { useEffect, useContext } from 'react';

import { Paper, Typography } from '@cision/rover-ui';

import WizardContext from '../../../components/wizard/wizard-context';
import {
  ADVANCED_PACKAGE_ID,
  CJL_PRICE,
  CSP_PRICE,
  PREMIUM_PACKAGE_ID,
  PRIORITY_PRICE,
} from '../../../constants';
import AdditionsCJLPanel from '../components/additions-cjl-panel';
import AdditionsCSPPanel from '../components/additions-csp-panel';
import AdditionsPriorityPanel from '../components/additions-priority-panel';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import DistributionWizardContext from '../DistributionWizardContext';
import PRWebPackages from '../PRWebPackages';

import styles from './styles/additions-step.module.css';

const AddOnStep: React.FC = () => {
  const { distributionData, updateDistributionData } = useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);

  const currentPackage = PRWebPackages.getPRWebPackageWithAbilities(
    distributionData.package,
  );

  const showPriorityOption =
    currentPackage?.packageId === ADVANCED_PACKAGE_ID ||
    currentPackage?.packageId === PREMIUM_PACKAGE_ID;

  const addCJL = (cjl: number) => {
    updateDistributionData({
      ...distributionData,
      cjl,
    });
  };

  const removeCJL = () => {
    updateDistributionData({
      ...distributionData,
      cjl: undefined,
      cjlSelected: undefined,
    });
  };

  const addCSP = () => {
    updateDistributionData({
      ...distributionData,
      hasCisionSocialPost: true,
    });
  };

  const removeCSP = () => {
    updateDistributionData({
      ...distributionData,
      hasCisionSocialPost: false,
      cisionSocialPost: '',
    });
  };

  const addPriority = () => {
    updateDistributionData({
      ...distributionData,
      hasPriorityRelease: true,
    });
  };

  const removePriority = () => {
    updateDistributionData({
      ...distributionData,
      hasPriorityRelease: false,
    });
  };

  useEffect(() => {
    wizardContext.setStepIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update progress
  useEffect(() => {
    if (
      !distributionData.cjl &&
      !distributionData.hasCisionSocialPost &&
      !distributionData.hasPriorityRelease
    ) {
      wizardContext.setProgressWhileEditing([]);

      distributionData.order = {
        ...distributionData.order,
        cjl: undefined,
        hasCisionSocialPost: false,
        priorityReleaseEnabled: false,
      };

      return;
    }

    const stepValuesArray: string[] = [];

    if (distributionData.cjl && distributionData.cjl > 0) {
      stepValuesArray.push(`CJL ${distributionData.cjl}`);

      distributionData.order = {
        ...distributionData.order,
        cjl: distributionData.cjl,
      };
    }

    if (distributionData.hasCisionSocialPost) {
      stepValuesArray.push('SocialPost');

      distributionData.order = {
        ...distributionData.order,
        hasCisionSocialPost: true,
      };
    }

    if (distributionData.hasPriorityRelease) {
      stepValuesArray.push('Priority Distribution');

      distributionData.order = {
        ...distributionData.order,
        priorityReleaseEnabled: true,
      };
    }

    wizardContext.setProgressWhileEditing(stepValuesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    distributionData.cjl,
    distributionData.hasCisionSocialPost,
    distributionData.hasPriorityRelease,
  ]);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Additions (Optional)
          </Typography>
        </div>
      </div>
      <div>
        <Paper className={styles.addonPaper}>
          <div className={styles.columnContainer}>
            <div className={styles.leftColumn}>
              <div>
                <Typography size="lg" color="black" weight="bold">
                  <span>Purchase more Cision Journalist Lists</span>
                </Typography>
              </div>
              <div>
                <Typography>
                  <p>
                    Increase your potential for earned media picked up by
                    including additional industry specific journalist list ($
                    {CJL_PRICE} / list)
                  </p>
                </Typography>
              </div>
              {PRWebPackages.isAdvancedPackage(distributionData.package) && (
                <div>
                  <Typography>
                    <strong>
                      The Advanced package already comes with 1 free list!
                    </strong>
                  </Typography>
                </div>
              )}
              {PRWebPackages.isPremiumPackage(distributionData.package) && (
                <div>
                  <Typography>
                    <strong>
                      The Premium package already comes with 2 free lists!
                    </strong>
                  </Typography>
                </div>
              )}
            </div>
            <div className={styles.rightColumn}>
              <AdditionsCJLPanel
                cjl={distributionData.cjl}
                onAddCJL={addCJL}
                onRemoveCJL={removeCJL}
                isAdvancedPackage={PRWebPackages.isAdvancedPackage(
                  distributionData.package,
                )}
                isPremiunPackage={PRWebPackages.isPremiumPackage(
                  distributionData.package,
                )}
              ></AdditionsCJLPanel>
            </div>
          </div>
        </Paper>
        {showPriorityOption && (
          <Paper className={styles.addonPaper}>
            <div className={styles.columnContainer}>
              <div className={styles.leftColumn}>
                <div>
                  <Typography size="lg" color="black" weight="bold">
                    <span>Priority Distribution</span>
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <p>
                      Add Priority to distribute your release within 4 hours ($
                      {PRIORITY_PRICE})
                    </p>
                  </Typography>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <AdditionsPriorityPanel
                  hasPriority={distributionData.hasPriorityRelease}
                  onAddPriority={addPriority}
                  onRemovePriority={removePriority}
                ></AdditionsPriorityPanel>
              </div>
            </div>
          </Paper>
        )}
      </div>
      <ExitWizardPrompt />
    </div>
  );
};

export default AddOnStep;
