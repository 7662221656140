import React, { useEffect, useState } from 'react';

import { Loader, Paper } from '@cision/rover-ui';
import { Stripe } from '@stripe/stripe-js';
import axios from 'axios';

import WizardContext from '../../components/wizard/wizard-context';
import HttpStatusCode from '../../httpStatusCodes';
import PaymentForm from '../../pages/distribution-wizard/steps/Payment/PaymentForm';
import PaymentValidator, {
  PaymentErrors,
} from '../../validators/PaymentValidator';

import BundleSummary from './BundleSummary';
import styles from './store-payment-steps.module.css';

interface Props {
  config: PRWebConfig;
  payment: Payment;
  paymentChangeHandler: (field: string, value?: string) => void;
  storeCart: BundlePackagePrice[];
  setOrderNumberHandler: React.Dispatch<React.SetStateAction<string>>;
  setBundleOrderId: React.Dispatch<React.SetStateAction<number>>;
  stripePromise: Promise<Stripe | null>;
}

interface BundleOrderApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface SubmissionError {
  response: {
    status: number;
    data: BundleOrderApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const StorePaymentStep = ({
  config,
  payment,
  paymentChangeHandler,
  storeCart,
  setOrderNumberHandler,
  setBundleOrderId,
  stripePromise,
}: Props) => {
  const wizardData = React.useContext(WizardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedTermsAndCond, setCheckedTermsAndCond] = useState(false);
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);

  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [paymentFormErrors, setPaymentFormErrors] = useState<PaymentErrors>({});

  const handleSubmitError = (error: SubmissionError): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  const handleSubmit = async (): Promise<boolean> => {
    let paymentSuccessful = false;
    if (wizardData.loading) return false;
    wizardData.setLoading(true);
    if (isHandlingPayment) return false;

    const validationIssues = PaymentValidator.validatePaymentData({
      ...payment,
    });
    if (!validationIssues.valid) {
      setPaymentFormErrors(validationIssues);
      wizardData.setLoading(false);
      return false;
    }
    if (storeCart.length < 1) {
      wizardData.setLoading(false);
      return false;
    }

    setIsHandlingPayment(true);

    const source = axios.CancelToken.source();
    const getStoreBundlesUrl = `${config.prwebApi.url}/bundleorder/pay`;
    const paymentData = {
      creditCard: payment,
      cart: storeCart,
    };
    await axios
      .post(getStoreBundlesUrl, paymentData)
      .then(response => {
        if (
          response.status === HttpStatusCode.CREATED &&
          response.data.OrderNumber &&
          response.data.BundleOrderId
        ) {
          setBundleOrderId(response.data.BundleOrderId);
          setOrderNumberHandler(response.data.OrderNumber);
          paymentSuccessful = true;
        } else {
          handleSubmitError(response.data);
        }
      })
      .catch(error => {
        source.cancel();
        handleSubmitError(error);
      })
      .finally(() => {
        setIsHandlingPayment(false);
        setIsLoading(false);
        wizardData.setLoading(false);
      });

    return paymentSuccessful;
  };

  const updateFieldValue = (fieldName: string, value?: string) => {
    paymentChangeHandler(fieldName, value);
  };

  const handleTermsAndConditions = () => {
    setCheckedTermsAndCond((prev: boolean) => {
      wizardData.setStepIsValid(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    wizardData.setCanMoveStepForward(handleSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payment,
    payment.cardholderAddress1,
    payment.cardholderAddress2,
    payment.cardholderFirstName,
    payment.cardholderLastName,
    payment.cardholderState,
    payment.cardholderCity,
    payment.cardholderZip,
    payment.creditCardNumber,
    payment.creditCardCvv,
    payment.creditCardExpiration,
  ]);

  useEffect(() => {
    wizardData.setProgressWhileEditingState({
      ...wizardData.progressWhileEditingState,
      position: 1,
    });
    wizardData.setCanMoveStepForward(handleSubmit);
    wizardData.setStepIsValid(checkedTermsAndCond);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isHandlingPayment || isLoading) && <Loader />}
      <div className={styles.storePaymentStepContainer}>
        <div className={styles.storePaymentForm}>
          {formErrorSummaryValue.length > 0 && (
            <div className={styles.errorMessage}>
              Error submitting bundle order:
              <ul>
                {formErrorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          <PaymentForm
            disabled={false}
            payment={payment}
            onChange={updateFieldValue}
            validationErrors={paymentFormErrors}
          />
        </div>
        <div className={styles.storePaymentSummary}>
          <BundleSummary storeCart={storeCart}></BundleSummary>
          <div className={styles.termsAndConditions}>
            <input
              id={'termsAndConditionsCheckbox'}
              type="checkbox"
              checked={checkedTermsAndCond}
              onClick={handleTermsAndConditions}
            />
            <label htmlFor="termsAndConditionsCheckbox">
              I acknowledge that I have read and accepted the PRWeb{' '}
              <a
                href={'https://service.prweb.com/legal/terms-of-service/'}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://service.prweb.com/legal/privacy-policy/'}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                Privacy Notice
              </a>
              .
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorePaymentStep;
