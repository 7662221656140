/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useRef, useLayoutEffect } from 'react';

import { Paper, Input, Typography } from '@cision/rover-ui';
import moment from 'moment-timezone';
import { DayPicker } from 'react-day-picker';

import useClickOutside from '../../utils/useClickOutside';

import styles from './date-picker.module.css';

const maxDate = '12/31/2999'; // max date to be allowed

interface DatePickerProps {
  id?: string;
  label?: string;
  date?: moment.Moment;
  onChange?: (date: moment.Moment) => void;
}

function DatePicker(props: DatePickerProps) {
  // https://github.com/gpbl/react-day-picker/blob/main/packages/react-day-picker/src/types/Styles.ts
  const styleOverride = {
    root: styles.container,
    nav: styles.navBar,
    nav_button_previous: styles.navButtonPrev,
    nav_button_next: styles.navButtonNext,
    months: styles.months,
    month: styles.month,
    caption: styles.caption,
    weeknumber: styles.weekNumber,
    tbody: styles.body,
    week: styles.week,
    day: styles.day,
    cell: styles.cell,
    tfooter: styles.footer,
    day_today: styles.today,
    day_selected: styles.selected,
    day_disabled: styles.disabled,
    day_outside: styles.outside,
  } as any;

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(),
  );
  const [dateString, setDateString] = useState('');
  const [inputErrors, setInputErrors] = useState('');
  const wrapperRef = useRef(null);

  const inputFocus = () => {
    if (!showCalendar) {
      setShowCalendar(true);
    }
  };

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let userInput = event.target.value;

    if (!moment.utc(userInput).isValid()) {
      setInputErrors('Invalid date format');
    } else {
      setInputErrors('');
    }

    if (moment.utc(userInput).valueOf() > moment.utc(maxDate).valueOf()) {
      userInput = maxDate;
    }
    if (moment.utc(userInput).isValid()) {
      setSelectedDate(moment(userInput).toDate());
    }
    setDateString(userInput);
  };

  const inputBlur = () => {
    const newDate = moment.utc(selectedDate?.valueOf());
    if (newDate.isValid() && props.onChange) {
      setDateString(newDate.format('MM/DD/YYYY'));
      props.onChange(newDate);
      setInputErrors('');
    }
  };

  const clickOutsideBlur = () => {
    setTimeout(() => {
      setShowCalendar(false);
    }, 1);
  };
  useClickOutside(clickOutsideBlur, wrapperRef);

  const changeCalendarDate = (date: Date) => {
    if (!props.onChange) {
      return;
    }
    let newDate = moment.utc(date?.valueOf());
    if (newDate.valueOf() > moment.utc(maxDate).valueOf()) {
      newDate = moment.utc(maxDate);
    }
    setDateString(newDate.format('MM/DD/YYYY'));
    setSelectedDate(newDate.toDate());
    props.onChange(newDate);
    setShowCalendar(false);
    if (inputErrors) {
      setInputErrors('');
    }
  };

  useLayoutEffect(() => {
    if (props.date && moment.utc(props.date).isValid()) {
      setSelectedDate(moment.utc(props.date).toDate());
      setDateString(moment.utc(props.date).format('MM/DD/YYYY'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  return (
    <div ref={wrapperRef}>
      {props.label && (
        <Typography
          className={styles.formLabel}
          size="md"
          tag="label"
          htmlFor={'input-' + (props.id ? `-${props.id}` : '')}
        >
          {props.label}
        </Typography>
      )}
      <Input
        id={'input-' + (props.id ? `-${props.id}` : '')}
        className={styles.calendarInput}
        value={dateString}
        onFocus={inputFocus}
        onBlur={inputBlur}
        onChange={inputChange}
      />
      {inputErrors && (
        <Typography className={styles.errorMsg}>{inputErrors}</Typography>
      )}
      {showCalendar && (
        <Paper className={styles.calendarContainer}>
          <DayPicker
            mode="single"
            classNames={styleOverride}
            defaultMonth={selectedDate ? selectedDate : undefined}
            selected={selectedDate}
            onSelect={setSelectedDate}
            onDayClick={changeCalendarDate}
          />
        </Paper>
      )}
    </div>
  );
}

export default DatePicker;
