import React, { useState, useEffect } from 'react';

import { Button, Loader, Modal } from '@cision/rover-ui';
import axios from 'axios';

import TextInputField from '../../../../components/text-input-field';
import NewsImageValidator, {
  validateNewsImage,
  NewsImageValidation,
  NewsImageErrors,
} from '../../../../validators/NewsImageDistributionValidator';

import styles from './featured-image-modal.module.css';

interface Props {
  config: PRWebConfig;
  addNewsImage: (arg0: NewsImage) => void;
  toggle: (arg0: boolean) => void;
  isOpen: boolean;
  newsImage?: NewsImage;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface C3File {
  Size: number;
  Path: string;
  Name: string;
  Base64Preview: string;
  AssetID?: number;
  UploadKey?: string;
}

const FeaturedImageModal = ({
  config,
  addNewsImage,
  toggle,
  isOpen,
  newsImage,
}: Props) => {
  const [caption, setCaption] = useState('');
  const [altText, setAltText] = useState('');
  const [c3File, setC3File] = useState<C3File>();
  const [isLoading, setIsLoading] = useState(false);
  const [showInputFile, setshowInputFile] = useState(true);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbnailAlt, setThumbnailAlt] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [frontEndFormErrorsValue, setFrontEndFormErrorsValue] = useState<
    NewsImageErrors
  >();
  const [saveClickedOnce, setSaveClickedOnce] = useState(false);

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const getNewsImageValidation = (data: NewsImage): NewsImageValidation => {
    return validateNewsImage(data);
  };

  const getNewsImageFromState = (): NewsImage => {
    return {
      caption,
      altText,
      fileName: c3File?.Name || '',
      filePath: c3File?.Path || '',
      fileSize: c3File?.Size || 0,
      base64Preview: c3File?.Base64Preview || '',
      assetId: c3File?.AssetID || 0,
      uploadKey: c3File?.UploadKey || undefined,
    };
  };

  const confirmHandler = () => {
    const newsImage: NewsImage = getNewsImageFromState();
    const newsImageValidation = getNewsImageValidation(newsImage);
    setSaveClickedOnce(true);

    if (newsImageValidation.valid) {
      addNewsImage(newsImage);
      close();
    }
  };

  const handleErrors = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (
      (error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 404 ||
        error.response.status === 413) &&
      error.response.data.errors
    ) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  const changeNewsImageFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setFormErrorSummaryValue([]);
    setIsLoading(true);
    setThumbnailSrc('');
    setThumbnailAlt('');
    setShowThumbnail(false);
    setFormIsValid(false);

    const files = (event && event.target && event.target.files) || [];
    const file = files.length === 1 ? files[0] : null;

    if (!file) {
      return;
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('myFile', file);

    try {
      const postResponse = await axios.post(
        `${prwebApi}/file`,
        formData,
        config,
      );

      setC3File(postResponse.data as C3File);
      setThumbnailSrc(postResponse.data.Base64Preview);
      setThumbnailAlt(postResponse.data.Name);
      setShowThumbnail(true);
      setIsLoading(false);
      setFormIsValid(true);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateField = (key: string, value = '') => {
    if (key === 'caption') {
      setCaption(value);
    }
  };

  const close = () => {
    toggle(false);
  };

  useEffect(() => {
    const NewsImage = getNewsImageFromState();
    if (saveClickedOnce) {
      setFrontEndFormErrorsValue(validateNewsImage(NewsImage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption, c3File, saveClickedOnce]);

  useEffect(() => {
    const loadNewsImage = async (): Promise<void> => {
      if (newsImage) {
        setshowInputFile(false);
        setCaption(newsImage.caption);
        setAltText(newsImage.altText);
        setThumbnailSrc(newsImage.base64Preview || '');
        setThumbnailAlt(newsImage.fileName);
        setFormIsValid(true);
        setShowThumbnail(true);
        const file: C3File = {
          Name: newsImage.fileName || '',
          Path: newsImage.filePath || '',
          Size: newsImage.fileSize || 0,
          Base64Preview: newsImage.base64Preview || '',
          AssetID: newsImage.assetId || 0,
          UploadKey: newsImage.uploadKey || undefined,
        };
        setC3File(file);
      } else {
        setshowInputFile(true);
        setCaption('');
        setAltText('');
        setThumbnailSrc('');
        setThumbnailAlt('');
        setFormIsValid(false);
      }
    };

    loadNewsImage();
  }, [newsImage]);

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>Release Featured Image</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <br />
        <div>{isLoading && <Loader />}</div>
        <div>
          {showInputFile && (
            <input
              id="NewsImage-input-file"
              type="file"
              accept="image/png, image/x-png, image/jpeg, image/gif, .jpg, .jpeg, .gif, .png"
              onChange={changeNewsImageFileInput}
            />
          )}
          {showThumbnail && (
            <img
              src={thumbnailSrc}
              alt={thumbnailAlt}
              className={styles.thumbnail}
            />
          )}
        </div>
        {formErrorSummaryValue.length ? (
          <div className={styles.errorMessage}>
            Error uploading file
            <ul>
              {formErrorSummaryValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        ) : (
          undefined
        )}
        <br />
        <div>
          <TextInputField
            label={'Caption'}
            id={'caption'}
            placeholder={''}
            onChangeValue={updateField}
            value={caption || ''}
            issues={frontEndFormErrorsValue?.captionErrors || []}
            validate={NewsImageValidator.validateCaption}
            tooltip={'Please provide a descriptive caption for accessibility.'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.rightFooter}>
        <Button level="tertiary" onClick={close}>
          Close
        </Button>
        <Button
          disabled={!formIsValid}
          level="primary"
          onClick={confirmHandler}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeaturedImageModal;
