import GenericDistributionValidator from './GenericDistributionValidator';

const COMMON_MAX_LENGTH = 45;

export const validateCreditCardNumber = (value?: string): Array<string> => {
  const MAX_LENGTH = 25;
  const FIELD_LABEL = 'credit card number';

  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);
  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  return errorMsgs;
};

export const validateCreditCardExpiration = (value?: string): Array<string> => {
  const REQUIRED_LENGTH = 5;
  const FIELD_LABEL = 'Expiration';
  const MISSING_MESSAGE = `Please provide an ${FIELD_LABEL}.`;
  const REQUIRED_LENGTH_MESSAGE = `The ${FIELD_LABEL} length must be ${REQUIRED_LENGTH} characters.  Please make sure the format is: mm/yy.`;

  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  if (value.length !== REQUIRED_LENGTH) {
    errorMsgs.push(REQUIRED_LENGTH_MESSAGE);
    return errorMsgs;
  }

  const month = parseInt(value.substr(0, 2), 10);
  const slash = value.substr(2, 1);
  const year = parseInt(value.substr(3, 2), 10);

  const formatErrorMsg = 'The format of the expiration date should be: mm/yy';

  if (slash !== '/') {
    errorMsgs.push(formatErrorMsg);
    return errorMsgs;
  }

  if (isNaN(month) || isNaN(year)) {
    errorMsgs.push(formatErrorMsg);
    return errorMsgs;
  }

  if (month < 1 || month > 12) {
    errorMsgs.push('Please enter a valid expiration month.');
    return errorMsgs;
  }

  if (year < 20) {
    errorMsgs.push('Please enter a valid expiration year.');
    return errorMsgs;
  }

  return errorMsgs;
};

export const validateCreditCardCvv = (value?: string): Array<string> => {
  const MAX_LENGTH = 5;
  const FIELD_LABEL = 'CVV';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderFirstName = (value?: string): Array<string> => {
  const FIELD_LABEL = 'First Name';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderLastName = (value?: string): Array<string> => {
  const FIELD_LABEL = 'Last Name';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderAddress1 = (value?: string): Array<string> => {
  const FIELD_LABEL = 'Address';
  const MISSING_MESSAGE = `Please provide an ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  // empty, null or undefined headlines don't go through all of the other validations
  // added Only numbers validations
  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderAddress2 = (value = ''): Array<string> => {
  const FIELD_LABEL = 'Address 2';
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderCity = (value?: string): Array<string> => {
  const FIELD_LABEL = 'City';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderState = (value?: string): Array<string> => {
  const FIELD_LABEL = 'State';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCardholderZip = (value?: string): Array<string> => {
  const FIELD_LABEL = 'Zip Code';
  const MISSING_MESSAGE = `Please provide a ${FIELD_LABEL}.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${COMMON_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, COMMON_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validatePaymentData = (
  paymentData: PaymentData,
): PaymentValidation => {
  const creditCardNumberErrors = validateCreditCardNumber(
    paymentData.creditCardNumber,
  );
  const creditCardExpirationErrors = validateCreditCardExpiration(
    paymentData.creditCardExpiration,
  );

  const creditCardCvvErrors = validateCreditCardCvv(paymentData.creditCardCvv);

  const cardholderAddress1Errors = validateCardholderAddress1(
    paymentData.cardholderAddress1,
  );

  const cardholderAddress2Errors = validateCardholderAddress2(
    paymentData.cardholderAddress2,
  );

  const cardholderCityErrors = validateCardholderCity(
    paymentData.cardholderCity,
  );

  const cardholderStateErrors = validateCardholderState(
    paymentData.cardholderState,
  );

  const cardholderZipErrors = validateCardholderZip(paymentData.cardholderZip);

  const cardholderFirstNameErrors = validateCardholderFirstName(
    paymentData.cardholderFirstName,
  );

  const cardholderLastNameErrors = validateCardholderLastName(
    paymentData.cardholderLastName,
  );

  const numErrors =
    creditCardNumberErrors.length +
    creditCardExpirationErrors.length +
    creditCardCvvErrors.length +
    cardholderAddress1Errors.length +
    cardholderAddress2Errors.length +
    cardholderCityErrors.length +
    cardholderStateErrors.length +
    cardholderZipErrors.length +
    cardholderFirstNameErrors.length +
    cardholderLastNameErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    creditCardNumberErrors,
    creditCardExpirationErrors,
    creditCardCvvErrors,
    cardholderAddress1Errors,
    cardholderAddress2Errors,
    cardholderCityErrors,
    cardholderStateErrors,
    cardholderFirstNameErrors,
    cardholderLastNameErrors,
    cardholderZipErrors,
  };
};

export interface PaymentData {
  creditCardNumber?: string;
  cardholderFirstName?: string;
  cardholderLastName?: string;
  creditCardExpiration?: string;
  creditCardCvv?: string;
  cardholderAddress1?: string;
  cardholderAddress2?: string;
  cardholderCity?: string;
  cardholderState?: string;
  cardholderZip?: string;
}
export interface PaymentErrors {
  creditCardNumberErrors?: Array<string>;
  cardholderFirstNameErrors?: Array<string>;
  cardholderLastNameErrors?: Array<string>;
  creditCardExpirationErrors?: Array<string>;
  creditCardCvvErrors?: Array<string>;
  cardholderAddress1Errors?: Array<string>;
  cardholderAddress2Errors?: Array<string>;
  cardholderCityErrors?: Array<string>;
  cardholderStateErrors?: Array<string>;
  cardholderZipErrors?: Array<string>;
}
export interface PaymentValidation extends PaymentErrors {
  valid: boolean;
}
const PaymentValidator = {
  validateCreditCardNumber,
  validateCreditCardExpiration,
  validateCreditCardCvv,
  validateCardholderFirstName,
  validateCardholderLastName,
  validateCardholderAddress1,
  validateCardholderAddress2,
  validateCardholderCity,
  validateCardholderState,
  validateCardholderZip,
  validatePaymentData,
};
export default PaymentValidator;
