import queryString from 'querystring';

import axios from 'axios';
import cookie from 'js-cookie';

import { SOCIAL_MEDIA_LINKEDIN } from '../../constants';

interface Props {
  config: PRWebConfig;
  onCallbackSuccessConnection?: (status: string) => void;
}

const LinkedInConnectModal = {
  Start: async ({ config, onCallbackSuccessConnection }: Props) => {
    const callBackUrl = `${window.location.protocol}//${window.location.host}/oauthconfirmcallback`;
    const url = new URL(`${config.prwebApi.url}/social/authorizationprweb`);
    const params = new URLSearchParams({
      clientReturnUrl: callBackUrl,
      clientCallbackUrl: callBackUrl,
      socialMedia: SOCIAL_MEDIA_LINKEDIN,
    });

    url.search = params.toString();
    const linkedInLoginUrl = await (await axios.get(url.toString())).data;

    const authWindow = window.open(
      linkedInLoginUrl,
      'linkedinLogin',
      `width=600,height=600`,
    );

    if (!authWindow) {
      alert(
        'please enable popups in order to authorize PRWeb on your linkedin account',
      );
      return;
    } else {
      // authWindow.document.body.innerHTML = linkedInLoginUrl;
      const poller = setInterval(() => {
        const oauthCompleteCookieKey = 'linkedinOAuthComplete';
        const statusCookieKey = 'linkedinOAuthStatus';
        try {
          if (authWindow.closed) {
            clearInterval(poller);
          }
          const authComplete =
            cookie.get(oauthCompleteCookieKey)?.toLowerCase() === 'true';
          if (
            authWindow.location.host === window.location.host &&
            authComplete
          ) {
            clearInterval(poller);
            const status = cookie.get(statusCookieKey) || '';
            const q = queryString.parse(status) || {};
            let errorObj;
            try {
              errorObj = JSON.parse(status);
            } catch (err) {
              // Not a json object, skip
            }
            if (q['?state'] === 'success') {
              onCallbackSuccessConnection &&
                onCallbackSuccessConnection(q['?state']);
            } else if (
              errorObj &&
              errorObj.status === 404 &&
              'message' in errorObj
            ) {
              onCallbackSuccessConnection &&
                onCallbackSuccessConnection(errorObj.message);
            }
            cookie.remove(oauthCompleteCookieKey);
            cookie.remove(statusCookieKey);
            authWindow.close();
          } else {
            //  deferred.reject(angular.extend({reason: 'User denied access or we got an error'}, q));
          }
        } catch (err) {
          const regex = /(blocked a frame with origin)|(Permission denied to access property "host"|(^Permission Denied))/gi;
          if (!err.message.match(regex)) {
            cookie.remove(oauthCompleteCookieKey);
            cookie.remove(statusCookieKey);
            console.error(err);
          }
        }
      }, 500);
      authWindow.focus();
    }
  },
};
export default LinkedInConnectModal;
