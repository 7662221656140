import React, { useEffect, useRef, useState } from 'react';

import { Typography, Button, Loader } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';

import { IS_NG_USER, PRWEB_PUBLIC_FEATURES } from '../constants';
import CacheService from '../utils/cache-service';
import PRWebFeatures from '../utils/PRWebFeatures';

interface Props {
  config: PRWebConfig;
}

const RedirectFromLegacy = (props: Props) => {
  const history = useHistory();
  const redirectRef = useRef<any>();
  const isImmediateRedirect = props.config.environment === 'production';
  const urlParams = new URLSearchParams(window.location.search);
  const [lastLoginUser, setLastLoginUser] = useState('');

  const redirect = () => {
    history.push({
      pathname: `/`,
    });
  };

  const cancelRedirect = () => {
    if (redirectRef.current) {
      clearInterval(redirectRef.current);
    }
    cookie.remove(`${IS_NG_USER}`);
    const url = urlParams.get('from');
    if (url) {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        window.location.replace(url);
      } else {
        window.location.replace(`https://${url}`);
      }
    }
  };

  const confirmRedirect = () => {
    if (redirectRef.current) {
      clearInterval(redirectRef.current);
    }
    redirect();
  };

  const loadCache = async (envConfig: PRWebConfig) => {
    const prwebApi = envConfig.prwebApi.url;
    const getFeaturesUrl = `${prwebApi}/publicFeatures`;

    if (!CacheService.exists(PRWEB_PUBLIC_FEATURES)) {
      await axios
        .get(getFeaturesUrl)
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            CacheService.set(PRWEB_PUBLIC_FEATURES, response.data);
          } else {
            console.log(
              'invalid response loading cache data: ',
              response,
              PRWEB_PUBLIC_FEATURES,
            );
          }
        })
        .catch(error => {
          console.log(
            'loadcache error retrieving data: ',
            error,
            PRWEB_PUBLIC_FEATURES,
          );
        });
    }
  };

  useEffect(() => {
    const delayRedirect = async () => {
      const lastLogin = cookie.get(`${IS_NG_USER}`);
      if (lastLogin) setLastLoginUser(lastLogin);
      await loadCache(props.config);
      const countDown = PRWebFeatures.legacyRedirectCountDown();
      redirectRef.current = setTimeout(redirect, countDown * 1000);
    };

    if (isImmediateRedirect) {
      redirect();
    } else {
      delayRedirect();
    }
    return () => {
      if (redirectRef.current) {
        clearInterval(redirectRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isImmediateRedirect) {
    return <Loader />;
  }

  return (
    <div style={{ width: '700px', margin: '64px auto', textAlign: 'center' }}>
      <br />
      <Typography weight="bold" color="black" size="xl">
        This page will automatically redirect
      </Typography>
      <br />
      {lastLoginUser && (
        <Typography color="black" size="md">
          Last login as {lastLoginUser}
        </Typography>
      )}
      <br />
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button level="secondary" onClick={cancelRedirect}>
          Cancel
        </Button>
        <Button level="primary" onClick={confirmRedirect}>
          Continue
        </Button>
      </div>
      <br />
    </div>
  );
};

export default RedirectFromLegacy;
