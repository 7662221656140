import React, { useEffect } from 'react';

import { Paper, Typography } from '@cision/rover-ui';

import PRWebPackage from '../distribution-wizard/PRWebPackages';

import styles from './bundle-summary.module.css';

interface BundleSummaryProps {
  storeCart: BundlePackagePrice[];
}

const BundleSummary: React.FC<BundleSummaryProps> = ({
  storeCart,
}: BundleSummaryProps) => {
  const [cartObject, setCartObject] = React.useState({} as ShoppingCartObject);

  const getCartTotal = (cart: ShoppingCartObject) => {
    if (cart) {
      return Object.values(cart).reduce(
        (t, { itemSubtotal }) => t + itemSubtotal,
        0,
      );
    }
    return 0;
  };
  useEffect(() => {
    const cart = {} as ShoppingCartObject;
    storeCart.forEach(bundle => {
      if (bundle.bundlePackagePriceId in cart) {
        cart[bundle.bundlePackagePriceId].itemQuantity += 1;
        cart[bundle.bundlePackagePriceId].itemSubtotal += Number(bundle.amount);
      } else {
        cart[bundle.bundlePackagePriceId] = {
          ...bundle,
          itemQuantity: 1,
          itemSubtotal: Number(bundle.amount),
        } as ShoppingCartItem;
      }
    });
    setCartObject(cart);
  }, [storeCart]);

  return (
    <Paper className={styles.bundleSummaryContainer}>
      <div className={styles.sectionTitle}>
        <Typography color="black" weight="bold" size="lg">
          Order Summary
        </Typography>
      </div>
      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>
      <div>
        {Object.keys(cartObject).map((key: string, index) => {
          return (
            <div key={index}>
              <div className={styles.bundleSummaryRow}>
                <span className={styles.bundleSummaryName}>
                  {cartObject[key].itemQuantity}{' '}
                  {
                    PRWebPackage.getPRWebPackage(
                      cartObject[key].packageId.toString(),
                    )?.name
                  }{' '}
                  Release x{cartObject[key].quantity}
                </span>
                <span>
                  {cartObject[key].itemSubtotal.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
            </div>
          );
        })}
        <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>
        <div className={styles.bundleSummaryRow}>
          <span className={styles.bundleSummaryName}>
            <Typography color="black" weight="bold" size="lg">
              Total
            </Typography>
          </span>
          <span>
            <Typography color="black" weight="bold" size="lg">
              {getCartTotal(cartObject).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </span>
        </div>
      </div>
    </Paper>
  );
};

export default BundleSummary;
