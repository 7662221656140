import React, { FC, useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useHistory, useLocation } from 'react-router-dom';

import Wizard from '../../../components/wizard/wizard';
import WizardContext from '../../../components/wizard/wizard-context';
import WizardProgress from '../../../components/wizard/wizard-progress';
import WizardStep from '../../../components/wizard/wizard-step';
import { PRWEB_PACKAGES, PRWEB_FEATURES } from '../../../constants';
import CacheService from '../../../utils/cache-service';
import PRWebFeatures from '../../../utils/PRWebFeatures';
import Prid from '../components/prid';
import DistributionWizardProvider from '../DistributionWizardProvider';
import PRWebPackages from '../PRWebPackages';
import AdditionsStep from '../steps/AdditionsStep';
import AmplifyStep from '../steps/AmplifyStep';
import CreateReleaseStep from '../steps/CreateReleaseStep';
import PaymentStep from '../steps/Payment/PaymentStep';
import StripePaymentStep from '../steps/Payment/StripePaymentStep';
import ScheduleStep from '../steps/ScheduleStep';
import SelectPackageStep from '../steps/SelectPackageStep';
import SummaryStep from '../steps/SummaryStep';
import VerificationStep from '../steps/VerificationStep';

interface Props {
  envConfig: PRWebConfig;
  savedDistro?: Distribution;
  initialProgress: Array<string>;
}

const CreateWizardSteps: FC<Props> = ({
  envConfig,
  savedDistro,
  initialProgress,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [stripePromise, setStripePromise] = useState<any>(undefined);

  const cacheIsLoaded = (): boolean => {
    return (
      CacheService.exists(PRWEB_FEATURES) && CacheService.exists(PRWEB_PACKAGES)
    );
  };

  if (!cacheIsLoaded()) {
    console.log('Cache unexpectedly empty CreateWizardSteps, redirecting to /');
    history.push({ pathname: '/' });
  }

  useEffect(() => {
    const stripePromise = loadStripe(envConfig.stripe.publishableKey);
    setStripePromise(stripePromise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getWizardWithAmplify = (wizardData: any) => {
    const canShowUpgrade = savedDistro && savedDistro?.hasPaid;
    return (
      <Wizard>
        <>
          <DistributionWizardProvider distro={savedDistro}>
            <Prid />
            <WizardStep order={1} nextLabel="Next">
              <SelectPackageStep />
            </WizardStep>
            <WizardStep order={2} backLabel="Back to Packages" nextLabel="Next">
              <AdditionsStep />
            </WizardStep>
            <WizardStep
              order={3}
              nextLabel="Save &amp; Continue"
              backLabel={'Back to Additions'}
              saveDraftButton
              upgradeButton={canShowUpgrade}
              previewButton
            >
              <CreateReleaseStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={4}
              backLabel="Back to Create Your Release"
              nextLabel="Save &amp; Continue"
              saveDraftButton
              previewButton
            >
              <AmplifyStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={5}
              nextLabel="Save &amp; Continue"
              backLabel="Back to Amplify"
              saveDraftButton
              previewButton
            >
              <ScheduleStep config={envConfig} />
            </WizardStep>
            <WizardStep order={6} nextLabel="Next" backLabel="Back to Schedule">
              <VerificationStep
                config={envConfig}
                // wizardSetStepIsValid={() => true}
              />
            </WizardStep>
            <WizardStep
              order={7}
              backLabel="Back to Verification"
              nextLabel="Pay"
            >
              <StripePaymentStep
                config={envConfig}
                stripePromise={stripePromise}
              />
            </WizardStep>
            <WizardStep
              order={8}
              backButtonIsVisible={false}
              previewButton
              upgradeButton
              submit
            >
              <SummaryStep config={envConfig} />
            </WizardStep>
          </DistributionWizardProvider>

          <WizardProgress
            title={'Release Summary'}
            initialProgress={initialProgress}
            maxVisibleCount={2}
          />
        </>
      </Wizard>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCreateSteps = (wizardData: any) => {
    if (location.state && location.state.packageId) {
      const packageObject = PRWebPackages.getPRWebPackage(
        location.state.packageId,
      );
      if (packageObject) {
        const releaseDate =
          savedDistro?.distributionId !== undefined &&
          savedDistro.distributionId === 0
            ? PRWebPackages.getPackageBasedMinTurnaroundDate(
                packageObject.packageId,
              )
                .add(1, 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')
            : savedDistro?.releaseDate;

        savedDistro = {
          order: {
            package: packageObject.packageId,
          },
          package: packageObject.packageId,
          releaseDate: releaseDate,
        } as Distribution;

        wizardData.progressState[0] = {
          name: 'Select a Package',
          valuesArray: [packageObject.name],
        };

        wizardData.moveStepForward();
      }
    }
    return getWizardWithAmplify(wizardData);
  };

  return (
    <WizardContext.Consumer>
      {wizardData => {
        return getCreateSteps(wizardData);
      }}
    </WizardContext.Consumer>
  );
};

export default CreateWizardSteps;
