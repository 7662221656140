import React, { useEffect, useState } from 'react';

import { Paper, Button } from '@cision/rover-ui';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CartSvg } from '../../assets/cart.svg';

import styles from './shopping-cart.module.css';

interface ShoppingCartProps {
  storeCart: BundlePackagePrice[];
  packages: PRWebPackage[];
  config: PRWebConfig;
}

const ShoppingCart: React.FC<ShoppingCartProps> = ({
  storeCart,
  packages,
  config,
}: ShoppingCartProps) => {
  const [showCartPanel, setShowCartPanel] = useState(false);
  const history = useHistory();
  const [cartObject, setCartObject] = useState({} as ShoppingCartObject);

  const handleCartIconClick = () => {
    setShowCartPanel(prev => !prev);
  };

  const searchPackageById = (packageId: string) => {
    return packages.find(pkg => pkg.packageId.toString() === packageId);
  };

  useEffect(() => {
    const cart = {} as ShoppingCartObject;
    storeCart.forEach(bundle => {
      if (bundle.bundlePackagePriceId in cart) {
        cart[bundle.bundlePackagePriceId].itemQuantity += 1;
        cart[bundle.bundlePackagePriceId].itemSubtotal += Number(bundle.amount);
      } else {
        cart[bundle.bundlePackagePriceId] = {
          ...bundle,
          itemQuantity: 1,
          itemSubtotal: Number(bundle.amount),
        } as ShoppingCartItem;
      }
    });
    setCartObject(cart);
  }, [storeCart]);

  const getCartSubtotal = () => {
    if (storeCart) {
      let subtotal = 0;
      storeCart.forEach((item: BundlePackagePrice) => {
        subtotal += Number(item.amount);
      });
      return Number(subtotal).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'currency',
        currency: 'USD',
      });
    }
    return '$0';
  };

  const handleCheckoutClick = () => history.push('store/checkout');

  return (
    <div className={styles.cartContainer}>
      {showCartPanel && storeCart.length > 0 && (
        <Paper style={{ padding: '20px' }}>
          {Object.keys(cartObject).map((key: string, index) => {
            return (
              <div key={index} style={{ padding: '10px 0px' }}>
                <div className={styles.cartPanelRow}>
                  <div color="dark">
                    {cartObject[key].itemQuantity}{' '}
                    {
                      searchPackageById(cartObject[key].packageId.toString())
                        ?.name
                    }
                  </div>
                  <div color="dark">
                    {Number(cartObject[key].itemSubtotal).toLocaleString(
                      'en-US',
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                        style: 'currency',
                        currency: 'USD',
                      },
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.cartPanelRow} ${styles.cartPanelDiscount}`}
                >
                  <div>x{cartObject[key].quantity}</div>
                  <div className={styles.saveDiscountText}>
                    SAVE {cartObject[key].description}
                  </div>
                </div>
              </div>
            );
          })}
          <div className={styles.separator}></div>
          <div style={{ padding: '10px 0px' }}>
            <div>{storeCart.length} bundles in bag</div>
          </div>
          <div style={{ padding: '10px 0px' }}>
            <div className={styles.cartPanelRow}>
              <div color="dark">Total</div>
              <div color="dark">{getCartSubtotal()}</div>
            </div>
          </div>
          <div style={{ padding: '10px 0px' }}>
            <div className={styles.cartPanelButton}>
              <Button
                id={'bundle-checkout-button'}
                modifiers={['round', 'primary']}
                onClick={handleCheckoutClick}
              >
                View Bag &#38; Checkout
              </Button>
            </div>
          </div>
        </Paper>
      )}
      <CartSvg
        id={'shopping-cart-icon'}
        fill={'#677078'}
        width={40}
        height={32}
        onClick={handleCartIconClick}
      />
      {storeCart && storeCart.length > 0 && (
        <div color={'light'}>{storeCart.length} bundles</div>
      )}
    </div>
  );
};

export default ShoppingCart;
