import React, { useState, FormEvent } from 'react';

import { Button, Modal, Tooltip, Input, Typography } from '@cision/rover-ui';

import { ReactComponent as InfoSvg } from '../../../../assets/info.svg';
import FieldValidationIssue from '../../../../components/field-validation-issue';
import { CSP_MAX_LENGTH } from '../../../../constants';
import AmplifyDistributionValidator from '../../../../validators/AmplifyDistributionValidator';

import styles from './cspmodal.module.css';

interface Props {
  addSocialPost: (arg0: string) => void;
  toggle: (arg0: boolean) => void;
  isOpen: boolean;
  socialPost: string;
}

const CSPModal = ({ addSocialPost, toggle, isOpen, socialPost }: Props) => {
  const [currentSocialPost, setCurrentSocialPost] = useState(socialPost);
  const [currentIssues, updateCurrentIssues] = useState<string[]>([]);
  const [charCount, setCharCount] = useState(
    socialPost ? CSP_MAX_LENGTH - socialPost.length : CSP_MAX_LENGTH,
  );
  const [isSaved, setIsSaved] = useState(false);
  const addButtonLabel = socialPost ? 'Update' : 'Add';

  const close = () => {
    if (!isSaved) {
      setCurrentSocialPost(socialPost);
    }
    toggle(false);
  };

  const add = () => {
    addSocialPost(currentSocialPost);
    setIsSaved(true);
    toggle(false);
  };

  const remove = () => {
    addSocialPost('');
    setCurrentSocialPost('');
    setCharCount(CSP_MAX_LENGTH);
    setIsSaved(true);
    toggle(false);
  };

  const changeHandler = (e: FormEvent): void => {
    const newValue = (e.target as HTMLInputElement).value;
    setCurrentSocialPost(newValue);
    setCharCount(CSP_MAX_LENGTH - newValue.length);
    if (newValue !== currentSocialPost) {
      setIsSaved(false);
      const newIssues = AmplifyDistributionValidator.validateCisionSocialPost(
        newValue,
      );
      updateCurrentIssues(newIssues);
    }
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <Modal.Header>
        <Typography size="lg">Cision SocialPost&trade;</Typography>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.center}>
          <Typography size="lg">Post your release</Typography>
        </div>
        <div className={styles.center}>
          <Typography size="lg" weight="bold">
            Streamline messaging, expand your social reach and nurture new
            followers.
          </Typography>
        </div>
        <div className={styles.container} id={'csp-container'}>
          <label
            htmlFor={'cision-social-post'}
            className={styles.labelContainer}
          >
            <div className={styles.flex}>
              <Typography weight="bold">
                Post your release to Cision&apos;s X accounts
              </Typography>
              <Tooltip
                className={styles.tooltip}
                tooltipWidth="250px"
                direction="right"
                content={
                  'Boost the visibility of your message by posting to industry-specific Cision X feeds. With our SocialPost service, you can reach thousands of followers and increase X search visibility.'
                }
                showOnHover
              >
                <InfoSvg className={styles.icon} />
              </Tooltip>
            </div>
            <Typography>
              <span
                className={
                  charCount < 0
                    ? `${styles.charCount} ${styles.errorMessage}`
                    : styles.charCount
                }
              >
                {charCount}
              </span>
            </Typography>
          </label>
          <div id={'csp-container'}>
            <Input
              id={'social-post'}
              className={styles.tweetInput}
              placeholder={
                'Enter your text here in order to include with your release'
              }
              onChange={changeHandler}
              value={currentSocialPost}
            />
            {currentIssues && currentIssues.length > 0 && (
              <FieldValidationIssue issues={currentIssues} id={'social-post'} />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.modalBtnContainer}>
        {socialPost && (
          <Button level="secondary" onClick={remove}>
            Remove
          </Button>
        )}
        <Button level="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          level="primary"
          onClick={add}
          disabled={
            !currentSocialPost ||
            currentSocialPost.length === 0 ||
            currentSocialPost.length > CSP_MAX_LENGTH
          }
        >
          {addButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CSPModal;
