import React, { useEffect, useState } from 'react';

import { Loader, Responsive } from '@cision/rover-ui';
import axios from 'axios';
import qs from 'qs';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import DistributionStatus from '../../components/distribution-list/distribution-status-enum';
import Footer from '../../components/footer';
import Header from '../../components/header';
import WizardNavigation from '../../components/wizard/wizard-navigation';
import WizardProvider from '../../components/wizard/wizard-provider';
import DistributionDetails from '../distribution-details/DistributionDetails';

import CreateWizardSteps from './create/CreateWizardSteps';
import EditWizardSteps from './edit/EditWizardSteps';

interface Props {
  config: PRWebConfig;
}

const DistributionRoute: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const { id: distributionId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const cancelRoute = '/';

  const [isLoading, setIsLoading] = useState(true);
  const [showDistroDetails, setShowDistroDetails] = useState(false);
  const [savedDistro, setSavedDistro] = useState<Distribution | undefined>(
    undefined,
  );
  const [errorSummaryValue, setErrorSummaryValue] = useState<Array<string>>([]);
  const [onHoldReasons, setOnHoldReasons] = useState<Array<string>>([]);
  const [editorAction, setEditorAction] = useState('');
  const [submitLabel, setSubmitLabel] = useState('Submit to Editorial');
  const [progressLabel, setProgressLabel] = useState([
    'Select a Package',
    'Additions',
    'Create Your Release',
    'Amplify',
    'Schedule',
    'Verify Contact Information',
    'Payment',
    'Summary',
  ]);

  const handleGetDistributionError = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setErrorSummaryValue(errors);
  };

  const loadExistingDistribution = async (id: string) => {
    return axios
      .get(`${prwebApi}/distribution/item/${id}`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          const distro = response.data;
          if (!distro || !distro.distributionId) {
            console.error('The requested release could not be reteieved.');
            setIsLoading(false);
          } else {
            setSavedDistro(distro);
            switch (distro.publicationState) {
              case DistributionStatus.Published:
              case DistributionStatus.PendingEditorialReview:
              case DistributionStatus.PendingDistribution:
                history.replace({
                  pathname: `/distribution/details/${id}`,
                });
                setShowDistroDetails(true);
                setIsLoading(false);
                break;
              case DistributionStatus.OnHold:
                if (distro.isOnHold && distro.holdReason) {
                  setOnHoldReasons(distro.holdReason);
                }
                history.replace({
                  pathname: `/distribution/edit/${id}`,
                });
                setProgressLabel([
                  'Edit Your Release',
                  'Amplify',
                  'Schedule',
                  'Verify Contact Information',
                  'Summary',
                ]);
                setSubmitLabel('Resubmit');
                // setIsLoading will be updated in chained request for onHoldReasons
                break;
              default:
                if (distro.hasPaid) {
                  history.replace({
                    pathname: `/distribution/edit/${id}`,
                  });
                  setProgressLabel([
                    'Edit Your Release',
                    'Amplify',
                    'Schedule',
                    'Verify Contact Information',
                    'Summary',
                  ]);
                } else {
                  history.replace({
                    pathname: `/distribution/create/${id}`,
                  });
                }
                setIsLoading(false);
            }
            return distro;
          }
        } else {
          handleGetDistributionError(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleGetDistributionError(error);
        setIsLoading(false);
      });
  };

  const getOnHoldReasons = async (distro?: Distribution) => {
    if (distro && distro.isOnHold && distro.holdReason) {
      setIsLoading(false);
      return Promise.resolve(distro);
    }
  };

  useEffect(() => {
    if (
      distributionId &&
      location.state &&
      location.state.action === 'republish'
    ) {
      setProgressLabel([
        'Edit Your Release',
        'Amplify',
        'Schedule',
        'Verify Contact Information',
        'Publishing',
      ]);
      setSubmitLabel('Republish');
      setEditorAction('republish');
      setShowDistroDetails(false);
    } else if (
      distributionId &&
      location.state &&
      location.state.action === 'edit'
    ) {
      setProgressLabel([
        'Edit Your Release',
        'Amplify',
        'Schedule',
        'Verify Contact Information',
        'Summary',
      ]);
      setSubmitLabel('Resubmit');
      setEditorAction('edit');
      setShowDistroDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionId, location]);

  useEffect(() => {
    const currentSearch = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const init = async (distroId: string) => {
      await loadExistingDistribution(distroId).then(distro =>
        getOnHoldReasons(distro),
      );
    };
    if (distributionId && !isNaN(Number(distributionId))) {
      init(distributionId);
    } else if ('distributionId' in currentSearch) {
      init(currentSearch.distributionId);
    } else {
      if (location.state && location.state.packageId) {
        history.replace({
          pathname: `/distribution/create`,
          state: { packageId: location.state.packageId },
        });
      } else {
        history.replace({
          pathname: `/distribution/create`,
        });
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      {errorSummaryValue.length > 0 ? (
        <div style={{ color: 'red' }}>
          Error getting release
          <ul>
            {errorSummaryValue.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          {isLoading && (
            <Loader
              style={{
                position: 'absolute',
                margin: 'auto',
                left: '0px',
                right: '0px',
                top: '200px',
                zIndex: 99,
              }}
            />
          )}
          {!isLoading && !showDistroDetails && (
            <WizardProvider history={history}>
              <WizardNavigation
                history={history}
                submitLabel={submitLabel}
                cancelRoute={cancelRoute}
                envConfig={props.config}
              />
              {savedDistro?.hasPaid ? (
                <EditWizardSteps
                  envConfig={props.config}
                  savedDistro={savedDistro}
                  onHoldReasons={onHoldReasons}
                  initialProgress={progressLabel}
                  editorAction={editorAction}
                />
              ) : (
                <CreateWizardSteps
                  envConfig={props.config}
                  savedDistro={savedDistro}
                  initialProgress={progressLabel}
                />
              )}
            </WizardProvider>
          )}
          {!isLoading && showDistroDetails && savedDistro && (
            <DistributionDetails config={props.config} distro={savedDistro} />
          )}
        </>
      )}
      <Footer />
    </Responsive.Container>
  );
};

export default DistributionRoute;
