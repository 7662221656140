import React from 'react';

import { Button, Loader, Typography, Paper, Input } from '@cision/rover-ui';

import axios, { AxiosError } from 'axios';

import { VALID_EMAIL_PATTERN } from '../../constants';

import styles from './forgotPassword.module.css';

interface Props {
  config: PRWebConfig;
}

const SendActivation: React.FC<Props> = (props: Props) => {
  const envConfig: PRWebConfig = props.config;
  const prwebApi = envConfig.prwebApi.url;

  let isLoading = false;

  const [formErrorValue, setFormErrorValue] = React.useState<Array<string>>([]);
  const [emailValue, setEmailValue] = React.useState('');
  const [hasValidEmail, setHasValidEmail] = React.useState(true);
  const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);
  const [hasSentActivationEmail, setHasSentActivationEmail] = React.useState(
    false,
  );

  const validateEmailAddress = (email: string): boolean => {
    return VALID_EMAIL_PATTERN.test(email);
  };

  const formIsValid = (): boolean => {
    const isValidEmail = validateEmailAddress(emailValue);

    setHasValidEmail(isValidEmail);

    return isValidEmail;
  };

  const handleSubmit = (): void => {
    setHasBeenSubmitted(true);

    const isValid = formIsValid();

    if (isValid) {
      isLoading = true;

      axios
        .post(`${prwebApi}/users/sendactivation`, {
          email: emailValue,
        })
        .then(response => {
          if (
            response.data &&
            response.data.string &&
            response.data.string.error
          ) {
            handleResetPasswordErrors({
              response,
              isAxiosError: false,
            } as AxiosError);
          } else {
            setHasSentActivationEmail(true);
          }
        })
        .catch((error: AxiosError) => {
          handleResetPasswordErrors(error);
        })
        .finally(() => {
          isLoading = false;
        });
    }
  };

  const handleResetPasswordErrors = (error: AxiosError): void => {
    const errors: Array<string> = [];

    if (error.response && error.response.status === 200) {
      errors.push(error.response.data.string.error);
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }

    setFormErrorValue(errors);
  };

  const handleEmailChange = (email: string): void => {
    setEmailValue(email);

    if (hasBeenSubmitted) {
      setHasValidEmail(validateEmailAddress(email));
    }
  };

  return (
    <div>
      <Paper>
        <Typography size="xl2">Send Activation Email</Typography>
        <div>
          <Typography size="md">
            Send Activation Email If you have previously registered for PRWeb
            but have misplaced or not received your account activation email,
            please submit your email below.
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
        <form>
          <div>
            <label htmlFor={'email'}>
              <Input
                id={'email'}
                placeholder={'Email address'}
                onChange={(e: React.FormEvent): void =>
                  handleEmailChange((e.target as HTMLInputElement).value)
                }
                value={emailValue}
              />
            </label>
            {!hasValidEmail && (
              <div className={styles.errorMessage}>
                Please enter a valid email address
              </div>
            )}
          </div>

          <div>
            <label htmlFor={'submit'}>
              {!hasSentActivationEmail && (
                <Button id={'submit'} level="primary" onClick={handleSubmit}>
                  Send Activation Email
                </Button>
              )}
              {hasSentActivationEmail && (
                <div>Activation Email sent to {emailValue}. </div>
              )}
              {!hasSentActivationEmail && formErrorValue.length > 0 && (
                <div className={styles.errorMessage}>
                  Error sending the Activation Email:
                  <ul>
                    {formErrorValue.map((err, i) => (
                      <li key={i}>{err}</li>
                    ))}
                  </ul>
                </div>
              )}
            </label>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default SendActivation;
