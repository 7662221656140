import React from 'react';

export default React.createContext({
  distributionData: {} as Distribution,
  updateDistributionData: (distro: Distribution) => {},
  /**
   * Use to update the top level attributes of the Distribution Object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateDistributionByField: (topLevelKey: string, value: any) => {},
  onHoldReasons: [] as string[],
});
