import { CSP_MAX_LENGTH } from '../constants';

import GenericDistributionValidator from './GenericDistributionValidator';

export const validateCisionSocialPost = (value?: string): Array<string> => {
  const EMPTY_MESSAGE = 'The tweet cannot be empty.';
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The tweet text cannot exceed ${CSP_MAX_LENGTH} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(EMPTY_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, CSP_MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateTwitterMessage = (
  maxLength: number,
  value?: string,
): Array<string> => {
  const EMPTY_MESSAGE = 'The tweet cannot be empty.';
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The tweet text cannot exceed ${maxLength} characters.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(EMPTY_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, maxLength) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

const AmplifyDistributionValidator = {
  validateCisionSocialPost,
  validateTwitterMessage,
};

export default AmplifyDistributionValidator;
