import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Confirm from '../../../../components/confirm';

interface Props {
  confirmMessage?: string;
}

interface LocationDescriptorObject {
  pathname: string;
  search?: string;
  state?: any;
  hash?: string;
  key?: string;
}

const ExitWizardPrompt = ({ confirmMessage }: Props) => {
  const history = useHistory();
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const message =
    'You might lose some data. Are you sure you want to leave the release wizard?';
  const [redirectPath, setRedirectPath] = useState('');

  const onConfirm = () => {
    setShowConfirmationPrompt(false);
    history.replace({
      pathname: redirectPath,
      state: {
        noConfirm: true,
      },
    });
  };

  const onCancel = () => {
    setRedirectPath('');
    setShowConfirmationPrompt(false);
  };

  useEffect(() => {
    const callback = (location: LocationDescriptorObject, action: string) => {
      const noPopup =
        location.state &&
        'noConfirm' in location.state &&
        location.state.noConfirm === true;

      if (
        location.pathname.startsWith('/distribution/create') ||
        location.pathname.startsWith('/distribution/edit') ||
        location.pathname.startsWith('/distribution/upgrade') ||
        location.pathname.startsWith('/distribution/purchaseedit') ||
        location.pathname.startsWith('/store/checkout') ||
        noPopup
      ) {
        return;
      }

      setRedirectPath(location.pathname);
      setShowConfirmationPrompt(true);
      return false;
    };
    const unregisterBlock = history.block(callback);
    return unregisterBlock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Confirm
      isOpen={showConfirmationPrompt}
      text={confirmMessage || message}
      yes={onConfirm}
      no={onCancel}
    ></Confirm>
  );
};

export default ExitWizardPrompt;
