/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

import axios from 'axios';
import ReactDOM from 'react-dom';
import short from 'short-uuid';

import WizardContext from '../../components/wizard/wizard-context';

import DistributionWizardContext from './DistributionWizardContext';
import OrderUtility from './OrderUtility';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const paypal: any;

interface Props {
  config: PRWebConfig;
  applyBundledPackage: boolean;
  universalCoupon: UniversalCoupon | undefined;
  appliedCoupon: CouponDetails | undefined;
  updateIsLoading: (status: boolean) => void;
  handlePaymentError: (error: any) => void;
}

const PayPalButton = ({
  config,
  applyBundledPackage,
  universalCoupon,
  appliedCoupon,
  updateIsLoading,
  handlePaymentError,
}: Props) => {
  // https://developer.paypal.com/docs/business/checkout/configure-payments/single-page-app/
  const PayPal = paypal.Buttons.driver('react', { React, ReactDOM });
  const prwebApi = config.prwebApi.url;

  const { distributionData, updateDistributionByField } = React.useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);

  const createOrder = (data: any, actions: any) => {
    const orderTotal = OrderUtility.getOrderTotal(
      distributionData,
      universalCoupon,
      appliedCoupon,
      applyBundledPackage,
    );
    const translator = short('0123456789abcdefghijklmnopqrstuvwxyz');
    const invoiceId = translator.generate();
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: orderTotal,
          },
          invoice_id: invoiceId,
          custom_id: `PR-${distributionData.prwebPressReleaseId}`,
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
    });
  };

  const handlePayPalPayment = (paypalOrderId: string) => {
    updateIsLoading(true);
    const couponDiscount = OrderUtility.getOrderCouponDiscount(
      distributionData,
      universalCoupon,
      appliedCoupon,
      applyBundledPackage,
    );
    const paymentData: PaymentOrder = OrderUtility.mapToApiPaymentOrder(
      undefined,
      paypalOrderId,
      applyBundledPackage,
      universalCoupon,
      appliedCoupon,
      couponDiscount,
      distributionData,
    );
    return axios.post(`${prwebApi}/order/pay`, paymentData);
  };

  const onApprove = (data: any, actions: any) => {
    actions.order.authorize().then((authorization: any) => {
      return handlePayPalPayment(data.orderID)
        .then(response => {
          if (response.status === 201) {
            updateDistributionByField(
              'orderNumber',
              response.data.OrderNumber ?? '',
            );
            updateDistributionByField(
              'transactionId',
              response.data.ProcessorTransactionID ?? '',
            );
            wizardContext.moveStepForward();
          }
        })
        .catch(error => {
          handlePaymentError(error);
        })
        .finally(() => {
          updateIsLoading(false);
        });
    });
  };

  return <></>;
};

export default PayPalButton;
