// @ts-nocheck
// Typescript will fail to compile if it checks the react-stripe elements variable
import React, { useEffect, useState, useCallback } from 'react';

import { Paper, Loader, Typography } from '@cision/rover-ui';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';

import WizardContext from '../../components/wizard/wizard-context';

interface CheckoutFormProps {
  children?: React.ReactFragment;
  config: PRWebConfig;
  storeCart: BundlePackagePrice[];
  setBundleOrderId: React.Dispatch<React.SetStateAction<number>>;
  setOrderNumberHandler: React.Dispatch<React.SetStateAction<string>>;
}

const CheckoutForm = ({
  children,
  config,
  storeCart,
  setBundleOrderId,
  setOrderNumberHandler,
}: CheckoutFormProps) => {
  const wizardData = React.useContext(WizardContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);
  const [validationMsg, setValidationMsg] = useState('');
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);
  const isNullOrUndefined = (
    value: T | null | undefined,
  ): value is null | undefined => {
    return value === null || value === undefined;
  };

  const handleSubmit = useCallback(
    async (): Promise<boolean> => {
      if (isHandlingPayment) return false;
      let paymentSuccessful = false;
      setIsHandlingPayment(true);

      await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            // eslint-disable-next-line @typescript-eslint/camelcase
            return_url: 'http://localhost:3334/',
          },
          redirect: 'if_required',
        })
        .then(response => {
          if (response.error) {
            setValidationMsg(
              response.error.message || 'Unable to confirm payment',
            );
            return;
          }
          return new Promise((resolve, reject) => {
            if (response.paymentIntent) {
              return resolve(
                axios.post(
                  `${config.prwebApi.url}/bundleorder/capturePaymentIntent`,
                  {
                    cart: storeCart,
                    paymentIntent: response.paymentIntent,
                  },
                ),
              );
            }
            return reject({ messsage: 'unable to find payment intent' });
          });
        })
        .then(response => {
          if (isNullOrUndefined(response) || isNullOrUndefined(response.data))
            return;

          setBundleOrderId(response.data.bundleOrderId);
          if (response.data.bundleOrderId) {
            setOrderNumberHandler(`BUNDLE-${response.data.bundleOrderId}`);
          }
          paymentSuccessful = true;
        })
        .catch(error => {
          const msg = error.message || 'unable to process payment';
          setValidationMsg(msg);
        })
        .finally(() => {
          setIsHandlingPayment(false);
        });
      return paymentSuccessful;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, stripe, wizardData.loading, isLoading, isHandlingPayment],
  );

  useEffect(() => {
    if (!stripe || !elements) {
      setIsLoading(true);
    } else {
      wizardData.setCanMoveStepForward(handleSubmit);
      setIsLoading(false);
    }

    if (isHandlingPayment) {
      setIsLoading(true);
    } else {
      wizardData.setCanMoveStepForward(handleSubmit);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, isHandlingPayment]);

  return (
    <Paper style={{ margin: '8px' }}>
      <Typography color="black" weight="bold" size="lg">
        Credit Card and Billing Information
      </Typography>
      {validationMsg && (
        <div style={{ padding: '8px 0px', color: 'red' }}>{validationMsg}</div>
      )}
      <div style={{ padding: '16px 0px' }}>
        <PaymentElement id="payment-element" />
      </div>
      {(isHandlingPayment || isLoading) && <Loader />}
      {children && children}
    </Paper>
  );
};

export default CheckoutForm;
