import React from 'react';

export default React.createContext({
  purchaseEditData: {} as PurchaseEdit,
  updatePurchaseEditData: (purchaseEdit: PurchaseEdit) => {},
  /**
   * Use to update the top level attributes of the Context Object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatePurchaseEditByField: (topLevelKey: string, value: any) => {},
});
