import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Paper, Loader, Button, Typography } from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as CheckmarkSvg } from '../../../assets/checkmark.svg';
import Kite from '../../../components/kite';
import WizardContext from '../../../components/wizard/wizard-context';
import { PRWEB_VERIFIED_EMAILS } from '../../../constants';
import CacheService from '../../../utils/cache-service';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import OnHoldReasonsList from '../components/on-hold-reasons-list';
import DistributionWizardContext from '../DistributionWizardContext';
import SaveUtility from '../SaveUtility';

import styles from './styles/verification-step.module.css';

interface Props {
  config: PRWebConfig;
}

const VerificationStep = ({ config }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const { distributionData, onHoldReasons } = useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);
  const [displayVerifiedStatus, setDisplayVerifiedStatus] = useState(false);
  const [kiteText, setKiteText] = useState('');
  const [kiteStatus, setKiteStatus] = useState('success');
  const [
    isDistributionContactEmailVerified,
    setIsDistributionContactEmailVerified,
  ] = useState(false);
  const [
    isDistributionUserEmailVerified,
    setIsDistributionUserEmailVerified,
  ] = useState(false);

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const verifyEmail = async (email: string, isPrivate = false) => {
    setFormErrorSummaryValue([]);
    setDisplayVerifiedStatus(false);
    setIsLoading(true);

    try {
      const postResponse = await axios.post(`${prwebApi}/users/verify`, {
        entityKey: email,
      });

      if (postResponse.status === 200) {
        addCache(postResponse.data);
        isPrivate
          ? setIsDistributionUserEmailVerified(true)
          : setIsDistributionContactEmailVerified(true);
      } else {
        setKiteStatus('warning');
        setKiteText(
          `An email has been sent to ${trimEmail(
            email,
          )} for verification.  Please check your email inbox.`,
        );
        setDisplayVerifiedStatus(true);
      }
    } catch (error) {
      setKiteStatus('danger');
      setKiteText(
        `There was an error verifying the following email: ${trimEmail(email)}`,
      );
      setDisplayVerifiedStatus(true);
      handleApiValidationError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const trimEmail = (text: string): string => {
    return trimToLength(text, 30);
  };

  const trimToLength = (text: string, maxLength: number): string => {
    return text.length > maxLength ? text.substr(0, maxLength) + '...' : text;
  };

  const handleApiValidationError = (error: ErrorType): void => {
    const errors: Array<string> = SaveUtility.handleApiErrors(error);
    setFormErrorSummaryValue(errors);
    for (const msg of errors) {
      if (
        msg.toLowerCase().includes(distributionData.userEmail.toLowerCase())
      ) {
        setIsDistributionUserEmailVerified(false);
      } else if (
        msg
          .toLowerCase()
          .includes(distributionData.primaryContactEmail.toLocaleLowerCase())
      ) {
        setIsDistributionContactEmailVerified(false);
      }
    }
  };

  const validateFull = useCallback(
    async () => {
      setIsLoading(true);
      setFormErrorSummaryValue([]);

      const dataToSend = SaveUtility.getDataToSend(distributionData);

      try {
        const postResponse = await axios.post(
          `${prwebApi}/distribution/item/validateFull`,
          dataToSend,
        );

        setIsLoading(false);
        if (postResponse.status === 200 || postResponse.status === 201) {
          return true;
        } else {
          handleApiValidationError(postResponse.data);
        }
      } catch (error) {
        setIsLoading(false);
        handleApiValidationError(error);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      distributionData.package,
      distributionData.body,
      distributionData.primaryContactCompany,
      distributionData.primaryContactEmail,
      distributionData.primaryContactName,
      distributionData.primaryContactPhone,
      distributionData.primaryContactPhoneCountryCode,
      distributionData.primaryContactPhoneExtension,
      distributionData.primaryContactWebsite,
      distributionData.secondaryContactCompany,
      distributionData.secondaryContactEmail,
      distributionData.secondaryContactName,
      distributionData.secondaryContactPhone,
      distributionData.secondaryContactPhoneCountryCode,
      distributionData.secondaryContactPhoneExtension,
      distributionData.secondaryContactWebsite,
      distributionData.headline,
      distributionData.summary,
      distributionData.userEmail,
      distributionData.distributionId,
      distributionData.prwebPressReleaseId,
      distributionData.distributionVersion,
      distributionData.distributionPRWebVersion,
      distributionData.publicationState,
      setIsLoading,
      isLoading,
    ],
  );

  const getVerifiedEmails = async (): Promise<Array<Verification>> => {
    try {
      const response = await axios.get(`${prwebApi}/users/all/verified`, {
        params: {
          type: 0,
          isVerified: true,
        },
      });

      if (response.data) {
        return response.data;
      }

      return [];
    } catch (e) {
      console.log(
        'something went wrong with get verified emails endpoint call',
        e,
      );
      return [];
    }
  };

  const loadCache = (verifications: Array<Verification>): void => {
    if (CacheService.exists(PRWEB_VERIFIED_EMAILS)) {
      return;
    }

    CacheService.set(PRWEB_VERIFIED_EMAILS, verifications);
  };

  const addCache = (verification: Verification): void => {
    let verifications: Array<Verification> = [];

    if (CacheService.exists(PRWEB_VERIFIED_EMAILS)) {
      verifications = CacheService.get(PRWEB_VERIFIED_EMAILS) as Array<
        Verification
      >;
      CacheService.remove(PRWEB_VERIFIED_EMAILS);
    }

    verifications.push(verification);

    CacheService.set(PRWEB_VERIFIED_EMAILS, verifications);
  };

  const checkEmailVerification = (): void => {
    setIsDistributionContactEmailVerified(false);
    setIsDistributionUserEmailVerified(false);

    if (!CacheService.exists(PRWEB_VERIFIED_EMAILS)) {
      return;
    }

    const verifiedEmails = CacheService.get(PRWEB_VERIFIED_EMAILS) as Array<
      Verification
    >;

    const contactEmailVerified =
      verifiedEmails.find(
        v =>
          v.entityKey.toLowerCase() ===
          distributionData.primaryContactEmail?.toLowerCase(),
      ) !== undefined;
    setIsDistributionContactEmailVerified(contactEmailVerified);

    const privateEmailVerified =
      verifiedEmails.find(
        v =>
          v.entityKey.toLowerCase() ===
          distributionData.userEmail?.toLowerCase(),
      ) !== undefined;
    setIsDistributionUserEmailVerified(privateEmailVerified);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepForward(validateFull), [
    validateFull,
  ]);

  // Update progress
  useEffect(() => {
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get all emails validated and put in FE cache
  // And check if contact email is verified
  useEffect(() => {
    const initVerifiedEmailsList = async (): Promise<void> => {
      const result = await getVerifiedEmails();
      loadCache(result);
      checkEmailVerification();
      setIsLoading(false);
    };

    initVerifiedEmailsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.stepContainer}>
      <div>
        {distributionData.isOnHold && (
          <OnHoldReasonsList reasons={onHoldReasons} />
        )}
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Verify Contact Information
          </Typography>
        </div>
        <div className={styles.stepSubtitle}>
          <Typography size="lg" color="black">
            Please verify your contact information before moving to the next
            step.
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
      <Paper>
        <div className={styles.verificationContentContainer}>
          <div>
            {formErrorSummaryValue.length > 0 && (
              <div className={styles.errorMessage}>
                Error validating press release:
                <ul>
                  {formErrorSummaryValue.map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <span>
            <div>
              <Typography size="lg" color="black">
                Contact Email
              </Typography>
            </div>
            <div className={styles.contactContainer}>
              <div>
                <Typography>{distributionData.primaryContactEmail}</Typography>
              </div>
              <div className={styles.verify}>
                {!isDistributionContactEmailVerified ? (
                  <Button
                    level="link"
                    onClick={() => {
                      verifyEmail(distributionData.primaryContactEmail);
                    }}
                    id={'verify-contact-email-button'}
                  >
                    Verify
                  </Button>
                ) : (
                  <CheckmarkSvg
                    className={styles.checkmark}
                    width={16}
                    height={16}
                  />
                )}
              </div>
            </div>
          </span>
          {distributionData.userEmail.toLocaleLowerCase() !==
          distributionData.primaryContactEmail.toLocaleLowerCase() ? (
            <span>
              <div>
                <Typography size="lg" color="black">
                  Private Email
                </Typography>
              </div>
              <div className={styles.contactContainer}>
                <div>
                  <Typography>{distributionData.userEmail}</Typography>
                </div>
                <div className={styles.verify}>
                  {!isDistributionUserEmailVerified ? (
                    <Button
                      level="link"
                      onClick={() => {
                        verifyEmail(distributionData.userEmail, true);
                      }}
                      id={'verify-private-email-button'}
                    >
                      Verify
                    </Button>
                  ) : (
                    <CheckmarkSvg
                      className={styles.checkmark}
                      width={16}
                      height={16}
                    />
                  )}
                </div>
              </div>
            </span>
          ) : null}
          <Kite status={kiteStatus} shouldShow={displayVerifiedStatus}>
            {kiteText}
          </Kite>
        </div>
      </Paper>
      <ExitWizardPrompt
        confirmMessage={'Are you sure you want to leave the release wizard?'}
      />
    </div>
  );
};

export default VerificationStep;
