import React from 'react';

import { Button, Paper } from '@cision/rover-ui';

import styles from './payment-step.module.css';

interface RecommendedCouponProps {
  coupon: CouponDetails;
  applicableAmount: number;
  onApply: () => void;
}
const RecommendedCoupon = ({
  coupon,
  applicableAmount,
  onApply,
}: RecommendedCouponProps) => {
  return (
    <Paper className={styles.couponContainer}>
      <div>
        <strong>
          You have&nbsp;
          {coupon.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          &nbsp;in PR Web Bonus credit available.
        </strong>
      </div>
      <Button onClick={onApply} level="primary">
        Apply&nbsp;
        {Math.min(coupon.amount, applicableAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
        &nbsp;Credit
      </Button>
    </Paper>
  );
};
export default RecommendedCoupon;
