import React, { useContext } from 'react';

import WizardContext from './wizard-context';

interface Props {
  children?: JSX.Element;
  order?: number;
  nextLabel?: string;
  backLabel?: string;
  submit?: boolean;
  saveDraftButton?: boolean;
  previewButton?: boolean;
  upgradeButton?: boolean;
  saveRequired?: boolean;
  backButtonIsVisible?: boolean;
}

const WizardStep = ({
  children = <div>default</div>,
  order = 1,
  backLabel = 'Back',
  nextLabel = 'Next',
  submit = false,
  saveDraftButton = false,
  previewButton = false,
  upgradeButton = false,
  saveRequired = true,
  backButtonIsVisible = true,
}: Props) => {
  const wizardData = useContext(WizardContext);
  if (order !== wizardData.currentStep) {
    return null;
  }

  wizardData.setIsSubmitStep(submit);
  wizardData.setHasSaveDraftButton(saveDraftButton);
  wizardData.setHasPreviewButton(previewButton);
  wizardData.setHasUpgradeButton(upgradeButton);
  wizardData.setSaveRequired(saveRequired);

  if (nextLabel) {
    wizardData.setNextLabel(nextLabel);
  }

  if (backLabel) {
    wizardData.setBackLabel(backLabel);
  }

  wizardData.setBackButtonIsVisible(backButtonIsVisible);

  return children;
};

export default WizardStep;
