import React, { useEffect, useState } from 'react';

import { Tooltip, Typography } from '@cision/rover-ui';
import ReactQuill, { Quill } from 'react-quill';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import FieldValidationIssue from '../field-validation-issue';

import 'react-quill/dist/quill.snow.css';
import PRWLink from './prw-link';
import PRWTheme from './prw-theme';
import styles from './richtexteditor.module.css';

import './richtexteditor.scss';

Quill.register(PRWLink);
Quill.register('themes/prw', PRWTheme);

const QBlockFormat = Quill.import('formats/blockquote');
class ParagraphBlock extends QBlockFormat {}
ParagraphBlock.blotName = 'block';
ParagraphBlock.tagName = 'P';
Quill.register(ParagraphBlock);

const QListFormat = Quill.import('formats/list');
class UnorderedList extends QListFormat {}
UnorderedList.blotName = 'list';
UnorderedList.tagName = 'UL';
Quill.register(UnorderedList);

interface Props {
  id: string;
  onChange: (value: string) => void;
  initialValue: string;
  allowLinks: boolean;
  issues: string[];
  validate?: (value?: string) => Array<string>;
  label?: string;
  tooltip?: string;
}

const PRWRichTextEditor = ({
  id,
  onChange,
  initialValue,
  allowLinks,
  issues,
  validate,
  label,
  tooltip,
}: Props) => {
  const [currentIssues, updateCurrentIssues] = useState<string[]>(issues);

  const toolBarConfig = {
    container: allowLinks
      ? [{ list: 'bullet' }, 'link']
      : [[{ list: 'bullet' }]],
  };

  const allowableFormats = ['list', 'block', 'break'];
  allowLinks && allowableFormats.push('link');

  const changeHandler = (
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delta: any,
    source: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editor: any,
  ) => {
    if (source === 'user') {
      onChange(value);
      if (validate !== undefined) {
        const newIssues = validate(value);
        updateCurrentIssues(newIssues);
      }
    }
  };

  useEffect(() => {
    updateCurrentIssues(issues);
  }, [issues]);

  return (
    <div className={styles.richTextEditorContainer}>
      {label && (
        <Typography htmlFor={id} tag="p">
          {label}
          {tooltip && (
            <Tooltip
              tooltipWidth="300px"
              showOnHover={true}
              direction="right"
              content={<div>{tooltip}</div>}
            >
              <InfoSvg className={styles.icon} />
            </Tooltip>
          )}
        </Typography>
      )}
      <ReactQuill
        id={id}
        className={styles.textEditor}
        value={initialValue}
        onChange={changeHandler}
        modules={{ toolbar: toolBarConfig }}
        theme={'prw'}
        formats={allowableFormats}
      />
      {currentIssues && <FieldValidationIssue issues={currentIssues} id={id} />}
    </div>
  );
};

export default PRWRichTextEditor;
