import React, { useState } from 'react';

import { Button } from '@cision/rover-ui';

import { ReactComponent as CloseSvg } from '../../../../assets/close.svg';
import { ReactComponent as PDFSvg } from '../../../../assets/third-party/pdf-logo.svg';
import AttachmentModal from '../attachment-modal';

import styles from './attachments.module.css';

interface AttachmentsProps {
  config: PRWebConfig;
  attachments?: Array<Attachment> | undefined;
  removeAttachment: (filePath: string, assetId?: number) => void;
  addAttachment: (attachment: Attachment) => void;
}

const Attachments = ({
  config,
  attachments,
  removeAttachment,
  addAttachment,
}: AttachmentsProps) => {
  const trimToLength = (text = '', maxLength: number): string => {
    return text.length > maxLength ? text.substr(0, maxLength) + '...' : text;
  };

  const [toggleAttachmentModal, setToggleAttachmentModal] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<
    Attachment | undefined
  >(undefined);

  const onOpenAttachmentModal = (attachment?: Attachment) => {
    setCurrentAttachment(attachment);
    setToggleAttachmentModal(true);
  };

  const attachmentModalToggle = () => setToggleAttachmentModal(prev => !prev);

  return (
    <>
      <div className={styles.mediaTitle}>Media Attachments</div>
      <div>
        <Button
          level="link"
          onClick={() => {
            onOpenAttachmentModal();
          }}
          id={'add-attachments-button'}
        >
          <span>+ Add Attachments</span>
        </Button>

        <div>
          {attachments?.map(attachment => {
            const isPdf =
              attachment.fileName
                .toLowerCase()
                .split('.')
                .pop() === 'pdf';

            return (
              <div
                key={attachment.assetId || attachment.filePath}
                className={styles.attachmentContainer}
              >
                <div className={styles.attachmentPic}>
                  {isPdf ? (
                    <PDFSvg
                      className={styles.thumbnail}
                      onClick={() => onOpenAttachmentModal(attachment)}
                    />
                  ) : (
                    <img
                      className={styles.thumbnail}
                      src={attachment.base64Preview}
                      alt={attachment.altText}
                      onClick={() => onOpenAttachmentModal(attachment)}
                    />
                  )}
                </div>
                <div className={styles.attachmentDesc}>
                  <div>{trimToLength(attachment.caption, 50)}</div>
                </div>
                <div
                  className={styles.attachmentClose}
                  onClick={() => {
                    removeAttachment(attachment.filePath, attachment.assetId);
                  }}
                >
                  <div className={styles.circle}>
                    <CloseSvg width={16} height={16} fill={'#fff'} />
                  </div>
                </div>
              </div>
            );
          }) || undefined}
        </div>
      </div>

      {toggleAttachmentModal && (
        <AttachmentModal
          config={config}
          isOpen={toggleAttachmentModal}
          addAttachment={addAttachment}
          toggle={attachmentModalToggle}
          attachment={currentAttachment}
        />
      )}
    </>
  );
};

export default Attachments;
