import React, { useEffect } from 'react';

import {
  PRW_SID,
  SOCIAL_MEDIA_FACEBOOK,
  SOCIAL_MEDIA_LINKEDIN,
  SOCIAL_MEDIA_TWITTER,
} from '../constants';

export const OAuth = {
  sendRequest: async query => {
    if (query === '?state=success') {
      return Promise.resolve(query);
    }
    const prwSidCookie = JSON.parse(OAuth.getCookie(PRW_SID) || '{}');

    let bearerToken = '';
    if (prwSidCookie && prwSidCookie.sid) {
      bearerToken = `Bearer ${prwSidCookie.sid}`;
    }

    const uri = await OAuth.createUrl(query);

    const METHOD = 'GET',
      HEADER = 'Authorization',
      URI = uri,
      xhr = new XMLHttpRequest(),
      jsonTryParse = OAuth.jsonTryParse; // onload is bound to its own scope, so this._jsonTryParse won't work there

    return new Promise(function p(resolve, reject) {
      xhr.open(METHOD, URI, true);
      xhr.setRequestHeader(HEADER, bearerToken);
      xhr.onload = function load() {
        if (this.status === 200) {
          if (this.responseText === 'success') {
            //here,  check for success from NG api.  We receive a 'success' in this form when
            //it is the NG api that is persisting the tokens and not c3
            const ret = '?state=success';
            resolve(ret);
          } else {
            //here, the NG api uses C3.  In that code we get back the full route so we might as well use it.
            const href =
                jsonTryParse(this.responseText) ||
                this.responseText.replace(/"/g, ''),
              qs = href.match(/(\?.+)/gi),
              ret = qs.length > 0 ? qs[0] : href;
            resolve(ret);
          }
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
            message: jsonTryParse(this.responseText)?.message ?? '',
          });
        }
      };
      xhr.onerror = function error() {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
          message: jsonTryParse(this.responseText)?.message ?? '',
        });
      };
      xhr.send();
    });
  },
  getCookie: name => {
    const nameEq = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(nameEq) === 0) {
        return c.substring(nameEq.length, c.length);
      }
    }
    return null;
  },
  setCookie: (key, value) => {
    const threeMinutesInMilliseconds = 3 * 60000;
    const expiry = new Date(
      new Date().getTime() + threeMinutesInMilliseconds,
    ).toUTCString();
    document.cookie = `${key}=${value}; expires=${expiry}; path=/`;
  },
  /** SOURCE:  http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript **/
  getParams: query => {
    if (typeof query !== 'string') {
      return {};
    }
    return (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, ' '))
          : '';
        return params;
      }, {});
  },
  createUrl: async query => {
    const configPromise = fetch('/config.json');
    let myConfig;
    await configPromise
      .then(res => res.json())
      .then(config => {
        myConfig = config;
      });
    const params = OAuth.getParams(query);
    let uri;

    // eslint-disable-next-line no-undef
    // const config = JSON.parse(prwConfig);
    uri = `${myConfig.prwebApi.url}/social/authorizationprweb/confirm?`;

    const keys = Object.keys(params);

    for (let i = 0; i < keys.length; i++) {
      uri += `${keys[i]}=${params[keys[i]]}&`;
    }

    return uri.replace(/&+$/, '');
  },
  jsonTryParse: obj => {
    try {
      const ret = JSON.parse(obj);
      return ret;
    } catch (exc) {
      return null;
    }
  },
};

const OAuthWindow = () => {
  const run = () => {
    if (!window.opener) {
      window.close();
      return;
    }

    let authStatus = '';
    let socialMedia = SOCIAL_MEDIA_TWITTER;
    let search = window.location.search;
    const params = OAuth.getParams(search);
    if (window.location.pathname.endsWith('linkedin')) {
      socialMedia = SOCIAL_MEDIA_LINKEDIN;
      search += '&socialMedia=linkedin';
    } else if (window.location.pathname.endsWith('facebook')) {
      socialMedia = SOCIAL_MEDIA_FACEBOOK;
      search += '&socialMedia=facebook';
    } else if (window.location.pathname.endsWith('twitter')) {
      search += '&socialMedia=twitter';
    }

    if (params && (params.error || params.error_description)) {
      window.close();
      return;
    }

    OAuth.sendRequest(search)
      .then(resp => {
        authStatus = resp;
      })
      .catch(err => {
        authStatus = JSON.stringify(err);
      })
      .then(() => {
        if (socialMedia === SOCIAL_MEDIA_LINKEDIN) {
          OAuth.setCookie('linkedinOAuthComplete', 'true');
          OAuth.setCookie('linkedinOAuthStatus', authStatus);
        }
        if (socialMedia === SOCIAL_MEDIA_FACEBOOK) {
          OAuth.setCookie('facebookOAuthComplete', 'true');
          OAuth.setCookie('facebookOAuthStatus', authStatus);
        }
        if (socialMedia === SOCIAL_MEDIA_TWITTER) {
          OAuth.setCookie('twitterOAuthComplete', 'true');
          OAuth.setCookie('twitterOAuthStatus', authStatus);
        }
      });
  };
  useEffect(run, []);
  return (
    <div>
      Done! If this pop-up window does not close automatically, please close it
      and refresh your page.
    </div>
  );
};

export default OAuthWindow;
