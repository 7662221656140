import Validator, { Validation } from './GenericValidator';

const InvalidMsg = {
  refundReasonValidation: {
    required: () => 'Please provide a refund reason.',
  } as Validation,
  amountValidation: {
    required: () => 'Please provide a refund amount.',
    isNumeric: () => 'The refund amount field must be in digits.',
    isBetween: () => 'The refund amount must be a positive number.',
    twoDecimalPlaces: () =>
      'The refund amount cannot have more than 2 decimal places.',
  } as Validation,
  noteValidation: {
    noHtml: () => 'The notes field cannot have html markup.',
  } as Validation,
  transactionValidation: {
    required: () => 'Please provide a transaction id message.',
    noHtml: () => 'The transaction field cannot have html markup.',
  } as Validation,
};

export const validateRefundReason = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.refundReasonValidation }).required()
    .results;

export const validateRefundAmount = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.amountValidation })
    .required()
    .isNumeric()
    .isBetween(0, Number.MAX_SAFE_INTEGER)
    .twoDecimalPlaces().results;

export const validateRefundNote = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.noteValidation }).noHtml().results;

export const validateTransaction = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.transactionValidation })
    .required()
    .noHtml().results;

export const validateCreateRefund = (
  reason: string,
  amount: string,
  note?: string,
  transaction?: string,
) => {
  const refundReasonErrors = validateRefundReason(reason);
  const refundAmountErrors = validateRefundAmount(amount);
  const refundNoteErrors = validateRefundNote(note);
  const refundTransactionErrors = validateTransaction(transaction);

  const numErrors =
    refundReasonErrors.length +
    refundAmountErrors.length +
    refundNoteErrors.length;

  const isValid = numErrors === 0;

  return {
    isValid,
    refundReasonErrors,
    refundAmountErrors,
    refundNoteErrors,
    refundTransactionErrors,
  };
};

export interface CreateRefundError {
  isValid: boolean;
  refundReasonErrors: Array<string>;
  refundAmountErrors: Array<string>;
  refundNoteErrors: Array<string>;
  refundTransactionErrors?: Array<string>;
}
