import React, { useState } from 'react';

import { Button, Input } from '@cision/rover-ui';

import FieldValidationIssue from '../field-validation-issue';

interface InputWithButtonProps {
  id: string;
  buttonId: string;
  initialValue?: string;
  issues: string[];
  onChangeValue?: (value: string) => void;
  onSubmitHandler: () => void;
}

const InputWithButton = ({
  id,
  buttonId,
  initialValue,
  issues,
  onChangeValue,
  onSubmitHandler,
}: InputWithButtonProps) => {
  const [currentValue, setCurrentValue] = useState(initialValue ?? '');

  const changeHandler = (event: React.ChangeEvent) => {
    const newValue = (event.target as HTMLInputElement).value;
    if (newValue !== currentValue) {
      if (onChangeValue !== undefined) {
        onChangeValue(newValue);
      }
    }
    setCurrentValue(newValue);
  };

  const pressHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmitHandler();
    }
  };

  return (
    <div>
      <div style={{ display: 'inline-block', width: '78%' }}>
        <div
          id={`${id}-text_input_field_component_container`}
          className={'text_input_field_component_container'}
        >
          <Input
            id={id}
            style={{ width: '100%' }}
            value={currentValue}
            onChange={changeHandler}
            onKeyPress={pressHandler}
            autoFocus={true}
          ></Input>
        </div>
      </div>
      <div
        style={{
          paddingLeft: '5px',
          display: 'inline-block',
          width: '18%',
        }}
      >
        <Button id={buttonId} onClick={onSubmitHandler} level="secondary">
          Search
        </Button>
      </div>
      {issues && issues.length > 0 && (
        <FieldValidationIssue issues={issues} id={id} />
      )}
    </div>
  );
};

export default InputWithButton;
