import { CJL_PRICE, CSP_PRICE, PRIORITY_PRICE } from '../../constants';

import PRWebPackage from '../distribution-wizard/PRWebPackages';

const UpgradeUtility = {
  mapToApiStripePayment: (
    paymentIntent: string | undefined,
    paypalOrderId: string | undefined,
    universalCoupon: UniversalCoupon | undefined,
    appliedCoupon: CouponDetails | undefined,
    couponAmount: number,
    upgrade: Upgrade,
  ) => {
    const paymentData: StripePaymentOrder = {
      distributionId: upgrade.distributionId as number,
      prwebPressReleaseId: upgrade.prwebPressReleaseId as number,
      releaseDate: upgrade.releaseDate as string,
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: false,
      universalCouponCode:
        universalCoupon === undefined ? '' : universalCoupon.couponCode,
      couponCode: appliedCoupon === undefined ? '' : appliedCoupon.couponCode,
      couponAmount: couponAmount,
      order: {
        package: upgrade.package,
        cjl: 0,
        hasCisionSocialPost: false,
        priorityReleaseEnabled: upgrade.priority,
      },
      paymentIntent: paymentIntent ?? '',
    };
    return paymentData;
  },
  mapToApiPaymentOrder: (
    creditCard: CreditCard | undefined,
    paypalOrderId: string,
    universalCoupon: UniversalCoupon | undefined,
    appliedCoupon: CouponDetails | undefined,
    couponAmount: number,
    upgrade: Upgrade,
  ) => {
    const paymentData: PaymentOrder = {
      distributionId: upgrade.distributionId as number,
      prwebPressReleaseId: upgrade.prwebPressReleaseId as number,
      releaseDate: upgrade.releaseDate,
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: false,
      universalCouponCode:
        universalCoupon === undefined ? '' : universalCoupon.couponCode,
      couponCode: appliedCoupon === undefined ? '' : appliedCoupon.couponCode,
      couponAmount: couponAmount,
      order: {
        package: upgrade.package,
        cjl: 0,
        hasCisionSocialPost: false,
        priorityReleaseEnabled: upgrade.priority,
      },
      creditCard: { ...creditCard } as CreditCard,
    };
    return paymentData;
  },
  getAdjustedUpgradeAmount: (upgradeData: Upgrade) => {
    const currentPackage = PRWebPackage.getPRWebPackage(
      upgradeData.initialPackage,
    );
    const releasePackage = PRWebPackage.getPRWebPackage(
      upgradeData.package || '',
    );

    let packageAmount = 0;

    if (currentPackage && releasePackage) {
      const currentPackageCents = Number(
        (currentPackage.price * 100).toFixed(2),
      );
      const releasePackageCents = Number(
        (releasePackage.price * 100).toFixed(2),
      );
      packageAmount += releasePackageCents - currentPackageCents;
      const result = Number((packageAmount / 100).toFixed(2));
      return result;
    }

    return packageAmount;
  },
  getOrderSubtotal: (upgradeData: Upgrade) => {
    const packageAmount = UpgradeUtility.getAdjustedUpgradeAmount(upgradeData);

    const packageAmountCents = Number((packageAmount * 100).toFixed(2));
    const priorityCents = Number((PRIORITY_PRICE * 100).toFixed(2));

    let orderAmount = packageAmountCents;
    if (upgradeData.priority) {
      orderAmount += priorityCents;
    }

    const result = Number((orderAmount / 100).toFixed(2));
    return result;
  },
  getUniversalCouponDiscount: (
    upgradeData: Upgrade,
    universalCoupon: UniversalCoupon | undefined,
  ) => {
    const subtotal = UpgradeUtility.getOrderSubtotal(upgradeData);

    if (universalCoupon && universalCoupon.isFixed) {
      const discount = Number((universalCoupon.amount || 0).toFixed(2));
      return Math.min(discount, subtotal);
    } else if (universalCoupon && !universalCoupon.isFixed) {
      const subtotalCents = Number((subtotal * 100).toFixed(2));

      const discountCents = Math.round(
        subtotalCents * (universalCoupon.percent || 0),
      );

      const result = Number((discountCents / 100).toFixed(2));
      return result;
    }
    return 0;
  },
  getOrderCouponDiscount: (
    upgradeData: Upgrade,
    universalCoupon: UniversalCoupon | undefined,
    coupon: CouponDetails | undefined,
  ) => {
    const subtotal = UpgradeUtility.getOrderSubtotal(upgradeData);
    const universalDiscount = UpgradeUtility.getUniversalCouponDiscount(
      upgradeData,
      universalCoupon,
    );

    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const universalDiscountCents = Number((universalDiscount * 100).toFixed(2));
    const couponDiscountCents =
      coupon && coupon.amount > 0
        ? Number((coupon.amount * 100).toFixed(2))
        : 0;

    const remainingAmount = subtotalCents - universalDiscountCents;
    if (couponDiscountCents > 0 && remainingAmount > 0) {
      const discount = Math.min(remainingAmount, couponDiscountCents);

      const result = Number((discount / 100).toFixed(2));
      return result;
    }
    return 0;
  },
  getOrderDiscount: (
    upgradeData: Upgrade,
    universalCoupon: UniversalCoupon | undefined,
    coupon: CouponDetails | undefined,
  ) => {
    const couponDiscount = UpgradeUtility.getOrderCouponDiscount(
      upgradeData,
      universalCoupon,
      coupon,
    );
    const universalDiscount = UpgradeUtility.getUniversalCouponDiscount(
      upgradeData,
      universalCoupon,
    );

    const couponDiscountCents = Number((couponDiscount * 100).toFixed(2));
    const universalDiscountCents = Number((universalDiscount * 100).toFixed(2));

    const discount = couponDiscountCents + universalDiscountCents;

    const result = Number((discount / 100).toFixed(2));
    return result;
  },
  getOrderTotal: (
    upgradeData: Upgrade,
    universalCoupon: UniversalCoupon | undefined,
    coupon: CouponDetails | undefined,
  ) => {
    const subtotal = UpgradeUtility.getOrderSubtotal(upgradeData);
    const universalDiscount = UpgradeUtility.getUniversalCouponDiscount(
      upgradeData,
      universalCoupon,
    );
    const couponDiscount = UpgradeUtility.getOrderCouponDiscount(
      upgradeData,
      universalCoupon,
      coupon,
    );
    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const universalDiscountCents = Number((universalDiscount * 100).toFixed(2));
    const couponDiscountCents = Number((couponDiscount * 100).toFixed(2));

    let total = subtotalCents;
    if (total > 0 && universalCoupon) {
      total -= Math.min(total, universalDiscountCents);
    }
    if (total > 0 && coupon) {
      total -= Math.min(total, couponDiscountCents);
    }

    const result = Number((total / 100).toFixed(2));
    return result;
  },
};

export default UpgradeUtility;
