const CacheService = {
  get: (key: string): unknown => {
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }

    return JSON.parse(item);
  },

  set: (key: string, value: unknown) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  exists: (key: string): boolean => {
    return !(sessionStorage.getItem(key) === null);
  },

  remove: (key: string) => {
    sessionStorage.removeItem(key);
  },

  removeAll: () => {
    sessionStorage.clear();
  },
};

export default CacheService;
