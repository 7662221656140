import React, { useState, useEffect } from 'react';

import { Button, Responsive, Paper } from '@cision/rover-ui';
import cookie from 'js-cookie';

import { useParams, useHistory } from 'react-router-dom';

import { ReactComponent as ForwardSvg } from '../../assets/arrow-forward.svg';
import DistributionStatus from '../../components/distribution-list/distribution-status-enum';
import NavigationBackwardArrow from '../../components/navigation-backward-arrow/navigation-backward-arrow';
import Preview from '../../components/preview';
import ReceiptModal from '../../components/receipt-modal';
import {
  ADMIN_GROUP,
  EDITOR_GROUP,
  LOGIN_AS,
  USER_GROUP_COOKIE,
} from '../../constants';
import PRWebFeatures from '../../utils/PRWebFeatures';
import PRWebPackage from '../distribution-wizard/PRWebPackages';

import styles from './distributions-details.module.css';

interface Props {
  config: PRWebConfig;
  distro: Distribution;
}

const DistributionDetails: React.FC<Props> = ({ config, distro }: Props) => {
  const history = useHistory();
  const { id: distributionId } = useParams();
  const [distributionPackage, setDistributionPackage] = useState<
    PRWebPackage | undefined
  >(undefined);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const showUpgrade = distro.publicationState !== DistributionStatus.Published;
  const isDistroInPendingStatus =
    distro.publicationState === DistributionStatus.PendingDistribution ||
    distro.publicationState === DistributionStatus.PendingEditorialReview;
  const currentGroup = cookie.get(USER_GROUP_COOKIE);
  const impersonating = cookie.get(LOGIN_AS) === 'true';
  const isAdminOrEditorGroup =
    currentGroup === ADMIN_GROUP || currentGroup === EDITOR_GROUP;

  const showEditRelease =
    isAdminOrEditorGroup || (impersonating && isDistroInPendingStatus);
  const canReplublish = false;
  // (isAdminOrEditorGroup || impersonating) &&
  // distro.publicationState === DistributionStatus.Published &&
  // PRWebFeatures.userSendDistributionToIris();

  const showRequestEdit =
    distro.publicationState === DistributionStatus.Published &&
    PRWebFeatures.enablePurchaseEdit();

  const openPreviewModal = () => {
    setShowPreview(true);
  };
  const closePreviewModal = () => {
    setShowPreview(false);
  };

  const openRecieptModal = () => {
    setShowReceipt(true);
  };
  const closeRecieptModal = () => {
    setShowReceipt(false);
  };

  const handleBackButtonClick = (): void => {
    history.goBack();
  };
  const openUpgradeWizard = () => {
    history.push({
      pathname: `/distribution/upgrade/${distributionId}`,
    });
  };

  const openRepublishWorkflow = () => {
    history.push({
      pathname: `/distribution/edit/${distributionId}`,
      state: { action: 'republish' },
    });
  };

  const openEditWorkflow = async () => {
    history.replace({
      pathname: `/distribution/edit/${distributionId}`,
      state: { action: 'edit' },
    });
  };

  const openRequestEditWorkflow = async () => {
    history.replace({
      pathname: `/distribution/purchaseedit/${distributionId}`,
    });
  };

  useEffect(() => {
    setDistributionPackage(
      PRWebPackage.getPRWebPackage(distro.package) as PRWebPackage,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <div>
        <Paper className={styles.releaseDetailContainer}>
          <Button
            id={'details-back-button'}
            level="tertiary"
            onClick={handleBackButtonClick}
          >
            <div className={styles.backButton}>
              <NavigationBackwardArrow />
              <span className={styles.backButtonText}>{'back'}</span>
            </div>
          </Button>
          <div>
            <h2>{distro.headline}</h2>
            <h3>{distro.summary}</h3>
            <p>Release ID: {distro.prwebPressReleaseId}</p>
            <p>Package Level: {distributionPackage?.name}</p>
          </div>
          <div>
            <p>
              Please note that edits to approved and published releases are no
              longer available from the PRWeb app.
            </p>
            <p>
              Please note that the distribution analytics for this release are
              not available in the PRWeb application. The analytics report will
              be emailed directly to the email address specified in the user
              contact section of this release.
            </p>
          </div>
          <div className={styles.spaceBetweenContainer}>
            <span>
              <Button
                id={'showReleaseReceiptButton'}
                onClick={openRecieptModal}
                level="link"
              >
                <ForwardSvg widths="24" height="24" />
                <span>Show My Receipt</span>
              </Button>
              <Button
                id={'showReleasePreviewButton'}
                onClick={openPreviewModal}
                level="link"
              >
                <ForwardSvg widths="24" height="24" />
                <span>Preview My Release</span>
              </Button>
              {showUpgrade && (
                <Button
                  id={'upgradeButton'}
                  onClick={openUpgradeWizard}
                  level="link"
                >
                  <ForwardSvg widths="24" height="24" />
                  <span>Upgrade</span>
                </Button>
              )}
              {showRequestEdit && (
                <Button
                  id={'requestEditButton'}
                  onClick={openRequestEditWorkflow}
                  level="link"
                >
                  <ForwardSvg widths="24" height="24" />
                  <span>Request an Edit</span>
                </Button>
              )}
            </span>
            <span>
              {showEditRelease && (
                <Button
                  id={'editButton'}
                  level="danger"
                  onClick={openEditWorkflow}
                >
                  Edit Release (editors only)
                </Button>
              )}
              &nbsp;
              {canReplublish && (
                <Button
                  id={'republishButton'}
                  onClick={openRepublishWorkflow}
                  level="danger"
                >
                  Republish
                </Button>
              )}
            </span>
          </div>

          <Preview
            isOpen={showPreview}
            close={closePreviewModal}
            prwebConfig={config}
            distribution={distro}
          />
          <ReceiptModal
            config={config}
            distributionId={Number(distributionId)}
            isOpen={showReceipt}
            closeModal={closeRecieptModal}
          />
        </Paper>
      </div>
    </Responsive.Container>
  );
};

export default DistributionDetails;
