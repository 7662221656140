import * as React from 'react';

import { Button, Loader } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import { useHistory, NavLink } from 'react-router-dom';

import { ReactComponent as DownAngleSvg } from '../../assets/angle-down-solid.svg';
import { ReactComponent as UpAngleSvg } from '../../assets/angle-up-solid.svg';
import { ReactComponent as LogoSvg } from '../../assets/cisionreversed_WhiteLogo.svg';
import {
  LOGIN_AS,
  PRW_SID,
  C3_AVAILABLE,
  USER_GROUP_COOKIE,
  PARTNER_ID,
} from '../../constants';
import CacheService from '../../utils/cache-service';
import PRWebPartner from '../../utils/PRWebPartner';
import Confirm from '../confirm';

import styles from './menu.module.css';

interface Link {
  name: string;
  to: string;
}

interface Props {
  links: Array<Link>;
  prwebApiUrl?: string;
}

const Menu: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useToggle = (initialState: any) => {
    const [isToggled, setIsToggled] = React.useState(initialState);

    // put [setIsToggled] into the useCallback's dependencies array
    // this value never changes so the callback is not going to be ever re-created
    const toggle = React.useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      () => setIsToggled((state: any) => !state),
      [setIsToggled],
    );

    return [isToggled, toggle];
  };

  const history = useHistory();
  const prwebApi = props.prwebApiUrl;
  const prwSid = JSON.parse(cookie.get(PRW_SID) || '{}');
  const impersonating = cookie.get(LOGIN_AS) === 'true';
  const partnerId = cookie.get(PARTNER_ID);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [toggleConfirm, setToggleConfirm] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');
  const [helpMenuExpanded, toggleHelpMenuExpanded] = useToggle(false);

  const isCreateReleaseButtonPage = (): boolean => {
    const createReleaseButtonPaths = ['/'];
    return createReleaseButtonPaths.some(path => {
      return path === window.location.pathname;
    });
  };

  const isActivePage = (link: string): boolean => {
    const route = '/';

    if (link === route) {
      return window.location.pathname === link;
    }

    return window.location.pathname.includes(link);
  };

  const onReturnToAdmin = (): void => {
    setConfirmMessage(`Are you sure you want to Return to Admin?`);
    setToggleConfirm(true);
  };

  const onConfirmYes = () => {
    setToggleConfirm(false);
    setIsLoading(true);
    returnToAdmin();
  };

  const onConfirmNo = () => {
    setToggleConfirm(false);
  };

  const createRelease = () =>
    history.push({ pathname: '/distribution/create' });

  const returnToAdmin = (): void => {
    const impersonateUrl = `${prwebApi}/admin/users/restoreadmin`;

    axios
      .post(impersonateUrl, {})
      .then(response => {
        if (response && response.status === 200) {
          cookie.set(
            PRW_SID,
            JSON.stringify({
              sid: response.data.sid,
              oktaLogin: response.data.oktaLogin,
            }),
          );

          cookie.set(C3_AVAILABLE, response.data.c3available);

          cookie.set(USER_GROUP_COOKIE, response.data.group);

          cookie.set(LOGIN_AS, response.data.impersonating);

          CacheService.removeAll();

          history.push({
            pathname: '/admin/users',
          });
        } else {
          console.log('invalid response post restore admin: ', response);
        }
      })
      .catch(e => {
        console.log('something went wrong with restore admin endpoint call', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const NavLinks = (): React.ReactNode =>
    props.links.map((link: Link) => (
      <div
        className={
          isActivePage(link.to)
            ? `${styles.menuItem} ${styles.menuItemActive}`
            : styles.menuItem
        }
        key={link.name}
      >
        <NavLink className={styles.menuLink} to={link.to}>
          {link.name}
        </NavLink>
      </div>
    ));

  const showLogo = (): React.ReactNode => {
    let partnerUrlLogoPath;
    if (partnerId) {
      partnerUrlLogoPath = PRWebPartner.getPRWebPartner()?.logoPath || '';
    }

    return partnerId && partnerUrlLogoPath ? (
      <img
        id="logo"
        data-qa="partnerlogo"
        className={styles.partnerLogo}
        src={partnerUrlLogoPath}
        alt="partner logo"
      />
    ) : (
      <LogoSvg
        id="logo"
        data-qa="logo"
        width="95"
        height="35"
        viewBox="0 0 600 120"
        preserveAspectRatio="xMidYMid meet"
      />
    );
  };

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.menuLogo}>{showLogo()}</div>
        <div className={styles.menuList}>{NavLinks()}</div>
        {impersonating &&
          (isLoading ? (
            <Loader style={{ marginTop: '0' }} />
          ) : (
            <div className={styles.impersonateMenu}>
              <div className={styles.impersonateItem}>
                <span className={styles.impersonateInfo}>
                  Logged In as
                  <br></br>
                  <strong>{prwSid.oktaLogin}</strong>
                </span>
              </div>
              <Button
                id={'returnToAdminHeaderButton'}
                level="primary"
                style={{
                  backgroundColor: '#ed7d31',
                  borderColor: '#ed7d31',
                  margin: '10px',
                }}
                onClick={onReturnToAdmin}
              >
                Return to Admin
              </Button>
            </div>
          ))}
        {isCreateReleaseButtonPage() ? (
          <Button
            id={'createDistroHeaderButton'}
            level="primary"
            onClick={createRelease}
          >
            Create Release
          </Button>
        ) : null}
        <div
          className={helpMenuExpanded ? styles.helpMenuHover : styles.helpMenu}
        >
          <div className={styles.menuItem}>
            <button
              className={
                helpMenuExpanded
                  ? styles.helpMenuItemHover
                  : styles.helpMenuItem
              }
              onClick={() => toggleHelpMenuExpanded()}
              aria-label="Questions? Please reach us at editorial@prweb.com"
            >
              Help
            </button>
            <span aria-hidden>
              {helpMenuExpanded ? (
                <UpAngleSvg
                  className={styles.svgAnglesHover}
                  onClick={() => toggleHelpMenuExpanded()}
                />
              ) : (
                <DownAngleSvg
                  className={styles.svgAngles}
                  onClick={() => toggleHelpMenuExpanded()}
                />
              )}
            </span>{' '}
            {helpMenuExpanded && (
              <div className={styles.helpMenuContentBlock}>
                <span className={styles.helpMenuContent}>
                  Questions?
                  <br />
                  Please reach us at{' '}
                  <a
                    href="mailto:editorial@prweb.com"
                    className={styles.helpMenuContent}
                  >
                    editorial@prweb.com
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.logoutMenu}>
          <div className={styles.menuItem}>
            <NavLink className={styles.menuLink} to="/logout">
              Logout
            </NavLink>
          </div>
        </div>
      </div>
      <Confirm
        isOpen={toggleConfirm}
        text={confirmMessage}
        yes={onConfirmYes}
        no={onConfirmNo}
      ></Confirm>
    </>
  );
};

export default Menu;
