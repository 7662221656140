import React, { FC, useState } from 'react';

import { Paper, Typography } from '@cision/rover-ui';

import PayPalButton from './PayPalButton';

import styles from './purchaseedit-summary.module.css';

import PurchaseEditWizardContext from './PurchaseEditWizardContext';

interface Props {
  config: PRWebConfig;
  isCreditCard: boolean;
  updateIsLoading: (status: boolean) => void;
  handlePaymentError: (error: any) => void;
}

const formatUSD = (price: number) => {
  return Number(price).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const PurchaseEditSummary: FC<Props> = ({
  config,
  isCreditCard,
  updateIsLoading,
  handlePaymentError,
}: Props) => {
  const editPrice = 120;

  return (
    <Paper className={styles.orderSummaryContainer}>
      <div className={styles.sectionTitle}>
        <Typography weight="bold" color="black" className={styles.font16}>
          Summary
        </Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName} id="qa-selected-package-info">
          Release edit
        </span>
        <span id="qa-selected-package-amount">{formatUSD(editPrice)}</span>
      </div>

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName}>Subtotal</span>
        <span>{formatUSD(editPrice)}</span>
      </div>

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={`${styles.orderSummaryName} ${styles.orderTotal}`}>
          <Typography weight="bold" color="black">
            Order Total
          </Typography>
        </span>
        <span id="qa-final-order-total">
          <Typography size="xl" weight="bold" color="black">
            {formatUSD(editPrice)}
          </Typography>
        </span>
      </div>

      {!isCreditCard && editPrice > 0 && (
        <PayPalButton
          config={config}
          updateIsLoading={updateIsLoading}
          handlePaymentError={handlePaymentError}
        />
      )}
    </Paper>
  );
};
export default PurchaseEditSummary;
