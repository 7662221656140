import React, { useEffect, useState } from 'react';

import { Typography, Button, Loader } from '@cision/rover-ui';
import axios from 'axios';

import Footer from '../../components/footer';
import Header from '../../components/header';
import PackageCard from '../../components/package-card';
import ViewTitleBar from '../../components/view-title-bar/ViewTitleBar';
import {
  STANDARD_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
} from '../../constants';
import NotFound from '../../pages/NotFound';
import PRWebBundles from '../../utils/PRWebBundles';
import PRWebFeatures from '../../utils/PRWebFeatures';
import PRWebPackage from '../distribution-wizard/PRWebPackages';

import RadioSelectField, { StylableCard } from './RadioSelectField';
import ShoppingCart from './ShoppingCart';

import styles from './store.module.css';

interface Props {
  config: PRWebConfig;
}

const Store: React.FC<Props> = ({ config }: Props) => {
  const enableBundleStorePage = PRWebFeatures.enableBundleStorePage();
  const prwebApi = config.prwebApi.url;
  const [isLoading, setIsLoading] = useState(true);
  const packages = [
    PRWebPackage.getPRWebPackage(STANDARD_PACKAGE_ID) as PRWebPackage,
    PRWebPackage.getPRWebPackage(ADVANCED_PACKAGE_ID) as PRWebPackage,
    PRWebPackage.getPRWebPackage(PREMIUM_PACKAGE_ID) as PRWebPackage,
  ];

  const [storeCart, setStoreCart] = useState([] as BundlePackagePrice[]);
  const [storeBundles, setStoreBundles] = useState([] as BundlePackagePrice[]);
  const [selectedQuantityIndex, setSelectedQuantityIndex] = useState(-1);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(-1);

  const getStoreBundles = () => {
    setIsLoading(true);
    const cachedStoreBundles = PRWebBundles.getStoreBundles();
    if (cachedStoreBundles) {
      setStoreBundles(cachedStoreBundles);
      setIsLoading(false);
    } else {
      const getStoreBundlesUrl = `${prwebApi}/distribution/bundlepackages`;
      axios
        .get(getStoreBundlesUrl)
        .then(response => {
          if (response && response.status === 200) {
            const processedData = response.data
              // eslint-disable-next-line
              .map((bundle: any) => {
                return {
                  bundlePackagePriceId: bundle.bundlePackagePriceId,
                  description: bundle.description,
                  packageId: Number(bundle.packageId),
                  quantity: Number(bundle.quantity),
                  amount: Number(bundle.amount),
                };
              })
              .sort((a: BundlePackagePrice, b: BundlePackagePrice) =>
                a.packageId === b.packageId
                  ? a.quantity - b.quantity
                  : a.packageId - b.packageId,
              );
            setStoreBundles(processedData);
            PRWebBundles.setStoreBundles(processedData);
          }
        })
        .catch(error => {
          console.log('Error retrieving data: ', error);
          PRWebBundles.removeStoreBundles();
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getStoreCart = () => {
    const getCartUrl = `${prwebApi}/bundleorder/cart`;
    axios.get(getCartUrl).then(response => {
      if (response && response.status >= 200) {
        setStoreCart(response.data ?? []);
      }
    });
  };

  const searchForBundle = (packageIndex: number, quantityIndex: number) => {
    if (storeBundles) {
      return storeBundles.find(bundle => {
        return (
          Number(bundle.packageId) ===
            Number(packages[packageIndex].packageId) &&
          Number(bundle.quantity) ===
            Number(storeBundles[quantityIndex].quantity)
        );
      });
    }
  };

  const handleAddToCartClick = () => {
    if (selectedPackageIndex === -1 || selectedQuantityIndex === -1) {
      console.warn('Please select a package and quantity.');
    } else {
      const itemToAdd = searchForBundle(
        selectedPackageIndex,
        selectedQuantityIndex,
      );
      const addToCartUrl = `${prwebApi}/bundleorder/cart`;
      if (itemToAdd) {
        axios.post(addToCartUrl, [...storeCart, itemToAdd]).then(response => {
          if (response && response.status >= 200) {
            return response.data;
          }
        });
        setStoreCart(prev => [...prev, itemToAdd]);
      }
      handleRestartClick();
    }
  };

  const handleRestartClick = () => {
    setSelectedPackageIndex(-1);
    setSelectedQuantityIndex(-1);
  };

  const updateDataFieldByValue = (key: string, value?: number) => {
    if (key === 'bundle') {
      if (value !== undefined && -1 < value && value < packages.length) {
        setSelectedPackageIndex(value);
      }
    }
    if (key === 'quantity') {
      if (value !== undefined && -1 < value && value < storeBundles.length) {
        setSelectedQuantityIndex(value);
      }
    }
  };

  const getBeforeDiscount = () => {
    if (selectedPackageIndex === -1 || selectedQuantityIndex === -1) {
      return '––';
    }
    const packageObject = packages[selectedPackageIndex];
    const quantityObject = storeBundles[selectedQuantityIndex];

    return quantityObject.quantity * Number(packageObject.price);
  };

  const getStoreTotal = () => {
    if (selectedPackageIndex === -1 || selectedQuantityIndex === -1) {
      return '––';
    }
    const packageObject = packages[selectedPackageIndex];
    const quantityObject = storeBundles[selectedQuantityIndex];

    const bundleObject = storeBundles.find(
      bundle =>
        bundle.packageId === Number(packageObject.packageId) &&
        bundle.quantity === quantityObject.quantity,
    );

    if (bundleObject) {
      return bundleObject.amount;
    }
    return '––';
  };

  const getAmountSaved = () => {
    const discountSaved = Number(getBeforeDiscount()) - Number(getStoreTotal());
    if (isNaN(Number(discountSaved))) {
      return '––';
    }
    return discountSaved;
  };

  useEffect(() => {
    if (enableBundleStorePage) {
      getStoreBundles();
      getStoreCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!enableBundleStorePage) {
    return <NotFound />;
  }

  return (
    <>
      <Header prwebApiUrl={config.prwebApi.url} />
      {isLoading && <Loader />}
      {!isLoading && (
        <ViewTitleBar
          fixed={true}
          title={'Store'}
          content={
            <ShoppingCart
              storeCart={storeCart}
              packages={packages}
              config={config}
            />
          }
        />
      )}
      {!isLoading && (
        <div className={styles.customContainer}>
          <div
            className={`${styles.releaseTitle} ${styles.colContainer} ${styles.padContainer}`}
          >
            <Typography color="black" weight="bold" size="xl2">
              Bundles
            </Typography>
            <Typography>
              Build press release bundles to save money. The more you buy the
              more you save.
            </Typography>
          </div>
          <div className={`${styles.rowContainer} ${styles.padContainer}`}>
            <PackageCard showBasic={false} />
          </div>

          <div className={styles.lineBreakContainer}>
            <Typography color="black" weight="bold">
              Step 1. Choose a release type
            </Typography>
          </div>

          <div className={`${styles.rowContainer} ${styles.padContainer}`}>
            <RadioSelectField
              label={'bundle'}
              selectedIndex={selectedPackageIndex}
              onChangeValue={updateDataFieldByValue}
            >
              <StylableCard id={'select-standard-bundle'}>
                <Typography color="black" weight="bold" size="xl">
                  Standard
                </Typography>
                <Typography>Increased Online Visibility</Typography>
              </StylableCard>
              <StylableCard id={'select-advanced-bundle'}>
                <Typography color="black" weight="bold" size="xl">
                  Advanced
                </Typography>
                <Typography>Increased Influencer Visibility</Typography>
              </StylableCard>
              <StylableCard id={'select-premium-bundle'}>
                <Typography color="black" weight="bold" size="xl">
                  Premium
                </Typography>
                <Typography>Largest Influencer Visibility</Typography>
              </StylableCard>
            </RadioSelectField>
          </div>

          <div className={styles.lineBreakContainer}>
            <Typography color="black" weight="bold">
              Step 2. Choose a quantity{' '}
              <Typography tag="span" className={styles.italic}>
                (to use over 12 months)
              </Typography>
            </Typography>
          </div>

          <div className={`${styles.rowContainer} ${styles.padContainer}`}>
            <RadioSelectField
              label={'quantity'}
              selectedIndex={selectedQuantityIndex}
              onChangeValue={updateDataFieldByValue}
            >
              {storeBundles.slice(0, 4).map((item, index) => {
                return (
                  <StylableCard
                    id={`select-quantity-${item.quantity}`}
                    key={index}
                  >
                    <Typography color="black" weight="bold" size="xl">
                      {item.quantity} Releases
                    </Typography>
                    <Typography
                      className={styles.discountColor}
                      size="md"
                      weight="bold"
                    >
                      SAVE&nbsp;{item.description}
                    </Typography>
                  </StylableCard>
                );
              })}
            </RadioSelectField>
          </div>

          <div className={styles.lineBreakContainer}>
            <Typography color="black" weight="bold">
              Step 3. Purchase
            </Typography>
          </div>

          <div className={`${styles.rowContainer} ${styles.padContainer}`}>
            <StylableCard
              selectable={false}
              className={
                styles.purchaseCard +
                (-1 < selectedPackageIndex
                  ? ` ${styles.selectedCardColor}`
                  : '')
              }
            >
              <Typography weight="bold" size="xl">
                {-1 < selectedPackageIndex
                  ? packages[selectedPackageIndex].name
                  : 'Step 1 Selection'}
              </Typography>
              <Typography color="black" weight="bold">
                RELEASE TYPE
              </Typography>
            </StylableCard>
            <StylableCard
              selectable={false}
              className={
                styles.purchaseCard +
                (-1 < selectedQuantityIndex
                  ? ` ${styles.selectedCardColor}`
                  : '')
              }
            >
              <Typography weight="bold" size="xl">
                {-1 < selectedQuantityIndex &&
                selectedQuantityIndex < storeBundles.length
                  ? `${storeBundles[selectedQuantityIndex].quantity} Releases`
                  : 'Step 2 Selection'}
              </Typography>
              <Typography color="black" weight="bold">
                RELEASE QUANTITY
              </Typography>
            </StylableCard>
            <StylableCard
              selectable={false}
              className={
                styles.purchaseCard +
                (-1 < selectedQuantityIndex
                  ? ` ${styles.discountCardColor}`
                  : '')
              }
            >
              <Typography weight="bold" size="xl">
                {-1 < selectedQuantityIndex
                  ? `${storeBundles[selectedQuantityIndex].description}`
                  : 'Discount %'}
              </Typography>
              <Typography color="black" weight="bold">
                APPLIED DISCOUNT
              </Typography>
            </StylableCard>

            <div className={`${styles.colContainer} ${styles.summaryDetail}`}>
              <div className={styles.rowContainer}>
                <Typography color="black">Before discount</Typography>
                <Typography color="black">
                  {getBeforeDiscount().toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </div>
              <div className={styles.rowContainer}>
                <Typography color="black">Amount saved</Typography>
                <Typography color="black">
                  {getAmountSaved().toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </div>
              <div className={`${styles.rowContainer} ${styles.summaryTotal}`}>
                <Typography color="black" weight="bold">
                  Subtotal
                </Typography>
                <Typography color="black" weight="bold">
                  {getStoreTotal().toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={`${styles.lineBreakContainer} ${styles.orderButtonContainer}`}
          >
            <Button
              id={'bundle-reset-button'}
              level="secondary"
              onClick={handleRestartClick}
            >
              Reset
            </Button>
            <Button
              id={'bundle-add-cart-button'}
              level="primary"
              onClick={handleAddToCartClick}
            >
              Add to cart
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Store;
