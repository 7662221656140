import React, { useEffect, useState } from 'react';

import { Button, Typography, TabMenu } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import InputWithButton from '../../components/input-with-button';

interface Props {
  config: PRWebConfig;
}

interface OrderRefund {
  orderId: number;
  oktaLogin: string;
  amount: number;
  createdDate: string;
  releaseId: number;
  directToIris: boolean;
}

interface ViewRefundRequest {
  orderRefundRequestId: number;
  orderId: number;
  status: string;
  amount: number;
  refundableAmount: number;
  refundedAmount: number;
  createdDate: Date;
}

const ViewRefundRequests: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const prwebApi = props.config.prwebApi.url;
  const [refundRequestsList, setRefundRequestsList] = useState(
    [] as ViewRefundRequest[],
  );

  const urlArgs = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const [status, setStatus] = useState(
    urlArgs?.status ? urlArgs.status : 'requested',
  );

  const formatUSD = (value: any) => {
    return Number(value).toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'USD',
    });
  };

  const getRefundRuests = async () => {
    const url = `${prwebApi}/admin/order/refund`;
    const query = encodeURIComponent(status?.trim() || '');
    const orderParams = {
      params: {
        status: query,
      },
    };
    history.push({
      pathname: '/admin/orders',
      search: `?status=${query}`,
    });
    await axios
      .get(url, orderParams)
      .then(response => {
        if (response && response.status >= 200 && response.status < 300) {
          setRefundRequestsList(response.data);
        }
      })
      .catch(error => {
        console.error('Error retrieving data: ', error);
      });

    //return [] as ViewRefundRequest[];
  };

  useEffect(() => {
    const queryParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if ('status' in queryParams && queryParams.status) {
      setStatus(decodeURIComponent(queryParams.status));
      getRefundRuests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectStatusChange = (value: string): void => {
    setStatus(value);
  };

  const goToOrderDetails = (orderId: number) => () => {
    history.push(`/admin/orders/${orderId}`);
  };

  return (
    <div>
      <div>
        <div style={{ display: 'inline-block', width: '60%' }}>
          <select
            id="selectStatus"
            name="selectStatus"
            onChange={(e: React.FormEvent): void =>
              onSelectStatusChange((e.target as HTMLInputElement).value)
            }
            value={status}
          >
            <option value="requested">Requested</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div
          style={{ paddingLeft: '5px', display: 'inline-block', width: '18%' }}
        >
          <Button
            modifiers={['secondary', 'round']}
            onClick={getRefundRuests}
            id={'GetRefundRequestsButton'}
          >
            Search
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Request Id</th>
            <th>Order Id</th>
            <th>Requested</th>
            <th>Status</th>
            <th>Refundable</th>
            <th>Refunded</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody id="adminRefundSearchList">
          {refundRequestsList?.length > 0 &&
            refundRequestsList.map(
              (refundRequest: ViewRefundRequest, index) => {
                return (
                  <tr key={index}>
                    <td>{refundRequest.orderRefundRequestId}</td>
                    <td>
                      <Button
                        level="link"
                        onClick={goToOrderDetails(refundRequest.orderId)}
                      >
                        {refundRequest.orderId}
                      </Button>
                    </td>
                    <td>{formatUSD(refundRequest.amount)}</td>
                    <td>{refundRequest.status}</td>
                    <td>{formatUSD(refundRequest.refundableAmount)}</td>
                    <td>{formatUSD(refundRequest.refundedAmount)}</td>
                    <td>
                      {moment(refundRequest.createdDate).format('M/D/YYYY')}
                    </td>
                  </tr>
                );
              },
            )}
        </tbody>
      </table>
    </div>
  );
};

const TAB_ORDERS = 'Orders List';
const TAB_VIEWREFUNDREQ = 'View Refund Requests';

const Orders: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [orderList, setOrderList] = useState([] as OrderRefund[]);
  const [searchField, setSearchField] = useState('');

  const urlArgs = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const [activeTab, setActiveTab] = useState(
    urlArgs?.status ? TAB_VIEWREFUNDREQ : TAB_ORDERS,
  );

  const prwebApi = props.config.prwebApi.url;

  const getOrders = async () => {
    const orderSearchUrl = `${prwebApi}/admin/order`;
    const query = encodeURIComponent(searchField?.trim() || '');
    const orderParams = {
      params: {
        searchText: query,
      },
    };
    history.push({
      pathname: '/admin/orders',
      search: `?search=${query}`,
    });
    await axios
      .get(orderSearchUrl, orderParams)
      .then(response => {
        if (response && response.status >= 200 && response.status < 300) {
          setOrderList(response.data);
        }
      })
      .catch(error => {
        console.error('Error retrieving data: ', error);
      });
  };

  const goToOrderDetails = (orderId: number) => () => {
    history.push(`/admin/orders/${orderId}`);
  };

  const changeTab = (tab: string) => () => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const queryParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    if ('search' in queryParams && queryParams.search) {
      setSearchField(decodeURIComponent(queryParams.search));
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <br />
      <TabMenu>
        <TabMenu.Item
          active={activeTab === TAB_ORDERS}
          onClick={changeTab(TAB_ORDERS)}
        >
          <Typography color="black" weight="bold" size="xl">
            Orders List&nbsp;
          </Typography>
        </TabMenu.Item>
        <TabMenu.Item
          active={activeTab === TAB_VIEWREFUNDREQ}
          onClick={changeTab(TAB_VIEWREFUNDREQ)}
        >
          <Typography color="black" weight="bold" size="xl">
            View Refund Requests&nbsp;
          </Typography>
        </TabMenu.Item>
      </TabMenu>
      <br />

      <div style={{ display: activeTab === TAB_ORDERS ? 'block' : 'none' }}>
        <InputWithButton
          id={'orderSearchField'}
          buttonId={'orderSearchButton'}
          onChangeValue={setSearchField}
          issues={[]}
          initialValue={searchField}
          onSubmitHandler={getOrders}
        />
        <table>
          <thead>
            <tr>
              <th>Order Id</th>
              <th>Login Username</th>
              <th>Order Amount</th>
              <th>Created Date</th>
              <th>Press Release Id</th>
              <th>Direct To Iris</th>
            </tr>
          </thead>
          <tbody id="adminOrderSearchList">
            {orderList?.length > 0 &&
              orderList.map((order: OrderRefund, index) => {
                return (
                  <tr key={index}>
                    <td>{order.orderId}</td>
                    <td>
                      <Button
                        level="link"
                        onClick={goToOrderDetails(order.orderId)}
                      >
                        {order.oktaLogin}
                      </Button>
                    </td>
                    <td>
                      {Number(order.amount).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                    <td>{moment(order.createdDate).format('M/D/YYYY')}</td>
                    <td>{order.releaseId}</td>
                    <td>{order.directToIris ? 'True' : 'False'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: activeTab === TAB_VIEWREFUNDREQ ? 'block' : 'none',
        }}
      >
        <ViewRefundRequests {...props} />
      </div>
    </div>
  );
};

export default Orders;
