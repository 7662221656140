import React from 'react';

import { Typography } from '@cision/rover-ui';

import Header from '../components/header';

const NotAuthorized: React.FC = () => (
  <div>
    <Header />
    <div style={{ textAlign: 'center', height: '220px' }}>
      <Typography size="xl2" color="inherit" weight="bold">
        401 - Not Authorized
      </Typography>
      <Typography size="xl">
        <p>These aren`t the droids you`re looking for.</p>
        <p>
          You should <a href="/">go about your business</a>.
        </p>
      </Typography>
    </div>
  </div>
);

export default NotAuthorized;
