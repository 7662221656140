/* eslint-disable jsx-a11y/iframe-has-title */
import React, { createRef, useEffect, useState } from 'react';

import { Modal, Button } from '@cision/rover-ui';
import axios from 'axios';

import styles from './receipt-modal.module.css';

interface Props {
  config: PRWebConfig;
  distributionId?: number;
  orderId?: number;
  isOpen: boolean;
  closeModal: () => void;
}

const ReceiptModal = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const [frameRefs, setFrameRefs] = useState<any[]>([]);
  const [receiptsHtml, setReceiptsHtml] = useState<any[]>([]);

  const print = () => {
    const newWindow = window.open('', 'PRINT', 'height=400,width=600');
    if (newWindow) {
      newWindow.document.write('<html><body>');
      for (let i = 0; i < receiptsHtml.length; i += 1) {
        newWindow.document.write(
          frameRefs[i].current.contentWindow.document.body.innerHTML,
        );
      }
      newWindow.document.write('</body></html>');

      newWindow.document.close(); // necessary for IE >= 10
      newWindow.focus(); // necessary for IE >= 10*/

      newWindow.print();
      newWindow.close();
    } else {
      console.error('Missing print Window');
    }
  };

  useEffect(() => {
    let receiptUrl = `${prwebApi}/order/receipt?`;
    if (props.distributionId) {
      receiptUrl += `distributionId=${props.distributionId}`;
    } else if (props.orderId) {
      receiptUrl += `orderId=${props.orderId}`;
    }
    axios
      .get(receiptUrl)
      .then(response => {
        if (
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data)
        ) {
          setReceiptsHtml(response.data);
          setFrameRefs(Array(response.data.length).fill(createRef()));
        }
      })
      .catch(error => {
        console.log(error);
        setReceiptsHtml([
          `Could not find receipt with id ${props.distributionId ||
            props.orderId}`,
        ]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={props.isOpen} size="lg">
      <Modal.Body className={styles.modalBody}>
        {receiptsHtml.map((receipts: string, index: number) => {
          return (
            <div key={index} className={styles.frameContainer}>
              <iframe
                key={index}
                ref={frameRefs[index]}
                srcDoc={receipts}
                className={styles.iframeElement}
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button id={'printReleaseReceiptButton'} onClick={print}>
          Print
        </Button>
        <Button id={'hideReleaseReceiptButton'} onClick={props.closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReceiptModal;
