import React, { FC } from 'react';

import './countryCodes.ts';
import styles from './contact-panel.module.css';

interface GenericContactProps {
  children: {
    contactName?: React.ReactNode;
    contactCompany?: React.ReactNode;
    contactWebsite?: React.ReactNode;
    contactEmail?: React.ReactNode;
    contactPhoneCountryCode?: React.ReactNode;
    contactPhone?: React.ReactNode;
    contactPhoneExtension?: React.ReactNode;
    cancelContactButton: React.ReactNode;
    saveContactButton: React.ReactNode;
    contactLabel: React.ReactNode;
  };
  contactNameLabel?: string;
  contactCompanyLabel?: string;
  contactWebsiteLabel?: string;
  contactEmailLabel?: string;
  contactPhoneCountryCodeLabel?: string;
  contactPhoneLabel?: string;
  contactPhoneExtensionLabel?: string;
  isEditMode: boolean;
}

const GenericContactPanel: FC<GenericContactProps> = ({
  children,
  contactNameLabel,
  contactCompanyLabel,
  contactWebsiteLabel,
  contactEmailLabel,
  contactPhoneCountryCodeLabel,
  contactPhoneLabel,
  contactPhoneExtensionLabel,
  isEditMode,
}: GenericContactProps) => {
  const createExternalLink = (link: string | undefined) => {
    if (link === undefined) {
      return '#';
    } else if (
      link &&
      (link.startsWith('http://') || link.startsWith('https://'))
    ) {
      return link;
    }
    return `https://${link}`;
  };

  return (
    <div>
      {children.contactLabel}

      {!isEditMode && (
        <div className="contactCollapsed">
          <div className={styles.contactCollapsedField}>
            <span color="normal">{contactNameLabel}</span>
          </div>

          <div className={styles.contactCollapsedField}>
            <span color="normal">{contactCompanyLabel}</span>
          </div>

          <div className={styles.contactCollapsedField}>
            <span color="normal">
              <a href={createExternalLink(contactWebsiteLabel)} target="new">
                {contactWebsiteLabel}
              </a>
            </span>
          </div>

          <div className={styles.contactCollapsedField}>
            {contactEmailLabel && (
              <span color="normal">
                <a href={`mailto:${contactEmailLabel}`} target="new">
                  Email
                </a>
              </span>
            )}
          </div>

          <div className={styles.contactCollapsedField}>
            <span color="normal">
              {contactPhoneCountryCodeLabel
                ? `+ ${contactPhoneCountryCodeLabel} `
                : ''}
              {contactPhoneLabel}
              {contactPhoneExtensionLabel
                ? ` Ext. ${contactPhoneExtensionLabel}`
                : ''}
            </span>
          </div>
        </div>
      )}

      {isEditMode && (
        <div className="contactExpanded">
          {children.contactName}
          {children.contactCompany}
          {children.contactWebsite}
          {children.contactEmail}
          {children.contactPhoneCountryCode}
          {children.contactPhone}
          {children.contactPhoneExtension}
          {children.cancelContactButton}
          {children.saveContactButton}
        </div>
      )}
    </div>
  );
};

export default GenericContactPanel;
