import React, { useContext } from 'react';

import { Typography } from '@cision/rover-ui';

import DistributionWizardContext from '../../DistributionWizardContext';

import styles from './prid.module.css';

const Prid = () => {
  const { distributionData } = useContext(DistributionWizardContext);

  return (
    <>
      {distributionData.prwebPressReleaseId ? (
        <span className={styles.pridContainer}>
          <Typography weight="bold" size="md">
            PRID: {distributionData.prwebPressReleaseId}
          </Typography>
        </span>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default Prid;
