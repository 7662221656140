import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as LeftArrowSvg } from '../../assets/arrow-backward.svg';
import { ReactComponent as RightArrowSvg } from '../../assets/arrow-forward.svg';

import styles from './pagination-controls.module.css';

const ICON_SIZE = 24;
const LABEL_TYPE_PAGE = 'pageNumber';
const LABEL_TYPE_ITEMS_RANGE = 'visibleItemsRange';

function RangeLabel({ totalItems, firstVisibleItem, lastVisibleItem }) {
  return (
    <div>
      <span
        className={styles.paginationText}
      >{`${firstVisibleItem}-${lastVisibleItem} of ${totalItems}`}</span>
    </div>
  );
}

RangeLabel.propTypes = {
  totalItems: PropTypes.number.isRequired,
  firstVisibleItem: PropTypes.number.isRequired,
  lastVisibleItem: PropTypes.number.isRequired,
};

function PageLabel({ currentPage, totalPages }) {
  return (
    <div>
      <span className={styles.paginationText}>
        {currentPage}
        {totalPages}
      </span>
    </div>
  );
}

PageLabel.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

function PaginationControls({
  currentPage,
  itemsPerPage,
  totalItems,
  onForward,
  onBackwards,
  canGoForward: canGoForwardProp,
  canGoBackwards = currentPage > 1,
  pageLabelType,
  buttonsClassName,
}) {
  const firstVisibleItem = (currentPage - 1) * itemsPerPage + 1;
  const lastVisibleItem = Math.min(totalItems, currentPage * itemsPerPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const canGoForward =
    canGoForwardProp === undefined
      ? currentPage < totalPages
      : canGoForwardProp;

  return (
    <div className={styles.container}>
      <button
        type="button"
        disabled={!canGoBackwards}
        onClick={onBackwards}
        className={classnames(styles.button, buttonsClassName, {
          [styles.disabled]: !canGoBackwards,
        })}
      >
        <LeftArrowSvg width={ICON_SIZE} height={ICON_SIZE} />
      </button>

      <span className={styles.paginationText}>
        {pageLabelType === LABEL_TYPE_PAGE && (
          <PaginationControls.PageLabel
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}

        {pageLabelType === LABEL_TYPE_ITEMS_RANGE && (
          <PaginationControls.RangeLabel
            totalItems={totalItems}
            firstVisibleItem={firstVisibleItem}
            lastVisibleItem={lastVisibleItem}
          />
        )}
      </span>

      <button
        type="button"
        disabled={!canGoForward}
        onClick={onForward}
        className={classnames(styles.button, buttonsClassName, {
          [styles.disabled]: !canGoForward,
        })}
      >
        <RightArrowSvg width={ICON_SIZE} height={ICON_SIZE} />
      </button>
    </div>
  );
}

PaginationControls.RangeLabel = RangeLabel;
PaginationControls.PageLabel = PageLabel;
PaginationControls.LABEL_TYPE_PAGE = LABEL_TYPE_PAGE;
PaginationControls.LABEL_TYPE_ITEMS_RANGE = LABEL_TYPE_ITEMS_RANGE;

PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onForward: PropTypes.func.isRequired,
  onBackwards: PropTypes.func.isRequired,
  canGoForward: PropTypes.bool,
  canGoBackwards: PropTypes.bool,
  pageLabelType: PropTypes.oneOf([LABEL_TYPE_PAGE, LABEL_TYPE_ITEMS_RANGE])
    .isRequired,
  buttonsClassName: PropTypes.string,
};

PaginationControls.defaultProps = {
  canGoForward: undefined,
  canGoBackwards: undefined,
  buttonsClassName: '',
};

export default PaginationControls;
