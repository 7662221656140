import React from 'react';

import PRWebFeatures from '../../utils/PRWebFeatures';

export enum SocialMediaState {
  Default,
  Removable,
  Selectable,
}

interface Props {
  children: {
    twitter?: React.ReactNode;
    linkedin?: React.ReactNode;
    facebook?: React.ReactNode;
  };
  config: PRWebConfig;
  socialMediaHandler?: (account: SocialMediaAccount) => void;
}

const SocialMediaContainer = ({
  children,
  config,
  socialMediaHandler,
}: Props) => {
  const enabledLinkedIn = PRWebFeatures.enableOAuthLinkedId();
  const enabledFacebook = PRWebFeatures.enableOAuthFacebook();

  const twitterProps = React.isValidElement(children.twitter)
    ? React.cloneElement(children.twitter, {
        config: config,
        twitterHandler: socialMediaHandler,
      } as any)
    : undefined;

  const linkedinProps =
    enabledLinkedIn && React.isValidElement(children.linkedin)
      ? React.cloneElement(children.linkedin, {
          config: config,
          linkedinHandler: socialMediaHandler,
        } as any)
      : undefined;

  const facebookProps =
    enabledFacebook && React.isValidElement(children.facebook)
      ? React.cloneElement(children.facebook, {
          config: config,
          facebookHandler: socialMediaHandler,
        } as any)
      : undefined;

  return (
    <div>
      {twitterProps}
      {linkedinProps}
      {facebookProps}
    </div>
  );
};

export default SocialMediaContainer;
