import React, { useEffect, useContext, useState, useCallback } from 'react';

import { Typography } from '@cision/rover-ui';

import TextAreaField from '../../../components/text-area-field';

import WizardContext from '../../../components/wizard/wizard-context';

import Validator, { Validation } from '../../../validators/GenericValidator';

import ExitWizardPrompt from '../../distribution-wizard/components/exit-wizard-prompt';

import PurchaseEditWizardContext from '../PurchaseEditWizardContext';

import styles from './styles/description-step.module.css';

const DescriptionStep: React.FC = () => {
  const {
    purchaseEditData,
    updatePurchaseEditData,
    updatePurchaseEditByField,
  } = React.useContext(PurchaseEditWizardContext);

  const wizardContext = useContext(WizardContext);

  useEffect(() => {
    wizardContext.setStepIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [descriptionErrorsValue, setDescriptionErrorsValue] = useState<
    Array<string>
  >();

  const validateDescription = (value?: string): Array<string> =>
    Validator({ value, invalidMsg: descriptionValidation })
      .required()
      .notNumeric()
      .noHtml()
      .maxLength(3000).results;

  const goNext = useCallback(async () => {
    const errors = validateDescription(purchaseEditData.description);
    setDescriptionErrorsValue(errors);
    return errors.length === 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseEditData.description]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepForward(goNext), [goNext]);

  const descriptionValidation = {
    required: () => 'Please provide an edit description.',
    notNumeric: () => 'Your edit description cannot be exclusively digits.',
    noHtml: () => 'Your edit description cannot have html markup.',
    maxLength: max => `Your edit description cannot exceed ${max} characters.`,
  } as Validation;

  const updateDataFieldByValue = (key: string, value?: string): void => {
    updatePurchaseEditByField(key, value);
  };

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Describe your changes
          </Typography>
        </div>
      </div>
      <div className={styles.formTextInputContainer}>
        <TextAreaField
          id={'description'}
          label={''}
          placeholder={'Enter your change description'}
          onChangeValue={updateDataFieldByValue}
          value={purchaseEditData.description}
          issues={descriptionErrorsValue || []}
          validate={validateDescription}
          tooltip={
            'Please describe as detailed as possible your required changes.'
          }
        />
      </div>
      <div>
        Please note: Post-distribution edits will only be made to PRWeb.com and
        will not be reflected by other downstream sites.
      </div>

      <ExitWizardPrompt />
    </div>
  );
};

export default DescriptionStep;
