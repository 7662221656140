import React, { useState, useCallback, useEffect } from 'react';

import {
  Responsive,
  Paper,
  Loader,
  Modal,
  Button,
  Typography,
  Badge,
} from '@cision/rover-ui';
import axios from 'axios';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import { ReactComponent as BackwardArrow } from '../../assets/arrow-backward.svg';
import { ReactComponent as CheckmarkSvg } from '../../assets/checkmark.svg';
import { ReactComponent as ClipboardSvg } from '../../assets/clipboard.svg';
import DatePicker from '../../components/date-picker';
import DropdownField, {
  DropdownPanel,
  PanelMapperAttribute,
} from '../../components/dropdown-field';
import Footer from '../../components/footer';
import Header from '../../components/header';
import TextAreaField from '../../components/text-area-field';
import TextInputField from '../../components/text-input-field';
import { COUPON_STATUS } from '../../constants';
import HttpStatusCode from '../../httpStatusCodes';
import AdminCouponValidator, {
  CouponDetailsErrors,
} from '../../validators/AdminCouponValidator';

import styles from './coupon-details.module.css';

interface PRWebUser {
  id: number;
  email: string;
  showLegacyLoginLink: boolean;
  group: string;
  oktaLogin: string;
}

interface Props {
  config: PRWebConfig;
}

interface PropsCouponModal {
  message: { header: string; body: string };
  isOpen: boolean;
  closeMessage: () => void;
}

const savedCouponMessage = {
  header: 'Coupon Saved',
  body: 'Coupon has been saved',
} as PropsCouponModal['message'];

const revokedCouponMessage = {
  header: 'Coupon Revoked',
  body: 'Coupon has been revoked',
} as PropsCouponModal['message'];

const CouponSavedMessage = (props: PropsCouponModal) => {
  return (
    <Modal isOpen={props.isOpen}>
      <Modal.Header>
        <strong>{props.message.header}</strong>
      </Modal.Header>
      <Modal.Body>{props.message.body}</Modal.Body>
      <Modal.Footer>
        <Button
          id="couponSavedModalButton"
          level="primary"
          onClick={props.closeMessage}
        >
          Got it, thanks!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CouponDetails: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { id: couponIdParam } = useParams();
  const isEditCoupon = couponIdParam ? true : false;
  const prwebApi = props.config.prwebApi.url;
  const userSearchLimit = 10;
  const [timezoneState] = React.useState('US/Eastern');

  const [isLoading, setIsLoading] = useState(isEditCoupon);
  const [canDropdown, setCanDropdown] = useState(false);
  const [userAccountId, setUserAccountId] = useState('');
  const [oktaLogin, setOktaLogin] = useState('');
  const [loginList, setLoginList] = useState([] as PRWebUser[]);
  const [couponId, setCouponId] = useState('');
  const [couponCode, setCouponCode] = useState(v4());
  const [couponOriginalAmount, setCouponOriginalAmount] = useState('');
  const [expirationDate, setExpirationDate] = useState(
    moment.utc().add(1, 'month'),
  );
  const [couponNotes, setCouponNotes] = useState('');
  const [couponStatus, setCouponStatus] = useState<COUPON_STATUS>(
    COUPON_STATUS.UNUSED,
  );
  const [frontEndFormErrors, setFrontEndFormErrors] = useState<
    CouponDetailsErrors
  >();
  const [isExitModal, setIsExitModal] = useState(false);
  const [exitModalMessage, setExitModalMessage] = useState(
    {} as PropsCouponModal['message'],
  );
  const [errorSummaryValue, setErrorSummaryValue] = useState<Array<string>>([]);

  const goToPreviousPage = () => {
    setIsExitModal(false);
    history.goBack();
  };

  const getUserLogins = (userSearchToken: string): Promise<void> => {
    return axios
      .get(`${prwebApi}/admin/users/search`, {
        params: {
          userSearchToken: userSearchToken,
          paginationToken: '',
          limit: userSearchLimit,
        },
      })
      .then(response => {
        if (response && response.status === HttpStatusCode.OK) {
          if (response.data.users.length > 0) {
            setLoginList(response.data.users);
          }
        } else {
          handleCouponError(response.data);
        }
      })
      .catch(e => {
        handleCouponError(e);
      });
  };

  const debounceGetUserLogins = useCallback(
    debounce(getUserLogins, 500, {
      leading: true,
      trailing: true,
    }),
    [],
  );

  const onExpirationDateBlur = (selectedDate: moment.Moment) => {
    const dt = moment(selectedDate.format('YYYY-MM-DDTHH:mm:ss')).tz(
      timezoneState,
      true,
    );
    setExpirationDate(dt);
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    if (key === 'oktaLogin') {
      if (!value || value === '') {
        setUserAccountId('');
      }
      setOktaLogin(value || '');
      if (value !== undefined && value.length > 3) debounceGetUserLogins(value);
    } else if (key === 'couponAmount') {
      setCouponOriginalAmount(value || '');
    } else if (key === 'notes') {
      setCouponNotes(value || '');
    } else if (key === 'couponCode') {
      setCouponCode(value || '');
    } else if (key === 'dropdownPanel') {
      const index = Number(value);
      if (!isNaN(index) && -1 < index && index < loginList.length) {
        setOktaLogin(loginList[index].oktaLogin);
        setUserAccountId(loginList[index].id.toString());
        setCanDropdown(false);
      }
    }
  };

  const createCouponDetails = async () => {
    const isValid = isValidCouponForm();
    if (isValid) {
      try {
        const postResponse = await axios.post(
          `${prwebApi}/admin/coupons/save`,
          {
            userAccountId: userAccountId,
            couponCode: couponCode,
            originalAmount: couponOriginalAmount,
            expirationDate: expirationDate
              .utc()
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            notes: couponNotes,
          },
        );
        if (
          postResponse.status === HttpStatusCode.OK ||
          postResponse.status === HttpStatusCode.CREATED
        ) {
          console.log(postResponse);
          setIsExitModal(() => {
            setExitModalMessage(savedCouponMessage);
            return true;
          });
        } else {
          handleCouponError(postResponse.data);
        }
      } catch (error) {
        handleCouponError(error);
      }
    }
  };

  const updateCouponDetails = async () => {
    const isValid = isValidCouponForm();
    if (isValid) {
      try {
        const postResponse = await axios.post(
          `${prwebApi}/admin/coupons/save`,
          {
            id: couponId,
            userAccountId: userAccountId,
            couponCode: couponCode,
            originalAmount: couponOriginalAmount,
            expirationDate: expirationDate
              .utc()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            notes: couponNotes,
            status: couponStatus?.toString() || COUPON_STATUS.UNUSED,
          },
        );
        if (
          postResponse.status === HttpStatusCode.OK ||
          postResponse.status === HttpStatusCode.CREATED
        ) {
          console.log(postResponse);
          setIsExitModal(() => {
            setExitModalMessage(savedCouponMessage);
            return true;
          });
        } else {
          handleCouponError(postResponse.data);
        }
      } catch (error) {
        handleCouponError(error);
      }
    }
  };

  const revokeCouponDetails = async () => {
    if (isEditCoupon) {
      try {
        const postResponse = await axios.post(
          `${prwebApi}/admin/coupon/${couponIdParam}/setstatus`,
          {
            status: COUPON_STATUS.REVOKED,
          },
        );
        if (postResponse.status === 200 || postResponse.status === 201) {
          console.log(postResponse);
          setIsExitModal(() => {
            setExitModalMessage(revokedCouponMessage);
            return true;
          });
        } else {
          handleCouponError(postResponse.data);
        }
      } catch (error) {
        handleCouponError(error);
      }
    }
  };

  const getCouponDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const couponResponse = await axios.get(`${prwebApi}/admin/coupon/${id}`);
      if (
        couponResponse.status === HttpStatusCode.OK ||
        couponResponse.status === HttpStatusCode.CREATED
      ) {
        const userResponse = await axios.get(
          `${prwebApi}/admin/user/${couponResponse.data.userAccountId}`,
        );
        if (
          userResponse.status === HttpStatusCode.OK ||
          userResponse.status === HttpStatusCode.CREATED
        ) {
          setUserAccountId(couponResponse.data.userAccountId.toString());
          setOktaLogin(userResponse.data.oktaLogin);
          setCouponId(couponResponse.data.id);
          setCouponCode(couponResponse.data.couponCode);
          setCouponOriginalAmount(
            couponResponse.data.originalAmount.toString(),
          );
          setCouponNotes(couponResponse.data.notes);
          setExpirationDate(
            moment(couponResponse.data.expirationDate).tz(timezoneState, true),
          );
          setCouponStatus(couponResponse.data.status);
          setIsLoading(false);
        } else {
          handleCouponError(couponResponse.data);
        }
      }
    } catch (error) {
      handleCouponError(error);
    }
  };

  const isValidCouponForm = (): boolean => {
    const validationResult = AdminCouponValidator.validateCouponDetails({
      userAccountId: userAccountId,
      emailAddress: oktaLogin,
      couponAmount: couponOriginalAmount,
      expirationDate: expirationDate.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
      couponNotes: couponNotes,
    });
    setFrontEndFormErrors(validationResult);
    return validationResult.valid;
  };

  const handleCouponError = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    console.log(error);
    let errors: Array<string> = [];

    if (
      error.response.status === HttpStatusCode.BAD_REQUEST &&
      error.response.data.errors
    ) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setErrorSummaryValue(errors);
  };

  const clipboardCouponCode = () => {
    const couponCodeElem = document.getElementById('couponCode') as HTMLElement;
    const selection = window.getSelection();
    const range = document.createRange();
    if (couponCodeElem && selection) {
      range.selectNodeContents(couponCodeElem);
      selection.removeAllRanges();
      selection.addRange(range);
      if ('clipboard' in navigator) {
        navigator.clipboard.writeText(couponCodeElem.innerText);
      }
    }
  };

  useEffect(() => {
    if (isEditCoupon) {
      getCouponDetails(couponIdParam as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findUserInputLogin = () => {
    // eslint-disable-next-line array-callback-return
    const exactMatch = loginList.find(loginResult => {
      if (
        oktaLogin.toLocaleUpperCase() ===
        loginResult.oktaLogin.toLocaleUpperCase()
      ) {
        return loginResult;
      }
    });
    if (exactMatch) {
      setUserAccountId(exactMatch.id.toString());
      setCanDropdown(false);
    } else {
      setUserAccountId('');
      if (loginList.length > 0) {
        setCanDropdown(true);
      }
    }
  };

  useEffect(() => {
    findUserInputLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginList]);

  const loginListMapper = (key: string) => {
    if (
      key === PanelMapperAttribute.PanelId ||
      key === PanelMapperAttribute.PanelData
    ) {
      return 'id';
    } else if (key === PanelMapperAttribute.PanelContent) {
      return 'oktaLogin';
    }
    return '';
  };

  const getStatusElement = (status?: COUPON_STATUS) => {
    if (status === COUPON_STATUS.REVOKED) {
      return (
        <Badge variant="danger">
          <Typography size="xl" style={{ color: 'white' }}>
            {COUPON_STATUS.REVOKED.toString().toUpperCase()}
          </Typography>
        </Badge>
      );
    }

    if (status === COUPON_STATUS.USED) {
      return (
        <Badge variant="success">
          <Typography size="xl" style={{ color: 'white' }}>
            {COUPON_STATUS.USED.toString().toUpperCase()}
          </Typography>
        </Badge>
      );
    }
  };

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      {isLoading ? (
        <Loader />
      ) : (
        <Paper className={styles.couponDetailsContainer}>
          <Button id={'details-back-button'} onClick={goToPreviousPage}>
            <BackwardArrow width="24" height="24" viewBox="0 0 24 24" />
            <span>back</span>
          </Button>
          {errorSummaryValue.length > 0 && (
            <div className={styles.errorMessage}>
              Error:
              <ul>
                {errorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles.formContainer}>
            <div className={styles.flexContainer}>
              <div className={styles.formTextInputContainer}>
                <label htmlFor={'couponCode'}>
                  Coupon Code
                  <Typography id="couponCode" tag="p">
                    {couponCode}
                  </Typography>
                </label>
              </div>
              <ClipboardSvg
                className={styles.clipboard}
                onClick={clipboardCouponCode}
              />
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.formTextInputContainer}>
                <DropdownField
                  id={'oktaLogin'}
                  label={'Login Username'}
                  canOpen={canDropdown}
                  value={oktaLogin}
                  dropdownList={loginList}
                  onChangeValue={updateDataFieldByValue}
                  disabled={isEditCoupon}
                  issues={frontEndFormErrors?.emailAddressErrors || []}
                  validate={AdminCouponValidator.validateCouponEmail}
                >
                  <DropdownPanel
                    id={'dropdownPanel'}
                    panelMapper={loginListMapper}
                  />
                </DropdownField>
              </div>
              {userAccountId !== '' && (
                <span
                  className={styles.checkmark}
                  style={{ paddingTop: '40px' }}
                >
                  <CheckmarkSvg width={16} height={16} fill={'#008080'} />
                </span>
              )}
            </div>
            <br />
            <div className={styles.formTextInputContainer}>
              <TextInputField
                id={'couponAmount'}
                label={'Coupon Amount'}
                value={couponOriginalAmount}
                onChangeValue={updateDataFieldByValue}
                issues={frontEndFormErrors?.couponAmountErrors || []}
                validate={AdminCouponValidator.validateCouponAmount}
              />
            </div>
            <div className={styles.formTextInputContainer}>
              <DatePicker
                id={'date'}
                label={'Expiration Date (EST)'}
                date={expirationDate.tz('UTC', true)}
                onChange={onExpirationDateBlur}
              />
              <br />
            </div>
            <div className={styles.formTextInputContainer}>
              <TextAreaField
                id={'notes'}
                label={'Notes'}
                value={couponNotes || ''}
                onChangeValue={updateDataFieldByValue}
                issues={frontEndFormErrors?.couponNotesErrors || []}
                validate={AdminCouponValidator.validateCouponNotes}
              />
            </div>
            <div>
              {couponStatus !== COUPON_STATUS.UNUSED ? (
                getStatusElement(couponStatus)
              ) : (
                <div className={styles.couponDetailsAction}>
                  {isEditCoupon ? (
                    <>
                      <Button level="primary" onClick={updateCouponDetails}>
                        Save Changes
                      </Button>
                      <Button level="danger" onClick={revokeCouponDetails}>
                        Revoke Coupon
                      </Button>
                    </>
                  ) : (
                    <Button level="primary" onClick={createCouponDetails}>
                      Create Coupon
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Paper>
      )}
      <Footer />
      <CouponSavedMessage
        message={exitModalMessage}
        isOpen={isExitModal}
        closeMessage={goToPreviousPage}
      />
    </Responsive.Container>
  );
};

export default CouponDetails;
