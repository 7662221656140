import React, { useCallback, useRef } from 'react';

import { Button, Modal, Loader } from '@cision/rover-ui';
// import axios from 'axios';
import moment from 'moment-timezone';
import { createPortal } from 'react-dom';

import { ReactComponent as PDFSVG } from '../../assets/third-party/pdf-logo.svg';

// import SaveUtility from '../../pages/distribution-wizard/SaveUtility';

import '../../prweb.d.ts';

import styles from './preview.module.css';

interface Props {
  isOpen: boolean;
  close: () => void;
  prwebConfig: PRWebConfig;
  distribution: Distribution;
}

const Preview = ({ isOpen, close, prwebConfig, distribution }: Props) => {
  // const [dangerousHtml, setDangerousHtml] = React.useState('');
  const [distroPreview, setDistroPreview] = React.useState<
    React.ReactFragment | undefined
  >();

  const [
    previewErrorSummaryValue,
    setPreviewErrorSummaryValue,
  ] = React.useState<Array<string>>([]);
  const frameRef = useRef<any>();

  const frameHead = frameRef.current?.contentWindow?.document?.head;
  const frameBody = frameRef.current?.contentWindow?.document?.body;

  const [isLoading, setIsLoading] = React.useState(false);

  // const downloadPreview = async () => {
  //   const dataToSend = SaveUtility.getDataToSend(distribution);
  //   setIsLoading(true);
  //   try {
  //     const api = prwebConfig.prwebApi;

  //     const newsImage = await createNewsImage(dataToSend.newsImage, api.url);

  //     if (dataToSend.newsImage && newsImage && newsImage.id) {
  //       dataToSend.newsImage.assetId = newsImage.id;
  //     }

  //     const attachmentsIds = await createAttachments(
  //       dataToSend.attachments,
  //       api.url,
  //     );

  //     if (dataToSend.attachments && attachmentsIds) {
  //       dataToSend.attachments.forEach(
  //         (attach, index) => (attach.assetId = attachmentsIds[index].id),
  //       );
  //     }

  //     const postResponse = await axios.post(
  //       `${api.url}/distribution/preview/download`,
  //       dataToSend,
  //     );

  //     setIsLoading(false);

  //     if (postResponse.data.Length) {
  //       const link = document.createElement('a');
  //       link.setAttribute('href', postResponse.data._links.file);
  //       link.setAttribute('download', 'ReleasePreview.pdf');
  //       link.setAttribute('target', '_blank');
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     }
  //   } catch (error) {
  //     console.error('Unable to create preview download');
  //     console.error(error);
  //   }
  // };

  const printPreview = () => {
    try {
      frameRef.current.contentWindow.print();
    } catch (error) {
      console.error('Missing print preview content', error);
    }
  };

  const makePreviewHtml = useCallback(async () => {
    let youtubeThumbnail: any = undefined;
    const regex = /^((?:https?:)\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
    const matches = (distribution.videoUrl || '').match(regex);
    if (matches) {
      youtubeThumbnail = matches[5];
    }

    const primaryContacts = [
      distribution.primaryContactName,
      distribution.primaryContactCompany,
      [
        distribution.primaryContactPhoneCountryCode,
        distribution.primaryContactPhone,
        distribution.primaryContactPhoneExtension,
      ]
        .filter(nonEmpty => nonEmpty)
        .join(' '),
      distribution.primaryContactEmail,
      distribution.primaryContactWebsite,
    ]
      .filter(nonEmpty => nonEmpty)
      .map((item, idx) => {
        if (item === distribution.primaryContactEmail) {
          return (
            <span key={idx}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`mailto:${item}`}
              >
                {item}
              </a>
            </span>
          );
        } else if (item === distribution.primaryContactWebsite) {
          const websiteUrl = (item || '').startsWith('http')
            ? item
            : `https://${item}`;
          return (
            <span key={idx}>
              <a rel="noopener noreferrer" target="_blank" href={websiteUrl}>
                {websiteUrl}
              </a>
            </span>
          );
        } else {
          return <span key={idx}>{item}</span>;
        }
      });

    const secondaryContacts = [
      distribution.secondaryContactName,
      distribution.secondaryContactCompany,
      [
        distribution.secondaryContactPhoneCountryCode,
        distribution.secondaryContactPhone,
        distribution.secondaryContactPhoneExtension,
      ]
        .filter(nonEmpty => nonEmpty)
        .join(' '),
      distribution.secondaryContactEmail,
      distribution.secondaryContactWebsite,
    ]
      .filter(nonEmpty => nonEmpty)
      .map((item, idx) => {
        if (item === distribution.secondaryContactEmail) {
          return (
            <span key={idx}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`mailto:${item}`}
              >
                {item}
              </a>
            </span>
          );
        } else if (item === distribution.secondaryContactWebsite) {
          const websiteUrl = (item || '').startsWith('http')
            ? item
            : `https://${item}`;
          return (
            <span key={idx}>
              <a rel="noopener noreferrer" target="_blank" href={websiteUrl}>
                {websiteUrl}
              </a>
            </span>
          );
        } else {
          return <span key={idx}>{item}</span>;
        }
      });

    const preview = (
      <>
        <h1>{distribution.headline}</h1>
        <hr />
        <div className="subtitle">
          <div>
            <div>NEWS PROVIDED BY</div>
            <div>
              <strong>{distribution.primaryContactCompany}</strong>
            </div>
            <div>
              {moment(distribution.releaseDate)
                .tz('US/Eastern')
                .format('D MMM, YYYY, HH:mm z')}
            </div>
          </div>
          <div>
            <div>SHARE THIS ARTICLE</div>
            <div className="social">
              <img
                width={34}
                height={34}
                src={`${prwebConfig.prwebFrontEnd.url}/img/Facebook.png`}
                alt="Share Facebook"
              />
              <img
                width={34}
                height={34}
                src={`${prwebConfig.prwebFrontEnd.url}/img/x-logo-black.png`}
                alt="Share X"
              />
              <img
                width={38}
                height={34}
                src={`${prwebConfig.prwebFrontEnd.url}/img/Linkedin.png`}
                alt="Share Linkedin"
              />
              <img
                width={34}
                height={34}
                src={`${prwebConfig.prwebFrontEnd.url}/img/Pinterest.png`}
                alt="Share Pinterest"
              />
              <img
                width={34}
                height={34}
                src={`${prwebConfig.prwebFrontEnd.url}/img/WeChat.png`}
                alt="Share WeChat"
              />
              <img
                width={34}
                height={34}
                style={{ background: 'gray', borderRadius: '40px' }}
                src={`${prwebConfig.prwebFrontEnd.url}/img/Email.png`}
                alt="Share Email"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="featured">
          {youtubeThumbnail && (
            <figure>
              <iframe
                src={`https://www.youtube.com/embed/${youtubeThumbnail}?frameborder=0`}
              ></iframe>
            </figure>
          )}
          {distribution.newsImage && (
            <figure>
              <img
                src={distribution.newsImage.base64Preview}
                alt={distribution.newsImage.caption}
              ></img>
              <figcaption>{distribution.newsImage.caption}</figcaption>
            </figure>
          )}
        </div>
        <br />
        <div>{distribution.summary}</div>
        <br />
        <div>
          {distribution.cityState ? `${distribution.cityState}, ` : ''}
          {moment(distribution.releaseDate).format('MMMM D, YYYY ')}
          /PRNewswire-PRWeb/ --
        </div>
        <br />
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: distribution.body }}
        ></div>
        <br />
        {distribution.pulloutQuote && (
          <aside>{distribution.pulloutQuote}</aside>
        )}
        <br />
        <div>
          <strong>Media Contact</strong>
          <div className="commaList">{primaryContacts}</div>
          <div className="commaList">{secondaryContacts}</div>
        </div>
        <br />
        <div>
          {distribution.twitterSocialHandle?.selected &&
            distribution.twitterSocialHandle?.accountName && (
              <span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://x.com/${distribution.twitterSocialHandle.accountName}`}
                >
                  X
                </a>
              </span>
            )}{' '}
          {distribution.facebookSocialHandle?.selected &&
            distribution.facebookSocialHandle?.avatar && (
              <span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://facebook.com/${distribution.facebookSocialHandle.avatar}`}
                >
                  Facebook
                </a>
              </span>
            )}{' '}
          {distribution.linkedinSocialHandle?.selected &&
            distribution.linkedinSocialHandle?.accountName && (
              <span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://linkedin.com/company/${distribution.linkedinSocialHandle.accountName}`}
                >
                  LinkedIn
                </a>
              </span>
            )}
        </div>
        <br />
        <div>SOURCE {distribution.primaryContactCompany}</div>
        <br />
        {distribution.attachments && (
          <div className="attachments">
            {(distribution.attachments || []).map((item, key) => {
              return (
                <figure key={key}>
                  {item.fileName.endsWith('.pdf') ? (
                    <div>
                      <PDFSVG width={50} height={60} />
                    </div>
                  ) : (
                    <img
                      className="thumbnail"
                      src={item.base64Preview}
                      alt={item.caption}
                    ></img>
                  )}
                  <figcaption>{item.caption}</figcaption>
                </figure>
              );
            })}
          </div>
        )}
      </>
    );

    setDistroPreview(preview);
  }, [distribution, prwebConfig.prwebFrontEnd.url]);

  // const setPreviewHtml = async () => {
  //   const dataToSend = SaveUtility.getDataToSend(distribution);

  //   try {
  //     const api = prwebConfig.prwebApi;

  //     const newsImage = await createNewsImage(dataToSend.newsImage, api.url);

  //     if (dataToSend.newsImage && newsImage && newsImage.id) {
  //       dataToSend.newsImage.assetId = newsImage.id;
  //     }

  //     const attachmentsIds = await createAttachments(
  //       dataToSend.attachments,
  //       api.url,
  //     );

  //     if (dataToSend.attachments && attachmentsIds) {
  //       dataToSend.attachments.forEach(
  //         (attach, index) => (attach.assetId = attachmentsIds[index].id),
  //       );
  //     }

  //     const postResponse = await axios.post(
  //       `${api.url}/distribution/preview`,
  //       dataToSend,
  //     );

  //     if (postResponse.status === 200 || postResponse.status === 201) {
  //       setDangerousHtml(
  //         postResponse.data.html.replace(
  //           '{environment.frontendurl}',
  //           prwebConfig.prwebFrontEnd.url,
  //         ),
  //       );
  //     } else {
  //       handleApiValidationError(postResponse.data);
  //     }
  //   } catch (error) {
  //     handleApiValidationError(error);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // };

  // const handleApiValidationError = (error: ErrorType): void => {
  //   const errors: Array<string> = SaveUtility.handleApiErrors(error);
  //   setPreviewErrorSummaryValue(errors);
  // };

  // const createNewsImage = async (
  //   newsImage: NewsImage | undefined,
  //   apiUrl: string,
  // ): Promise<any | undefined> => {
  //   if (
  //     newsImage &&
  //     newsImage.fileName &&
  //     (!newsImage.assetId || newsImage.assetId === 0)
  //   ) {
  //     const newsImageResponse = await axios.post(
  //       `${apiUrl}/asset/newsimage`,
  //       newsImage,
  //     );

  //     if (
  //       newsImageResponse.status === 200 ||
  //       newsImageResponse.status === 201
  //     ) {
  //       return newsImageResponse.data;
  //     }
  //   }

  //   return undefined;
  // };

  // const createAttachments = async (
  //   attachments: Attachment[] | undefined,
  //   apiUrl: string,
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // ): Promise<any[] | undefined> => {
  //   if (attachments && attachments.length > 0) {
  //     const attachmentsResponse = await axios.post(
  //       `${apiUrl}/asset/attachments`,
  //       attachments,
  //     );

  //     if (
  //       attachmentsResponse.status === 200 ||
  //       attachmentsResponse.status === 201
  //     ) {
  //       return attachmentsResponse.data;
  //     }
  //   }

  //   return undefined;
  // };

  React.useEffect(() => {
    if (isOpen) {
      // setPreviewHtml();
      makePreviewHtml();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="lg" className={styles.modalContainer}>
      <Modal.Header>
        <div className={styles.title}>
          <strong>Preview</strong>
          <div style={{ color: 'red' }}>
            Please Note: This preview is intended as a guide as you build your
            press release or make changes so that you can confirm your release
            components prior to submitting. The preview will not reflect
            formatting updates made by our editorial staff after submission. The
            preview may not fully match the aesthetic appearance of the release
            once it&apos;s published.
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {previewErrorSummaryValue.length > 0 && (
            <div>
              Error loading Preview:
              <ul>
                {previewErrorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {isOpen && (
          <iframe
            id="previewModal"
            title="preview"
            ref={frameRef}
            className={styles.frameContainer}
          >
            {frameHead &&
              createPortal(
                <link
                  rel="stylesheet"
                  type="text/css"
                  href={`${prwebConfig.prwebFrontEnd.url}/preview/css/style.css`}
                ></link>,
                frameHead,
              )}
            {frameBody && createPortal(distroPreview, frameBody)}
          </iframe>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <div style={{ display: 'flex' }}>
          {isLoading ? (
            <Loader size="md" />
          ) : (
            <Button
              level="secondary"
              id={'downloadPreviewButton'}
              onClick={printPreview}
            >
              Print or save as PDF
            </Button>
          )}
        </div>
        <Button level="secondary" id={'previewCloseButton'} onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Preview;
