import React, { useEffect } from 'react';

import { Responsive, Typography } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';

import Footer from '../components/footer/Footer';
import Header from '../components/header';

import PaginationControls from '../components/pagination-controls';
import ViewTitleBar from '../components/view-title-bar/ViewTitleBar';

import styles from './ArchiveReleases.module.css';

interface Props {
  config: PRWebConfig;
}

interface ArchiveReleaseProp {
  c3PRWebPressReleaseId?: number;
  c3CompanyLoginName: string;
  c3DistributionId: number;
  releaseStatusId: number;
  releaseStatusDescription: string;
  releaseDate?: string | undefined;
  submittedDate?: string | undefined;
  creationDate: string;
  headline: string;
  publicUrl?: string;
  totalAmount?: number;
}

interface TableListProps extends React.HTMLAttributes<HTMLTableElement> {
  pageSize?: number;
  headerElm: React.ReactFragment;
  rowList: any[];
  rowMapper: (row: any, idx: number) => React.ReactFragment;
}

const TableList: React.FC<TableListProps> = ({
  pageSize = 50,
  headerElm,
  rowList,
  rowMapper,
  ...props
}: TableListProps) => {
  const [pageNum, setPageNum] = React.useState(1);
  const startIndex = (pageNum - 1) * pageSize;

  const getTableData = () => {
    return (
      <>
        {rowList
          // eslint-disable-next-line react/prop-types
          .slice(startIndex, startIndex + pageSize)
          .map((release: any, idx: number) => rowMapper(release, idx))}
      </>
    );
  };

  return (
    <table className={styles.table} {...props}>
      <caption>
        <div className={styles.pagination}>
          <Typography color="black">
            Showing {startIndex + 1}-{pageSize} of {rowList.length}
          </Typography>
          <PaginationControls
            currentPage={pageNum}
            totalItems={rowList.length}
            itemsPerPage={pageSize}
            onForward={() => setPageNum(pageNum + 1)}
            onBackwards={() => setPageNum(pageNum - 1)}
            pageLabelType={PaginationControls.LABEL_TYPE_ITEMS_RANGE}
          />
        </div>
      </caption>
      {headerElm}
      <tbody>{getTableData()}</tbody>
    </table>
  );
};

const ArchivedReleases: React.FC<Props> = (props: Props) => {
  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const [archiveReleases, setArchiveReleases] = React.useState<
    ArchiveReleaseProp[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getArchiveReleases = async (): Promise<ArchiveReleaseProp[]> => {
    return axios
      .get(`${PRWEB_API_BASE_URL}/distribution/archiverelease`)
      .then(response => {
        if (response.data && Array.isArray(response.data)) {
          return response.data;
        }
        return [];
      });
  };

  const getTableHeader = () => {
    return (
      <>
        <thead className={styles.header}>
          <tr>
            <th style={{ width: '70%' }}>Name</th>
            <th>Release Date</th>
            <th style={{ width: '10%' }}>Amount</th>
          </tr>
        </thead>
      </>
    );
  };

  const archiveReleaseTableMapper = (
    release: ArchiveReleaseProp,
    index: number,
  ) => {
    return (
      <tr className={styles.releaseRow} key={index}>
        <th>
          <a
            href={release.publicUrl ? release.publicUrl : '#'}
            target={release.publicUrl ? '_blank' : '_self'}
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Typography weight="bold">{release.headline}</Typography>
            <br />
            <Typography weight="normal">
              {release.c3PRWebPressReleaseId}
            </Typography>
          </a>
        </th>
        <th>
          <Typography weight="normal" color="black">
            {release.releaseDate
              ? moment(release.releaseDate).format('M/D/YYYY')
              : '-'}
          </Typography>
        </th>
        <th>
          <Typography weight="normal" color="black">
            {Number(release.totalAmount).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        </th>
      </tr>
    );
  };

  useEffect(() => {
    const initArchive = async (): Promise<void> => {
      const archive = await getArchiveReleases();
      setArchiveReleases(archive);
      setIsLoading(false);
    };

    initArchive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={PRWEB_API_BASE_URL} />
      <ViewTitleBar title={'Archived Releases'} />
      {!isLoading && (
        <div style={{ marginTop: '60px' }}>
          <TableList
            style={{ textAlign: 'left' }}
            headerElm={getTableHeader()}
            rowList={archiveReleases}
            rowMapper={archiveReleaseTableMapper}
          ></TableList>
        </div>
      )}
      <Footer />
    </Responsive.Container>
  );
};

export default ArchivedReleases;
