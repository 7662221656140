import React, { useEffect } from 'react';

import { Responsive } from '@cision/rover-ui';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

import { useHistory } from 'react-router-dom';

import Footer from '../../components/footer';
import Header from '../../components/header';
import PopupModal, { PopupContentProps } from '../../components/popup-modal';
import Wizard from '../../components/wizard/wizard';
import wizardContext from '../../components/wizard/wizard-context';
import WizardNavigation from '../../components/wizard/wizard-navigation';
import WizardProgress from '../../components/wizard/wizard-progress';
import WizardProvider from '../../components/wizard/wizard-provider';
import WizardStep from '../../components/wizard/wizard-step';
import PRWebBundles from '../../utils/PRWebBundles';
import PRWebFeatures from '../../utils/PRWebFeatures';

import CheckoutForm from './CheckoutForm';
import styles from './store-checkout-wizard.module.css';
import StoreCheckoutStep from './StoreCheckoutStep';
import StoreConfirmationStep from './StoreConfirmationStep';
import StorePaymentStep from './StorePaymentStep';
import StorePaymentStripeStep from './StorePaymentStripeStep';

const stripePromise = loadStripe(
  'pk_test_51NVGA1B8ZoTBdwjCVCSOuuTkkL0ap5UO6ZwdELvqYY2qHrcHDFaaiT0VuK8L394M1F56TJ81OxVy8BR72p6tFj5W000mj1CwgE',
);

interface Props {
  config: PRWebConfig;
}

const StoreCheckoutWizard = ({ config }: Props) => {
  const prwebApi = config.prwebApi.url;
  const history = useHistory();
  const progressText = ['Items', 'Payment', 'Confirmation'];
  const [payment, setPayment] = React.useState({} as Payment);
  const [stripePayment, setStripePayment] = React.useState(
    {} as { intentId: string; clientSecret: string },
  );
  const [orderNumber, setOrderNumber] = React.useState('');
  const [bundleOrderId, setBundleOrderId] = React.useState(-1);
  const [storeCart, setStoreCart] = React.useState([] as BundlePackagePrice[]);
  const [storeBundles, setStoreBundles] = React.useState(
    [] as BundlePackagePrice[],
  );

  const [togglePopup, setTogglePopup] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState(
    {} as PopupContentProps,
  );

  const showStripePaymentStep = PRWebFeatures.userHasStripeApi();

  const getStoreBundles = () => {
    const cachedStoreBundles = PRWebBundles.getStoreBundles();
    if (cachedStoreBundles) {
      setStoreBundles(cachedStoreBundles);
    } else {
      const getStoreBundlesUrl = `${prwebApi}/distribution/bundlepackages`;
      axios
        .get(getStoreBundlesUrl)
        .then(response => {
          if (response && response.status === 200) {
            const processedData = response.data
              // eslint-disable-next-line
              .map((bundle: any) => {
                return {
                  bundlePackagePriceId: bundle.bundlePackagePriceId,
                  description: bundle.description,
                  packageId: Number(bundle.packageId),
                  quantity: Number(bundle.quantity),
                  amount: Number(bundle.amount),
                };
              })
              .sort((a: BundlePackagePrice, b: BundlePackagePrice) =>
                a.packageId === b.packageId
                  ? a.quantity - b.quantity
                  : a.packageId - b.packageId,
              );
            setStoreBundles(processedData);
            PRWebBundles.setStoreBundles(processedData);
          }
        })
        .catch(error => {
          console.log('Error retrieving data: ', error);
          PRWebBundles.removeStoreBundles();
        });
    }
  };

  const getStoreCart = () => {
    const getCartUrl = `${prwebApi}/bundleorder/cart`;
    axios.get(getCartUrl).then(response => {
      if (response && response.status >= 200) {
        setStoreCart(response.data ?? []);
      }
    });
  };

  const searchBundleById = (bundleId: number) => {
    return storeBundles.filter(
      bundle => Number(bundle.bundlePackagePriceId) === Number(bundleId),
    )[0];
  };

  const removeBundleById = (bundleId: number): BundlePackagePrice[] => {
    let deleted = false;
    const newCart: BundlePackagePrice[] = [];
    storeCart.forEach(bundle => {
      if (!deleted && bundle.bundlePackagePriceId === bundleId) {
        deleted = true;
      } else {
        newCart.push({ ...bundle });
      }
    });
    return newCart;
  };

  const paymentChangeHandler = (field: string, value?: string) => {
    setPayment((prevState: Payment) => {
      return { ...prevState, [field]: value };
    });
  };

  const cartChangeHandler = (bundleId: number, type: string) => {
    let changeRequest = null;
    if (type === 'increment') {
      const itemToAdd = searchBundleById(bundleId);
      if (itemToAdd) {
        const addToCartUrl = `${prwebApi}/bundleorder/cart`;
        changeRequest = axios.post(addToCartUrl, [...storeCart, itemToAdd]);
      }
    } else if (type === 'decrement') {
      const newCart = removeBundleById(bundleId);
      if (newCart) {
        const addToCartUrl = `${prwebApi}/bundleorder/cart`;
        changeRequest = axios.post(addToCartUrl, newCart);
      }
    }
    if (changeRequest !== null) {
      changeRequest.then(response => {
        if (response && response.status >= 200 && response.status < 300) {
          setStoreCart(response.data);
        } else {
          setPopupContent({
            header: 'Attention!',
            body: 'Could not update the cart, please try again.',
            action: [
              {
                name: 'Ok',
                level: 'primary',
              },
            ],
          });
          setTogglePopup(true);
        }
      });
    }
  };

  const handleModalClick = (key: string, value?: string) => {
    if (key === 'Ok') {
      setTogglePopup(false);
    }
  };

  useEffect(() => {
    getStoreBundles();
    getStoreCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStoreCheckoutSteps = () => {
    return (
      <Wizard>
        <div className={styles.stepContainer}>
          <PopupModal
            id={'checkout-modal'}
            isOpen={togglePopup}
            content={popupContent}
            onActionClick={handleModalClick}
          ></PopupModal>
          <WizardProgress
            title={'Progress Bar'}
            initialProgress={progressText}
            maxVisibleCount={2}
            isHorizontal={true}
          />
          <WizardStep order={1} nextLabel={'Next'}>
            <StoreCheckoutStep
              config={config}
              storeCart={storeCart}
              cartChangeHandler={cartChangeHandler}
            ></StoreCheckoutStep>
          </WizardStep>
          {showStripePaymentStep ? (
            <WizardStep order={2} backLabel={'Back'} nextLabel={'Purchase'}>
              <StorePaymentStripeStep
                config={config}
                stripePayment={stripePayment}
                setStripePayment={setStripePayment}
                storeCart={storeCart}
                stripePromise={stripePromise}
              >
                <CheckoutForm
                  config={config}
                  storeCart={storeCart}
                  setBundleOrderId={setBundleOrderId}
                  setOrderNumberHandler={setOrderNumber}
                ></CheckoutForm>
              </StorePaymentStripeStep>
            </WizardStep>
          ) : (
            <WizardStep order={2} backLabel={'Back'} nextLabel={'Purchase'}>
              <StorePaymentStep
                config={config}
                payment={payment}
                paymentChangeHandler={paymentChangeHandler}
                storeCart={storeCart}
                setOrderNumberHandler={setOrderNumber}
                setBundleOrderId={setBundleOrderId}
                stripePromise={stripePromise}
              ></StorePaymentStep>
            </WizardStep>
          )}
          <WizardStep order={3} backButtonIsVisible={false} submit>
            <StoreConfirmationStep
              config={config}
              orderNumber={orderNumber}
              bundleOrderId={bundleOrderId}
              payment={payment}
              storeCart={storeCart}
              stripePayment={stripePayment}
              isStripePayment={showStripePaymentStep}
            ></StoreConfirmationStep>
          </WizardStep>
        </div>
      </Wizard>
    );
  };

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      <WizardProvider history={history}>
        <WizardNavigation
          envConfig={config}
          history={history}
          cancelRoute={'/store'}
          submitLabel="Go To My Releases"
          cancelLabel="Back to Store"
        ></WizardNavigation>
        <wizardContext.Consumer>
          {wizardData => {
            return getStoreCheckoutSteps();
          }}
        </wizardContext.Consumer>
      </WizardProvider>
      <Footer />
    </Responsive.Container>
  );
};

export default StoreCheckoutWizard;
