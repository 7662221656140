import validator from 'validator';

import Validator from './GenericValidator';
import InvalidMsg from './SubmitDistributionValidationMessage';

export const validateHeadline = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.headlineValidation })
    .required()
    .notNumeric()
    .noHtml()
    .minLength(55)
    .maxLength(170)
    .allLowerCase()
    .allUpperCase().results;

export const validateSummary = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.summaryValidation })
    .required()
    .notNumeric()
    .noHtml()
    .minLength(30)
    .allLowerCase()
    .allUpperCase().results;

export const validateCityState = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.cityStateValiation })
    .required()
    .notNumeric()
    .noHtml()
    .maxLength(250).results;

export const validateVideoUrl = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.videoUrlValidation }).matchRegex(
    /^(((?:https?:)\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)).*/,
  ).results;

export const validateBody = (value?: string): Array<string> => {
  let valueWithoutHtml = value || '';
  valueWithoutHtml = valueWithoutHtml
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, '')
    .replace(/\r\n/, '');

  return Validator({
    value: valueWithoutHtml,
    invalidMsg: InvalidMsg.bodyValidation,
  })
    .required()
    .notNumeric()
    .minLength(30)
    .allLowerCase()
    .allUpperCase().results;
};

export const validatePulloutQuote = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.pulloutQuoteValidation })
    .maxLength(300)
    .noHtml().results;

export const validateContactName = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactNameValidation })
    .required()
    .notNumeric()
    .noHtml()
    .maxLength(100).results;

export const validateOptionalContactName = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactNameValidation })
    .notNumeric()
    .noHtml()
    .maxLength(100).results;

export const validateContactCompany = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactCompanyValidation })
    .required()
    .notNumeric()
    .noHtml()
    .maxLength(100).results;

export const validateOptionalContactCompany = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactCompanyValidation })
    .notNumeric()
    .noHtml()
    .maxLength(100).results;

export const validateContactWebsite = (value?: string): Array<string> =>
  Validator({
    value,
    invalidMsg: InvalidMsg.contactWebsiteValidation,
  })
    .maxLength(100)
    .noHtml().results;

export const validateContactEmail = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactEmailValidation })
    .required()
    .isEmail()
    .noHtml()
    .maxLength(100).results;

export const validateOptionalContactEmail = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactEmailValidation })
    .isEmail()
    .noHtml()
    .maxLength(100).results;

export const validateContactPhoneCountryCode = (
  value?: string,
): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactPhoneCountryCodeValidation })
    .required()
    .maxLength(6).results;

export const validateOptionalContactPhoneCountryCode = (
  value?: string,
  callback?: (val: string) => string,
): Array<string> => {
  const validation = Validator({
    value,
    invalidMsg: InvalidMsg.contactPhoneCountryCodeValidation,
  }).maxLength(6);

  if (callback) validation.customFormat(callback);

  return validation.results;
};

export const validateContactPhone = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactPhoneValidation })
    .required()
    .noHtml()
    .minLength(7)
    .maxLength(32).results;

export const validateOptionalContactPhone = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.contactPhoneValidation })
    .noHtml()
    .minLength(7)
    .maxLength(32).results;

export const validateContactPhoneExtension = (value?: string): Array<string> =>
  Validator({
    value,
    invalidMsg: InvalidMsg.contactPhoneExtensionValidation,
  })
    .maxLength(32)
    .noHtml().results;

const isPrimaryContactValid = (contactValidation: PrimaryContactValidation) => {
  const valid =
    (!contactValidation.primaryContactNameErrors ||
      contactValidation.primaryContactNameErrors.length === 0) &&
    (!contactValidation.primaryContactCompanyErrors ||
      contactValidation.primaryContactCompanyErrors.length === 0) &&
    (!contactValidation.primaryContactWebsiteErrors ||
      contactValidation.primaryContactWebsiteErrors.length === 0) &&
    (!contactValidation.primaryContactEmailErrors ||
      contactValidation.primaryContactEmailErrors.length === 0) &&
    (!contactValidation.primaryContactPhoneCountryCodeErrors ||
      contactValidation.primaryContactPhoneCountryCodeErrors.length === 0) &&
    (!contactValidation.primaryContactPhoneErrors ||
      contactValidation.primaryContactPhoneErrors.length === 0) &&
    (!contactValidation.primaryContactPhoneExtensionErrors ||
      contactValidation.primaryContactPhoneExtensionErrors.length === 0);
  return valid;
};

const isSecondaryContactValid = (
  contactValidation: SecondaryContactValidation,
) => {
  const valid =
    (!contactValidation.secondaryContactNameErrors ||
      contactValidation.secondaryContactNameErrors.length === 0) &&
    (!contactValidation.secondaryContactCompanyErrors ||
      contactValidation.secondaryContactCompanyErrors.length === 0) &&
    (!contactValidation.secondaryContactWebsiteErrors ||
      contactValidation.secondaryContactWebsiteErrors.length === 0) &&
    (!contactValidation.secondaryContactEmailErrors ||
      contactValidation.secondaryContactEmailErrors.length === 0) &&
    (!contactValidation.secondaryContactPhoneCountryCodeErrors ||
      contactValidation.secondaryContactPhoneCountryCodeErrors.length === 0) &&
    (!contactValidation.secondaryContactPhoneErrors ||
      contactValidation.secondaryContactPhoneErrors.length === 0) &&
    (!contactValidation.secondaryContactPhoneExtensionErrors ||
      contactValidation.secondaryContactPhoneExtensionErrors.length === 0);
  return valid;
};

const isPrivateContactValid = (
  privateContactValidation: PrivateContactValidation,
) => {
  const valid =
    (!privateContactValidation.userErrors ||
      privateContactValidation.userErrors.length === 0) &&
    (!privateContactValidation.userEmailErrors ||
      privateContactValidation.userEmailErrors.length === 0) &&
    (!privateContactValidation.userPhoneCountryCodeErrors ||
      privateContactValidation.userPhoneCountryCodeErrors.length === 0) &&
    (!privateContactValidation.userPhoneErrors ||
      privateContactValidation.userPhoneErrors.length === 0) &&
    (!privateContactValidation.userPhoneExtensionErrors ||
      privateContactValidation.userPhoneExtensionErrors.length === 0);
  return valid;
};

export const validateSecondaryContactData = (
  distributionData: GenericContactData,
) => {
  const msg = {} as SecondaryContactValidation;
  msg.secondaryContactNameErrors = validateOptionalContactName(
    distributionData.secondaryContactName,
  );

  msg.secondaryContactCompanyErrors = validateOptionalContactCompany(
    distributionData.secondaryContactCompany,
  );

  msg.secondaryContactWebsiteErrors = validateContactWebsite(
    distributionData.secondaryContactWebsite,
  );

  msg.secondaryContactEmailErrors = validateOptionalContactEmail(
    distributionData.secondaryContactEmail,
  );

  msg.secondaryContactPhoneErrors = validateOptionalContactPhone(
    distributionData.secondaryContactPhone,
  );

  function phoneWithContryCode(val: string) {
    if (
      !validator.isEmpty(distributionData.secondaryContactPhone || '') &&
      validator.isEmpty(val || '')
    )
      return 'Please provide a country code for your contact phone.';
    return '';
  }

  msg.secondaryContactPhoneCountryCodeErrors = validateOptionalContactPhoneCountryCode(
    distributionData.secondaryContactPhoneCountryCode,
    phoneWithContryCode,
  );

  msg.secondaryContactPhoneExtensionErrors = validateContactPhoneExtension(
    distributionData.secondaryContactPhoneExtension,
  );

  msg.valid = isSecondaryContactValid(msg);

  return msg;
};

export const validatePrimaryContactData = (
  distributionData: GenericContactData,
) => {
  const msg = {} as PrimaryContactValidation;
  msg.primaryContactNameErrors = validateContactName(
    distributionData.primaryContactName,
  );

  msg.primaryContactCompanyErrors = validateContactCompany(
    distributionData.primaryContactCompany,
  );

  msg.primaryContactWebsiteErrors = validateContactWebsite(
    distributionData.primaryContactWebsite,
  );

  msg.primaryContactEmailErrors = validateContactEmail(
    distributionData.primaryContactEmail,
  );

  msg.primaryContactPhoneCountryCodeErrors = validateContactPhoneCountryCode(
    distributionData.primaryContactPhoneCountryCode,
  );

  msg.primaryContactPhoneErrors = validateContactPhone(
    distributionData.primaryContactPhone,
  );

  msg.primaryContactPhoneExtensionErrors = validateContactPhoneExtension(
    distributionData.primaryContactPhoneExtension,
  );

  msg.valid = isPrimaryContactValid(msg);

  return msg;
};

export const validatePrivateContactData = (
  privateContactData: PrivateContact | null,
): PrivateContactValidation | null => {
  if (privateContactData === null) {
    return null;
  }
  const msg = {} as PrivateContactValidation;
  msg.userErrors = validateContactName(privateContactData.user);

  msg.userEmailErrors = validateContactEmail(privateContactData.userEmail);

  msg.userPhoneCountryCodeErrors = validateContactPhoneCountryCode(
    privateContactData.userPhoneCountryCode,
  );

  msg.userPhoneErrors = validateContactPhone(privateContactData.userPhone);

  msg.userPhoneExtensionErrors = validateContactPhoneExtension(
    privateContactData.userPhoneExtension,
  );

  msg.valid = isPrivateContactValid(msg);

  return msg;
};

export const validateDistributionData = (
  distributionData: Distribution,
): DistributionValidation => {
  let msg = {} as DistributionValidation;
  msg.headlineErrors = validateHeadline(distributionData.headline);

  msg.summaryErrors = validateSummary(distributionData.summary);

  msg.bodyErrors = validateBody(distributionData.body);

  msg.cityStateErrors = validateCityState(distributionData.cityState);

  msg.videoUrlErrors = validateVideoUrl(distributionData.videoUrl);

  msg.pulloutQuoteErrors = validatePulloutQuote(distributionData.pulloutQuote);

  const primaryContactValidation = validatePrimaryContactData(distributionData);
  const secondaryContactValidation = validateSecondaryContactData(
    distributionData,
  );

  const valid =
    primaryContactValidation.valid &&
    secondaryContactValidation.valid &&
    (!msg.headlineErrors || msg.headlineErrors.length === 0) &&
    (!msg.summaryErrors || msg.summaryErrors.length === 0) &&
    (!msg.cityStateErrors || msg.cityStateErrors.length === 0) &&
    (!msg.pulloutQuoteErrors || msg.pulloutQuoteErrors.length === 0) &&
    (!msg.pulloutQuoteErrors || msg.videoUrlErrors.length === 0) &&
    (!msg.bodyErrors || msg.bodyErrors.length === 0);

  msg = {
    ...msg,
    ...primaryContactValidation,
    ...secondaryContactValidation,
    valid,
  };

  return msg;
};
export interface GenericContactData {
  primaryContactName?: string;
  primaryContactCompany?: string;
  primaryContactWebsite?: string;
  primaryContactEmail?: string;
  primaryContactPhoneCountryCode?: string;
  primaryContactPhone?: string;
  primaryContactPhoneExtension?: string;
  secondaryContactName?: string;
  secondaryContactCompany?: string;
  secondaryContactWebsite?: string;
  secondaryContactEmail?: string;
  secondaryContactPhoneCountryCode?: string;
  secondaryContactPhone?: string;
  secondaryContactPhoneExtension?: string;
}
export interface PrivateContactValidationData {
  user?: string;
  userEmail?: string;
  userPhoneCountryCode?: string;
  userPhone?: string;
  userPhoneExtension?: string;
}
export interface PrimaryContactErrors {
  primaryContactNameErrors: Array<string>;
  primaryContactCompanyErrors: Array<string>;
  primaryContactWebsiteErrors: Array<string>;
  primaryContactEmailErrors: Array<string>;
  primaryContactPhoneCountryCodeErrors: Array<string>;
  primaryContactPhoneErrors: Array<string>;
  primaryContactPhoneExtensionErrors: Array<string>;
}
export interface SecondaryContactErrors {
  secondaryContactNameErrors: Array<string>;
  secondaryContactCompanyErrors: Array<string>;
  secondaryContactWebsiteErrors: Array<string>;
  secondaryContactEmailErrors: Array<string>;
  secondaryContactPhoneCountryCodeErrors: Array<string>;
  secondaryContactPhoneErrors: Array<string>;
  secondaryContactPhoneExtensionErrors: Array<string>;
}
export interface PrivateContactErrors {
  userErrors: Array<string>;
  userEmailErrors: Array<string>;
  userPhoneCountryCodeErrors: Array<string>;
  userPhoneErrors: Array<string>;
  userPhoneExtensionErrors: Array<string>;
}
export interface PrimaryContactValidation extends PrimaryContactErrors {
  valid?: boolean;
}
export interface SecondaryContactValidation extends SecondaryContactErrors {
  valid?: boolean;
}
export interface PrivateContactValidation extends PrivateContactErrors {
  valid?: boolean;
}
export interface DistributionErrors
  extends PrimaryContactErrors,
    SecondaryContactErrors {
  headlineErrors: Array<string>;
  summaryErrors: Array<string>;
  cityStateErrors: Array<string>;
  bodyErrors: Array<string>;
  videoUrlErrors: Array<string>;
  pulloutQuoteErrors: Array<string>;
}
export interface DistributionValidation
  extends PrimaryContactValidation,
    SecondaryContactValidation,
    DistributionErrors {}

const SubmitDistributionValidator = {
  validateHeadline,
  validateSummary,
  validateCityState,
  validateBody,
  validateVideoUrl,
  validatePulloutQuote,
  validateContactName,
  validateOptionalContactName,
  validateContactCompany,
  validateOptionalContactCompany,
  validateContactWebsite,
  validateContactEmail,
  validateOptionalContactEmail,
  validateContactPhoneCountryCode,
  validateOptionalContactPhoneCountryCode,
  validateContactPhone,
  validateOptionalContactPhone,
  validateContactPhoneExtension,
  validatePrimaryContactData,
  validateSecondaryContactData,
  validatePrivateContactData,
  validateDistributionData,
};
export default SubmitDistributionValidator;
