import React, { useState, useEffect } from 'react';

import { Typography, Tooltip } from '@cision/rover-ui';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import FieldValidationIssue from '../field-validation-issue';

import styles from './textareafield.module.css';

interface Props {
  id: string;
  placeholder?: string;
  value?: string;
  issues: string[];
  label?: string;
  validate?: (value?: string) => Array<string>;
  onChangeValue?: (key: string, value?: string) => void;
  tooltip?: string;
}
const TextAreaField: React.FC<Props> = ({
  id,
  placeholder,
  value,
  issues,
  label,
  validate,
  onChangeValue,
  tooltip,
}: Props) => {
  const [currentIssues, updateCurrentIssues] = useState<string[]>(issues);
  const [currentValue, updateCurrentValue] = useState<string | undefined>(
    value,
  );

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    updateCurrentValue(newValue);
    if (newValue !== currentValue) {
      if (validate !== undefined) {
        const newIssues = validate(newValue);
        updateCurrentIssues(newIssues);
      }
      if (onChangeValue !== undefined) {
        onChangeValue(id, newValue);
      }
    }
  };

  useEffect(() => {
    updateCurrentIssues(issues);
  }, [issues]);

  useEffect(() => {
    updateCurrentValue(value);
  }, [value]);

  return (
    <div
      id={`${id}-text_area_field_component_container`}
      className={'text_area_field_component_container'}
    >
      {label && (
        <Typography htmlFor={id} className={styles.textLabel} tag="label">
          {label}
          {tooltip && (
            <Tooltip
              tooltipWidth="300px"
              showOnHover={true}
              direction="right"
              content={<div>{tooltip}</div>}
            >
              <InfoSvg className={styles.icon} />
            </Tooltip>
          )}
        </Typography>
      )}
      <textarea
        id={id}
        className={styles.textArea}
        placeholder={placeholder || ''}
        onChange={changeHandler}
        value={currentValue}
      />
      {currentIssues && <FieldValidationIssue issues={currentIssues} id={id} />}
    </div>
  );
};

export default TextAreaField;
