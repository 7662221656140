import React, { useState, useEffect } from 'react';

import { Typography } from '@cision/rover-ui';

import FieldValidationIssue from '../field-validation-issue';

import styles from './select-menu.module.css';

interface Props {
  id: string;
  placeholder?: string;
  className?: string;
  value?: string;
  issues: string[];
  label?: string;
  options: { label: string; id: string }[];
  validate?: (value?: string) => Array<string>;
  onChangeValue?: (key: string, value?: string) => void;
}

const SelectMenuField: React.FC<Props> = ({
  id,
  placeholder,
  className,
  value,
  issues,
  label,
  options,
  validate,
  onChangeValue,
}: Props) => {
  const [currentIssues, updateCurrentIssues] = useState<string[]>(issues);
  const [selectedValue, updateSelectedValue] = useState<string | undefined>(
    value,
  );

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const idx = event?.target?.selectedIndex || 0;
    if (0 < idx && idx - 1 < options.length && onChangeValue !== undefined) {
      const newValue = options[idx - 1];
      onChangeValue(id, newValue.id);
      updateSelectedValue(newValue.label);
    }
  };

  useEffect(() => {
    updateCurrentIssues(issues);
  }, [issues]);

  useEffect(() => {
    updateSelectedValue(value);
  }, [value]);

  return (
    <div
      id={`${id}-text_input_field_component_container`}
      className={className}
    >
      {label && (
        <Typography
          htmlFor={id}
          size="md"
          tag="label"
          className={styles.selectLabel}
        >
          {label}
        </Typography>
      )}
      <div className={styles.selectWrapper}>
        <select
          id={id}
          className={styles.roverSelect}
          value={selectedValue}
          onChange={changeHandler}
          defaultValue={selectedValue}
        >
          <option key={''} style={{ display: 'none' }}>
            {placeholder}
          </option>
          {options.map((item, idx) => {
            return (
              <option key={idx} value={item.id}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
      {currentIssues && <FieldValidationIssue issues={currentIssues} id={id} />}
    </div>
  );
};

export default SelectMenuField;
