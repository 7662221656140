import React from 'react';
import qs from 'qs';

const FilterContext = React.createContext({
  searchId: null,
  fromDate: null,
  toDate: null,
  dateRangeType: null,
});

export function updateLocationQuery(
  { query, pathname },
  { history, options = {} },
) {
  history.push({
    pathname: pathname || history.location.pathname,
    search: qs.stringify({
      ...(options.clear
        ? {}
        : qs.parse(history.location.search, { ignoreQueryPrefix: true })),
      ...query,
    }),
  });
}

export function replaceLocationQuery(
  { query, pathname },
  { history, options = {} },
) {
  history.replace({
    pathname: pathname || history.location.pathname,
    search: qs.stringify({
      ...(options.clear
        ? {}
        : qs.parse(history.location.search, { ignoreQueryPrefix: true })),
      ...query,
    }),
  });
}

export default FilterContext;
