import React, { useEffect, useContext, useCallback, useState, FC } from 'react';

import { Typography, Loader } from '@cision/rover-ui';
import axios, { AxiosResponse } from 'axios';

import WizardContext from '../../../components/wizard/wizard-context';

import ExitWizardPrompt from '../../distribution-wizard/components/exit-wizard-prompt';
import PaymentSelector from '../../distribution-wizard/steps/Payment/PaymentSelector';
import StripePaymentForm from '../../distribution-wizard/steps/Payment/StripePaymentForm';
import PurchaseEditSummary from '../PurchaseEditSummary';
import PurchaseEditUtility from '../PurchaseEditUtility';
import PurchaseEditWizardContext from '../PurchaseEditWizardContext';

import styles from './payment-step.module.css';

interface PayAndSubmitStepProps {
  config: PRWebConfig;
  stripePromise: any;
}

interface DistributionApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface PayAndSubmitError {
  response: {
    status: number;
    data: DistributionApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const PayAndSubmitStep: FC<PayAndSubmitStepProps> = ({
  config,
  stripePromise,
}: PayAndSubmitStepProps) => {
  const [isCreditCard, setIsCreditCard] = useState(true);
  const [stripePayment, setStripePayment] = React.useState(
    {} as { intentId: string; clientSecret: string },
  );

  const { purchaseEditData, updatePurchaseEditData } = useContext(
    PurchaseEditWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);
  const [
    paymentAndSubmissionCompleted,
    setPaymentAndSubmissionCompleted,
  ] = useState(false);

  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldDisablePaymentForm, setShouldDisablePaymentForm] = useState(
    false,
  );
  const [isFormComplete, setIsFormComplete] = useState(false);

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const updateIsLoading = (status: boolean) => {
    setIsLoading(status);
  };

  const getPaymentIntent = useCallback(async () => {
    const getPaymentIntentUrl = `${config.prwebApi.url}/purchaseedit/createPaymentIntent`;
    const EXTRAS_POST_DISTRO_COST = 199;
    const finalTotal = EXTRAS_POST_DISTRO_COST;

    const payload: any = {
      amount: finalTotal,
    };

    await axios
      .post(getPaymentIntentUrl, payload)
      .then((response: any) => {
        setStripePayment({
          intentId: response.data.intentId,
          clientSecret: response.data.clientSecret,
        });
      })
      .catch((error: any) => {
        console.log('get payment intent', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.prwebApi.url]);

  const handleStripePayment = useCallback(async () => {
    const source = axios.CancelToken.source();
    let paymentSuccessful = false;

    const paymentData = PurchaseEditUtility.mapToApiStripePayment(
      isFormComplete ? stripePayment.intentId : '',
      '',
      purchaseEditData,
    );

    try {
      const postResponse = await axios.post(
        `${prwebApi}/purchaseedit/payandsubmit`,
        paymentData,
        { cancelToken: source.token },
      );
      paymentSuccessful = handlePaymentResponse(postResponse);
    } catch (error) {
      handlePaymentError(error as any);
      getPaymentIntent();
    } finally {
      source.cancel();
    }
    return paymentSuccessful;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreditCard,
    shouldDisablePaymentForm,
    isFormComplete,
    stripePayment,
    stripePayment.intentId,
  ]);

  const updateFieldByValue = (fieldName: string, value?: string): void => {
    if (fieldName === 'isStripe') {
      setIsCreditCard((prev: boolean) => !prev);
    } else if (fieldName === 'stripeFormComplete') {
      setIsFormComplete(value === 'true');
    } else if (fieldName === 'handlingPayment') {
      setIsHandlingPayment(value === 'true');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePaymentResponse = (postResponse: AxiosResponse<any>): boolean => {
    let paymentSuccessful = false;
    if (postResponse.status === 200 || postResponse.status === 201) {
      updatePurchaseEditData({
        ...purchaseEditData,
        orderNumber: postResponse.data.OrderNumber ?? '',
        transactionId: postResponse.data.ProcessorTransactionID ?? '',
      });
      paymentSuccessful = true;
    } else {
      handlePaymentError(postResponse.data);
    }
    setPaymentAndSubmissionCompleted(paymentSuccessful);
    return paymentSuccessful;
  };

  const handlePaymentError = (error: PayAndSubmitError): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (
      error.response.status === 500 &&
      error.response &&
      error.response.statusText
    ) {
      errors.push(error.response.statusText);
      if (error.response.data && error.response.data.message) {
        errors.push(error.response.data.message);
      }
    } else if (error.response.status === 429) {
      errors.push(error.message);
      errors.push('Too many requests');
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  useEffect(() => {
    if (isCreditCard && isFormComplete) {
      wizardContext.setStepIsValid(true);
    } else {
      wizardContext.setStepIsValid(false);
    }

    setShouldDisablePaymentForm(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditCard, isFormComplete]);

  useEffect(() => {
    setIsLoading(true);
    // Update progress
    console.log('default extras useeffect');
    getPaymentIntent();
    wizardContext.setProgressWhileEditing([]);
    let cancelled = false;
    if (!cancelled) {
      setIsLoading(false);
    }
    // this is a useEffect cleanup so there are not attempts to update state if the user has exited this scope
    // https://dev.to/otamnitram/react-useeffect-cleanup-how-and-when-to-use-it-2hbm
    return () => {
      cancelled = true;
    };
    // useEffect with no dependencies so it only run once during component initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Choose a Payment Method
          </Typography>
        </div>
        {(isHandlingPayment || isLoading) && <Loader />}
      </div>
      <div>
        {!paymentAndSubmissionCompleted && formErrorSummaryValue.length > 0 && (
          <div className={styles.errorMessage}>
            Error submitting distribution:
            <ul>
              {formErrorSummaryValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={styles.pageContainer}>
        <div className={styles.flexContainerLeft}>
          {stripePayment?.intentId && (
            <PaymentSelector
              isStripe={isCreditCard}
              isDisabled={shouldDisablePaymentForm}
              stripeSecret={stripePayment}
              stripePromise={stripePromise}
              onChange={updateFieldByValue}
            >
              <StripePaymentForm
                isHandlingPayment={isHandlingPayment}
                isDisabled={shouldDisablePaymentForm}
                handleError={setFormErrorSummaryValue}
                onChange={updateFieldByValue}
                stripePay={handleStripePayment}
              ></StripePaymentForm>
            </PaymentSelector>
          )}
        </div>

        <PurchaseEditSummary
          config={config}
          isCreditCard={isCreditCard}
          updateIsLoading={updateIsLoading}
          handlePaymentError={handlePaymentError}
        ></PurchaseEditSummary>
      </div>
      <>
        <div className={styles.redBox}>
          <div>
            <Typography weight="bold" color="black" size="xl">
              You are submitting your post-distribution change request for
              editorial review.
            </Typography>
          </div>
          <div>
            <Typography weight="normal" color="primary-alt" size="lg">
              By clicking &quot;Pay & Submit&quot;, I hereby acknowledge and
              agree to the following:
            </Typography>
            <ul>
              <li>This submission includes all of my changes</li>
              <li>
                Any subsequent requests for post-distribution edits on this
                release may be subject to additional fees
              </li>
            </ul>
          </div>
        </div>
        <div>
          By clicking &quot;Pay & Submit&quot;, I acknowledge that I have read
          and accept the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.cision.com/legal/msa/"
          >
            Master Subscription Agreement
          </a>
          ,{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.cision.com/legal/service-appendices/pr-services-appendix/"
          >
            PR Services Appendix
          </a>
          , and{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.cision.com/legal/service-appendices/pr-services-appendix/"
          >
            Privacy Policy
          </a>
          .
        </div>
      </>
      <ExitWizardPrompt
        confirmMessage={'Are you sure you want to leave the release wizard?'}
      />
    </div>
  );
};

export default PayAndSubmitStep;
