import Validator, { Validation } from './GenericValidator';

const InvalidMsg = {
  firstNameValidation: {
    required: () => 'Please provide a first name.',
    notNumeric: () => 'The first name cannot be all digits.',
    maxLength: max => `The first name cannot exceed ${max} characters.`,
    noHtml: () => 'First name cannot have html markup.',
  } as Validation,
  lastNameValidation: {
    required: () => 'Please provide a last name.',
    notNumeric: () => 'The last name cannot be all digits.',
    maxLength: max => `The last name cannot exceed ${max} characters.`,
    noHtml: () => 'Last name cannot have html markup.',
  } as Validation,
  countryValidation: {
    required: () => 'Please select a country.',
    maxLength: max => `The country code cannot exceed ${max} characters.`,
    noHtml: () => 'Country cannot have html markup.',
  } as Validation,
  phoneValidation: {
    required: () => 'Please provide a phone number.',
    maxLength: max => `The phone number cannot exceed ${max} characters.`,
    noHtml: () => 'Phone number cannot have html markup.',
  } as Validation,
  companyValidation: {
    required: () => 'Please provide a company name.',
    maxLength: max => `The company name cannot exceed ${max} characters.`,
    noHtml: () => 'Company field cannot have html markup.',
  } as Validation,
  userEmailValidation: {
    required: () => 'Please provide an email address.',
    isEmail: () => 'Please provide a valid email address format.',
    noHtml: () => 'Email address cannot have html markup.',
  } as Validation,
  address1Validation: {
    maxLength: max => `The address field cannot exceed ${max} characters.`,
    noHtml: () => 'Address field cannot have html markup.',
  } as Validation,
  address2Validation: {
    maxLength: max => `The address field cannot exceed ${max} characters.`,
    noHtml: () => 'Address field cannot have html markup.',
  } as Validation,
  cityValidation: {
    maxLength: max => `The city field cannot exceed ${max} characters.`,
    noHtml: () => 'City field cannot have html markup.',
  } as Validation,
  stateValidation: {
    maxLength: max => `The state field cannot exceed ${max} characters.`,
    noHtml: () => 'State field cannot have html markup.',
  } as Validation,
  zipValidation: {
    maxLength: max => `The zip code cannot exceed ${max} characters.`,
    isNumeric: () => 'Zip code must be in digits.',
    noHtml: () => 'Zip code cannot have html markup.',
  } as Validation,
};

export const validateFirstName = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.firstNameValidation })
    .required()
    .notNumeric()
    .noHtml()
    .maxLength(50).results;

export const validateLastName = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.lastNameValidation })
    .required()
    .notNumeric()
    .noHtml()
    .maxLength(50).results;

export const validateCompanyName = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.companyValidation })
    .required()
    .noHtml()
    .maxLength(80).results;

export const validatePhoneNumber = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.phoneValidation })
    .required()
    .noHtml()
    .maxLength(30).results;

export const validateCountry = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.countryValidation })
    .required()
    .noHtml()
    .maxLength(3).results;

export const validateAddress1 = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.address1Validation })
    .noHtml()
    .maxLength(45).results;

export const validateAddress2 = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.address2Validation })
    .noHtml()
    .maxLength(45).results;

export const validateCity = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.cityValidation })
    .noHtml()
    .maxLength(45).results;

export const validateState = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.stateValidation })
    .noHtml()
    .maxLength(25).results;

export const validateZipcode = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.zipValidation })
    .noHtml()
    .isNumeric()
    .maxLength(25).results;

export const validateEmail = (value?: string): Array<string> =>
  Validator({ value, invalidMsg: InvalidMsg.userEmailValidation })
    .required()
    .isEmail()
    .noHtml().results;

export const validateAccount = (userAccount: UserAccount) => {
  const firstNameErrors = validateFirstName(userAccount.firstName);
  const lastNameErrors = validateLastName(userAccount.lastName);
  const address1Errors = validateAddress1(userAccount.address1);
  const address2Errors = validateAddress2(userAccount.address2);
  const cityErrors = validateCity(userAccount.city);
  const stateErrors = validateState(userAccount.state);
  const zipErrors = validateZipcode(userAccount.zip);
  const countryErrors = validateCountry(userAccount.country);
  const phoneErrors = validatePhoneNumber(userAccount.phone);
  const organizationErrors = validateCompanyName(userAccount.organization);
  const emailErrors = validateEmail(userAccount.email);

  const numErrors =
    firstNameErrors.length +
    lastNameErrors.length +
    address1Errors.length +
    address2Errors.length +
    cityErrors.length +
    stateErrors.length +
    zipErrors.length +
    organizationErrors.length +
    phoneErrors.length +
    countryErrors.length +
    emailErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    firstNameErrors,
    lastNameErrors,
    address1Errors,
    address2Errors,
    cityErrors,
    stateErrors,
    zipErrors,
    countryErrors,
    phoneErrors,
    organizationErrors,
    emailErrors,
  };
};

export interface UserAccount {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  organization: string;
  group: string;
  c3CompanyId: string;
  oktaLogin: string;
  userSendDistributionToIris: boolean;
}

export interface AccountErrors {
  firstNameErrors: Array<string>;
  lastNameErrors: Array<string>;
  address1Errors: Array<string>;
  address2Errors: Array<string>;
  cityErrors: Array<string>;
  stateErrors: Array<string>;
  zipErrors: Array<string>;
  countryErrors: Array<string>;
  phoneErrors: Array<string>;
  organizationErrors: Array<string>;
  emailErrors: Array<string>;
}

export interface SettingsAccountValidation extends AccountErrors {
  valid: boolean;
}

const SettingsAccountValidator = {
  validateFirstName,
  validateLastName,
  validateCompanyName,
  validatePhoneNumber,
  validateCountry,
  validateAddress1,
  validateAddress2,
  validateCity,
  validateState,
  validateZipcode,
  validateEmail,
  validateAccount,
};
export default SettingsAccountValidator;
