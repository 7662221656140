import extend from 'extend';
import { Quill } from 'react-quill';
import Emitter from 'quill/core/emitter';
import BaseTheme, { BaseTooltip } from 'quill/themes/base';
import PRWLinkBlot from './prw-link';

const icons = Quill.import('ui/icons');
const PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];
// since SnowTooltip is not Quill.import-able, this class started as a copy of that class
// hence it's base of "BaseTooltip"
class PRWTooltip extends BaseTooltip {
  constructor(quill, bounds) {
    super(quill, bounds);
    this.preview = this.root.querySelector('a.ql-preview');
    this.urlTextbox = this.root.querySelector(
      'input.prw-link-url[type="text"]',
    );
    this.titleTextbox = this.root.querySelector(
      'input.prw-link-title[type="text"]',
    );
  }

  listen() {
    super.listen();
    this.root.querySelector('a.ql-action').addEventListener('click', event => {
      if (this.root.classList.contains('ql-editing')) {
        this.save();
      } else {
        this.edit('link', this.preview);
      }
      event.preventDefault();
    });
    this.root.querySelector('a.ql-remove').addEventListener('click', event => {
      if (this.linkRange != null) {
        const range = this.linkRange;
        this.restoreFocus();
        this.quill.formatText(range, 'link', false, Emitter.sources.USER);
        delete this.linkRange;
      }
      event.preventDefault();
      this.hide();
    });
    this.quill.on(
      Emitter.events.SELECTION_CHANGE,
      (range, oldRange, source) => {
        if (range == null) return;
        if (range.length === 0 && source === Emitter.sources.USER) {
          const [link, offset] = this.quill.scroll.descendant(
            PRWLinkBlot,
            range.index,
          );
          console.debug(
            'theme listen quill.on(SelectionChange) [link, offset]]:',
          );
          console.debug({ link, offset });
          if (link != null) {
            console.debug(
              'theme listen quill.on(SelectionChange) new Range(range.index - offset, link.length():',
            );
            console.debug({
              rangeDotIndex: range.index,
              offset,
              linkDotLengthFunc: link.length(),
            });

            // this.linkRange = new Range(range.index - offset, link.length());
            // using the Quill, core, selection, Range class like Snow does causes weird errors here
            // but seems like the class isn't necessary -- just creating an object with the same structure
            this.linkRange = {
              index: range.index - offset,
              length: link.length() || 0,
            };
            const preview = PRWLinkBlot.formats(link.domNode);
            console.debug(
              'theme listen quill.on(SelectionChange) preview (LinkBlot.formats(link.domNode)): ',
            );
            console.debug(preview);
            console.debug(
              'theme listen quill.on(SelectionChange) this.preview: ',
            );
            console.debug(this.preview);
            this.preview.textContent = preview.href;
            this.urlTextbox.value = link.domNode.href;
            this.titleTextbox.value = link.domNode.title;
            this.preview.setAttribute('href', preview.href);
            this.preview.setAttribute('title', preview.title);
            this.show();
            this.position(this.quill.getBounds(this.linkRange));
            return;
          }
        } else {
          delete this.linkRange;
        }
        this.hide();
      },
    );
  }
  edit(mode = 'link', preview = null) {
    console.debug('prwTooltip edit preview: ');
    console.debug(preview);
    this.root.classList.remove('ql-hidden');
    this.root.classList.add('ql-editing');
    if (preview != null) {
      this.urlTextbox.value = '';
      this.titleTextbox.value = '';

      if (preview.getAttribute) {
        this.urlTextbox.value =
          preview.getAttribute('href') &&
          preview.getAttribute('href') !== 'null'
            ? preview.getAttribute('href')
            : '';
        this.titleTextbox.value =
          preview.getAttribute('title') &&
          preview.getAttribute('title') !== 'null'
            ? preview.getAttribute('title')
            : '';
      }
    } else if (mode !== this.root.getAttribute('data-mode')) {
      this.urlTextbox.value = '';
      this.titleTextbox.value = '';
    }
    this.position(this.quill.getBounds(this.quill.selection.savedRange));
    this.titleTextbox.select();
    this.titleTextbox.setAttribute(
      'placeholder',
      this.titleTextbox.getAttribute(`data-${mode}`) || '',
    );
    this.urlTextbox.select();
    this.urlTextbox.setAttribute(
      'placeholder',
      this.urlTextbox.getAttribute(`data-${mode}`) || '',
    );
    this.root.setAttribute('data-mode', mode);
  }
  save() {
    let url = this.urlTextbox.value;
    if (!url) {
      this.hide();
      return;
    }
    const protocol = url.slice(0, url.indexOf(':'));
    if (PROTOCOL_WHITELIST.indexOf(protocol) < 0) {
      url = `http://${url}`;
    }

    const dataMode = this.root.getAttribute('data-mode');
    const value = {
      href: url,
      title: this.titleTextbox.value,
    };
    if (dataMode === 'link') {
      const scrollTop = this.quill.root.scrollTop;
      if (this.linkRange) {
        this.quill.formatText(
          this.linkRange,
          'link',
          value,
          Emitter.sources.USER,
        );
        delete this.linkRange;
      } else {
        this.restoreFocus();
        this.quill.format('link', value, Emitter.sources.USER);
      }
      this.quill.root.scrollTop = scrollTop;
      this.textbox.value = '';
      this.hide();
    }
  }
  show() {
    super.show();
    this.root.removeAttribute('data-mode');
  }
}
PRWTooltip.TEMPLATE = [
  '<a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>',
  '<input class="prw-link-url" type="text" data-link="Enter URL"> <input class="prw-link-title" type="text" data-link="Enter Title">',
  '<a class="ql-action"></a>',
  '<a class="ql-remove"></a>',
].join('');

const TOOLBAR_CONFIG = [
  [{ header: ['1', '2', '3', false] }],
  ['bold', 'italic', 'underline', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean'],
];

// this class started as a copy of the Snow theme class
// hence it's base of "BaseTheme"
class PRWTheme extends BaseTheme {
  constructor(quill, options) {
    if (
      options.modules.toolbar != null &&
      options.modules.toolbar.container == null
    ) {
      options.modules.toolbar.container = TOOLBAR_CONFIG;
    }
    super(quill, options);
    this.quill.container.classList.add('ql-snow');
  }

  extendToolbar(toolbar) {
    toolbar.container.classList.add('ql-snow');
    this.buildButtons(
      [].slice.call(toolbar.container.querySelectorAll('button')),
      icons,
    );
    this.buildPickers(
      [].slice.call(toolbar.container.querySelectorAll('select')),
      icons,
    );
    this.tooltip = new PRWTooltip(this.quill, this.options.bounds);
    if (toolbar.container.querySelector('.ql-link')) {
      this.quill.keyboard.addBinding({ key: 'K', shortKey: true }, function(
        range,
        context,
      ) {
        toolbar.handlers['link'].call(toolbar, !context.format.link);
      });
    }
  }
}
PRWTheme.DEFAULTS = extend(true, {}, BaseTheme.DEFAULTS, {
  modules: {
    clipboard: {
      // https://github.com/quilljs/quill/issues/1328#issuecomment-680008674 => https://github.com/quilljs/quill/issues/1379#issuecomment-396114612
      // { matchVisual: false } => prevents unnecessarily <p><br></p> according to above post
      // Does not actually prevent <br> tag wrapping, but renders text without extra line breaks so users don think something is wrong
      matchVisual: false,
    },
    toolbar: {
      handlers: {
        link: function(value) {
          if (value) {
            const range = this.quill.getSelection();
            if (range == null || range.length === 0) return;
            let preview = this.quill.getText(range);
            if (
              /^\S+@\S+\.\S+$/.test(preview) &&
              preview.indexOf('mailto:') !== 0
            ) {
              preview = 'mailto:' + preview;
            }
            const tooltip = this.quill.theme.tooltip;
            tooltip.edit('link', preview);
          } else {
            this.quill.format('link', false);
          }
        },
      },
    },
  },
});

export { PRWTooltip, PRWTheme as default };
