import validator from 'validator';

const validateUserAccountId = (value?: string): Array<string> => {
  const MISSING_MESSAGE = `This email address does not exist.`;
  const errorMsgs: Array<string> = [];
  console.log(value);
  if (value === null || value === undefined || validator.isEmpty(value)) {
    errorMsgs.push(MISSING_MESSAGE);
  }

  return errorMsgs;
};

// duplicate function src/validators/SubmitDistributionValidator.ts -> validateContactEmail
export const validateCouponEmail = (value?: string): Array<string> => {
  // const LENGTH_OPTIONS = { max: 100 };
  // const FIELD_LABEL = 'Email Address';
  // const MISSING_MESSAGE = `Please provide a valid ${FIELD_LABEL}.`;
  // const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${LENGTH_OPTIONS.max} characters.`;
  const errorMsgs: Array<string> = [];
  /*
  if (
    value === null ||
    value === undefined ||
    validator.isEmpty(value) ||
    validator.isNumeric(value) ||
    !validator.isEmail(value)
  ) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  !validator.isLength(value, LENGTH_OPTIONS) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);
*/
  return errorMsgs;
};

export const validateCouponAmount = (value?: string): Array<string> => {
  const LENGTH_OPTIONS = { max: 24 };
  const FIELD_LABEL = 'Coupon Amount';
  const MISSING_MESSAGE = `Please provide an amount for ${FIELD_LABEL}.`;
  const NUMERIC_ONLY = `Please provide only numeric values.`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${LENGTH_OPTIONS.max} characters.`;
  const errorMsgs: Array<string> = [];

  if (value === undefined || validator.isEmpty(value)) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }
  if (!validator.isNumeric(value)) {
    errorMsgs.push(NUMERIC_ONLY);
    return errorMsgs;
  }

  !validator.isLength(value, { max: LENGTH_OPTIONS.max }) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateCouponExpirationDate = (value?: string): Array<string> => {
  const errorMsgs: Array<string> = [];
  // InputDatePicker component does not have validate prop
  return errorMsgs;
};

export const validateCouponNotes = (value?: string): Array<string> => {
  const LENGTH_OPTIONS = { max: 1024 };
  const FIELD_LABEL = `Notes`;
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${LENGTH_OPTIONS.max} characters.`;
  const errorMsgs: Array<string> = [];

  if (value !== undefined) {
    !validator.isLength(value, LENGTH_OPTIONS) &&
      errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);
  }

  return errorMsgs;
};

export const validateCouponDetails = (
  couponDetails: CouponDetails,
): CouponDetailsValidation => {
  let emailAddressErrors = validateCouponEmail(couponDetails.emailAddress);
  if (emailAddressErrors.length === 0) {
    emailAddressErrors = emailAddressErrors.concat(
      validateUserAccountId(couponDetails.userAccountId),
    );
  }
  const couponAmountErrors = validateCouponAmount(couponDetails.couponAmount);
  const expirationDateErrors = validateCouponExpirationDate(
    couponDetails.expirationDate,
  );
  const couponNotesErrors = validateCouponNotes(couponDetails.couponNotes);

  console.log(emailAddressErrors);
  const numErrors =
    emailAddressErrors.length +
    couponAmountErrors.length +
    expirationDateErrors.length +
    couponNotesErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    emailAddressErrors,
    couponAmountErrors,
    expirationDateErrors,
    couponNotesErrors,
  };
};

export interface CouponDetails {
  userAccountId: string;
  emailAddress: string;
  couponAmount: string;
  expirationDate: string;
  couponNotes: string;
}

export interface CouponDetailsErrors {
  emailAddressErrors: Array<string>;
  couponAmountErrors: Array<string>;
  expirationDateErrors: Array<string>;
  couponNotesErrors: Array<string>;
}

export interface CouponDetailsValidation extends CouponDetailsErrors {
  valid: boolean;
}

const AdminCouponValidator = {
  validateCouponEmail,
  validateCouponAmount,
  validateCouponNotes,
  validateCouponExpirationDate,
  validateCouponDetails,
};
export default AdminCouponValidator;
