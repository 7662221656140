import React, { FC, useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import Wizard from '../../components/wizard/wizard';
import WizardContext from '../../components/wizard/wizard-context';
import WizardProgress from '../../components/wizard/wizard-progress';
import WizardStep from '../../components/wizard/wizard-step';
import { PRWEB_PACKAGES, PRWEB_FEATURES } from '../../constants';
import CacheService from '../../utils/cache-service';

import Prid from '../distribution-wizard/components/prid';

import PurchaseEditWizardProvider from './PurchaseEditWizardProvider';
import DescriptionStep from './steps/DescriptionStep';
import StripePaymentStep from './steps/PayAndSubmitStep';
import SummaryStep from './steps/SummaryStep';

interface Props {
  envConfig: PRWebConfig;
  savedPurchase?: PurchaseEdit;
  initialProgress: Array<string>;
}

const PurchaseEditWizardSteps: FC<Props> = ({
  envConfig,
  savedPurchase,
  initialProgress,
}: Props) => {
  const history = useHistory();
  const [stripePromise, setStripePromise] = useState<any>(undefined);

  const cacheIsLoaded = (): boolean => {
    return (
      CacheService.exists(PRWEB_FEATURES) && CacheService.exists(PRWEB_PACKAGES)
    );
  };

  if (!cacheIsLoaded()) {
    console.log(
      'Cache unexpectedly empty PurchaseEditWizardSteps, redirecting to /',
    );
    history.push({ pathname: '/' });
  }

  useEffect(() => {
    const stripePromise = loadStripe(envConfig.stripe.publishableKey);
    setStripePromise(stripePromise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getWizard = (wizardData: any) => {
    return (
      <Wizard>
        <>
          <PurchaseEditWizardProvider purchaseEdit={savedPurchase}>
            <Prid />
            <WizardStep order={1} nextLabel="Next">
              <DescriptionStep />
            </WizardStep>
            <WizardStep order={2} nextLabel="Pay & Submit">
              <StripePaymentStep
                config={envConfig}
                stripePromise={stripePromise}
              />
            </WizardStep>
            <WizardStep backButtonIsVisible={false} order={3} submit>
              <SummaryStep config={envConfig} />
            </WizardStep>
          </PurchaseEditWizardProvider>

          <WizardProgress
            title={'Purchase Edit'}
            initialProgress={initialProgress}
            maxVisibleCount={3}
          />
        </>
      </Wizard>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPurchaseEditSteps = (wizardData: any) => {
    return getWizard(wizardData);
  };

  return (
    <WizardContext.Consumer>
      {wizardData => {
        return getPurchaseEditSteps(wizardData);
      }}
    </WizardContext.Consumer>
  );
};

export default PurchaseEditWizardSteps;
