import React from 'react';

import { Responsive as RvrResponsive } from '@cision/rover-ui';

const RvrContainer = RvrResponsive.Container;

interface ContainerProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: any;
  background?: string;
}

const Container = ({ children, background }: ContainerProps) => {
  // background: #f7f7f7; - default trendkite Container background color
  const styles = background ? { background } : { background: '#f7f7f7' };
  return <RvrContainer style={styles}>{children}</RvrContainer>;
};

const Responsive = {
  Container,
};

export default Responsive;
