import React, { useEffect, useState } from 'react';

import { Loader, Responsive } from '@cision/rover-ui';
import axios from 'axios';
import qs from 'qs';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import DistributionStatus from '../../components/distribution-list/distribution-status-enum';
import Footer from '../../components/footer';
import Header from '../../components/header';
import WizardNavigation from '../../components/wizard/wizard-navigation';
import WizardProvider from '../../components/wizard/wizard-provider';
import DistributionDetails from '../distribution-details/DistributionDetails';

import UpgradeWizardSteps from './UpgradeWizardSteps';

interface Props {
  config: PRWebConfig;
}

const UpgradeRoute: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const { id: distributionId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const cancelRoute = '/';

  const [isLoading, setIsLoading] = useState(true);
  const [showDistroDetails, setShowDistroDetails] = useState(false);
  const [savedUpgrade, setSavedUpgrade] = useState<Upgrade | undefined>(
    undefined,
  );
  const [savedDistribution, setSavedDistribution] = useState<
    Distribution | undefined
  >(undefined);

  const [errorSummaryValue, setErrorSummaryValue] = useState<Array<string>>([]);
  const [submitLabel, setSubmitLabel] = useState('Edit Release');
  const [progressLabel, setProgressLabel] = useState([
    'Select a Package',
    'Additions',
    'Payment',
    'Summary',
  ]);

  const handleGetDistributionError = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setErrorSummaryValue(errors);
  };

  const loadExistingDistribution = async (id: string) => {
    return axios
      .get(`${prwebApi}/distribution/item/${id}`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          const distro = response.data;
          if (!distro || !distro.distributionId) {
            console.error('The requested release could not be reteieved.');
            setIsLoading(false);
          } else {
            const upgrade: Upgrade = {
              orderNumber: '',
              transactionId: '',
              distributionId: distro.distributionId,
              prwebPressReleaseId: distro.prwebPressReleaseId,
              package: distro.package,
              initialPackage: distro.package,
              releaseDate: distro.releaseDate,
              priority: false,
              initialPriority: distro.hasPriorityRelease,
            };

            setSavedUpgrade(upgrade);
            setSavedDistribution(distro);
            switch (distro.publicationState) {
              case DistributionStatus.Published:
                history.replace({
                  pathname: `/distribution/details/${id}`,
                });
                setShowDistroDetails(true);
                setIsLoading(false);
                break;
              default:
                history.replace({
                  pathname: `/distribution/upgrade/${id}`,
                });
                setIsLoading(false);
            }
            return distro;
          }
        } else {
          handleGetDistributionError(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleGetDistributionError(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const currentSearch = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const init = async (distroId: string) => {
      await loadExistingDistribution(distroId);
    };
    if (distributionId && !isNaN(Number(distributionId))) {
      init(distributionId);
    } else if ('distributionId' in currentSearch) {
      init(currentSearch.distributionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      {errorSummaryValue.length > 0 ? (
        <div style={{ color: 'red' }}>
          Error getting release
          <ul>
            {errorSummaryValue.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          {isLoading && (
            <Loader
              style={{
                position: 'absolute',
                margin: 'auto',
                left: '0px',
                right: '0px',
                top: '200px',
                zIndex: 99,
              }}
            />
          )}
          {!isLoading && !showDistroDetails && (
            <WizardProvider history={history}>
              <WizardNavigation
                history={history}
                submitLabel={submitLabel}
                cancelRoute={cancelRoute}
                envConfig={props.config}
              />
              <UpgradeWizardSteps
                envConfig={props.config}
                savedUpgrade={savedUpgrade}
                initialProgress={progressLabel}
              />
            </WizardProvider>
          )}
          {!isLoading && showDistroDetails && savedDistribution && (
            <DistributionDetails
              config={props.config}
              distro={savedDistribution}
            />
          )}
        </>
      )}
      <Footer />
    </Responsive.Container>
  );
};

export default UpgradeRoute;
