import React, { useEffect } from 'react';

import { Typography, Button, Input, Loader, Paper } from '@cision/rover-ui';
import axios, { AxiosError } from 'axios';
import cookie from 'js-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import listBullet from '../../assets/registration/bullet.gif';
import prwebLogo from '../../assets/registration/prweb-logo.png';
import prwebWebsite from '../../assets/registration/prweb-website.png';
import { VALID_EMAIL_PATTERN, IS_NG_USER } from '../../constants';

import styles from './register.module.css';

interface Props {
  config: PRWebConfig;
}

const setNGRedirectCookies = (config: PRWebConfig, email: string) => {
  const cookieOption: { path: string; domain?: string; expires: number } = {
    path: '/',
    expires: 365,
  };

  if (config.environment === 'production') {
    const domain = config.prwebFrontEnd.url.match(
      /^https:\/\/\w+(\.\w+\.\w+)$/,
    );
    if (domain) cookieOption.domain = domain[1];
  }
  cookie.set(`${IS_NG_USER}`, `${email}`, cookieOption);
};

const Register: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const envConfig: PRWebConfig = props.config;
  const prwebApi = envConfig.prwebApi.url;
  const loginUrl = envConfig.prwebFrontEnd.url;
  const captchaSitekey = envConfig.captcha.sitekey;
  const isPartnerUrl = history.location.pathname.startsWith('/prwebpartner');

  const urlParams = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = React.useState(true);
  const [firstNameValue, setFirstNameValue] = React.useState('');
  const [lastNameValue, setLastNameValue] = React.useState('');
  const [phoneValue, setPhoneValue] = React.useState('');
  const [organizationValue, setOrganizationValue] = React.useState('');
  const [countryValue, setCountryValue] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');
  const [formErrorValue, setFormErrorValue] = React.useState<Array<string>>([]);
  const [hasValidEmail, setHasValidEmail] = React.useState(true);
  const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);
  const [hasCreatedAccount, setHasCreatedAccount] = React.useState(false);
  const [newOktaUser, setNewOktaUser] = React.useState(false);
  const [hasBeenCaptchad, sethasBeenCaptchad] = React.useState(false);
  const [isMigratingFromLegacy, setIsMigratingFromLegacy] = React.useState(
    false,
  );
  const [prwebPartner, setPRWebPartner] = React.useState<PRWebPartner>();

  const handleCountryChange = (e: React.FormEvent): void => {
    const newCountry = (e.target as HTMLInputElement).value;
    if (stateValue && countryValue !== newCountry) {
      setStateValue('');
    }
    setCountryValue((e.target as HTMLInputElement).value);
  };

  const validateEmailAddress = (email: string): boolean => {
    return VALID_EMAIL_PATTERN.test(emailValue);
  };

  const formIsValid = (): boolean => {
    const isValidEmail = validateEmailAddress(emailValue);
    setHasValidEmail(isValidEmail);

    if (!isValidEmail) {
      return false;
    }

    const hasStateValue = countryValue === 'USA' ? !!stateValue : true;

    return (
      !!firstNameValue &&
      !!lastNameValue &&
      !!phoneValue &&
      !!organizationValue &&
      !!countryValue &&
      hasStateValue &&
      hasBeenCaptchad
    );
  };

  const handleSubmit = (): void => {
    setHasBeenSubmitted(true);

    const isValid = formIsValid();

    if (isValid) {
      setIsLoading(true);

      const payload: any = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        phone: phoneValue,
        organization: organizationValue,
        email: emailValue,
        languagedId: '1033',
        campaignCode: 'PRWebNG',
        prwebPackage: '0',
        country: countryValue,
        state: stateValue,
        loginurl: loginUrl,
        migrate: isMigratingFromLegacy,
        ipaddress: '0',
      };

      if (isPartnerUrl && prwebPartner) {
        payload.partnerId = prwebPartner.partnerId;
        payload.PRWebPartnerSiteField = prwebPartner.slug;
      }

      axios
        .post(`${prwebApi}/users/add`, payload)
        .then(response => {
          setNewOktaUser(response.data.newOktaUser);
          setHasCreatedAccount(true);
          setNGRedirectCookies(props.config, payload.email);
        })
        .catch((error: AxiosError) => {
          handleRegistrationError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleRegistrationError = (error: AxiosError): void => {
    let errors: Array<string> = [];

    if (error.response && Array.isArray(error.response.data.errors)) {
      errors = error.response.data.errors;
    } else if (error.response && error.response.data.error) {
      errors.push(error.response.data.error);
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }

    setFormErrorValue(errors);
  };

  const handleEmailChange = (email: string): void => {
    setEmailValue(email);

    if (hasBeenSubmitted) {
      setHasValidEmail(validateEmailAddress(email));
    }
  };

  const liStyle = { listStyleImage: `url(${listBullet})` };

  const recaptchaCallback = (token: string | null) => {
    //console.log('*********** recaptcha callback *************');
    if (token == null) {
      sethasBeenCaptchad(false);
      return;
    }

    sethasBeenCaptchad(true);
  };

  const getPRWebPartners = async () => {
    const urlParts = history.location.pathname.split('/');
    const partnerSlug = urlParts.length > 2 ? urlParts[2] : '';
    await axios
      .get(`${prwebApi}/partner/search?slug=${encodeURIComponent(partnerSlug)}`)
      .then(response => {
        if (response.status === 200 && response.data) {
          if (response.data.redirect) {
            history.replace({ pathname: '/register' });
          }
          setPRWebPartner(response.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const isMigrating = urlParams.get('migratefromlegacy');
    if (isMigrating) {
      setIsMigratingFromLegacy(true);
      const email = urlParams.get('email') || '';
      const firstName = urlParams.get('firstname') || '';
      const lastName = urlParams.get('lastname') || '';
      setEmailValue(email);
      setFirstNameValue(firstName);
      setLastNameValue(lastName);
    }
    const initPartners = async () => {
      await getPRWebPartners();
    };
    if (isPartnerUrl) {
      initPartners();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && isPartnerUrl && !prwebPartner && (
        <div style={{ textAlign: 'center', height: '220px' }}>
          <br />
          <Typography weight="bold" size="xl2">
            404 - Not Found
          </Typography>
          <Typography size="xl">
            <p>We don&apos;t know where you are!</p>
            <p>
              Be safe. <a href="/">Go Home</a>.
            </p>
          </Typography>
        </div>
      )}
      {!isLoading && ((isPartnerUrl && prwebPartner) || !isPartnerUrl) && (
        <>
          <div>
            {prwebPartner ? (
              <div className={styles.pageHeader}>
                <img
                  src={`${loginUrl}${prwebPartner.logoPath}`}
                  alt="PRWeb Partner Logo"
                />
              </div>
            ) : (
              <>
                <div className={styles.pageHeader}>
                  <img src={prwebLogo} width="161" alt="prweb logo" />
                  <div className={styles.headerRightAlign}>
                    <div>
                      <Typography size="md">
                        Need to create buzz for your business?
                      </Typography>
                    </div>
                    <Typography weight="bold" size="lg">
                      Use PRWeb, the worlds #1 news release service
                    </Typography>
                  </div>
                </div>
                <div className={styles.headerBorder}></div>
              </>
            )}
          </div>

          {!hasCreatedAccount && (
            <div className={styles.pageContentContainer}>
              {isMigratingFromLegacy && (
                <div>
                  <div className={styles.pageTitle}>
                    <Typography color="inherit" size="xl3">
                      Upgrade your account
                    </Typography>
                  </div>
                  <div className={styles.maxTextWidth}>
                    <div>
                      <Typography weight="bold" size="xl2">
                        We’re excited to give you access to our new platform.
                      </Typography>
                    </div>
                    <div className={styles.prwebDescription}>
                      <Typography size="md">
                        You get the same distribution you already rely on with
                        enhanced account security and an easy-to-use, modern
                        interface. You can now create unlimited drafts before
                        payment to better plan your distribution strategy.
                        <br />
                        <br />
                        All your previously created releases, drafts and
                        discounts are still available. Check out the Settings
                        page for more information.
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              {!isMigratingFromLegacy && (
                <div>
                  <div className={styles.pageTitle}>
                    <Typography color="inherit" size="xl3">
                      Create A Free Account
                    </Typography>
                  </div>
                  <div className={styles.maxTextWidth}>
                    <div>
                      <Typography weight="bold" size="xl">
                        Use the power of the industry’s #1 News Release service
                        to gain online visibility for your news.
                      </Typography>
                    </div>
                    <div className={styles.prwebDescription}>
                      <Typography size="md">
                        With PRWeb, you can make sure your news is easily found
                        online on all major search engines, thousands of news
                        sites and by 30,000 journalists and bloggers, as well as
                        more than 250,000 PRWeb newsfeed subscribers. We
                        distribute your news and put your business in front of
                        millions of potential customers searching online for
                        products and services just like yours.
                      </Typography>
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.pageContentColumns}>
                <div className={styles.publicSiteColumn}>
                  <div className={styles.publicSiteTitle}>
                    <Typography weight="bold" size="xl" color="inherit">
                      Use Your News to Attract Customers
                    </Typography>
                  </div>
                  <img src={prwebWebsite} alt="prweb website" />
                  <ul>
                    <li style={liStyle}>
                      <Typography size="xl">
                        Drive an immediate increase in site traffic
                      </Typography>
                    </li>
                    <li style={liStyle}>
                      <Typography size="xl">
                        Be found by prospects with top search engine placement
                      </Typography>
                    </li>
                    <li style={liStyle}>
                      <Typography size="xl">
                        Boost your business buzz with top-tier media coverage
                      </Typography>
                    </li>
                  </ul>
                </div>
                <div className={styles.registrationContainer}>
                  <Paper style={{ padding: '0' }}>
                    <div className={styles.titleBar}>
                      {isMigratingFromLegacy && (
                        <Typography
                          className={styles.inverseText}
                          size="xl"
                          weight="bold"
                        >
                          Update your account info to begin!
                        </Typography>
                      )}
                      {!isMigratingFromLegacy && (
                        <Typography
                          className={styles.inverseText}
                          size="xl"
                          weight="bold"
                        >
                          Register for your FREE account to begin!
                        </Typography>
                      )}
                    </div>
                    <form>
                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'firstname'}>First Name: *</label>
                        <div>
                          <Input
                            id={'firstname'}
                            onChange={(e: React.FormEvent): void =>
                              setFirstNameValue(
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            value={firstNameValue}
                          />

                          {hasBeenSubmitted && !firstNameValue && (
                            <div
                              id="firstNameValidation"
                              className={styles.errorMessage}
                            >
                              Please enter a first name
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'lastname'}>Last Name: *</label>
                        <div>
                          <Input
                            id={'lastname'}
                            onChange={(e: React.FormEvent): void =>
                              setLastNameValue(
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            value={lastNameValue}
                          />
                          {hasBeenSubmitted && !lastNameValue && (
                            <div
                              id="lastNameValidation"
                              className={styles.errorMessage}
                            >
                              Please enter a last name
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'phoneNumber'}>Phone: *</label>
                        <div>
                          <Input
                            id={'phoneNumber'}
                            onChange={(e: React.FormEvent): void =>
                              setPhoneValue(
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            value={phoneValue}
                          />

                          {hasBeenSubmitted && !phoneValue && (
                            <div
                              id="phoneValidation"
                              className={styles.errorMessage}
                            >
                              Please enter a phone number
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'organization'}>Organization: *</label>
                        <div>
                          <Input
                            id={'organization'}
                            onChange={(e: React.FormEvent): void =>
                              setOrganizationValue(
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            value={organizationValue}
                          />
                          {hasBeenSubmitted && !organizationValue && (
                            <div
                              id="orgValidation"
                              className={styles.errorMessage}
                            >
                              Please enter an organization
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'country'}>Country: *</label>
                        <div>
                          <select
                            id="country"
                            name="country"
                            onChange={handleCountryChange}
                            defaultValue={countryValue}
                          >
                            <option value="">-- Please Select --</option>
                            <option value="USA">United States</option>
                            <option value="CAN">Canada</option>
                            <option value="GBR">United Kingdom</option>
                            <option value="SWE">Sweden</option>
                            <option value="">--------------</option>
                            <option value="AFG">Afghanistan</option>
                            <option value="ALA">Åland Islands</option>
                            <option value="ALB">Albania</option>
                            <option value="DZA">Algeria</option>
                            <option value="ASM">American Samoa</option>
                            <option value="AND">Andorra</option>
                            <option value="AGO">Angola</option>
                            <option value="AIA">Anguilla</option>
                            <option value="ATA">Antarctica</option>
                            <option value="ATG">Antigua and Barbuda</option>
                            <option value="ARG">Argentina</option>
                            <option value="ARM">Armenia</option>
                            <option value="ABW">Aruba</option>
                            <option value="AUS">Australia</option>
                            <option value="AUT">Austria</option>
                            <option value="AZE">Azerbaijan</option>
                            <option value="BHS">Bahamas</option>
                            <option value="BHR">Bahrain</option>
                            <option value="BGD">Bangladesh</option>
                            <option value="BRB">Barbados</option>
                            <option value="BLR">Belarus</option>
                            <option value="BEL">Belgium</option>
                            <option value="BLZ">Belize</option>
                            <option value="BEN">Benin</option>
                            <option value="BMU">Bermuda</option>
                            <option value="BTN">Bhutan</option>
                            <option value="BOL">Bolivia</option>
                            <option value="BIH">Bosnia and Herzegovina</option>
                            <option value="BWA">Botswana</option>
                            <option value="BVT">Bouvet Island</option>
                            <option value="BRA">Brazil</option>
                            <option value="IOT">Brit/Indian Ocean Terr.</option>
                            <option value="BRN">Brunei Darussalam</option>
                            <option value="BGR">Bulgaria</option>
                            <option value="BFA">Burkina Faso</option>
                            <option value="BDI">Burundi</option>
                            <option value="KHM">Cambodia</option>
                            <option value="CMR">Cameroon</option>
                            <option value="CAN">Canada</option>
                            <option value="CPV">Cape Verde</option>
                            <option value="CYM">Cayman Islands</option>
                            <option value="CAF">
                              Central African Republic
                            </option>
                            <option value="TCD">Chad</option>
                            <option value="CHL">Chile</option>
                            <option value="CHN">China</option>
                            <option value="CXR">Christmas Island</option>
                            <option value="CCK">Cocos (Keeling) Islands</option>
                            <option value="COL">Colombia</option>
                            <option value="COM">Comoros</option>
                            <option value="COG">Congo</option>
                            <option value="CDZ">
                              Congo, The Dem. Republic Of
                            </option>
                            <option value="COK">Cook Islands</option>
                            <option value="CRI">Costa Rica</option>
                            <option value="CIV">Côte D&apos;Ivore</option>
                            <option value="HRV">Croatia</option>
                            <option value="CUB">Cuba</option>
                            <option value="CYP">Cyprus</option>
                            <option value="CZE">Czech Republic</option>
                            <option value="DNK">Denmark</option>
                            <option value="DJI">Djibouti</option>
                            <option value="DMA">Dominica</option>
                            <option value="DOM">Dominican Republic</option>
                            <option value="ECU">Ecuador</option>
                            <option value="EGY">Egypt</option>
                            <option value="SLV">El Salvador</option>
                            <option value="GNQ">Equatorial Guinea</option>
                            <option value="ERI">Eritrea</option>
                            <option value="EST">Estonia</option>
                            <option value="ETH">Ethiopia</option>
                            <option value="FLK">Falkland Islands</option>
                            <option value="FRO">Faroe Islands</option>
                            <option value="FJI">Fiji</option>
                            <option value="FIN">Finland</option>
                            <option value="FRA">France</option>
                            <option value="GUF">French Guiana</option>
                            <option value="PYF">French Polynesia</option>
                            <option value="ATF">French Southern Terr.</option>
                            <option value="GAB">Gabon</option>
                            <option value="GMB">Gambia</option>
                            <option value="GEO">Georgia</option>
                            <option value="DEU">Germany</option>
                            <option value="GHA">Ghana</option>
                            <option value="GIB">Gibraltar</option>
                            <option value="GRC">Greece</option>
                            <option value="GRL">Greenland</option>
                            <option value="GRD">Grenada</option>
                            <option value="GLP">Guadeloupe</option>
                            <option value="GUM">Guam</option>
                            <option value="GTM">Guatemala</option>
                            <option value="GIN">Guinea</option>
                            <option value="GNB">Guinea-Bissau</option>
                            <option value="GUY">Guyana</option>
                            <option value="HTI">Haiti</option>
                            <option value="HMD">Heard/McDonald Isls.</option>
                            <option value="HND">Honduras</option>
                            <option value="HKG">Hong Kong</option>
                            <option value="HUN">Hungary</option>
                            <option value="ISL">Iceland</option>
                            <option value="IND">India</option>
                            <option value="IDN">Indonesia</option>
                            <option value="IRN">Iran</option>
                            <option value="IRQ">Iraq</option>
                            <option value="IRL">Ireland</option>
                            <option value="ISR">Israel</option>
                            <option value="ITA">Italy</option>
                            <option value="JAM">Jamaica</option>
                            <option value="JPN">Japan</option>
                            <option value="JOR">Jordan</option>
                            <option value="KAZ">Kazakhstan</option>
                            <option value="KEN">Kenya</option>
                            <option value="KIR">Kiribati</option>
                            <option value="PRK">Korea (North)</option>
                            <option value="KOR">Korea (South)</option>
                            <option value="KWT">Kuwait</option>
                            <option value="KGZ">Kyrgyzstan</option>
                            <option value="LAO">Laos</option>
                            <option value="LVA">Latvia</option>
                            <option value="LBN">Lebanon</option>
                            <option value="LSO">Lesotho</option>
                            <option value="LBR">Liberia</option>
                            <option value="LBY">Libya</option>
                            <option value="LIE">Liechtenstein</option>
                            <option value="LTU">Lithuania</option>
                            <option value="LUX">Luxembourg</option>
                            <option value="MAC">Macau</option>
                            <option value="MKD">Macedonia</option>
                            <option value="MDG">Madagascar</option>
                            <option value="MWI">Malawi</option>
                            <option value="MYS">Malaysia</option>
                            <option value="MDV">Maldives</option>
                            <option value="MLI">Mali</option>
                            <option value="MLT">Malta</option>
                            <option value="MHL">Marshall Islands</option>
                            <option value="MTQ">Martinique</option>
                            <option value="MRT">Mauritania</option>
                            <option value="MUS">Mauritius</option>
                            <option value="MYT">Mayotte</option>
                            <option value="MEX">Mexico</option>
                            <option value="FSM">Micronesia</option>
                            <option value="MDA">Moldova</option>
                            <option value="MCO">Monaco</option>
                            <option value="MNG">Mongolia</option>
                            <option value="MNE">Montenegro</option>
                            <option value="MSR">Montserrat</option>
                            <option value="MAR">Morocco</option>
                            <option value="MOZ">Mozambique</option>
                            <option value="MMR">Myanmar</option>
                            <option value="MNP">N. Mariana Isls.</option>
                            <option value="NAM">Namibia</option>
                            <option value="NRU">Nauru</option>
                            <option value="NPL">Nepal</option>
                            <option value="NLD">Netherlands</option>
                            <option value="ANT">Netherlands Antilles</option>
                            <option value="NCL">New Caledonia</option>
                            <option value="NZL">New Zealand</option>
                            <option value="NIC">Nicaragua</option>
                            <option value="NER">Niger</option>
                            <option value="NGA">Nigeria</option>
                            <option value="NIU">Niue</option>
                            <option value="NFK">Norfolk Island</option>
                            <option value="NOR">Norway</option>
                            <option value="OMN">Oman</option>
                            <option value="PAK">Pakistan</option>
                            <option value="PLW">Palau</option>
                            <option value="PSE">
                              Palestinian Territory, Occupied
                            </option>
                            <option value="PAN">Panama</option>
                            <option value="PNG">Papua New Guinea</option>
                            <option value="PRY">Paraguay</option>
                            <option value="PER">Peru</option>
                            <option value="PHL">Philippines</option>
                            <option value="PCN">Pitcairn</option>
                            <option value="POL">Poland</option>
                            <option value="PRT">Portugal</option>
                            <option value="PRI">Puerto Rico</option>
                            <option value="QAT">Qatar</option>
                            <option value="REU">Reunion</option>
                            <option value="ROU">Romania</option>
                            <option value="RUS">Russian Federation</option>
                            <option value="RWA">Rwanda</option>
                            <option value="KNA">Saint Kitts and Nevis</option>
                            <option value="LCA">Saint Lucia</option>
                            <option value="WSM">Samoa</option>
                            <option value="SMR">San Marino</option>
                            <option value="STP">Sao Tome/Principe</option>
                            <option value="SAU">Saudi Arabia</option>
                            <option value="SEN">Senegal</option>
                            <option value="SRB">Serbia</option>
                            <option value="SYC">Seychelles</option>
                            <option value="SLE">Sierra Leone</option>
                            <option value="SGP">Singapore</option>
                            <option value="SVK">Slovak Republic</option>
                            <option value="SVN">Slovenia</option>
                            <option value="SLB">Solomon Islands</option>
                            <option value="SOM">Somalia</option>
                            <option value="ZAF">South Africa</option>
                            <option value="ESP">Spain</option>
                            <option value="LKA">Sri Lanka</option>
                            <option value="SHN">St. Helena</option>
                            <option value="SPM">St. Pierre and Miquelon</option>
                            <option value="VCT">
                              St. Vincent and Grenadines
                            </option>
                            <option value="SDN">Sudan</option>
                            <option value="SUR">Suriname</option>
                            <option value="SJM">
                              Svalbard/Jan Mayen Isls.
                            </option>
                            <option value="SWZ">Swaziland</option>
                            <option value="SWE">Sweden</option>
                            <option value="CHE">Switzerland</option>
                            <option value="SYR">Syria</option>
                            <option value="TWN">Taiwan</option>
                            <option value="TJK">Tajikistan</option>
                            <option value="TZA">Tanzania</option>
                            <option value="THA">Thailand</option>
                            <option value="TMP">Timor-Leste</option>
                            <option value="TGO">Togo</option>
                            <option value="TKL">Tokelau</option>
                            <option value="TON">Tonga</option>
                            <option value="TTO">Trinidad and Tobago</option>
                            <option value="TUN">Tunisia</option>
                            <option value="TUR">Turkey</option>
                            <option value="TKM">Turkmenistan</option>
                            <option value="TCA">Turks/Caicos Isls.</option>
                            <option value="TUV">Tuvalu</option>
                            <option value="UGA">Uganda</option>
                            <option value="UKR">Ukraine</option>
                            <option value="ARE">United Arab Emirates</option>
                            <option value="GBR">United Kingdom</option>
                            <option value="USA">United States</option>
                            <option value="UMI">US Minor Outlying Is.</option>
                            <option value="URY">Uruguay</option>
                            <option value="UZB">Uzbekistan</option>
                            <option value="VUT">Vanuatu</option>
                            <option value="VAT">Vatican City</option>
                            <option value="VEN">Venezuela</option>
                            <option value="VNM">Viet Nam</option>
                            <option value="VGB">
                              Virgin Islands (British)
                            </option>
                            <option value="VIR">Virgin Islands (U.S.)</option>
                            <option value="WLF">Wallis/Futuna Isls.</option>
                            <option value="ESH">Western Sahara</option>
                            <option value="YEM">Yemen</option>
                            <option value="ZMB">Zambia</option>
                            <option value="ZWE">Zimbabwe</option>
                          </select>
                          {hasBeenSubmitted && !countryValue && (
                            <div
                              id="countryValidation"
                              className={styles.errorMessage}
                            >
                              Please select a country
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.registerFormColumns}>
                        {countryValue === 'USA' ? (
                          <>
                            <label htmlFor={'state'}>State: *</label>
                            <div>
                              <select
                                id="state"
                                name="state"
                                onChange={(e: React.FormEvent): void =>
                                  setStateValue(
                                    (e.target as HTMLInputElement).value,
                                  )
                                }
                                defaultValue={stateValue}
                              >
                                <option value="">
                                  -- Select State/Province --
                                </option>
                                <option value="AK">Alaska</option>
                                <option value="AL">Alabama</option>
                                <option value="AR">Arkansas</option>
                                <option value="AS">American Samoa</option>
                                <option value="AZ">Arizona</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DC">D.C.</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="FM">Micronesia</option>
                                <option value="GA">Georgia</option>
                                <option value="GU">Guam</option>
                                <option value="HI">Hawaii</option>
                                <option value="IA">Iowa</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MD">Maryland</option>
                                <option value="ME">Maine</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MO">Missouri</option>
                                <option value="MP">Marianas</option>
                                <option value="MS">Mississippi</option>
                                <option value="MT">Montana</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="NE">Nebraska</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NV">Nevada</option>
                                <option value="NY">New York</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="PW">Palau</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VA">Virginia</option>
                                <option value="VI">Virgin Islands</option>
                                <option value="VT">Vermont</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WV">West Virginia</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              {hasBeenSubmitted && !stateValue && (
                                <div
                                  id="stateValidation"
                                  className={styles.errorMessage}
                                >
                                  Please select a state
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <label htmlFor={'state'}>State: </label>
                            <Input
                              id={'state'}
                              onChange={(e: React.FormEvent): void =>
                                setStateValue(
                                  (e.target as HTMLInputElement).value,
                                )
                              }
                              value={stateValue}
                            />
                          </>
                        )}
                      </section>

                      <section className={styles.registerFormColumns}>
                        <label htmlFor={'email'}>Email: *</label>
                        <div>
                          <Input
                            id={'email'}
                            onChange={(e: React.FormEvent): void =>
                              handleEmailChange(
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            value={emailValue}
                          />
                          {!hasValidEmail && (
                            <div
                              id="emailValidation"
                              className={styles.errorMessage}
                            >
                              Please enter a valid email address
                            </div>
                          )}
                        </div>
                      </section>

                      <section className={styles.captchaContainer}>
                        <div>
                          <ReCAPTCHA
                            sitekey={`${captchaSitekey}`}
                            onChange={recaptchaCallback}
                          />
                          {hasBeenSubmitted && !hasBeenCaptchad && (
                            <div
                              id="captchaValidation"
                              className={styles.errorMessage}
                            >
                              Please confirm the captcha above
                            </div>
                          )}
                        </div>
                      </section>

                      <div>
                        {!hasCreatedAccount && (
                          <div className={styles.registerFormButtonContainer}>
                            <Button
                              id={'submitOkta'}
                              level="primary-alt"
                              onClick={handleSubmit}
                              className={styles.registerFormButton}
                              disabled={isLoading}
                            >
                              Get Free Account
                            </Button>
                          </div>
                        )}
                        {isLoading ? <Loader /> : null}
                        {!hasCreatedAccount && formErrorValue.length > 0 && (
                          <div
                            id="summaryValidation"
                            className={styles.errorMessage}
                          >
                            Error registering account:
                            <ul>
                              {formErrorValue.map((err, i) => (
                                <li key={i}>{err}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className={styles.prwebDescription}>
                          <Typography size="md">
                            Did you register but did not receive your activation
                            email? <a href="/sendactivation">Click Here</a>
                          </Typography>
                        </div>
                      </div>
                    </form>
                  </Paper>
                </div>
              </div>
            </div>
          )}
          {hasCreatedAccount && newOktaUser && (
            <Typography size="xl" className={styles.registeredDetails}>
              <br />
              <br />
              <div>
                <h3> Thank you for registering!</h3>
              </div>
              <br />
              <strong>
                Check your inbox for an email that will allow you to complete
                your registration. <br /> <br /> If you did not receive your
                activation email, please check your spam or junk folder. <br />{' '}
                If you still can&apos;t find it, please{' '}
                <a href="/sendactivation">Click Here</a>
              </strong>
            </Typography>
          )}
          {hasCreatedAccount && !newOktaUser && (
            <Typography size="xl" className={styles.registeredDetails}>
              <br />
              <br />
              <div>
                <h3>Thank you for registering with PRWeb!</h3>
              </div>
              <div>
                Click <a href={loginUrl}>here</a> to login using your existing
                Cision password.
              </div>
            </Typography>
          )}
          <div className={styles.footer}>RELEASE-2.28.3</div>
        </>
      )}
    </>
  );
};

export default Register;
