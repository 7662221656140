import FileValidator from './FileValidator';

export const validateNewsImage = (
  newsImage: NewsImage,
): NewsImageValidation => {
  const captionErrors = FileValidator.validateCaption(newsImage.caption);

  const fileNameErrors = FileValidator.validateFileName(newsImage.fileName);

  const numErrors = captionErrors.length + fileNameErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    captionErrors,
    fileNameErrors,
  };
};

export interface NewsImageErrors {
  captionErrors: Array<string>;
  fileNameErrors: Array<string>;
}

export interface NewsImageValidation extends NewsImageErrors {
  valid: boolean;
}

const NewsImageDistributionValidator = {
  validateCaption: FileValidator.validateCaption,
  validateFileName: FileValidator.validateFileName,
};

export default NewsImageDistributionValidator;
