import React, { useState, useEffect } from 'react';

import { Loader } from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as LinkSvg } from '../../assets/link-solid.svg';
import { ReactComponent as TrashSvg } from '../../assets/trash-solid.svg';
import { ReactComponent as TwitterSvg } from '../../assets/x-brands.svg';
import { PRWEB_TWITTER, SOCIAL_MEDIA_TWITTER } from '../../constants';
import PRWebSocial from '../../utils/PRWebSocial';
import Confirm from '../confirm';

import { SocialMediaState } from './social-media-container';
import styles from './social-media-container.module.css';
import TwitterConnectModal from './TwitterConnectModal';

interface TwitterProps {
  config?: PRWebConfig;
  buttonState?: SocialMediaState;
  isTwitterSelected?: boolean;
  twitterHandler?: (twitter: SocialMediaAccount) => void;
  callback?: () => void;
}

const TwitterToggle = ({
  config = {} as PRWebConfig,
  buttonState = SocialMediaState.Default,
  isTwitterSelected = true,
  twitterHandler,
  callback,
}: TwitterProps) => {
  const prwebApi = config.prwebApi.url;
  const [isLoading, setIsLoading] = useState(true);
  const [prWebTwitterAccount, setPrWebTwitterAccount] = useState<
    PRWebSocialAccount | undefined
  >(undefined);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const animationRef = React.createRef<HTMLDivElement>();

  const onCallbackSuccessConnection = () => {
    getSocialAccounts();
  };

  const isConnectedContextHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    if (prWebTwitterAccount === undefined) {
      TwitterConnectModal.Start({ config, onCallbackSuccessConnection });
    } else {
      if (buttonState === SocialMediaState.Removable) {
        trashButtonHandler();
      } else if (buttonState === SocialMediaState.Selectable) {
        selectTwitterHandler();
      }
    }
  };

  const trashButtonHandler = () => {
    setIsLoading(true);

    const twitterAccount = PRWebSocial.getSocialAccount(PRWEB_TWITTER);
    const checkActiveReleasesUrl = `${prwebApi}/social/doesprweboauthhaveactivereleaseses/${twitterAccount?.Id}`;

    axios
      .get(checkActiveReleasesUrl, {
        params: { socialMedia: SOCIAL_MEDIA_TWITTER },
      })
      .then(response => {
        setIsLoading(false);
        if (response && response.status === 200) {
          if (response.data === true) {
            console.log('response data true');
            setConfirmMessage(
              'There are/is an active Press Release(s) associated with this Social Media Account. If you continue active release(s) will have your social media account removed. Do you wish to continue?',
            );
            setToggleConfirm(true);
          } else {
            console.log('response data false');
            setConfirmMessage(
              'Your social media account will be deleted.  Do you wish to continue?',
            );
            setToggleConfirm(true);
          }
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('trashButtonHandler: ', error);
        setIsLoading(false);
      });
  };

  const selectTwitterHandler = () => {
    if (twitterHandler && prWebTwitterAccount) {
      twitterHandler({
        type: prWebTwitterAccount.Type,
        accountName: prWebTwitterAccount.AccountName,
        avatar: prWebTwitterAccount.Avatar,
        selected: prWebTwitterAccount.Selected,
      } as SocialMediaAccount);
    }
  };

  const removeSocialAccount = () => {
    setIsLoading(true);

    const twitterAccount = PRWebSocial.getSocialAccount(PRWEB_TWITTER);
    const removeTwitterAccountsUrl = `${prwebApi}/social/account/${twitterAccount?.Id}`;

    axios
      .delete(removeTwitterAccountsUrl, {
        params: { socialMedia: SOCIAL_MEDIA_TWITTER },
      })
      .then(response => {
        if (response && (response.status === 200 || response.status === 201)) {
          console.log('deleted');
          setPrWebTwitterAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_TWITTER);
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('loadcache removing twitter acct: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  const onConfirmYes = () => {
    removeSocialAccount();
    setToggleConfirm(false);
  };

  const onConfirmNo = () => {
    setToggleConfirm(false);
  };

  const getSocialAccounts = () => {
    setIsLoading(true);
    if (PRWebSocial.isConnectedToSocialMedia(PRWEB_TWITTER)) {
      const twitterAccount = PRWebSocial.getSocialAccount(PRWEB_TWITTER);
      setPrWebTwitterAccount(twitterAccount);
      setIsLoading(false);
      callback && callback();
    } else {
      const getSocialAccountsUrl = `${prwebApi}/social/account`;

      axios
        .get(getSocialAccountsUrl, {
          params: { socialMedia: SOCIAL_MEDIA_TWITTER },
        })
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const twitterAccount = {
              Id: response.data.id,
              Type: response.data.type,
              AccountName: response.data.userLoginName,
              Avatar: response.data.avatarLink,
              Authorized: response.data.authorized,
              ExternalApplicationId: response.data.twitterUserId,
              MaxContentLength: 280, // hardcode
            } as PRWebSocialAccount;
            if (twitterAccount && twitterAccount.Id) {
              setPrWebTwitterAccount(twitterAccount);
              PRWebSocial.setSocialAccount(PRWEB_TWITTER, twitterAccount);
            } else {
              setPrWebTwitterAccount(undefined);
              PRWebSocial.removeSocialAccount(PRWEB_TWITTER);
            }
            callback && callback();
          } else {
            console.log('invalid response loading social account: ', response);
            setPrWebTwitterAccount(undefined);
            PRWebSocial.removeSocialAccount(PRWEB_TWITTER);
          }
        })
        .catch(error => {
          console.log('loadcache error retrieving data: ', error);
          setPrWebTwitterAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_TWITTER);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getSocialAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.buttonContainer}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <button
            className={styles.toggleButton}
            onClick={isConnectedContextHandler}
          >
            {prWebTwitterAccount ? (
              <div
                ref={animationRef}
                className={`${styles.slider} ${isTwitterSelected &&
                  styles.sliderTransition}`}
              >
                <div
                  className={`${styles.innerContent} ${buttonState ===
                    SocialMediaState.Selectable && styles.enableBackground}`}
                >
                  <div className={styles.avatar}>
                    <img
                      className={`${styles.avatarIcon} ${styles.avatarRounded}`}
                      src={prWebTwitterAccount.Avatar}
                      alt={prWebTwitterAccount.AccountName}
                    />
                  </div>
                  <div className={styles.buttonContent}>
                    <span>{prWebTwitterAccount.AccountName}</span>
                    <span>X</span>
                    <span></span>
                  </div>
                  {buttonState === SocialMediaState.Selectable && (
                    <>
                      <div
                        className={`${styles.toggleNotch} ${
                          !isTwitterSelected ? styles.notchTransition : ''
                        }`}
                      ></div>
                      <div className={styles.sliderContent}>Use X Account</div>
                    </>
                  )}
                  {buttonState === SocialMediaState.Removable && (
                    <TrashSvg className={styles.trashSvg}></TrashSvg>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.innerContent}>
                <div className={styles.avatar}>
                  <TwitterSvg className={styles.twitterSvg} />
                </div>
                <div className={styles.buttonContent}>
                  <div>Connect to X</div>
                </div>
                <LinkSvg className={styles.linkSvg}></LinkSvg>
              </div>
            )}
          </button>
          <Confirm
            isOpen={toggleConfirm}
            text={confirmMessage}
            yes={onConfirmYes}
            no={onConfirmNo}
          ></Confirm>
        </>
      )}
    </div>
  );
};

export default TwitterToggle;
