import React, { useEffect, useState } from 'react';

import {
  Paper,
  Responsive,
  Button,
  Typography,
  Loader,
  Modal,
  EasyDropdown,
  Tooltip,
} from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import FieldValidationIssue from '../../components/field-validation-issue';
import Footer from '../../components/footer';
import Header from '../../components/header';
import NavigationBackwardArrow from '../../components/navigation-backward-arrow/navigation-backward-arrow';
import TextAreaField from '../../components/text-area-field';

import TextInputField from '../../components/text-input-field';
import {
  USER_GROUP_COOKIE,
  FINANCE_GROUP,
  EDITOR_GROUP,
  ADMIN_GROUP,
} from '../../constants';
import {
  CreateRefundError,
  validateTransaction,
  validateRefundReason,
  validateRefundAmount,
  validateRefundNote,
  validateCreateRefund,
} from '../../validators/AdminOrderValidator';

import styles from './order-details.module.css';

interface Props {
  config: PRWebConfig;
}

interface OrderLineItem {
  orderLineItemId: number;
  orderId: number;
  quantity: number;
  amount: number;
  type: string;
  description?: string;
}

interface OrderPayment {
  paymentId: number;
  orderId: number;
  type: string;
  amount: number;
  confirmationCode?: string;
  createdDate: string;
  description: string;
  thirdPartyPaymentData?: string;
}

interface OrderDetails {
  orderId: number;
  amount: number;
  createdDate: string;
  oktaLogin: string;
  email: string;
  orderNumber: string;
  lineItems: OrderLineItem[];
  payments: OrderPayment[];
  revenueRecognitionDate: Date;
}

interface OrderRefundRequest {
  orderRefundRequestId: number;
  orderId: number;
  amount: number;
  createdDate: string;
  modifiedDate: string;
  refundReason?: string;
  note?: string;
  status: string;
  refundTransactionId?: string;
}

interface OrderRefund {
  orderId: number;
  userAccountId: number;
  refundableAmount: number;
  refundedAmount: number;
  createdDate: string;
  modifiedDate: string;
  refundRequests: OrderRefundRequest[];
}

const formatUSD = (val: number) => {
  return Number(val).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const formatDecimal = (val: number | string) => {
  return Number(Number(val).toFixed(2));
};

const OrderDetails: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const userGroup = cookie.get(USER_GROUP_COOKIE);
  const prwebApi = props.config.prwebApi.url;
  const { id: orderIdParam } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({} as OrderDetails);
  const [refundRequest, setRefundRequest] = useState({} as OrderRefund);

  const [maxRefundable, setMaxRefundable] = useState(0);
  const [requestType, setRequestType] = useState(''); // approve, reject, create
  const [refundRequestId, setRefundRequestId] = useState(-1);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [
    askRefundForRevenueRecognized,
    setAskRefundForRevenueRecognized,
  ] = useState(false); // approve, reject, create

  const [formErrorValue, setFormErrorValue] = useState<Array<string>>([]);
  const [createRefundValidation, setCreateRefundValidaiton] = useState<
    CreateRefundError
  >();

  const [openRefundReason, setOpenRefundReason] = useState(false);
  const [modalRefundNotes, setModalRefundNotes] = useState('');
  const [modalRefundReason, setModalRefundReason] = useState('');
  const [modalRefundAmount, setModalRefundAmount] = useState('');
  const [modalTransactionId, setModalTransactionId] = useState('');

  const checkValidCreateRefund = () => {
    const isValid = validateCreateRefund(
      modalRefundReason,
      modalRefundAmount,
      modalRefundNotes,
      modalTransactionId,
    );
    setCreateRefundValidaiton(isValid);
    return isValid;
  };

  const updateDataFieldByValue = (key: string, value?: string) => {
    if (key === 'modalRefundNotes') {
      setModalRefundNotes(value || '');
      setCreateRefundValidaiton({
        ...createRefundValidation,
        refundNoteErrors: validateRefundNote(value || ''),
      } as CreateRefundError);
    } else if (key === 'reason') {
      setModalRefundReason(value || '');
      setCreateRefundValidaiton({
        ...createRefundValidation,
        refundReasonErrors: validateRefundReason(value || ''),
      } as CreateRefundError);
    } else if (key === 'modalRefundAmount') {
      setModalRefundAmount(value || '');
      setCreateRefundValidaiton({
        ...createRefundValidation,
        refundAmountErrors: validateRefundAmount(value || ''),
      } as CreateRefundError);
    } else if (key === 'modalRefundTransactionId') {
      setModalTransactionId(value || '');
      setCreateRefundValidaiton({
        ...createRefundValidation,
        refundTransactionErrors: validateTransaction(value || ''),
      } as CreateRefundError);
    }
  };

  const requestMaxRefund = () => {
    setModalRefundAmount(maxRefundable.toString());
    setCreateRefundValidaiton({
      ...createRefundValidation,
      refundAmountErrors: validateRefundAmount(maxRefundable.toString() || ''),
    } as CreateRefundError);
  };

  const changeReasonDropdown = () => {
    setOpenRefundReason(!openRefundReason);
  };

  const isAdminOrFinanceGroup =
    userGroup === FINANCE_GROUP || userGroup === ADMIN_GROUP;

  const getModalContent = (type: string, requestId?: number) => {
    if (type === 'Approve') {
      const refundReq = (refundRequest?.refundRequests || []).find(
        item => item.orderRefundRequestId === requestId,
      );
      if (refundReq) {
        return (
          <div className={styles.modalContent}>
            <Typography tag="p">
              Reason: <strong>{refundReq?.refundReason}</strong>
            </Typography>
            <Typography>Notes:</Typography>
            <Typography>{refundReq?.note !== '' && refundReq?.note}</Typography>
            <br />
            <TextInputField
              id="modalRefundTransactionId"
              label="Refund Transaction Id"
              value={modalTransactionId}
              onChangeValue={updateDataFieldByValue}
              placeholder="TransactionId"
              validate={validateTransaction}
              issues={createRefundValidation?.refundTransactionErrors || []}
            ></TextInputField>
          </div>
        );
      }
    } else if (type === 'Reject') {
      const refundReq = (refundRequest?.refundRequests || []).find(
        item => item.orderRefundRequestId === requestId,
      );
      if (refundReq) {
        return (
          <div className={styles.modalContent}>
            <Typography tag="p">
              Reason: <strong>{refundReq?.refundReason}</strong>
            </Typography>
            <Typography>Notes:</Typography>
            <Typography>{refundReq.note !== '' && refundReq.note}</Typography>
            <br />
            <Typography weight="bold">
              Click confirm to reject request for{' '}
              {formatUSD(refundReq.amount || 0)} refund.
            </Typography>
          </div>
        );
      }
    } else if (type === 'Create') {
      const menuItems = [
        {
          label: 'Customer Changed Mind',
          onClick: () =>
            updateDataFieldByValue('reason', 'Customer Changed Mind'),
        },
        {
          label: 'Competitive Service',
          onClick: () =>
            updateDataFieldByValue('reason', 'Competitive Service'),
        },
        {
          label: 'Explicit Content',
          onClick: () => updateDataFieldByValue('reason', 'Explicit Content'),
        },
        {
          label: 'Foreign Language',
          onClick: () => updateDataFieldByValue('reason', 'Foreign Language'),
        },
        {
          label: 'Other',
          onClick: () => updateDataFieldByValue('reason', 'Other'),
        },
        {
          label: 'Online Gambling',
          onClick: () => updateDataFieldByValue('reason', 'Online Gambling'),
        },
        {
          label: `Didn't Like Service`,
          onClick: () =>
            updateDataFieldByValue('reason', `Did Not Like Service`),
        },
      ];
      return (
        <div className={styles.modalContent}>
          {maxRefundable === 0 ? (
            <Typography size="lg" color="black">
              This order has reached the maximum refundable amount.
            </Typography>
          ) : (
            <>
              <span className={styles.inputWithButtonContainer}>
                <Typography>
                  Reason: <strong>{modalRefundReason}</strong>
                </Typography>
                <EasyDropdown
                  id="setRefundReason"
                  onClick={changeReasonDropdown}
                  isOpen={openRefundReason}
                  menuItems={menuItems}
                >
                  Set Refund Reason
                </EasyDropdown>
              </span>
              {createRefundValidation?.refundReasonErrors && (
                <FieldValidationIssue
                  id="refundReasonErrors"
                  issues={createRefundValidation?.refundReasonErrors}
                />
              )}
              <br />
              <span className={styles.inputWithButtonContainer}>
                <TextInputField
                  id="modalRefundAmount"
                  label="Amount"
                  value={modalRefundAmount}
                  onChangeValue={updateDataFieldByValue}
                  placeholder="Amount"
                  issues={createRefundValidation?.refundAmountErrors || []}
                ></TextInputField>
                <Button
                  id="modalRefundSetMax"
                  style={{ alignSelf: 'flex-start', marginTop: '29px' }}
                  onClick={requestMaxRefund}
                >
                  Set Max
                </Button>
              </span>
              <br />
              <TextAreaField
                id={'modalRefundNotes'}
                label="Notes - Optional"
                value={modalRefundNotes}
                onChangeValue={updateDataFieldByValue}
                issues={createRefundValidation?.refundNoteErrors || []}
              />
            </>
          )}
        </div>
      );
    }
  };

  const noToWarning = () => {
    setAskRefundForRevenueRecognized(false);
  };
  const yesToWarning = () => {
    setCreateRefundValidaiton(undefined);
    setRefundRequestId(-1);
    setAskRefundForRevenueRecognized(false);
    setRequestType('Create');
  };

  const refundRequestClick = (type: string, requestId?: number) => () => {
    if (orderDetails.revenueRecognitionDate && type === 'Create') {
      if (userGroup === FINANCE_GROUP || userGroup === ADMIN_GROUP) {
        setAskRefundForRevenueRecognized(true);
      }
    } else {
      setRequestType(type);
      setCreateRefundValidaiton(undefined);
      setRefundRequestId(requestId || -1);
    }
  };

  const cancel = () => {
    if (requestType === 'Create') {
      setModalRefundNotes('');
      setModalRefundReason('');
      setModalRefundAmount('');
    } else {
      setModalTransactionId('');
    }
    setRequestType('');
    setFormErrorValue([]);
    setRefundRequestId(-1);
  };
  const confirm = () => {
    if (requestType === 'Create') {
      createRefundRequest();
    } else {
      handleRefundRequest(requestType);
    }
  };

  const createRefundRequest = async () => {
    if (!checkValidCreateRefund().isValid) return;

    setLoadingRequest(true);
    const createRefundRequestUrl = `${prwebApi}/admin/order/refund`;
    const payload = {
      orderId: orderDetails.orderId,
      amount: modalRefundAmount,
      note: modalRefundNotes,
      reason: modalRefundReason,
    };
    await axios
      .post(createRefundRequestUrl, payload)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalRefundNotes('');
          setModalRefundAmount('');
          setModalRefundReason('');
          setRequestType(''); // empty string will close modal
        }
      })
      .catch(error => {
        let errors: Array<string> = [];
        if (error.response && Array.isArray(error.response.data.errors)) {
          errors = error.response.data.errors;
        } else if (error.response && error.response.data.error) {
          errors.push(error.response.data.error);
        } else if (error.response && error.response.statusText) {
          errors.push(error.response.statusText);
        } else {
          errors.push(error.message || 'Unable to create refund request.');
        }
        setFormErrorValue(errors);
        return Promise.reject(error);
      })
      .then(getRefundRequest)
      .finally(() => {
        setLoadingRequest(false);
      });
  };

  const handleRefundRequest = async (status: string) => {
    if (status.toLowerCase() === 'approve') {
      const transactionIdErrors = checkValidCreateRefund()
        .refundTransactionErrors;
      if (Array.isArray(transactionIdErrors) && transactionIdErrors.length > 0)
        return;
    }

    setLoadingRequest(true);
    const updateRefundStatusUrl = `${prwebApi}/admin/order/refund/setstatus`;
    const payload = {
      orderRefundRequestId: refundRequestId,
      status: status.toLowerCase() === 'approve' ? 'approved' : 'rejected',
    } as any;
    if (modalTransactionId) {
      payload.refundTransactionId = modalTransactionId;
    }
    await axios
      .post(updateRefundStatusUrl, payload)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalTransactionId('');
          setRequestType(''); // empty string will close modal
        }
      })
      .catch(error => {
        let errors: Array<string> = [];
        if (error.response && Array.isArray(error.response.data.errors)) {
          errors = error.response.data.errors;
        } else if (error.response && error.response.data.error) {
          errors.push(error.response.data.error);
        } else if (error.response && error.response.statusText) {
          errors.push(error.response.statusText);
        } else {
          errors.push(error.message || 'Unable to update refund request.');
        }
        setFormErrorValue(errors);
        return Promise.reject(error);
      })
      .then(getRefundRequest)
      .finally(() => {
        setLoadingRequest(false);
      });
  };

  const getOrderDetails = () => {
    const params = new URLSearchParams({
      orderId: (orderIdParam || '').toString(),
    });
    const getOrderDetailsUrl = `${prwebApi}/admin/order/item`;
    return axios
      .get(getOrderDetailsUrl, { params })
      .then(response => {
        if (response.status === 200 && response.data) {
          setOrderDetails(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getRefundRequest = () => {
    const params = new URLSearchParams({
      orderId: (orderIdParam || '').toString(),
    });
    const getRefundRequestUrl = `${prwebApi}/admin/order/refund/item`;
    return axios
      .get(getRefundRequestUrl, { params })
      .then(response => {
        if (response.status === 200 && response.data) {
          setRefundRequest(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if ('payments' in orderDetails && Array.isArray(orderDetails.payments)) {
      const refundable = (orderDetails.payments || [])
        .filter(
          item =>
            item.type.toLowerCase() === 'credit card' ||
            item.type.toLowerCase() === 'paypal',
        )
        .map(item => item.amount)
        .reduce((a, b) => formatDecimal(Number(a) + Number(b)), 0);

      let requested = 0;

      if (
        'refundRequests' in refundRequest &&
        Array.isArray(refundRequest.refundRequests)
      ) {
        const amount = (refundRequest.refundRequests || [])
          .filter(item => item.status.toLowerCase() !== 'rejected')
          .map(item => item.amount)
          .reduce((a, b) => formatDecimal(Number(a) + Number(b)), 0);
        requested = amount;
      }

      setMaxRefundable(formatDecimal(refundable - requested));
    }
  }, [
    orderDetails,
    orderDetails.payments,
    refundRequest,
    refundRequest.refundRequests,
  ]);

  useEffect(() => {
    const init = async () => {
      await Promise.all([getOrderDetails(), getRefundRequest()]).then(() => {
        setIsLoading(false);
      });
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPreviousPage = () => history.goBack();

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      <Paper className={styles.orderDetailsContainer}>
        <Button
          id={'details-back-button'}
          level={'tertiary'}
          onClick={goToPreviousPage}
        >
          <div className={styles.backButton}>
            <NavigationBackwardArrow />
            <span>back</span>
          </div>
        </Button>
        {isLoading && <Loader />}
        {!isLoading && !('orderId' in orderDetails) && (
          <section className={styles.sectionContainer}>
            <Typography weight="bold" color="black" size="xl2" tag="h2">
              Order Not found
            </Typography>
          </section>
        )}
        {!isLoading && 'orderId' in orderDetails && (
          <section className={styles.sectionContainer}>
            <Typography weight="bold" color="black" size="xl2" tag="h2">
              Order Details
            </Typography>
            <Typography color="black" tag="p">
              Order {orderDetails.orderNumber} on{' '}
              {moment.utc(orderDetails.createdDate).format('lll')} UTC
            </Typography>
            <Typography color="black" tag="p">
              Login Username: {orderDetails.oktaLogin}
            </Typography>
            <Typography color="black" tag="p">
              Primary Email: {orderDetails.email}
            </Typography>
            <table className={styles.lineItemTable}>
              <tbody>
                {(orderDetails.lineItems || []).map((item, index) => {
                  let label = '';
                  switch (item.type.toLowerCase()) {
                    case 'package':
                      label = `${item.description} Package`;
                      break;
                    case 'cjl':
                      label = `Cision Journalist List - Qty ${item.quantity}`;
                      break;
                    case 'socialmedia':
                      label = `Cision Social Post`;
                      break;
                    case 'priority':
                      label = 'Priority Distribution';
                    case 'purchaseedit':
                      label = 'Release Edit';
                  }
                  return (
                    <tr key={index}>
                      <td>{label}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatUSD(item.amount)}
                      </td>
                    </tr>
                  );
                })}
                <tr className={styles.lastLineItem}>
                  <td>Total</td>
                  <td>{formatUSD(orderDetails.amount)}</td>
                </tr>
                <tr>
                  {!isLoading && orderDetails.revenueRecognitionDate && (
                    <td>Revenue Recognized</td>
                  )}
                </tr>
              </tbody>
            </table>
            <div>
              <Typography weight="bold" color="black" size="xl">
                Payment Information
              </Typography>
            </div>
            <table className={styles.lineItemTable}>
              <tbody>
                {orderDetails.payments.map((item, index) => {
                  let label = '';
                  if (
                    (item.confirmationCode || '')
                      .toLowerCase()
                      .startsWith('pi_')
                  ) {
                    label = `Stripe ${item.type} - ${item.confirmationCode}`;
                  } else if (item.type.toLowerCase() === 'credit card') {
                    label = `Authorize.Net ${item.type} - Confirmation Code ${item.confirmationCode}`;
                  } else if (item.type.toLowerCase().endsWith('coupon')) {
                    label = `${item.type} - Code ${item.description}`;
                  } else {
                    label = `${item.type} - ${item.description}`;
                  }
                  return (
                    <tr key={index}>
                      <td>{label}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatUSD(item.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        )}
      </Paper>
      {!isLoading && 'orderId' in orderDetails && (
        <Paper className={styles.orderDetailsContainer}>
          <section className={styles.sectionContainer}>
            <Typography weight="bold" color="black" size="xl2" tag="h2">
              Refund Requests
            </Typography>
            <table className={styles.lineItemTable}>
              <tbody id="updatedRefundRequestList">
                <tr>
                  <td>New Refund Request</td>
                  <td style={{ textAlign: 'right', padding: '4px' }}>
                    {(maxRefundable > 0 && userGroup !== EDITOR_GROUP) ||
                    (maxRefundable > 0 &&
                      userGroup === EDITOR_GROUP &&
                      !orderDetails.revenueRecognitionDate) ? (
                      <Button
                        id="createRefundBtn"
                        className={styles.rowButtons}
                        onClick={refundRequestClick('Create')}
                      >
                        Create
                      </Button>
                    ) : (
                      <Typography weight="bold" size="lg">
                        {maxRefundable === 0 ? (
                          <div>
                            This order has reached the maximum refundable
                            amount.
                          </div>
                        ) : (
                          <div>You are not allowed to create a refund.</div>
                        )}
                      </Typography>
                    )}
                  </td>
                </tr>
                {(refundRequest.refundRequests || [])
                  .filter(item => item.status.toLowerCase() !== 'requested')
                  .map((item, index) => {
                    let label = `Refund ${item.status} on ${moment
                      .utc(item.modifiedDate)
                      .format('lll')} UTC `;
                    if (item.refundTransactionId)
                      label += ` - Transaction Id ${item.refundTransactionId}`;
                    return (
                      <tr key={index}>
                        <td>
                          {label}
                          {item.note && (
                            <Tooltip
                              showOnHover
                              tooltipWidth={'400px'}
                              content={
                                <div>
                                  <Typography>
                                    Reason: <strong>{item.refundReason}</strong>
                                  </Typography>
                                  <br />
                                  {item.note && (
                                    <Typography tag="p">
                                      Note: {item.note}
                                    </Typography>
                                  )}
                                </div>
                              }
                            >
                              <InfoSvg width={15} height={15}></InfoSvg>
                            </Tooltip>
                          )}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          {formatUSD(item.amount)}
                        </td>
                      </tr>
                    );
                  })}
                {Array.isArray(refundRequest.refundRequests) && (
                  <tr className={styles.lastLineItem}>
                    <td>Total</td>
                    <td>
                      {formatUSD(refundRequest.refundedAmount || 0)} Refunded
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              <Typography weight="bold" color="black" size="xl">
                Pending Requests
              </Typography>
            </div>
            <table className={styles.lineItemTable}>
              <tbody id="pendingRefundRequestList">
                {(refundRequest.refundRequests || [])
                  .filter(item => item.status.toLowerCase() === 'requested')
                  .map((item, index) => {
                    const label = `Refund ${item.status} on ${moment
                      .utc(item.createdDate)
                      .format('lll')} UTC `;
                    return (
                      <tr key={index}>
                        <td>
                          {label}
                          <Tooltip
                            showOnHover
                            tooltipWidth={'400px'}
                            content={
                              <div>
                                <Typography>
                                  Reason: <strong>{item.refundReason}</strong>
                                </Typography>
                                <br />
                                {item.note && (
                                  <Typography tag="p">
                                    Note: {item.note}
                                  </Typography>
                                )}
                              </div>
                            }
                          >
                            <InfoSvg width={15} height={15}></InfoSvg>
                          </Tooltip>
                        </td>
                        <td style={{ textAlign: 'right', padding: '4px' }}>
                          {formatUSD(item.amount)}
                          {isAdminOrFinanceGroup && (
                            <>
                              &nbsp;
                              <Button
                                level="primary-alt"
                                className={styles.rowButtons}
                                onClick={refundRequestClick(
                                  'Approve',
                                  item.orderRefundRequestId,
                                )}
                              >
                                Approve
                              </Button>
                              <Button
                                level="danger"
                                className={styles.rowButtons}
                                onClick={refundRequestClick(
                                  'Reject',
                                  item.orderRefundRequestId,
                                )}
                              >
                                Reject
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {Array.isArray(refundRequest.refundRequests) && (
                  <tr className={styles.lastLineItem}>
                    <td>Total</td>
                    <td>{formatUSD(maxRefundable || 0)} Refundable</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </Paper>
      )}
      <Modal isOpen={requestType !== ''}>
        <Modal.Header>
          <Typography>{requestType} Refund Request</Typography>
        </Modal.Header>
        <Modal.Body>
          {getModalContent(requestType, refundRequestId)}
          <br />
          {formErrorValue.length > 0 && (
            <ul className={styles.errorMsg}>
              {formErrorValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.modalFooter}>
            {loadingRequest ? (
              <Loader />
            ) : (
              <>
                <Button id={'cancelButton'} level="secondary" onClick={cancel}>
                  Cancel
                </Button>
                <Button id={'confirmButton'} level="primary" onClick={confirm}>
                  Confirm
                </Button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal isOpen={askRefundForRevenueRecognized}>
        <Modal.Header>
          <Typography>Warning</Typography>
        </Modal.Header>
        <Modal.Body>
          <p>
            30 days have passed since any modifications and the revenue for this
            order has been recognized by the system.
          </p>
          <p> Are you sure you wish to continue creating a refund?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.modalFooter}>
            {loadingRequest ? (
              <Loader />
            ) : (
              <>
                <Button id={'noButton'} level="secondary" onClick={noToWarning}>
                  No
                </Button>
                <Button id={'yesButton'} level="primary" onClick={yesToWarning}>
                  Yes
                </Button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <Footer />
    </Responsive.Container>
  );
};

export default OrderDetails;
