import React from 'react';

import { ReactComponent as BackwardArrowSVG } from './arrow-backward.svg';

function NavigationBackwardArrow() {
  const BACKWARD_ARROW_SIZE = 24;

  return (
    <BackwardArrowSVG
      width={BACKWARD_ARROW_SIZE}
      height={BACKWARD_ARROW_SIZE}
      viewBox="0 0 24 24"
    ></BackwardArrowSVG>
  );
}

export default NavigationBackwardArrow;
