import React, { useEffect, useContext } from 'react';

import { Typography, Paper } from '@cision/rover-ui';

import WizardContext from '../../../components/wizard/wizard-context';
import {
  ADVANCED_PACKAGE_ID,
  CJL_PRICE,
  CSP_PRICE,
  PREMIUM_PACKAGE_ID,
  PRIORITY_PRICE,
} from '../../../constants';

import AdditionsPriorityPanel from '../../distribution-wizard/components/additions-priority-panel';
import ExitWizardPrompt from '../../distribution-wizard/components/exit-wizard-prompt';
import PRWebPackages from '../../distribution-wizard/PRWebPackages';

import UpgradeWizardContext from '../UpgradeWizardContext';

import styles from './styles/additions-step.module.css';

const AddOnStep: React.FC = () => {
  const { upgradeData, updateUpgradeData } = React.useContext(
    UpgradeWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);

  const initialPackage = PRWebPackages.getPRWebPackageWithAbilities(
    upgradeData.initialPackage,
  );
  const currentPackage = PRWebPackages.getPRWebPackageWithAbilities(
    upgradeData.package,
  );

  const showPriorityOption =
    !upgradeData.initialPriority &&
    (currentPackage?.packageId === ADVANCED_PACKAGE_ID ||
      currentPackage?.packageId === PREMIUM_PACKAGE_ID);

  const getHidePriorityText = () => {
    if (upgradeData.initialPriority) {
      return `Priority Distribution is the only addition that PRWeb currently offers for upgraded releases. You have already selected the Priority Distribution addition for this release.`;
    } else if (Number(upgradeData.package) < Number(ADVANCED_PACKAGE_ID)) {
      return `Priority Distribution is the only addition that PRWeb currently offers for upgraded releases. Please select an Advanced or Premium package upgrade if you'd like the option to add Priority Distribution.`;
    }
  };

  const addCJL = (cjl: number) => {};

  const removeCJL = () => {};

  const addCSP = () => {};

  const removeCSP = () => {};

  const addPriority = () => {
    updateUpgradeData({
      ...upgradeData,
      priority: true,
    });
  };

  const removePriority = () => {
    updateUpgradeData({
      ...upgradeData,
      priority: false,
    });
  };

  useEffect(() => {
    wizardContext.setStepIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update progress
  useEffect(() => {
    if (!upgradeData.priority) {
      wizardContext.setProgressWhileEditing([]);
      return;
    }

    const stepValuesArray: string[] = [];

    if (upgradeData.priority) {
      stepValuesArray.push('Priority Distribution');
    }

    wizardContext.setProgressWhileEditing(stepValuesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradeData.priority]);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Additions (Optional)
          </Typography>
        </div>
      </div>
      <div>
        {/* <Paper className={styles.addonPaper}>
          <div className={styles.columnContainer}>
            <div className={styles.leftColumn}>
              <div>
                <Typography size="lg" color="black" weight="bold">
                  <span>Purchase more Cision Journalist Lists</span>
                </Typography>
              </div>
              <div>
                <Typography>
                  <p>
                    Increase your potential for earned media picked up by
                    including additional industry specific journalist list ($
                    {CJL_PRICE} / list)
                  </p>
                </Typography>
              </div>
              {PRWebPackages.isAdvancedPackage(distributionData.package) && (
                <div>
                  <Typography>
                    <strong>
                      The Advanced package already comes with 1 free list!
                    </strong>
                  </Typography>
                </div>
              )}
              {PRWebPackages.isPremiumPackage(distributionData.package) && (
                <div>
                  <Typography>
                    <strong>
                      The Premium package already comes with 2 free lists!
                    </strong>
                  </Typography>
                </div>
              )}
            </div>
            <div className={styles.rightColumn}>
              <AdditionsCJLPanel
                cjl={distributionData.cjl}
                onAddCJL={addCJL}
                onRemoveCJL={removeCJL}
                isAdvancedPackage={PRWebPackages.isAdvancedPackage(
                  distributionData.package,
                )}
                isPremiunPackage={PRWebPackages.isPremiumPackage(
                  distributionData.package,
                )}
              ></AdditionsCJLPanel>
            </div>
          </div>
        </Paper> */}
        {showPriorityOption ? (
          <Paper className={styles.addonPaper}>
            <div className={styles.columnContainer}>
              <div className={styles.leftColumn}>
                <div>
                  <Typography size="lg" color="black" weight="bold">
                    <span>Priority Distribution</span>
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <p>
                      Add Priority to distribute your release within 4 hours ($
                      {PRIORITY_PRICE})
                    </p>
                  </Typography>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <AdditionsPriorityPanel
                  hasPriority={upgradeData.priority || false}
                  onAddPriority={addPriority}
                  onRemovePriority={removePriority}
                ></AdditionsPriorityPanel>
              </div>
            </div>
          </Paper>
        ) : (
          <div>{getHidePriorityText()}</div>
        )}
      </div>
      <ExitWizardPrompt />
    </div>
  );
};

export default AddOnStep;
