import * as React from 'react';

import { Button, Modal } from '@cision/rover-ui';

import styles from './confirm.module.css';

interface Props {
  isOpen: boolean;
  text: string;
  yes: () => void;
  no: () => void;
}

const Confirm = ({ isOpen, text, yes, no }: Props) => {
  return (
    <Modal isOpen={isOpen} size="md">
      <Modal.Header className={styles.centered}>
        <strong>Attention!</strong>
      </Modal.Header>
      <Modal.Body className={styles.centered}>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Modal.Body>
      <Modal.Footer className={`${styles.centered} ${styles.padding}`}>
        <div id={'confirmMessageModal'}>
          <Button level="secondary" id={'noButton'} onClick={no}>
            No
          </Button>
          <Button level="primary" id={'yesButton'} onClick={yes}>
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
