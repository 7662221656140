import React from 'react';

export default React.createContext({
  upgradeData: {} as Upgrade,
  updateUpgradeData: (distro: Upgrade) => {},
  /**
   * Use to update the top level attributes of the Distribution Object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUpgradeByField: (topLevelKey: string, value: any) => {},
});
