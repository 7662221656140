import moment from 'moment-timezone';

import {
  PRWEB_PACKAGES,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
  BASIC_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
} from '../../constants';
import CacheService from '../../utils/cache-service';

interface PackageWithAbilities extends PRWebPackage {
  releaseCapabilities?: { hasBodyLinks: boolean };
}

const PRWebPackages = {
  getPackages: (): Array<PackageWithAbilities> => {
    if (CacheService.exists(PRWEB_PACKAGES)) {
      return CacheService.get(PRWEB_PACKAGES) as Array<PackageWithAbilities>;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  },

  getPRWebPackage: (packageId: string): PRWebPackage | undefined => {
    const packages = PRWebPackages.getPackages();
    if (!packages) {
      return { packageId: '0', name: 'N/A', price: 0 };
    }

    const selectedPackage = packages.find(pkg => pkg.packageId === packageId);

    return selectedPackage ? selectedPackage : undefined;
  },

  getPRWebPackageWithAbilities: (
    packageId: string,
  ): PackageWithAbilities | undefined => {
    const packages = PRWebPackages.getPackages();
    if (!packages) {
      return { packageId: '0', name: 'N/A', price: 0 };
    }

    const selectedPackage = packages.find(pkg => pkg.packageId === packageId);

    return selectedPackage ? selectedPackage : undefined;
  },

  isAdvancedPackage: (packageId: string): boolean => {
    return packageId === ADVANCED_PACKAGE_ID;
  },

  isPremiumPackage: (packageId: string): boolean => {
    return packageId === PREMIUM_PACKAGE_ID;
  },

  isLowerPackage: (packageId: string): boolean => {
    return packageId === BASIC_PACKAGE_ID || packageId === STANDARD_PACKAGE_ID;
  },

  isHigherPackage: (packageId: string): boolean => {
    return (
      packageId === ADVANCED_PACKAGE_ID || packageId === PREMIUM_PACKAGE_ID
    );
  },
  getPackageBasedMinTurnaroundDate: (
    packageId?: string,
    timezone?: string,
  ): moment.Moment => {
    let tat = 48;
    if (packageId) {
      switch (packageId) {
        case ADVANCED_PACKAGE_ID:
        case PREMIUM_PACKAGE_ID:
          tat = 24;
          break;
        default:
          break;
      }
    }
    return moment()
      .tz(timezone || 'UTC')
      .add(tat, 'hours');
  },

  getPriorityReleaseTurnaroundDate: (timezone?: string): moment.Moment => {
    return moment()
      .tz(timezone || 'UTC')
      .add(4, 'hours');
  },
};

export default PRWebPackages;
