import React, { useEffect, useContext, useCallback, useState, FC } from 'react';

import { Typography, Loader } from '@cision/rover-ui';

import axios, { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import ReCAPTCHA from 'react-google-recaptcha';

import WizardContext from '../../../../components/wizard/wizard-context';
import { COUPON_STATUS } from '../../../../constants';
import ExitWizardPrompt from '../../components/exit-wizard-prompt';
import OrderSummary from '../../components/order-summary';
import DistributionWizardContext from '../../DistributionWizardContext';
import OrderUtility from '../../OrderUtility';

import styles from './payment-step.module.css';
import PaymentSelector from './PaymentSelector';
import RecommendedCoupon from './RecommendedCoupon';
import StripePaymentForm from './StripePaymentForm';

interface PaymentStepProps {
  config: PRWebConfig;
  stripePromise: any;
}

interface DistributionApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface PaymentError {
  response: {
    status: number;
    data: DistributionApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const StripePaymentStep: FC<PaymentStepProps> = ({
  config,
  stripePromise,
}: PaymentStepProps) => {
  const [isCreditCard, setIsCreditCard] = useState(true); // TODO: Refactor to something like isPayPal or isStripePayment
  const [stripePayment, setStripePayment] = React.useState(
    {} as { intentId: string; clientSecret: string },
  );
  const [appliedCoupon, setAppliedCoupon] = useState<
    CouponDetails | undefined
  >();
  const [universalCoupon, setUniversalCoupon] = useState<
    UniversalCoupon | undefined
  >();

  const { distributionData, updateDistributionData } = useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [couponList, setCouponList] = useState<CouponDetails[]>([]);
  const [recommendedCoupon, setRecommendedCoupon] = useState<
    CouponDetails | undefined
  >();
  // applicableCouponAmt is the maximum partially-charged amount for a coupon after prepaid package and universal coupons are appiled
  const [applicableCouponAmt, setApplicableCouponAmt] = useState<number>(0);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldDisablePaymentForm, setShouldDisablePaymentForm] = useState(
    false,
  );
  const [isFormComplete, setIsFormComplete] = useState(false);

  const [hasBeenCaptchad, sethasBeenCaptchad] = React.useState(false);
  const [showPaymentCaptcha, setShowPaymentCaptcha] = React.useState(false);

  const captchaSitekey = config.captcha.sitekey;

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const recaptchaCallback = (token: string | null) => {
    if (token == null) {
      sethasBeenCaptchad(false);
      return;
    }
    sethasBeenCaptchad(true);
  };

  const updateIsLoading = (status: boolean) => {
    setIsLoading(status);
  };

  const getUserCoupons = (): Promise<any> => {
    return axios.get(`${prwebApi}/users/coupons`).then(response => {
      if (response && response.status === 200) {
        return response.data;
      }
    });
  };

  const getUniversalCoupon = async (code: string) => {
    return await axios
      .get(`${prwebApi}/universalcoupon?couponCode=${encodeURIComponent(code)}`)
      .then(response => {
        if (response && response.status === 200) {
          if (
            moment
              .utc()
              .endOf('day')
              .isBefore(response.data.startDate)
          ) {
            return 'Cannot apply an inactive coupon.';
          }
          if (
            moment
              .utc()
              .startOf('day')
              .isAfter(response.data.expirationDate)
          ) {
            return 'Cannot apply an expired coupon.';
          }
          setUniversalCoupon(response.data);
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          return 'Coupon code not found';
        } else {
          let msg = '';
          if (
            error?.response?.data?.errors &&
            Array.isArray(error?.response?.data?.errors)
          ) {
            msg = error.response.data.errors.join('\n');
          } else {
            msg =
              error?.response?.data?.message?.toString() ||
              'Unable to apply coupon code.';
          }
          return msg;
        }
      });
  };

  const getBestCoupon = (coupons: CouponDetails[]) => {
    let bestCoupon: CouponDetails | undefined = undefined;
    for (const coupon of coupons) {
      if (coupon.status === COUPON_STATUS.UNUSED) {
        if (bestCoupon === undefined) {
          if (moment.utc(coupon.expirationDate).isAfter(moment.utc())) {
            bestCoupon = coupon;
          }
        } else if (
          moment
            .utc(coupon.expirationDate)
            .isBefore(moment.utc(bestCoupon.expirationDate)) &&
          moment.utc(coupon.expirationDate).isAfter(moment.utc())
        ) {
          bestCoupon = coupon;
        }
      }
    }
    return bestCoupon;
  };

  const handleApplyRecommendedCoupon = () => {
    if (recommendedCoupon !== undefined) {
      setAppliedCoupon(recommendedCoupon);
    }
  };

  const changeCouponCode = async (
    couponCode: string,
    isApply: boolean,
  ): Promise<{ errors: string[] }> => {
    let coupon: CouponDetails | undefined = undefined;
    const errorMessages = [] as string[];
    if (couponCode) {
      coupon = couponList.find(c => c.couponCode === couponCode);
      if (coupon) {
        if (!isApply) {
          setAppliedCoupon(undefined);
        } else if (coupon.status !== COUPON_STATUS.UNUSED) {
          errorMessages.push(`Selected coupon is ${coupon.status}.`);
        } else if (moment.utc(coupon.expirationDate).isBefore(moment.utc())) {
          errorMessages.push(`Selected coupon has expired.`);
        }

        if (isApply && errorMessages.length === 0) {
          setAppliedCoupon(coupon);
        }
      } else {
        if (!isApply) {
          setUniversalCoupon(undefined);
        } else {
          const error = await getUniversalCoupon(couponCode);
          if (error) {
            errorMessages.push(error);
          }
        }
      }
    }

    return { errors: errorMessages };
  };

  const handleStripePayment = useCallback(async () => {
    if (showPaymentCaptcha && !hasBeenCaptchad) {
      return false;
    }
    if (distributionData.hasPaid) return true;
    const source = axios.CancelToken.source();
    let paymentSuccessful = false;
    const couponDiscount = OrderUtility.getOrderCouponDiscount(
      distributionData,
      universalCoupon,
      appliedCoupon,
      false, // applyBundledPackage,
    );

    const paymentData: PaymentOrder = OrderUtility.mapToApiStripePayment(
      isFormComplete ? stripePayment.intentId : '',
      '',
      false,
      universalCoupon,
      appliedCoupon,
      couponDiscount,
      distributionData,
    );

    try {
      const postResponse = await axios.post(
        `${prwebApi}/order/capturePaymentIntent`,
        paymentData,
        { cancelToken: source.token },
      );
      paymentSuccessful = handlePaymentResponse(postResponse);
    } catch (error) {
      handlePaymentError(error as any);
      getPaymentIntent();
    } finally {
      source.cancel();
    }
    return paymentSuccessful;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreditCard,
    universalCoupon,
    appliedCoupon,
    appliedCoupon?.amount,
    distributionData.hasPaid,
    applicableCouponAmt,
    shouldDisablePaymentForm,
    hasBeenCaptchad,
    showPaymentCaptcha,
    isFormComplete,
    stripePayment,
    stripePayment.intentId,
  ]);

  const getPaymentIntent = useCallback(async () => {
    const getPaymentIntentUrl = `${config.prwebApi.url}/order/createPaymentIntent`;
    const finalTotal = OrderUtility.getOrderTotal(
      distributionData,
      undefined,
      undefined,
      false,
    );

    const payload: any = {
      amount: finalTotal,
    };

    await axios
      .post(getPaymentIntentUrl, payload)
      .then((response: any) => {
        setStripePayment({
          intentId: response.data.intentId,
          clientSecret: response.data.clientSecret,
        });
      })
      .catch((error: any) => {
        console.log('get payment intent', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.prwebApi.url, distributionData]);

  const updateFieldByValue = (key: string, value?: string) => {
    if (key === 'isStripe') {
      setIsCreditCard((prev: boolean) => !prev);
    } else if (key === 'stripeFormComplete') {
      setIsFormComplete(value === 'true');
    } else if (key === 'handlingPayment') {
      setIsHandlingPayment(value === 'true');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePaymentResponse = (postResponse: AxiosResponse<any>): boolean => {
    let paymentSuccessful = false;
    if (postResponse.status === 200 || postResponse.status === 201) {
      updateDistributionData({
        ...distributionData,
        hasPaid: true,
        orderNumber: postResponse.data.OrderNumber ?? '',
        transactionId: postResponse.data.ProcessorTransactionID ?? '',
      });
      paymentSuccessful = true;
    } else {
      handlePaymentError(postResponse.data);
    }
    setPaymentCompleted(paymentSuccessful);
    return paymentSuccessful;
  };

  const handlePaymentError = (error: PaymentError): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (
      error.response.status === 500 &&
      error.response &&
      error.response.statusText
    ) {
      errors.push(error.response.statusText);
      if (error.response.data && error.response.data.message) {
        errors.push(error.response.data.message);
      }
    } else if (error.response.status === 429) {
      errors.push(error.message);
      errors.push('Too many requests');
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  useEffect(() => {
    if (showPaymentCaptcha && !hasBeenCaptchad) {
      wizardContext.setStepIsValid(false);
      return;
    }

    const finalTotal = OrderUtility.getOrderTotal(
      distributionData,
      universalCoupon,
      appliedCoupon,
      false,
    );

    if (isCreditCard && isFormComplete && finalTotal > 0) {
      wizardContext.setStepIsValid(true);
    } else if (finalTotal === 0) {
      wizardContext.setStepIsValid(true);
    } else {
      wizardContext.setStepIsValid(false);
    }

    const shouldDisable = distributionData.hasPaid || finalTotal === 0;
    setShouldDisablePaymentForm(shouldDisable);

    if (appliedCoupon === undefined) {
      const maxRecommendedCouponAmount = OrderUtility.getOrderCouponDiscount(
        distributionData,
        universalCoupon,
        recommendedCoupon,
        false,
      );
      setApplicableCouponAmt(maxRecommendedCouponAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appliedCoupon,
    appliedCoupon?.amount,
    universalCoupon,
    recommendedCoupon,
    isCreditCard,
    hasBeenCaptchad,
    showPaymentCaptcha,
    isFormComplete,
  ]);

  const getFeatureFlag = (): Promise<any> => {
    return axios.get(`${prwebApi}/features`).then(response => {
      if (response && response.status === 200) {
        setShowPaymentCaptcha(response.data.showPaymentCaptcha);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    // Update progress
    getPaymentIntent();
    wizardContext.setProgressWhileEditing([]);
    wizardContext.setStepIsValid(false);
    let cancelled = false;
    if (!cancelled) {
      const promiseArray = [getUserCoupons(), getFeatureFlag()];
      Promise.all(promiseArray)
        .then(responses => {
          const userCoupons = responses[0];
          if (userCoupons) {
            const coupon = getBestCoupon(userCoupons);
            if (!cancelled) setCouponList(userCoupons);
            if (!cancelled && coupon !== undefined)
              setRecommendedCoupon(coupon);
          }
        })
        .finally(() => {
          if (!cancelled) setIsLoading(false);
        });
    }
    // this is a useEffect cleanup so there are not attempts to update state if the user has exited this scope
    // https://dev.to/otamnitram/react-useeffect-cleanup-how-and-when-to-use-it-2hbm
    return () => {
      cancelled = true;
    };
    // useEffect with no dependencies so it only run once during component initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Choose a Payment Method
          </Typography>

          {showPaymentCaptcha && (
            <div>
              <ReCAPTCHA
                sitekey={`${captchaSitekey}`}
                onChange={recaptchaCallback}
              />
              {!hasBeenCaptchad && (
                <div id="captchaValidation" className={styles.errorMessage}>
                  Please confirm the captcha above
                </div>
              )}
            </div>
          )}
        </div>
        {(isHandlingPayment || isLoading) && <Loader />}
      </div>
      <div>
        {!paymentCompleted && formErrorSummaryValue.length > 0 && (
          <div className={styles.errorMessage}>
            Error submitting distribution:
            <ul>
              {formErrorSummaryValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={styles.pageContainer}>
        <div className={styles.flexContainerLeft}>
          {recommendedCoupon !== undefined &&
            appliedCoupon === undefined &&
            applicableCouponAmt > 0 && (
              <RecommendedCoupon
                coupon={recommendedCoupon}
                applicableAmount={applicableCouponAmt}
                onApply={handleApplyRecommendedCoupon}
              />
            )}

          {stripePayment?.intentId && (
            <PaymentSelector
              isStripe={isCreditCard}
              isDisabled={shouldDisablePaymentForm}
              stripeSecret={stripePayment}
              stripePromise={stripePromise}
              onChange={updateFieldByValue}
            >
              <StripePaymentForm
                isHandlingPayment={isHandlingPayment}
                isDisabled={shouldDisablePaymentForm}
                handleError={setFormErrorSummaryValue}
                onChange={updateFieldByValue}
                stripePay={handleStripePayment}
              ></StripePaymentForm>
            </PaymentSelector>
          )}
        </div>

        <OrderSummary
          config={config}
          universalCoupon={universalCoupon}
          appliedCoupon={appliedCoupon}
          changeCoupon={changeCouponCode}
          prepaidPackages={[]}
          applyBundledPackage={false}
          toggleBundle={() => {}}
          isCreditCard={isCreditCard}
          updateIsLoading={updateIsLoading}
          handlePaymentError={handlePaymentError}
        ></OrderSummary>
      </div>
      <ExitWizardPrompt
        confirmMessage={'Are you sure you want to leave the release wizard?'}
      />
    </div>
  );
};

export default StripePaymentStep;
