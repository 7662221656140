import React, { useEffect, useLayoutEffect } from 'react';

import { Loader, Responsive } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';

import BundleLabel from '../components/bundle-label';
import DistributionList from '../components/distribution-list';
import Footer from '../components/footer/Footer';
import Header from '../components/header';
import SocialMediaWarning from '../components/social-media-warning';
import ViewTitleBar from '../components/view-title-bar/ViewTitleBar';
import { C3_AVAILABLE, SOCIAL_MEDIA_LINKEDIN } from '../constants';

import './distributions.css';

interface Props {
  config: PRWebConfig;
}

const Distributions: React.FC<Props> = (props: Props) => {
  const c3available = cookie.get(C3_AVAILABLE) === 'true';
  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const [distroList, setDistroList] = React.useState<
    Array<PRWebReleaseDistribution>
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [lockTitle, setLockTitle] = React.useState(false);
  const scrollLockRef = React.createRef<HTMLDivElement>();

  const getDistributions = (): Promise<PRWebReleaseDistribution[]> => {
    return axios
      .get(`${PRWEB_API_BASE_URL}/distribution`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch(e => {
        console.log('something went wrong with get users endpoint call', e);
        return [];
      });
  };

  useEffect(() => {
    const initDistroList = async (): Promise<void> => {
      const distributionResult = await getDistributions();
      setDistroList(distributionResult);
      setIsLoading(false);
    };

    if (c3available) {
      initDistroList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    function updateScrollPosition() {
      // hardcoded 65 refers to the height of the "My Releases, Store, Settings, Admin" header
      if (scrollLockRef.current) {
        if (
          !lockTitle &&
          scrollLockRef.current.offsetTop - window.pageYOffset < 65
        ) {
          setLockTitle(true);
        } else if (
          lockTitle &&
          scrollLockRef.current.offsetTop - window.pageYOffset > 65
        ) {
          setLockTitle(false);
        }
      }
    }
    window.addEventListener('scroll', updateScrollPosition);
    updateScrollPosition();
    return () => window.removeEventListener('scroll', updateScrollPosition);
  });

  return (
    <Responsive.Container>
      <Header prwebApiUrl={PRWEB_API_BASE_URL} />
      <BundleLabel prwebApiUrl={PRWEB_API_BASE_URL} />
      <SocialMediaWarning
        prwebApiUrl={PRWEB_API_BASE_URL}
        prwebSocialMedia={SOCIAL_MEDIA_LINKEDIN}
      />
      <ViewTitleBar
        passRef={scrollLockRef}
        fixed={false}
        title={'My Releases'}
      />
      {lockTitle && <ViewTitleBar title={'My Releases'} />}
      {c3available && !isLoading ? (
        <DistributionList
          distributionList={distroList}
          pageSize={50}
          lockControl={lockTitle}
        ></DistributionList>
      ) : (
        <Loader />
      )}
      {!c3available && !isLoading && (
        <div>Warning: C3 Distributions NOT available</div>
      )}
      <Footer />
    </Responsive.Container>
  );
};

export default Distributions;
