import FileValidator from './FileValidator';

export const validateAttachment = (
  attachment: Attachment,
): AttachmentValidation => {
  const captionErrors = FileValidator.validateCaption(attachment.caption);

  const fileNameErrors = FileValidator.validateFileName(attachment.fileName);

  const numErrors = captionErrors.length + fileNameErrors.length;

  const valid = numErrors === 0;

  return {
    valid,
    captionErrors,
    fileNameErrors,
  };
};

export interface AttachmentErrors {
  captionErrors: Array<string>;
  fileNameErrors: Array<string>;
}

export interface AttachmentValidation extends AttachmentErrors {
  valid: boolean;
}

const AttachmentDistributionValidator = {
  validateCaption: FileValidator.validateCaption,
  validateFileName: FileValidator.validateFileName,
};

export default AttachmentDistributionValidator;
