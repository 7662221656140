import React from 'react';

import { Typography } from '@cision/rover-ui';

import Header from '../components/header';

const NotFound: React.FC = () => (
  <div>
    <Header />
    <div style={{ textAlign: 'center', height: '220px' }}>
      <br />
      <Typography size="xl2" color="inherit" weight="bold">
        404 - Not Found
      </Typography>
      <Typography size="xl">
        <p>We don&apos;t know where you are!</p>
        <p>
          Be safe. <a href="/">Go Home</a>.
        </p>
      </Typography>
    </div>
  </div>
);

export default NotFound;
