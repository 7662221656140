import React from 'react';

import { Loader } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';

import {
  PRW_SID,
  C3_AVAILABLE,
  PRWEB_PACKAGES,
  PRWEB_FEATURES,
  PRWEB_PARTNER,
  USER_GROUP_COOKIE,
  LOGIN_AS,
  PRWEB_TWITTER,
  PRWEB_FACEBOOK,
  PRWEB_LINKEDIN,
  PRWEB_NOTIFY_BUNDLES,
  PARTNER_ID,
  PRWEB_VERIFIED_EMAILS,
} from '../../constants';
import CacheService from '../../utils/cache-service';

interface Props {
  config: PRWebConfig;
}

const Logout: React.FC<Props> = (props: Props) => {
  const envConfig: PRWebConfig = props.config;
  const prwebApi = envConfig.prwebApi.url;
  const prwebFrontEnd = envConfig.prwebFrontEnd.url;
  const oktaUrl = envConfig.prwebOkta.url;

  axios
    .post(`${prwebApi}/removesession`, {})
    .then(response => {
      cookie.remove(PRW_SID);
      cookie.remove(C3_AVAILABLE);
      cookie.remove(USER_GROUP_COOKIE);
      cookie.remove(LOGIN_AS);
      cookie.remove(PARTNER_ID);
      CacheService.remove(PRWEB_PACKAGES);
      CacheService.remove(PRWEB_FEATURES);
      CacheService.remove(PRWEB_PARTNER);
      CacheService.remove(PRWEB_TWITTER);
      CacheService.remove(PRWEB_FACEBOOK);
      CacheService.remove(PRWEB_LINKEDIN);
      CacheService.remove(PRWEB_NOTIFY_BUNDLES);
      CacheService.remove(PRWEB_VERIFIED_EMAILS);

      // if there is no okta session id token
      if (response.data === 'Ok') {
        window.location.assign('/');
        return;
      }

      const logoutUrl = `${oktaUrl}/oauth2/default/v1/logout?id_token_hint=${
        response.data
      }&post_logout_redirect_uri=${encodeURI(`${prwebFrontEnd}`)}&state=state`;

      window.location.assign(logoutUrl);
    })
    .catch(error => {
      console.log(error);

      throw new Error(`Error logging out: ${error}`);
    });

  return <Loader style={{ marginTop: '40vh' }} />;
};

export default Logout;
