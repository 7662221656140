import React from 'react';

import { Badge } from '@cision/rover-ui';

import styles from './distribution-row-status.module.css';
import DistributionStatus from './distribution-status-enum';

interface Props {
  ReleaseStatusID: number;
  ReleaseStatusDescription: string;
}

const DistributionRowStatus = (props: Props) => {
  const getClassByStatus = () => {
    switch (props.ReleaseStatusID) {
      case DistributionStatus.NotSent:
        return styles.default;
      case DistributionStatus.Deleted:
        return styles.default;
      case DistributionStatus.PendingEditorialReview:
        return styles.pending;
      case DistributionStatus.DraftMode:
        return styles.draft;
      case DistributionStatus.OnHold:
        return styles.onhold;
      case DistributionStatus.PendingDistribution:
        return styles.default;
      case DistributionStatus.Published:
        return styles.published;
      case DistributionStatus.InEditorialReview:
        return styles.default;
      case DistributionStatus.OnHoldLocked:
        return styles.default;
      case DistributionStatus.OnHoldLimitedDistribution:
        return styles.default;
      case DistributionStatus.PendingDistributionUponUserApproval:
        return styles.default;
      case DistributionStatus.PendingSeniorReview:
        return styles.default;
      case DistributionStatus.Ignore:
        return styles.default;
      case DistributionStatus.NotFound:
        return styles.default;
      default:
        return styles.default;
    }
  };

  return (
    <Badge
      id={props?.ReleaseStatusID.toString() || ''}
      round
      className={getClassByStatus()}
    >
      &nbsp;
      {props.ReleaseStatusDescription.toLowerCase() ===
      'pending editorial review'
        ? 'Submitted'
        : props.ReleaseStatusDescription}
      &nbsp;
    </Badge>
  );
};

export default DistributionRowStatus;
