import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './_view-title-bar.scss';

const ViewTitleBar = props => {
  const mainClass = classNames(ViewTitleBar.baseClass, props.className, {
    [`${ViewTitleBar.baseClass}--fixed`]: props.fixed,
  });

  const links = props.links.map(link => {
    if (link.target && link.href) {
      return (
        <a
          className={`${ViewTitleBar.baseClass}-link`}
          href={link.href}
          key={link.href}
          target={link.target}
        >
          {link.label}
          {link.suffix}
        </a>
      );
    }

    if (link.path) {
      return (
        <NavLink
          to={link.path}
          className={`${ViewTitleBar.baseClass}-link`}
          activeClassName={`${ViewTitleBar.baseClass}-link--active`}
          exact={link.exact}
          href={link.href}
          key={link.path}
          isActive={link.isActive}
        >
          {link.label}
          {link.suffix}
        </NavLink>
      );
    }

    return link;
  });

  return (
    <div ref={props.passRef} className={mainClass}>
      <div className={`${ViewTitleBar.baseClass}-content-container`}>
        {props.title && (
          <div className={`${ViewTitleBar.baseClass}-title`}>{props.title}</div>
        )}
        {links && !!links.length && (
          <div className={`${ViewTitleBar.baseClass}-links`}>{links}</div>
        )}
      </div>
      {props.content && (
        <div
          style={props.rightContentStyle}
          className={`${ViewTitleBar.baseClass}-right-content`}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

ViewTitleBar.baseClass = 'view-title-bar';

ViewTitleBar.propTypes = {
  passRef: PropTypes.any,
  className: PropTypes.string,
  content: PropTypes.node,
  fixed: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        path: PropTypes.string,
        label: PropTypes.string,
        exact: PropTypes.bool,
        isActive: PropTypes.func,
      }),
      PropTypes.node,
    ]),
  ),
  rightContentStyle: PropTypes.object,
  title: PropTypes.node.isRequired,
};

ViewTitleBar.defaultProps = {
  className: '',
  content: null,
  fixed: true,
  links: [],
  rightContentStyle: {},
};

export default ViewTitleBar;
