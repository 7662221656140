import React, { useContext, useState } from 'react';

import { Button } from '@cision/rover-ui';
import { useLocation } from 'react-router-dom';

import NavigationBackwardArrow from '../navigation-backward-arrow/navigation-backward-arrow';
import Preview from '../preview';
import ViewTitleBar from '../view-title-bar/ViewTitleBar';

import WizardContext from './wizard-context';
import styles from './wizard-navigation.module.css';

interface Props {
  cancelRoute: string;
  submitLabel?: string;
  cancelLabel?: string;
  history: { push: (arg0: any) => void };
  envConfig: PRWebConfig;
}

const WizardNavigation = ({
  history,
  cancelRoute = '#',
  submitLabel = 'submit',
  cancelLabel = 'Cancel',
  envConfig,
}: Props) => {
  const wizardData = useContext(WizardContext);
  const location = useLocation();

  const [showPreview, setShowPreview] = useState(false);

  const cancelButton = (
    <Button
      id={'wizard-cancel-button'}
      level="tertiary"
      component={Button}
      onClick={() => {
        history.push(cancelRoute);
      }}
    >
      {cancelLabel}
    </Button>
  );

  const backButton = () => {
    return (
      wizardData.backButtonIsVisible && (
        <Button
          id={'wizard-back-button'}
          level="tertiary"
          onClick={async () => {
            if (
              !wizardData.canMoveStepBackwardState?.preCheck ||
              (await wizardData.canMoveStepBackwardState.preCheck())
            ) {
              wizardData.setProgress(
                wizardData.progressWhileEditingState.position,
                {
                  valuesArray: wizardData.progressWhileEditingState.valuesArray,
                  labelsArray: wizardData.progressWhileEditingState.labelsArray,
                },
              );
              wizardData.setCanMoveStepForward(() => true);
              wizardData.setCanMoveStepBackward(() => true);
              wizardData.moveStepBackward();
            }
          }}
          disabled={wizardData.loading}
        >
          <div className={styles.navigationButton}>
            <NavigationBackwardArrow />
            <span className={styles.navigationButtonText}>
              {wizardData.backLabel}
            </span>
          </div>
        </Button>
      )
    );
  };

  const nextButton = () => {
    return (
      <Button
        id={'wizard-next-button'}
        level="primary"
        onClick={async () => {
          if (
            !wizardData.canMoveStepForwardState?.preCheck ||
            (await wizardData.canMoveStepForwardState.preCheck())
          ) {
            wizardData.setProgress(
              wizardData.progressWhileEditingState.position,
              {
                valuesArray: wizardData.progressWhileEditingState.valuesArray,
                labelsArray: wizardData.progressWhileEditingState.labelsArray,
              },
            );
            wizardData.setCanMoveStepForward(() => true);
            wizardData.setCanMoveStepBackward(() => true);
            wizardData.moveStepForward();
          }
        }}
        disabled={!wizardData.isValid || wizardData.loading}
      >
        {wizardData.nextLabel}
      </Button>
    );
  };

  const submitButton = () => {
    return (
      wizardData.isSubmitStep && (
        <Button
          id={'wizard-submit-button'}
          level="primary"
          onClick={wizardData.submitState.submit}
          disabled={!wizardData.isValid || wizardData.loading}
        >
          {submitLabel}
        </Button>
      )
    );
  };

  const saveDraftButton = () => {
    return (
      wizardData.hasSaveDraftButton && (
        <span>
          <Button
            id={'wizard-save-draft-button'}
            level="secondary"
            onClick={wizardData.saveDraftButtonState.handler}
          >
            Save Draft
          </Button>{' '}
        </span>
      )
    );
  };

  const previewButton = () => {
    return (
      wizardData.hasPreviewButton && (
        <span>
          <Button
            id={'wizard-preview-button'}
            level="secondary"
            onClick={() => setShowPreview(true)}
          >
            Preview
          </Button>{' '}
        </span>
      )
    );
  };

  const goToUpgrade = () => {
    let distroId = '';
    const matchesPath = location.pathname.match(
      /\/distribution\/(create|edit)\/([0-9]+)/,
    );

    if (matchesPath && matchesPath.length === 3) {
      distroId = matchesPath[2];
    }

    if (distroId === '') {
      const matchesQuery = location.search.match(/distributionId=([0-9]+)/);
      if (matchesQuery && matchesQuery.length === 2) {
        distroId = matchesQuery[1];
      }
    }

    history.push({
      pathname: `/distribution/upgrade/${distroId}`,
    });
  };

  const upgradeButton = () => {
    return (
      wizardData.hasUpgradeButton && (
        <span>
          <Button
            id={'wizard-upgrade-button'}
            level="secondary"
            onClick={goToUpgrade}
          >
            Upgrade
          </Button>{' '}
        </span>
      )
    );
  };

  /**
   * render either
   * (Custom Button AND
   *    (Submit Button OR Next Button) OR
   *  (Submit Button OR Next Button)
   * )
   */
  const saveDraftAndOrNextOrSubmitButtons = () => {
    return (
      <div>
        {upgradeButton()}
        {previewButton()}
        {saveDraftButton()}
        {submitButton() || nextButton()}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ViewTitleBar
        title={wizardData.currentStep === 1 ? cancelButton : backButton()}
        content={saveDraftAndOrNextOrSubmitButtons()}
      />
      <Preview
        isOpen={showPreview}
        close={() => setShowPreview(false)}
        prwebConfig={envConfig}
        distribution={wizardData.previewDataState}
      />
    </React.Fragment>
  );
};

export default WizardNavigation;
