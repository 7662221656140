import React, { useEffect, useState, FC } from 'react';

import { Loader, Responsive } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

interface Props {
  config: PRWebConfig;
}

const Publication: FC<Props> = (props: Props) => {
  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const [isLoading, setIsLoading] = useState(true);
  const [release, setRelease] = useState<PublishERAItem>();
  const { id } = useParams<{ id?: string | undefined }>();

  const formatDate = (date: string): string => {
    let returnDate = '';

    if (date) {
      const newDate = moment(date, '').tz('US/Eastern', true);
      returnDate = `${newDate.format('YYYY-MM-DD hh:mm:ss')} UTC`;
    }

    return returnDate;
  };

  const renderElementLabelAndPath = (label: string, path: string) => {
    return (
      <h4>
        {label}{' '}
        <small>
          <em>[{path}]</em>
        </small>
      </h4>
    );
  };

  const renderElement = (item: EraItemProperty, label: string) => {
    return (
      <div>
        {renderElementLabelAndPath(label, item.jsonPath)}
        <p>{item.value}</p>
      </div>
    );
  };

  const renderDateElement = (item: EraItemProperty, label: string) => {
    return (
      <div>
        {renderElementLabelAndPath(label, item.jsonPath)}
        <p>{formatDate(item.value)}</p>
      </div>
    );
  };

  const renderArrayOfElements = (
    label: string,
    arrayJsonPath: string,
    items?: EraItemProperty[],
  ) => {
    return (
      <div>
        {items && renderElementLabelAndPath(label, arrayJsonPath)}
        <ul>
          {items?.map((item, index) => {
            return (
              <li key={index}>
                <div>
                  [{index}]{' '}
                  <span dangerouslySetInnerHTML={{ __html: item.value }}></span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const getRelease = (): Promise<PublishERAItem> => {
    return axios
      .get(`${PRWEB_API_BASE_URL}/publish/getera/${id}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
        return undefined;
      })
      .catch(e => {
        console.log('something went wrong with get era data endpoint call', e);
        return undefined;
      });
  };

  useEffect(() => {
    const initRelease = async (): Promise<void> => {
      const result = await getRelease();
      setRelease(result);
      setIsLoading(false);
    };
    initRelease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      {isLoading ? (
        <Loader />
      ) : release && release !== undefined ? (
        <div style={{ margin: '20px' }}>
          {renderElement(release.release_id, 'ReleaseID')}
          {renderElement(release.title, 'Headline')}
          {renderArrayOfElements(
            'Summaries',
            'result.result.release.hits[0]._source.sub_title',
            release.summary.value,
          )}
          {renderElement(release.sourceCompany, 'Source Company')}
          {renderElement(release.body, 'Body')}
          {renderArrayOfElements(
            'Industries',
            'result.result.release.hits[0]._source.industry',
            release.industryCodes.value,
          )}
          {release.transmissionDateTime &&
            renderDateElement(
              release.transmissionDateTime,
              'Transmission Date and Time',
            )}
          {renderElement(release.dateline, 'Dateline')}
          {renderArrayOfElements(
            'Geography',
            'result.result.release.hits[0]._source.geography',
            release.geography.value,
          )}
        </div>
      ) : (
        <span>No Release Data Found</span>
      )}
    </Responsive.Container>
  );
};

export default Publication;
