import React, { useContext, useEffect, useState } from 'react';

import { ReactComponent as CheckmarkSvg } from '../../assets/checkmark.svg';

import WizardContext from './wizard-context';
import horizontalProgressStyle from './wizard-progress-horizontal.module.css';
import WizardProgressStep from './wizard-progress-step';
import verticalProgressStyle from './wizard-progress.module.css';

interface WizardProgressProps {
  title: string;
  initialProgress: string[];
  maxVisibleCount: number;
  isHorizontal?: boolean;
}

const WizardProgress = ({
  title,
  initialProgress,
  maxVisibleCount,
  isHorizontal = false,
}: WizardProgressProps) => {
  const style = isHorizontal ? horizontalProgressStyle : verticalProgressStyle;
  const wizardData = useContext(WizardContext);
  const steps = wizardData.progressState;
  const [progressStepInEditState, setProgressStepInEditState] = useState({
    position: 1,
    valuesArray: [] as string[],
    labelsArray: [] as string[],
  });

  useEffect(() => {
    setProgressStepInEditState(wizardData.progressWhileEditingState);
  }, [wizardData.progressWhileEditingState]);

  // Only run this on initialization
  useEffect(() => {
    initialProgress.forEach((value, index) => {
      // do not override exisiting data that are passed to wizardData prior to this initialProgress loop
      if (!wizardData.progressState[index]) {
        wizardData.setProgress(index, { name: value, valuesArray: [] });
      }
    });
    // Leave an empty dependency array.  This should be run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.audienceWizardProgress}>
      {!isHorizontal && (
        <div className={style.audienceWizardProgressTitle}>{title}</div>
      )}
      <div className={style.audienceWizardPlaceholder}>
        {steps.map((step, index) => (
          <div
            className={`${style.row} ${
              progressStepInEditState.position === index ? style.active : ''
            } ${
              progressStepInEditState.position > index ? style.completed : ''
            }`}
            key={`step-${step.name}
                `}
          >
            <div className={`${style.column} ${style.left}`}>
              <div
                className={`${style.line} ${style.upperLine} ${
                  index === 0 ? style.hidden : ''
                }`}
              />
              <div className={style.circle}>
                {progressStepInEditState.position > index ? (
                  <CheckmarkSvg width={16} height={16} fill={'#fff'} />
                ) : (
                  <span>{index + 1}</span>
                )}
              </div>
              <div
                className={`${style.line} ${style.lowerLine} ${
                  index === steps.length - 1 ? style.hidden : ''
                }`}
              />
            </div>
            <div className={`${style.column} ${style.right}`}>
              <div
                className={`${style.stepTitle} ${
                  index === progressStepInEditState.position
                    ? style.activeStep
                    : ''
                }`}
              >
                {step.name}
              </div>
              <div className={style.audienceWizardProgressStepData}>
                <WizardProgressStep maxCount={maxVisibleCount} step={step} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WizardProgress;
