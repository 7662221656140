import React, { useState } from 'react';

import PurchaseEditWizardContext from './PurchaseEditWizardContext';

interface Props {
  children: React.ReactNode;
  purchaseEdit?: PurchaseEdit;
}

function PurchaseEditWizardProvider({ children, purchaseEdit }: Props) {
  const [state, setState] = useState<PurchaseEdit>({
    orderNumber: purchaseEdit?.orderNumber ?? '',
    transactionId: purchaseEdit?.transactionId ?? '',
    distributionId: purchaseEdit?.distributionId ?? 0,
    prwebPressReleaseId: purchaseEdit?.prwebPressReleaseId ?? 0,
    description: purchaseEdit?.description ?? '',
  });
  // console.trace('default state.releaseDate', state.releaseDate);

  return (
    <PurchaseEditWizardContext.Provider
      value={{
        purchaseEditData: state,
        updatePurchaseEditData: updatedState => {
          setState((prevState: PurchaseEdit) => {
            return { ...prevState, ...updatedState };
          });
        },
        // For situations when the app have a partial distribution object and only want to update certain attributes.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatePurchaseEditByField: (topLevelKey: string, value: any) => {
          setState((prevState: PurchaseEdit) => {
            return {
              ...prevState,
              [topLevelKey]: value,
            };
          });
        },
      }}
    >
      {children}
    </PurchaseEditWizardContext.Provider>
  );
}

export default PurchaseEditWizardProvider;
