import React, { useState, FormEvent, useEffect } from 'react';

import { Button, Modal, Input, Typography } from '@cision/rover-ui';

import FieldValidationIssue from '../../../../components/field-validation-issue';
import SocialMediaContainer, {
  TwitterToggle,
} from '../../../../components/social-media-container';
import { PRWEB_TWITTER } from '../../../../constants';
import PRWebSocial from '../../../../utils/PRWebSocial';
import AmplifyDistributionValidator from '../../../../validators/AmplifyDistributionValidator';

import styles from './twittermodal.module.css';

interface Props {
  config: PRWebConfig;
  addTwitterMessage: (arg0: string) => void;
  toggle: (arg0: boolean) => void;
  isOpen: boolean;
  twitterMessage: string;
  onClose: () => void;
}

const TwitterModal = ({
  config,
  addTwitterMessage,
  toggle,
  isOpen,
  twitterMessage,
  onClose,
}: Props) => {
  const [currentTwitterMessage, setCurrentTwitterMessage] = useState(
    twitterMessage,
  );
  const [isConnectedToTwitter, setIsConnectedToTwitter] = useState(
    PRWebSocial.isConnectedToSocialMedia(PRWEB_TWITTER),
  );
  const [TWITTER_MSG_MAX_LENGTH, setTwitterMsgMaxLength] = useState<number>(0);

  const [currentIssues, updateCurrentIssues] = useState<string[]>([]);
  const [charCount, setCharCount] = useState(
    twitterMessage
      ? TWITTER_MSG_MAX_LENGTH - twitterMessage.length
      : TWITTER_MSG_MAX_LENGTH,
  );
  const [isSaved, setIsSaved] = useState(false);
  const addButtonLabel = twitterMessage ? 'Update' : 'Add';

  const close = () => {
    if (!isSaved) {
      setCurrentTwitterMessage(twitterMessage);
    }
    onClose();
    toggle(false);
  };

  const add = () => {
    addTwitterMessage(currentTwitterMessage);
    setIsSaved(true);
    toggle(false);
  };

  const remove = () => {
    addTwitterMessage('');
    setCurrentTwitterMessage('');
    setCharCount(TWITTER_MSG_MAX_LENGTH);
    setIsSaved(true);
    toggle(false);
  };

  const onCallbackTwitterConnection = () => {
    setIsConnectedToTwitter(
      PRWebSocial.isConnectedToSocialMedia(PRWEB_TWITTER),
    );
  };

  const changeHandler = (e: FormEvent): void => {
    const newValue = (e.target as HTMLInputElement).value;
    setCurrentTwitterMessage(newValue);
    setCharCount(TWITTER_MSG_MAX_LENGTH - newValue.length);
    if (newValue !== currentTwitterMessage) {
      const newIssues = AmplifyDistributionValidator.validateTwitterMessage(
        TWITTER_MSG_MAX_LENGTH,
        newValue,
      );
      setIsSaved(false);
      updateCurrentIssues(newIssues);
    }
  };

  useEffect(() => {
    if (isConnectedToTwitter) {
      const twitterAccount = PRWebSocial.getSocialAccount(PRWEB_TWITTER);

      if (twitterAccount) {
        setTwitterMsgMaxLength(twitterAccount.MaxContentLength);
        setCharCount(
          twitterMessage
            ? twitterAccount.MaxContentLength - twitterMessage.length
            : twitterAccount.MaxContentLength,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectedToTwitter]);

  return (
    <Modal isOpen={isOpen} size="lg">
      <Modal.Header>
        <Typography size="lg">Share on X</Typography>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.center}>
          <Typography size="lg">Post your release</Typography>
        </div>
        <div className={styles.center}>
          <Typography size="lg" weight="bold">
            Share your release in your social media networks.
          </Typography>
        </div>
        <div className={styles.container} id={'tw-container'}>
          <label htmlFor={'twitter-message'}>
            <span className={styles.containerLabel}>Post to my X account</span>
          </label>
          <SocialMediaContainer config={config}>
            {{
              twitter: <TwitterToggle callback={onCallbackTwitterConnection} />,
            }}
          </SocialMediaContainer>
        </div>
        {isConnectedToTwitter && (
          <div className={styles.container} id={'tw-container'}>
            <label htmlFor={'twitter-message'}>
              <span className={styles.containerLabel}>Message</span>
            </label>
            <Typography>
              <span
                className={
                  charCount < 0
                    ? `${styles.charCount} ${styles.errorMessage}`
                    : styles.charCount
                }
              >
                {charCount}
              </span>
            </Typography>
            <div id={'tw-container'}>
              <Input
                id={'twitter-message'}
                className={styles.tweetInput}
                placeholder={'Compose a message'}
                onChange={changeHandler}
                value={currentTwitterMessage}
              />
              {currentIssues && currentIssues.length > 0 && (
                <FieldValidationIssue
                  issues={currentIssues}
                  id={'twitter-message'}
                />
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.modalBtnContainer}>
        {twitterMessage && (
          <Button level="secondary" onClick={remove}>
            Remove
          </Button>
        )}
        <Button level="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          level="primary"
          onClick={add}
          disabled={
            !currentTwitterMessage ||
            currentTwitterMessage.length === 0 ||
            currentTwitterMessage.length > TWITTER_MSG_MAX_LENGTH
          }
        >
          {addButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TwitterModal;
