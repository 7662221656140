import * as React from 'react';

const Footer: React.FC = () => {
  if (window.location.pathname.startsWith('/admin')) {
    return <div>RELEASE-2.28.3</div>;
  }
  return <></>;
};

export default Footer;
