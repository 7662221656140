import React, { useState } from 'react';

import { Button } from '@cision/rover-ui';

import styles from './wizard-progress.module.css';

interface StepValueProps {
  value: string;
  label?: string;
}

interface WizardProgressStepProps {
  step: { valuesArray: string[]; labelsArray?: string[] };
  maxCount: number;
}

const WizardProgressStep = ({ maxCount, step }: WizardProgressStepProps) => {
  const [overflowIsHidden, setOverflowIsHidden] = useState(true);

  const StepValue = ({ value, label = '' }: StepValueProps) => {
    return (
      <div className={styles.audienceWizardProgressStepValue} title={value}>
        {label ? `${label}: ` : ''}
        <span>{value}</span>
      </div>
    );
  };

  if (!step.valuesArray) {
    return <></>;
  }

  const visibleStepData = step.valuesArray.slice(0, maxCount);
  const hiddenStepData = step.valuesArray.slice(maxCount);

  const labels = step.labelsArray || [];
  const visibleLabelData = labels.slice(0, maxCount);
  const hiddenLabelData = labels.slice(maxCount);

  return (
    <React.Fragment>
      <div>
        {visibleStepData.map((stepValue, index) => {
          return stepValue ? (
            <StepValue
              key={stepValue}
              value={stepValue}
              label={visibleLabelData[index]}
            />
          ) : (
            ''
          );
        })}
      </div>
      {step.valuesArray.length > maxCount && (
        <div>
          <div>
            {!overflowIsHidden &&
              hiddenStepData.map((stepValue, index) => {
                return stepValue ? (
                  <StepValue
                    key={stepValue}
                    value={stepValue}
                    label={hiddenLabelData[index]}
                  />
                ) : (
                  ''
                );
              })}
          </div>
          <Button
            level="link"
            onClick={() => setOverflowIsHidden(!overflowIsHidden)}
          >
            {overflowIsHidden
              ? `See all (${step.valuesArray.length})`
              : 'See less'}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default WizardProgressStep;
