import React, { FC, useEffect } from 'react';

import { Button, Typography } from '@cision/rover-ui';

import { CJL_PRICE } from '../../../../constants';

import styles from './additions-cjl-panel.module.css';
import CJLOptions from './CJLs';

interface Props {
  cjl?: number | null;
  onAddCJL: (cjl: number) => void;
  onRemoveCJL: () => void;
  isAdvancedPackage: boolean;
  isPremiunPackage: boolean;
}

const AdditionsCJLPanel: FC<Props> = ({
  cjl,
  onAddCJL,
  onRemoveCJL,
  isAdvancedPackage,
  isPremiunPackage,
}: Props) => {
  const [cjlValue, setCjlValue] = React.useState('');
  const [disableSelect, setDisableSelect] = React.useState(false);
  const [showAddButton, setShowAddButton] = React.useState(true);
  const [showRemoveButton, setShowRemoveButton] = React.useState(false);

  const onChangeCJLValue = (value: string) => {
    setCjlValue(value);
  };

  const addCJL = (): void => {
    if (cjlValue === '') {
      return;
    }

    const cjlNumber: number = parseInt(cjlValue);
    onAddCJL(cjlNumber);
    setCjlValue('');
    setDisableSelect(true);
    setShowAddButton(false);
    setShowRemoveButton(true);
  };

  const removeCJL = (): void => {
    onRemoveCJL();
    setCjlValue('');
    setDisableSelect(false);
    setShowAddButton(true);
    setShowRemoveButton(false);
  };

  useEffect(() => {
    setShowAddButton(cjl ? false : true);
    setShowRemoveButton(cjl ? true : false);
    setDisableSelect(cjl ? true : false);
  }, [cjl]);

  return (
    <div className={styles.cjlContainer}>
      <select
        id="select-cjl-option"
        name="select-cjl-option"
        onChange={(e: React.FormEvent): void =>
          onChangeCJLValue((e.target as HTMLInputElement).value)
        }
        value={cjlValue}
        disabled={disableSelect}
      >
        <CJLOptions
          isAdvancedPackage={isAdvancedPackage}
          isPremiunPackage={isPremiunPackage}
        />
      </select>
      {showAddButton && (
        <Button
          modifiers={['round', 'secondary']}
          id={'add-cjl-button'}
          onClick={addCJL}
          disabled={cjlValue === ''}
        >
          + Add to Release
        </Button>
      )}
      {showRemoveButton && (
        <Button
          id={'remove-cjl-button'}
          level="primary"
          style={{
            backgroundColor: '#ed7d31',
            borderColor: '#ed7d31',
          }}
          onClick={removeCJL}
        >
          - Remove from Release
        </Button>
      )}
      {cjl !== null && cjl !== undefined && cjl !== 0 && (
        <div className={styles.cjlListContainer} key={'cjl-description'}>
          <div className={styles.cjlDesc}>
            <Typography>
              {cjl} lists added for ${(cjl || 0) * CJL_PRICE}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdditionsCJLPanel;
