import React, { FC, useState, useEffect } from 'react';

import { Button, Paper, Typography, Loader } from '@cision/rover-ui';

import { useHistory } from 'react-router-dom';

import { ReactComponent as ForwardSvg } from '../../../assets/arrow-forward.svg';
import ReceiptModal from '../../../components/receipt-modal';
import WizardContext from '../../../components/wizard/wizard-context';

import PurchaseEditWizardContext from '../PurchaseEditWizardContext';

import styles from './styles/summary-step.module.css';

interface Props {
  config: PRWebConfig;
}

interface DistributionApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface SubmissionError {
  response: {
    status: number;
    data: DistributionApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const SummaryStep: FC<Props> = ({ config }: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { purchaseEditData, updatePurchaseEditData } = React.useContext(
    PurchaseEditWizardContext,
  );

  const wizardContext = React.useContext(WizardContext);
  const [showReceipt, setShowReceipt] = useState(false);

  const openModal = () => {
    setShowReceipt(true);
  };

  const closeModal = () => {
    setShowReceipt(false);
  };

  const goToHome = async () => {
    history.replace({
      pathname: `/`,
    });
  };

  // Update progress
  useEffect(() => {
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);
    wizardContext.setSubmit(goToHome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Summary
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
      <div>
        <Paper className={styles.creditCardContainer}>
          <div>
            <p>
              Your Order number is{' '}
              <strong>{purchaseEditData.orderNumber}</strong>
            </p>
            <Button
              id={'showReleaseReceiptButton'}
              onClick={openModal}
              level="link"
            >
              <ForwardSvg widths="24" height="24" />
              <span>Show My Receipt</span>
            </Button>
            <ReceiptModal
              config={config}
              distributionId={Number(purchaseEditData.distributionId)}
              isOpen={showReceipt}
              closeModal={closeModal}
            />
          </div>
          <div>
            <strong>
              You have successfully purchased a post release edit.
            </strong>
          </div>
          <Button
            className={styles.spaceTop}
            id={'wizard-next-button'}
            level="primary"
            onClick={goToHome}
          >
            Go to my releases
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default SummaryStep;
