const PurchaseEditUtility = {
  mapToApiStripePayment: (
    paymentIntent: string | undefined,
    paypalOrderId: string | undefined,
    purchaseEdit: PurchaseEdit,
  ) => {
    const paymentData: StripePaymentOrder = {
      distributionId: purchaseEdit.distributionId as number,
      prwebPressReleaseId: purchaseEdit.prwebPressReleaseId as number,
      releaseDate: '',
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: false,
      order: {},
      paymentIntent: paymentIntent ?? '',
      purchaseEditDescription: purchaseEdit.description ?? '',
    };
    return paymentData;
  },
  mapToApiPaymentOrder: (
    creditCard: CreditCard | undefined,
    paypalOrderId: string,
    purchaseEdit: PurchaseEdit,
  ) => {
    const paymentData: PaymentOrder = {
      distributionId: purchaseEdit.distributionId as number,
      prwebPressReleaseId: purchaseEdit.prwebPressReleaseId as number,
      releaseDate: '',
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: false,
      order: {
        cjl: 0,
        hasCisionSocialPost: false,
      },
      creditCard: { ...creditCard } as CreditCard,
      purchaseEditDescription: purchaseEdit.description ?? '',
    };
    return paymentData;
  },

  getOrderTotal: () => {
    const subtotal = 120;
    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const total = subtotalCents;

    const result = Number((total / 100).toFixed(2));
    return result;
  },
};

export default PurchaseEditUtility;
