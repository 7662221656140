import { useEffect } from 'react';

/*
Hook implementation of the following click outside component
https://github.com/kentor/react-click-outside/blob/e3e133799c51f896c1f65a799f0007827dd48248/index.js
*/
function useClickOutside(callback: any, ref: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && callback) {
        callback(event);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

export default useClickOutside;
