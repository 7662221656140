import { CJL_PRICE, CSP_PRICE, PRIORITY_PRICE } from '../../constants';

import PRWebPackage from './PRWebPackages';

const OrderUtility = {
  mapToApiStripePayment: (
    paymentIntent: string | undefined,
    paypalOrderId: string | undefined,
    applyBundledPackage: boolean,
    universalCoupon: UniversalCoupon | undefined,
    appliedCoupon: CouponDetails | undefined,
    couponAmount: number,
    distro: Distribution,
  ) => {
    const paymentData: StripePaymentOrder = {
      distributionId: distro.distributionId as number,
      prwebPressReleaseId: distro.prwebPressReleaseId as number,
      releaseDate: distro.releaseDate as string,
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: applyBundledPackage ?? false,
      universalCouponCode:
        universalCoupon === undefined ? '' : universalCoupon.couponCode,
      couponCode: appliedCoupon === undefined ? '' : appliedCoupon.couponCode,
      couponAmount: couponAmount,
      order: {
        package: distro.package,
        cjl: distro.cjl,
        hasCisionSocialPost: distro.hasCisionSocialPost,
        priorityReleaseEnabled: distro.hasPriorityRelease,
      },
      paymentIntent: paymentIntent ?? '',
    };
    return paymentData;
  },
  mapToApiPaymentOrder: (
    creditCard: CreditCard | undefined,
    paypalOrderId: string,
    applyBundledPackage: boolean,
    universalCoupon: UniversalCoupon | undefined,
    appliedCoupon: CouponDetails | undefined,
    couponAmount: number,
    distro: Distribution,
  ) => {
    const paymentData: PaymentOrder = {
      distributionId: distro.distributionId as number,
      prwebPressReleaseId: distro.prwebPressReleaseId as number,
      releaseDate: distro.releaseDate as string,
      paypalOrderId: paypalOrderId ?? '',
      applyBundlePackage: applyBundledPackage ?? false,
      universalCouponCode:
        universalCoupon === undefined ? '' : universalCoupon.couponCode,
      couponCode: appliedCoupon === undefined ? '' : appliedCoupon.couponCode,
      couponAmount: couponAmount,
      order: {
        package: distro.package,
        cjl: distro.cjl,
        hasCisionSocialPost: distro.hasCisionSocialPost,
        priorityReleaseEnabled: distro.hasPriorityRelease,
      },
      creditCard: { ...creditCard } as CreditCard,
    };
    return paymentData;
  },
  getOrderSubtotal: (distributionData: Distribution) => {
    const releasePackage = PRWebPackage.getPRWebPackage(
      distributionData.package,
    );

    const packageCents = releasePackage
      ? Number((releasePackage.price * 100).toFixed(2))
      : 0;
    const cjlCents = Number((CJL_PRICE * 100).toFixed(2));
    const cspCents = Number((CSP_PRICE * 100).toFixed(2));
    const priorityCents = Number((PRIORITY_PRICE * 100).toFixed(2));

    let orderAmount = packageCents;
    if (distributionData.cjl && distributionData.cjl > 0) {
      orderAmount += cjlCents * Number(distributionData.cjl);
    }
    if (distributionData.hasCisionSocialPost) {
      orderAmount += cspCents;
    }
    if (distributionData.hasPriorityRelease) {
      orderAmount += priorityCents;
    }

    const result = Number((orderAmount / 100).toFixed(2));
    return result;
  },
  getOrderBundleDiscount: (
    distributionData: Distribution,
    hasBundlePackageApplied: boolean,
  ) => {
    const releasePackage = PRWebPackage.getPRWebPackage(
      distributionData.package,
    );

    const packageCents = releasePackage
      ? Number((releasePackage.price * 100).toFixed(2))
      : 0;

    let bundleDiscount = 0;
    if (hasBundlePackageApplied) {
      bundleDiscount += packageCents;
    }

    const result = Number((bundleDiscount / 100).toFixed(2));
    return result;
  },
  getUniversalCouponDiscount: (
    distributionData: Distribution,
    universalCoupon: UniversalCoupon | undefined,
    hasBundlePackageApplied: boolean,
  ) => {
    const subtotal = OrderUtility.getOrderSubtotal(distributionData);
    const bundleDiscount = OrderUtility.getOrderBundleDiscount(
      distributionData,
      hasBundlePackageApplied,
    );
    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const bundleDiscountCents = Number((bundleDiscount * 100).toFixed(2));

    if (universalCoupon && universalCoupon.isFixed) {
      const subtotalAfterPrepaid = Number(
        ((subtotalCents - bundleDiscountCents) / 100).toFixed(2),
      );
      const discount = Number((universalCoupon.amount || 0).toFixed(2));
      return Math.min(discount, subtotalAfterPrepaid);
    } else if (universalCoupon && !universalCoupon.isFixed) {
      const subtotalAfterPrepaidCents = subtotalCents - bundleDiscountCents;
      // JavaScript Number.toFixed uses banker's rounding, may be a bit too complex to convey to users
      // This function needs to match api calculation to avoid payment validation errors
      const discountCents = Math.round(
        subtotalAfterPrepaidCents * (universalCoupon.percent || 0),
      );
      const result = Number((discountCents / 100).toFixed(2));
      return result;
    }
    return 0;
  },
  getOrderCouponDiscount: (
    distributionData: Distribution,
    universalCoupon: UniversalCoupon | undefined,
    coupon: CouponDetails | undefined,
    hasBundlePackageApplied: boolean,
  ) => {
    const subtotal = OrderUtility.getOrderSubtotal(distributionData);
    const bundleDiscount = OrderUtility.getOrderBundleDiscount(
      distributionData,
      hasBundlePackageApplied,
    );
    const universalDiscount = OrderUtility.getUniversalCouponDiscount(
      distributionData,
      universalCoupon,
      hasBundlePackageApplied,
    );

    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const bundleDiscountCents = Number((bundleDiscount * 100).toFixed(2));
    const universalDiscountCents = Number((universalDiscount * 100).toFixed(2));
    const couponDiscountCents =
      coupon && coupon.amount > 0
        ? Number((coupon.amount * 100).toFixed(2))
        : 0;

    const remainingAmount =
      subtotalCents - bundleDiscountCents - universalDiscountCents;
    if (couponDiscountCents > 0 && remainingAmount > 0) {
      const discount = Math.min(remainingAmount, couponDiscountCents);

      const result = Number((discount / 100).toFixed(2));
      return result;
    }
    return 0;
  },
  getOrderTotal: (
    distributionData: Distribution,
    universalCoupon: UniversalCoupon | undefined,
    coupon: CouponDetails | undefined,
    hasBundlePackageApplied: boolean,
  ) => {
    const subtotal = OrderUtility.getOrderSubtotal(distributionData);
    const bundleDiscount = OrderUtility.getOrderBundleDiscount(
      distributionData,
      hasBundlePackageApplied,
    );
    const universalDiscount = OrderUtility.getUniversalCouponDiscount(
      distributionData,
      universalCoupon,
      hasBundlePackageApplied,
    );
    const couponDiscount = OrderUtility.getOrderCouponDiscount(
      distributionData,
      universalCoupon,
      coupon,
      hasBundlePackageApplied,
    );

    const subtotalCents = Number((subtotal * 100).toFixed(2));
    const bundleDiscountCents = Number((bundleDiscount * 100).toFixed(2));
    const universalDiscountCents = Number((universalDiscount * 100).toFixed(2));
    const couponDiscountCents = Number((couponDiscount * 100).toFixed(2));

    let total = subtotalCents;
    if (total > 0 && hasBundlePackageApplied) {
      total -= Math.min(total, bundleDiscountCents);
    }
    if (total > 0 && universalCoupon) {
      total -= Math.min(total, universalDiscountCents);
    }
    if (total > 0 && coupon) {
      total -= Math.min(total, couponDiscountCents);
    }

    const result = Number((total / 100).toFixed(2));
    return result;
  },
};

export default OrderUtility;
