import React from 'react';

import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import '@cision/rover-ui/dist/rover-ui.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const rg4js: any;

const configPromise = fetch('/config.json');
configPromise
  .then(res => res.json())
  .then(config => {
    const tagManagerArgs = {
      gtmId: config.googleTagManager.gtmId,
    };
    if (config.environment !== 'local') {
      TagManager.initialize(tagManagerArgs);
    }
    rg4js('setVersion', process.env.VERSION ?? '');

    ReactDOM.render(
      <Router>
        <App config={config} />
      </Router>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
