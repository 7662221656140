import React, { useEffect } from 'react';

import { Tooltip, Button, Typography, Paper, Kite } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import { ReactComponent as WarningSvg } from '../../assets/warning.svg';
import {
  BASIC_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
  PRWEB_NOTIFY_BUNDLES,
} from '../../constants';
import CacheService from '../../utils/cache-service';
import PRWebFeatures from '../../utils/PRWebFeatures';

import styles from './BundleLabel.module.css';

interface BundleLabelProps {
  prwebApiUrl: string;
}

const BundleLabel = ({ prwebApiUrl }: BundleLabelProps) => {
  const history = useHistory();
  const enableBundleStorePage = PRWebFeatures.enableBundleStorePage();
  const [userBundles, setUserBundles] = React.useState(
    [] as UserPackageBundleGroup[],
  );
  const [showBundleToast, setShowBundleToast] = React.useState(false);
  const handleBundleCloseToast = () => {
    setShowBundleToast(false);
    const notifyBundles = CacheService.exists(PRWEB_NOTIFY_BUNDLES);
    if (!notifyBundles) {
      CacheService.set(PRWEB_NOTIFY_BUNDLES, true);
    }
  };

  const handleUseBundle = (packageId: string) => () => {
    history.push({
      pathname: '/distribution/create',
      state: { packageId },
    });
  };

  const gotoStorePage = () => history.push('/store');

  const getUserPackageBundles = () => {
    return axios
      .get(`${prwebApiUrl}/bundleorder/userPackageBundles`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch(e => {
        return [];
      });
  };

  const mapUserPackageBundles = (data: any) => {
    const userPackageBundles = [
      {
        name: 'Basic',
        packageId: BASIC_PACKAGE_ID,
        quantity: 0,
        bundles: [] as UserPackageBundle[],
      },
      {
        name: 'Standard',
        packageId: STANDARD_PACKAGE_ID,
        quantity: 0,
        bundles: [] as UserPackageBundle[],
      },
      {
        name: 'Advanced',
        packageId: ADVANCED_PACKAGE_ID,
        quantity: 0,
        bundles: [] as UserPackageBundle[],
      },
      {
        name: 'Premium',
        packageId: PREMIUM_PACKAGE_ID,
        quantity: 0,
        bundles: [] as UserPackageBundle[],
      },
    ] as UserPackageBundleGroup[];

    data.forEach((bundle: UserPackageBundle) => {
      if (Number(bundle.packageId) === 80) {
        userPackageBundles[0].quantity += Number(bundle.quantity);
        userPackageBundles[0].bundles.push(bundle);
      } else if (Number(bundle.packageId) === 140) {
        userPackageBundles[1].quantity += Number(bundle.quantity);
        userPackageBundles[1].bundles.push(bundle);
      } else if (Number(bundle.packageId) === 200) {
        userPackageBundles[2].quantity += Number(bundle.quantity);
        userPackageBundles[2].bundles.push(bundle);
      } else if (Number(bundle.packageId) === 360) {
        userPackageBundles[3].quantity += Number(bundle.quantity);
        userPackageBundles[3].bundles.push(bundle);
      }
    });
    for (let i = 0; i < 4; i++) {
      if (userPackageBundles[i].bundles.length > 1) {
        userPackageBundles[i].bundles.sort(
          (a: UserPackageBundle, b: UserPackageBundle) => {
            const dateA = moment(a.expirationDate).unix();
            const dateB = moment(b.expirationDate).unix();
            if (dateA === dateB) {
              return a.quantity - b.quantity;
            }
            return dateA - dateB;
          },
        );
      }
    }
    hasExpiringBundles(userPackageBundles);

    setUserBundles(userPackageBundles);
  };

  const hasExpiringBundles = (bundles: UserPackageBundleGroup[]) => {
    const notifyBundles = CacheService.exists(PRWEB_NOTIFY_BUNDLES);
    if (!notifyBundles) {
      for (const bundleType of bundles) {
        if (bundleType.bundles.length > 0) {
          const firstToExpire = bundleType.bundles[0];
          if (
            moment(firstToExpire.expirationDate).isBefore(
              moment().add(30, 'day'),
            )
          ) {
            setShowBundleToast(true);
            break;
          }
        }
      }
    }
  };

  const getBundleExpirationLabels = (bundleIndex: number) => {
    if (userBundles[bundleIndex]?.bundles) {
      if (userBundles[bundleIndex].bundles.length === 0) {
        return '';
      }
      const oldestBundle = userBundles[bundleIndex].bundles[0];
      if (userBundles[bundleIndex].bundles.length === 1) {
        if (oldestBundle.expirationDate) {
          return `Expiring ${moment
            .utc(oldestBundle.expirationDate)
            .format('M/D/YYYY')}`;
        }
      } else {
        if (oldestBundle.expirationDate) {
          return (
            <>
              {oldestBundle.quantity} Expiring{' '}
              {moment.utc(oldestBundle.expirationDate).format('M/D/YYYY')}{' '}
              <Tooltip
                className={styles.hoverTooltip}
                content={getExpirationTooltip(bundleIndex)}
                tooltipWidth={'200px'}
                direction="bottom"
                showOnHover
              >
                {bundleIndex === 3 ? (
                  <InfoSvg fill={'white'} width={15} height={15}></InfoSvg>
                ) : (
                  <InfoSvg width={15} height={15}></InfoSvg>
                )}
              </Tooltip>
            </>
          );
        }
      }
    }
    return '';
  };

  useEffect(() => {
    const initBundleLabel = async () => {
      if (enableBundleStorePage) {
        const packageBundleResult = await getUserPackageBundles();
        mapUserPackageBundles(packageBundleResult);
      }
    };

    initBundleLabel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExpirationTooltip = (bundleIndex: number) => {
    return userBundles[bundleIndex].bundles?.map((bundle, index) => {
      return (
        <div key={index} style={{ color: 'black' }}>
          {bundle.quantity} Expiring{' '}
          {moment.utc(bundle.expirationDate).format('M/D/YYYY')}
        </div>
      );
    });
  };

  return !enableBundleStorePage ? (
    <></>
  ) : (
    <div className={styles.cardContainer}>
      <>
        <Paper className={styles.paperCard}>
          <Typography color="black" weight="bold" size="xl">
            Basic
          </Typography>
          <Typography color="primary" size="md">
            Increased Search Engine Visibility
          </Typography>
          <Button
            id={'use-basic-bundle'}
            className={styles.releaseButton}
            level="secondary"
            onClick={handleUseBundle('80')}
          >
            Create Basic
          </Button>
        </Paper>
        <Paper className={styles.paperCard}>
          <Typography color="black" weight="bold" size="xl">
            Standard
          </Typography>
          <Typography color="primary" size="md">
            Increased Online Visibility
          </Typography>
          <Typography color="black" weight="bold" size="md">
            {userBundles[1]?.quantity ?? 0}
            &nbsp;Releases Remaining
          </Typography>
          <Typography color="black" weight="bold" size="md">
            {getBundleExpirationLabels(1)}
          </Typography>
          <Button
            id={'use-standard-bundle'}
            className={styles.releaseButton}
            level="secondary"
            onClick={handleUseBundle('140')}
          >
            Create Standard
          </Button>
        </Paper>
        <Paper className={styles.paperCard}>
          <Typography color="black" weight="bold" size="xl">
            Advanced
          </Typography>
          <Typography color="primary" size="md">
            Increased Influencer Visibility
          </Typography>
          <Typography color="black" weight="bold" size="md">
            {userBundles[2]?.quantity ?? 0}
            &nbsp;Releases Remaining
          </Typography>
          <Typography color="black" weight="bold" size="md">
            {getBundleExpirationLabels(2)}
          </Typography>
          <Button
            id={'use-advanced-bundle'}
            className={styles.releaseButton}
            level="secondary"
            onClick={handleUseBundle('200')}
          >
            Create Advanced
          </Button>
        </Paper>
        <Paper className={`${styles.paperCard} ${styles.premiumPackageColors}`}>
          <Typography color="inherit" weight="bold" size="xl">
            Premium
          </Typography>
          <Typography color="inherit" size="md">
            Largest Influencer Visibility
          </Typography>
          <Typography color="inherit" weight="bold" size="md">
            {userBundles[3]?.quantity ?? 0}
            &nbsp;Releases Remaining
          </Typography>
          <Typography color="inherit" weight="bold" size="md">
            {getBundleExpirationLabels(3)}
          </Typography>
          <Button
            id={'use-premium-bundle'}
            className={`${styles.releaseButton} ${styles.premiumPackageButton}`}
            level="secondary"
            hollow
            onClick={handleUseBundle('360')}
          >
            Create Premium
          </Button>
        </Paper>
        <Paper className={styles.paperCard}>
          <Typography color="black" weight="bold" size="xl">
            Bundles
          </Typography>
          <Typography color="black" size="md">
            Build a bundle to save
          </Typography>
          <Button
            className={styles.releaseButton}
            level="secondary"
            onClick={gotoStorePage}
          >
            Go to Store
          </Button>
        </Paper>
        <Kite
          style={{ width: '500px' }}
          icon={<WarningSvg width="30px" height="30px" fill="#de4543" />}
          canBeDismissed
          visible={showBundleToast}
          onClose={handleBundleCloseToast}
        >
          <Typography color="black" size="xl" weight="bold">
            You have bundles expiring soon!
          </Typography>
        </Kite>
      </>
    </div>
  );
};

export default BundleLabel;
