export const isMoreThanMaxLength = (model: string, length: number): boolean => {
  return model.length > length;
};
export const isLessThanMinLength = (model: string, length: number): boolean => {
  return model.length < length;
};
export const isAllLowerCase = (model: string): boolean => {
  return model.toLowerCase() === model;
};
export const isAllUpperCase = (model: string): boolean => {
  return model.toUpperCase() === model;
};
export const containsOnlyNumbers = (model: string): boolean => {
  const IS_NUM_REGEX = new RegExp('^\\d+$');
  return IS_NUM_REGEX.test(model);
};
export const containsHTML = (model: string): boolean => {
  const HTML_REGEX = new RegExp('\\<\\/?[a-zA-Z][\\s\\S]*\\>');
  return HTML_REGEX.test(model);
};
const GenericDistributionValidator = {
  containsHTML,
  containsOnlyNumbers,
  isAllUpperCase,
  isAllLowerCase,
  isLessThanMinLength,
  isMoreThanMaxLength,
};
export default GenericDistributionValidator;
