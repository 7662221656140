import React, { useState, useEffect } from 'react';

import { Button, Paper, Typography, Loader, Tooltip } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';

import { ReactComponent as LockSvg } from '../../assets/lock.svg';
import CountryOptions from '../../components/country-options';
import FieldValidationIssue from '../../components/field-validation-issue';
import Kite from '../../components/kite';
import TextInputField from '../../components/text-input-field';
import { PRW_SID, C3_AVAILABLE, LOGIN_AS } from '../../constants';
import AccountValidator, {
  AccountErrors,
  UserAccount,
} from '../../validators/SettingsAccountValidator';

import styles from './account.module.css';

interface Props {
  config: PRWebConfig;
}

const Account: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const oktaUrl = props.config.prwebOkta.url;

  const prwSid = JSON.parse(cookie.get(PRW_SID) || '{}');
  const c3available = cookie.get(C3_AVAILABLE) === 'true';
  const impersonating = cookie.get(LOGIN_AS) === 'true';
  const extraInfoLabel =
    "Enter the email where you'd like to receive receipts, content content analytics, and other PRWeb communication. You'll still log in to PRWeb using the Login Username above.";
  const [isLoading, setIsLoading] = useState(false);
  const [userAccount, setUserAccount] = useState({} as UserAccount);
  const [passwordErrors, setPasswordErrors] = useState([] as Array<string>);
  const [frontEndFormErrorsValue, setFrontEndFormErrorsValue] = useState<
    AccountErrors
  >();
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [showConfirmationQuestion, setShowConfirmationQuestion] = useState(
    false,
  );
  const [hasSavedUser, setHasSavedUser] = useState(false);
  const [countryValue, setCountryValue] = useState('');
  const [countryErrors, setCountryErrors] = useState([] as Array<string>);

  const updateDataFieldByValue = (key: string, value?: string): void => {
    setUserAccount({ ...userAccount, [key]: value });
  };

  const onCountryChange = (value: string): void => {
    setCountryValue(value);
    setUserAccount({ ...userAccount, country: value });
    setCountryErrors(AccountValidator.validateCountry(value) || []);
  };

  const formIsValid = (): boolean => {
    const validationResult = AccountValidator.validateAccount({
      ...userAccount,
    });

    setFrontEndFormErrorsValue(validationResult);

    return validationResult.valid;
  };

  const saveChanges = async (): Promise<void> => {
    const isValid = formIsValid();

    if (isValid) {
      try {
        setIsLoading(true);
        setHasSavedUser(false);

        const postResponse = await axios.put(
          `${prwebApi}/users/save`,
          userAccount,
        );

        setIsLoading(false);

        if (postResponse.status === 200 || postResponse.status === 201) {
          setHasSavedUser(true);
        } else {
          handleErrors(postResponse.data);
        }
      } catch (error) {
        setIsLoading(false);
        handleErrors(error);
      }
    }
  };

  const checkBeforeChangePassword = (): void => {
    setShowConfirmationQuestion(true);
  };

  const onAcceptChangePassword = (): void => {
    setShowConfirmationQuestion(false);

    changePassword();
  };

  const onCancelChangePassword = (): void => {
    setShowConfirmationQuestion(false);
  };

  const changePassword = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${prwebApi}/settings/changepassword`);
      setIsLoading(false);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data?.changePasswordUrl
      ) {
        window.location.assign(response.data.changePasswordUrl);
      } else {
        handlePasswordError(response.data);
      }
    } catch (error) {
      handlePasswordError(error);
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePasswordError = (error: any) => {
    const errors: Array<string> = [];

    if (error?.response?.status === 401) {
      errors.push('Invalid session.  Please logout and log back in.');
    } else if (error?.response?.status === 500) {
      errors.push('Error resetting password.');
    } else if (error.message) {
      errors.push(error.message);
    }
    console.log(error);

    setPasswordErrors(errors);
  };

  const handleErrors = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (
      (error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 404) &&
      error.response.data.errors
    ) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  useEffect(() => {
    const initLoadUserInfo = async () => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const getResponse = await axios.get(`${prwebApi}/users/me`);
        if (getResponse.status === 200 || getResponse.status === 201) {
          const user = getResponse.data;

          if (!user) {
            setFormErrorSummaryValue([
              'The requested user could not be retrieved.',
            ]);
          } else {
            setUserAccount({ ...user });
            setCountryValue(user.country);
          }
        } else {
          handleErrors(getResponse.data);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setIsLoading(false);
      }
    };

    initLoadUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.rightPageColumn}>
          <Paper>
            {formErrorSummaryValue.length > 0 && (
              <div className={styles.errorMessage}>
                Error with User Account
                <ul>
                  {formErrorSummaryValue.map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className={styles.internalColumnContainer}>
              <div className={styles.spaceRight}>
                <TextInputField
                  label={'First Name'}
                  id={'firstName'}
                  placeholder={''}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.firstName || ''}
                  issues={frontEndFormErrorsValue?.firstNameErrors || []}
                  validate={AccountValidator.validateFirstName}
                />
              </div>

              <div>
                <TextInputField
                  id={'lastName'}
                  label={'Last Name'}
                  placeholder={''}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.lastName || ''}
                  issues={frontEndFormErrorsValue?.lastNameErrors || []}
                  validate={AccountValidator.validateLastName}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <TextInputField
                id={'loginUsername'}
                label={'Login Username'}
                placeholder={''}
                disabled={true}
                value={userAccount.oktaLogin || ''}
                tooltip={'Enter this username when logging into PRWeb.'}
                issues={[]}
              />
            </div>
            <TextInputField
              id={'email'}
              label={'Primary Email'}
              onChangeValue={updateDataFieldByValue}
              value={userAccount.email || ''}
              issues={frontEndFormErrorsValue?.emailErrors || []}
              validate={AccountValidator.validateEmail}
              extraInfoLabel={extraInfoLabel}
            />
            <TextInputField
              id={'organization'}
              label={'Organization'}
              onChangeValue={updateDataFieldByValue}
              value={userAccount.organization || ''}
              issues={frontEndFormErrorsValue?.organizationErrors || []}
              validate={AccountValidator.validateCompanyName}
            />

            <TextInputField
              label={'Address Line 1'}
              id={'address1'}
              placeholder={''}
              onChangeValue={updateDataFieldByValue}
              value={userAccount.address1 || ''}
              issues={frontEndFormErrorsValue?.address1Errors || []}
              validate={AccountValidator.validateAddress1}
            />

            <TextInputField
              id={'address2'}
              label={'Address Line 2'}
              placeholder={''}
              onChangeValue={updateDataFieldByValue}
              value={userAccount.address2 || ''}
              issues={frontEndFormErrorsValue?.address2Errors || []}
              validate={AccountValidator.validateAddress2}
            />

            <div className={styles.internalColumnContainer}>
              <div className={styles.spaceRight}>
                <TextInputField
                  id={'city'}
                  label={'City'}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.city || ''}
                  issues={frontEndFormErrorsValue?.cityErrors || []}
                  validate={AccountValidator.validateCity}
                />
              </div>
              <div>
                <TextInputField
                  id={'state'}
                  label={'State/Province'}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.state || ''}
                  issues={frontEndFormErrorsValue?.stateErrors || []}
                  validate={AccountValidator.validateState}
                />
              </div>
            </div>

            <div className={styles.internalColumnContainer}>
              <div className={styles.spaceRight}>
                <TextInputField
                  id={'zip'}
                  label={'Zip'}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.zip || ''}
                  issues={frontEndFormErrorsValue?.zipErrors || []}
                  validate={AccountValidator.validateZipcode}
                />
              </div>
              <div>
                <Typography
                  className={styles.formLabel}
                  htmlFor="country"
                  size="md"
                  tag="label"
                >
                  Country
                </Typography>
                <select
                  className={styles.selector}
                  id="country"
                  name="country"
                  onChange={(e: React.FormEvent): void =>
                    onCountryChange((e.target as HTMLInputElement).value)
                  }
                  value={countryValue}
                >
                  <CountryOptions />
                </select>
                {countryErrors && (
                  <FieldValidationIssue
                    issues={countryErrors}
                    id="country-validation"
                  />
                )}
              </div>
            </div>

            <div className={styles.internalColumnContainer}>
              <div className={styles.spaceRight}>
                <TextInputField
                  id={'phone'}
                  label={'Phone'}
                  onChangeValue={updateDataFieldByValue}
                  value={userAccount.phone || ''}
                  issues={frontEndFormErrorsValue?.phoneErrors || []}
                  validate={AccountValidator.validatePhoneNumber}
                />
              </div>
              <div></div>
            </div>

            <Button
              id={'save-changes-button'}
              level="primary"
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </Paper>

          {!impersonating && (
            <Paper>
              <Typography className={styles.formLabel} size="md" tag="label">
                Password
              </Typography>
              <Typography size="md" tag="div">
                <div
                  className={styles.linkButton}
                  onClick={checkBeforeChangePassword}
                  id={'change-password'}
                >
                  <LockSvg className={styles.lock} width={12} height={12} />
                  Change Password
                </div>
                {passwordErrors && passwordErrors.length > 0 && (
                  <FieldValidationIssue
                    issues={passwordErrors}
                    id={'password-error'}
                  />
                )}
              </Typography>
              <br />
              <Typography className={styles.formLabel} size="md" tag="label">
                Okta Settings
              </Typography>
              <Typography size="md" tag="div">
                <a
                  id={'change-okta-settings'}
                  className={styles.linkButton}
                  href={oktaUrl + '/enduser/settings'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Additional Account Security Settings
                </a>
              </Typography>
            </Paper>
          )}

          <Kite
            shouldShow={showConfirmationQuestion}
            actionText="Accept"
            action={onAcceptChangePassword}
            onCancel={onCancelChangePassword}
          >
            Are you sure you want to change your password?
          </Kite>
          <Kite status="success" shouldShow={hasSavedUser} ttl={8000}>
            User saved
          </Kite>
        </div>
      )}
      {!c3available && <div>Warning: C3 Distributions NOT available</div>}
    </>
  );
};

export default Account;
