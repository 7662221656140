import React, { useState, useCallback, useEffect } from 'react';

import qs from 'qs';

import usePreviousValue from '../../hooks/use-previous-value';
import {
  updateLocationQuery,
  replaceLocationQuery,
} from '../../utils/filter-context';

import WizardContext from './wizard-context';
import { Progress } from './wizard-types';

interface WizardProviderProps {
  children: JSX.Element[];
  history: {
    location: {
      search: string;
    };
  };
}

function WizardProvider({ history, children }: WizardProviderProps) {
  const queryParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const queryParamCurrentStep = Math.max(
    Number(queryParams.currentStep) || 0,
    1,
  );

  const [currentStep, setCurrentStep] = useState(1);
  const previousCurrentStep = usePreviousValue(currentStep, currentStep);
  const previousQueryParamCurrentStep = usePreviousValue(
    queryParamCurrentStep,
    queryParamCurrentStep,
  );
  const [isValid, setIsValid] = useState(false);
  const [nextLabel, setNextLabelState] = useState('Next');
  const [backLabel, setBackLabelState] = useState('Back');
  const [isSubmitStep, setIsSubmitStepState] = useState(false);
  const [saveRequired, setSavedRequiredState] = useState(true);
  const [submitState, setSubmitState] = useState({});
  const [hasSaveDraftButton, setHasSaveDraftButtonState] = useState(false);
  const [saveDraftButtonState, setSaveDraftButtonHandlerState] = useState({});
  const [hasPreviewButton, setHasPreviewButtonState] = useState(false);
  const [hasUpgradeButton, setHasUpgradeButtonState] = useState(false);
  const [backButtonIsVisible, setBackButtonIsVisibleState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [progressState, setProgressState] = useState([] as Progress[]);
  const [progressWhileEditingState, setProgressWhileEditingState] = useState({
    position: 0,
    valuesArray: [] as string[],
    labelsArray: [] as string[],
  });
  const [highestVisitableStep, setHighestVisitableStep] = useState(1);
  const [isDirty, setIsDirtyState] = useState(false);
  const [canMoveStepForwardState, setCanMoveStepForwardState] = useState({});
  const [canMoveStepBackwardState, setCanMoveStepBackwardState] = useState({});
  const [previewDataState, setPreviewDataState] = useState<Distribution>(
    {} as Distribution,
  );

  // add currentStep queryParam on first render
  useEffect(() => {
    replaceLocationQuery(
      { query: { currentStep: 1 }, pathname: null },
      { history },
    );
  }, [history]);

  // reflect currentStep change on queryParam
  useEffect(() => {
    if (previousCurrentStep === currentStep) return;
    updateLocationQuery(
      { query: { currentStep }, pathname: null },
      { history },
    );
  }, [history, currentStep, previousCurrentStep]);

  // update currentStep if step from queryParam changes
  useEffect(() => {
    if (queryParamCurrentStep === previousQueryParamCurrentStep) {
      return;
    }
    if (queryParamCurrentStep <= highestVisitableStep) {
      setCurrentStep(queryParamCurrentStep);
    }
  }, [
    queryParamCurrentStep,
    highestVisitableStep,
    currentStep,
    previousQueryParamCurrentStep,
  ]);

  return (
    <WizardContext.Provider
      value={{
        currentStep,
        isValid,
        nextLabel,
        backLabel,
        isSubmitStep,
        saveRequired,
        submitState,
        hasSaveDraftButton,
        saveDraftButtonState,
        hasPreviewButton,
        hasUpgradeButton,
        backButtonIsVisible,
        progressState,
        progressWhileEditingState,
        setProgressWhileEditingState,
        loading,
        isDirty,
        canMoveStepForwardState,
        canMoveStepBackwardState,
        previewDataState,
        setStepIsValid: valid => {
          if (valid) {
            // Consider the case where the user went back and then forward, so
            // make sure that we always keep the highest visitable/valid step index
            // by using Math.max.
            const highestValidStep = Math.max(
              currentStep,
              highestVisitableStep,
            );
            setHighestVisitableStep(highestValidStep);
          } else {
            // User should not be able to go forward this step if is invalid
            setHighestVisitableStep(currentStep);
          }
          setIsValid(valid);
        },
        setCanMoveStepForward: useCallback(
          preCheck => {
            setCanMoveStepForwardState({ preCheck });
          },
          [setCanMoveStepForwardState],
        ),
        moveStepForward: () => {
          setHighestVisitableStep(
            Math.max(highestVisitableStep, currentStep + 1),
          );
          setCurrentStep(currentStep + 1);
        },
        moveStepBackward: () => {
          setCurrentStep(currentStep - 1);
        },
        setNextLabel: label => {
          setNextLabelState(label);
        },
        setBackLabel: label => {
          setBackLabelState(label);
        },
        setBackButtonIsVisible: isVisible => {
          setBackButtonIsVisibleState(isVisible);
        },
        setIsSubmitStep: isSubmit => {
          setIsSubmitStepState(isSubmit);
        },
        setSaveRequired: isRequired => {
          setSavedRequiredState(isRequired);
        },
        setSubmit: useCallback(
          submit => {
            setSubmitState({ submit });
          },
          [setSubmitState],
        ),
        setHasSaveDraftButton: hasSaveDraftButton => {
          setHasSaveDraftButtonState(hasSaveDraftButton);
        },
        setSaveDraftButtonHandler: useCallback(
          handler => {
            setSaveDraftButtonHandlerState({ handler });
          },
          [setSaveDraftButtonHandlerState],
        ),
        setHasPreviewButton: hasPreviewButton => {
          setHasPreviewButtonState(hasPreviewButton);
        },
        setHasUpgradeButton: hasUpgradeButton => {
          setHasUpgradeButtonState(hasUpgradeButton);
        },
        setProgress: (position, progress) => {
          setProgressState((prevProgressState: Progress[]): Progress[] => {
            const newProgressState = [...prevProgressState];

            if (position > progressState.length) {
              newProgressState.push({ ...progress });
            } else {
              newProgressState[position] = {
                ...newProgressState[position],
                ...progress,
              };
            }

            return [...newProgressState];
          });
        },
        setProgressWhileEditing: useCallback(
          (valuesArray: string[], labelsArray: string[] = []) => {
            // subtract 1 to calculate from current step in the wizard to array position
            setProgressWhileEditingState({
              position: currentStep - 1,
              valuesArray,
              labelsArray,
            });
          },
          [setProgressWhileEditingState, currentStep],
        ),
        setIsDirty: useCallback(
          (newIsDirty: boolean) => {
            setIsDirtyState(isDirty || newIsDirty);
          },
          [setIsDirtyState, isDirty],
        ),
        setLoading: useCallback((loading: boolean) => setLoading(loading), [
          setLoading,
        ]),
        setCanMoveStepBackward: useCallback(
          preCheck => {
            setCanMoveStepBackwardState({ preCheck });
          },
          [setCanMoveStepBackwardState],
        ),
        setPreviewData: useCallback(
          (distribution: Distribution) => setPreviewDataState(distribution),
          [setPreviewDataState],
        ),
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

export default WizardProvider;
