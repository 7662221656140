import React from 'react';

import { Button } from '@cision/rover-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CheckmarkSvg } from '../../assets/checkmark.svg';
import { ReactComponent as CrossSvg } from '../../assets/cross.svg';
import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import { ReactComponent as NoticeSvg } from '../../assets/notice.svg';

const baseClass = 'tk-kite-area';
const KiteArea = ({
  status,
  title,
  children,
  actionText,
  action,
  onCancel,
}) => {
  const cancelButton = onCancel ? (
    <div>
      <Button onClick={onCancel} level="link">
        <CrossSvg width={14} height={14} />
      </Button>
    </div>
  ) : null;

  const dismissButton = onCancel ? (
    <div>
      <Button level="link" onClick={onCancel}>
        Dismiss
      </Button>
    </div>
  ) : null;

  const getStatusIcon = () => {
    if (status === 'success') {
      return <CheckmarkSvg width={16} height={16} fill={'green'} />;
    }

    if (status === 'info') {
      return <InfoSvg width={16} height={16} />;
    }

    return <NoticeSvg width={20} height={20} />;
  };

  const statusIcon = getStatusIcon();

  const statusIconBaseClass = `${baseClass}__icon`;
  const statusIconClasses = classNames(statusIconBaseClass, {
    [`${statusIconBaseClass}--svg-icon`]: true,
  });

  const statusIconArea = <div className={statusIconClasses}>{statusIcon}</div>;

  const contentArea = <div className={`${baseClass}__content`}>{children}</div>;

  const titleArea = (
    <div className={`${baseClass}__title`}>
      <strong>{title}</strong>
    </div>
  );

  const showContent = (title && contentArea) || (action && actionText);

  return (
    <div className={baseClass} data-qa="KiteArea">
      <div className={`${baseClass}__header`}>
        {statusIconArea}
        {title && titleArea}
        {!title && contentArea}
        {cancelButton}
      </div>
      {showContent && (
        <div className={`${baseClass}__main`}>
          {title && contentArea}
          {action && actionText && (
            <div className={`${baseClass}__actions`}>
              <div className={`${baseClass}__action`}>
                <Button level="link" onClick={action} active>
                  {actionText}
                </Button>
              </div>
              {dismissButton}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

KiteArea.propTypes = {
  /** status (matches semantic colors) */
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
  /** show loading */
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  /** text/node to show */
  children: PropTypes.node,
  /** Text to show in button NOTE: button only shows if both action and actionText
   * provided
   */
  actionText: PropTypes.string,
  /** action func to call NOTE: button only shows if both action and actionText
   * provided
   */
  action: PropTypes.func,
  /** cancel func to call NOTE: cancel button only shows if this is provided */
  onCancel: PropTypes.func,
};

KiteArea.defaultProps = {
  status: 'info',
  isLoading: false,
  title: '',
  actionText: '',
  children: null,
  action: null,
  onCancel: null,
};

export default KiteArea;
