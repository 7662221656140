import { PRWEB_BUNDLES } from '../constants';

import CacheService from './cache-service';

const PRWebBundles = {
  getStoreBundles: (): BundlePackagePrice[] | undefined => {
    if (CacheService.exists(PRWEB_BUNDLES)) {
      return CacheService.get(PRWEB_BUNDLES) as BundlePackagePrice[];
    }
    return undefined;
  },
  setStoreBundles: (bundlePackage: BundlePackagePrice[]) => {
    PRWebBundles.removeStoreBundles();
    CacheService.set(PRWEB_BUNDLES, bundlePackage);
  },
  removeStoreBundles: () => {
    if (CacheService.exists(PRWEB_BUNDLES)) {
      CacheService.remove(PRWEB_BUNDLES);
    }
  },
};

export default PRWebBundles;
