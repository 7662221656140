import React, { useState, useEffect } from 'react';

import TextInputField from '../../../../components/text-input-field';
import SubmitDistributionValidator from '../../../../validators/SubmitDistributionValidator';

import styles from './video-url.module.css';

interface Props {
  initialValue?: string;
  initialIssues?: string[];
  onChangeValue: (key: string, value?: string) => void;
}
const VideoUrl = ({
  //   config,
  initialValue,
  onChangeValue,
  initialIssues,
}: Props) => {
  enum VideoUrlType {
    unknown,
    Youtube,
  }

  const youtubeRegex = /^((?:https?:)\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

  const [videoThumbnailUrlState, setVideoThumbnailUrlState] = useState<
    string | undefined
  >();

  const setVideoUrl = (key: string, value?: string): void => {
    setVideoThumbnailUrl(value);
    onChangeValue(key, value);
  };

  const setVideoThumbnailUrl = (value?: string): void => {
    if (value === undefined || value === '') {
      setVideoThumbnailUrlState(undefined);
      return;
    }

    let url;

    switch (getVideoUrlType(value)) {
      case VideoUrlType.Youtube: {
        url = getYoutubeVideoThumbnailUrl(value);
        url && setVideoThumbnailUrlState(url);
        break;
      }

      default:
        setVideoThumbnailUrlState(undefined);
    }
  };

  const getVideoUrlType = (videoUrl: string): VideoUrlType => {
    if (videoUrl.match(youtubeRegex)) {
      return VideoUrlType.Youtube;
    }

    return VideoUrlType.unknown;
  };

  const getYoutubeVideoThumbnailUrl = (
    youtubeVideoUrl: string,
  ): string | null => {
    const id = getYoutubeVideoIdFromUrl(youtubeVideoUrl);

    return id === '' ? null : `https://i.ytimg.com/vi/${id}/hqdefault.jpg`;
  };

  const getYoutubeVideoIdFromUrl = (url: string): string => {
    const regex = /^((?:https?:)\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
    const matches = url.match(regex);

    return matches === null ? '' : matches[5];
  };

  useEffect(() => setVideoThumbnailUrl(initialValue));

  return (
    <div>
      <TextInputField
        label={'Video URL'}
        id={'videoUrl'}
        placeholder={''}
        onChangeValue={setVideoUrl}
        value={initialValue || ''}
        issues={initialIssues || []}
        validate={SubmitDistributionValidator.validateVideoUrl}
      />
      {videoThumbnailUrlState && (
        <div className={styles.videoThumbnailContainer}>
          <img
            src={videoThumbnailUrlState}
            alt={'video thumbnail'}
            className={styles.videoThumbnail}
          />
        </div>
      )}
    </div>
  );
};
export default VideoUrl;
