import React, { useEffect, useContext, useCallback, useState } from 'react';

import { Typography, Button } from '@cision/rover-ui';

import PackageCard from '../../../components/package-card';
import WizardContext from '../../../components/wizard/wizard-context';
import {
  BASIC_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
} from '../../../constants';
import ExitWizardPrompt from '../../distribution-wizard/components/exit-wizard-prompt';
import PRWebPackages from '../../distribution-wizard/PRWebPackages';
import UpgradeWizardContext from '../UpgradeWizardContext';

import styles from './styles/select-package-step.module.css';
import './styles/SelectPackageStep.scss';

const SelectPackageStep: React.FC = () => {
  const { upgradeData, updateUpgradeData } = useContext(UpgradeWizardContext);
  const wizardContext = React.useContext(WizardContext);

  const basicPackage = PRWebPackages.getPRWebPackage(
    BASIC_PACKAGE_ID,
  ) as PRWebPackage;
  const standardPackage = PRWebPackages.getPRWebPackage(
    STANDARD_PACKAGE_ID,
  ) as PRWebPackage;
  const advancedPackage = PRWebPackages.getPRWebPackage(
    ADVANCED_PACKAGE_ID,
  ) as PRWebPackage;
  const premiumPackage = PRWebPackages.getPRWebPackage(
    PREMIUM_PACKAGE_ID,
  ) as PRWebPackage;

  const setPRWebPackage = (prwebPackage: string) => {
    updateUpgradeData({
      ...upgradeData,
      package: prwebPackage,
    });
  };

  const isValidUpgrade = (
    initialPackageId: string,
    selectedPackageId: string,
  ) => Number(initialPackageId) <= Number(selectedPackageId);

  // Update progress
  useEffect(() => {
    if (!upgradeData.package) {
      return;
    }
    const selectedPackage = PRWebPackages.getPRWebPackage(upgradeData.package);

    const stepValuesArray: string[] = selectedPackage
      ? [selectedPackage.name]
      : [];

    wizardContext.setProgressWhileEditing(stepValuesArray);

    const isValid =
      upgradeData.initialPackage !== undefined &&
      !isNaN(Number(upgradeData.package)) &&
      isValidUpgrade(upgradeData.initialPackage, upgradeData.package);

    wizardContext.setStepIsValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradeData.package]);

  const getBasicPkg = () => {
    if (basicPackage) {
      return (
        <Button
          id={'select-basic-button'}
          level="secondary"
          onClick={(): void => setPRWebPackage(basicPackage.packageId)}
          active={upgradeData && upgradeData.package === basicPackage.packageId}
          disabled={
            upgradeData &&
            !isValidUpgrade(upgradeData.initialPackage, basicPackage.packageId)
          }
        >
          Select Basic
        </Button>
      );
    }
  };

  const getStandardPkg = () => {
    if (standardPackage) {
      return (
        <Button
          id={'select-standard-button'}
          level="secondary"
          onClick={(): void => setPRWebPackage(standardPackage.packageId)}
          active={
            upgradeData && upgradeData.package === standardPackage.packageId
          }
          disabled={
            upgradeData &&
            !isValidUpgrade(
              upgradeData.initialPackage,
              standardPackage.packageId,
            )
          }
        >
          Select Standard
        </Button>
      );
    }
  };

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" weight="bold" color="black">
            Select Your Package
          </Typography>
        </div>
        <div className={styles.stepSubtitle}>
          <Typography className={styles.font16}>
            <span className={styles.font16}>
              Choose an enhanced package and boost your online visibility
            </span>
          </Typography>
        </div>
      </div>
      <div className={styles.packagesContainer} id={'packageContainer'}>
        <PackageCard>
          {{
            basic: getBasicPkg(),
            standard: getStandardPkg(),
            advanced: (
              <Button
                id={'select-advanced-button'}
                level="secondary"
                onClick={(): void => setPRWebPackage(advancedPackage.packageId)}
                active={
                  upgradeData &&
                  upgradeData.package === advancedPackage.packageId
                }
                disabled={
                  upgradeData &&
                  !isValidUpgrade(
                    upgradeData.initialPackage,
                    advancedPackage.packageId,
                  )
                }
              >
                Select Advanced
              </Button>
            ),
            premium: (
              <Button
                id={'select-premium-button'}
                className={styles.premiumPackageButton}
                level="secondary"
                onClick={(): void => setPRWebPackage(premiumPackage.packageId)}
                active={
                  upgradeData &&
                  upgradeData.package === premiumPackage.packageId
                }
                disabled={
                  upgradeData &&
                  !isValidUpgrade(
                    upgradeData.initialPackage,
                    premiumPackage.packageId,
                  )
                }
              >
                Select Premium
              </Button>
            ),
          }}
        </PackageCard>
      </div>
      <ExitWizardPrompt />
    </div>
  );
};

export default SelectPackageStep;
