import React, { useState, useEffect } from 'react';

import { Loader, Tooltip } from '@cision/rover-ui';
import axios from 'axios';
import moment, { Moment } from 'moment-timezone';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import { ReactComponent as LinkSvg } from '../../assets/link-solid.svg';
import { ReactComponent as LinkedinSvg } from '../../assets/linkedin-logo.svg';
import { ReactComponent as TrashSvg } from '../../assets/trash-solid.svg';
import {
  PRWEB_LINKEDIN,
  SHOW_LINKEDIN_EXPIRY_DAY,
  SOCIAL_MEDIA_LINKEDIN,
} from '../../constants';
import PRWebSocial from '../../utils/PRWebSocial';
import PopupModal, { PopupContentProps } from '../popup-modal';

import LinkedInConnectModal from './LinkedInConnectModal';
import { SocialMediaState } from './social-media-container';
import styles from './social-media-container.module.css';

interface LinkedinProps {
  config?: PRWebConfig;
  buttonState?: SocialMediaState;
  isLinkedinSelected?: boolean;
  linkedinHandler?: (linkin: SocialMediaAccount) => void;
  callback?: () => void;
}

const isLinkedinExpiring = (tokenDate: Moment) => {
  const expirationMark = moment()
    .utc()
    .add(SHOW_LINKEDIN_EXPIRY_DAY, 'days');
  return tokenDate.isBefore(expirationMark);
};

const LinkedinToggle = ({
  config = {} as PRWebConfig,
  buttonState = SocialMediaState.Default,
  isLinkedinSelected = true,
  linkedinHandler,
  callback,
}: LinkedinProps) => {
  const prwebApi = config.prwebApi.url;
  const [isLoading, setIsLoading] = useState(true);
  const [prWebLinkedinAccount, setPrWebLinkedinAccount] = useState<
    PRWebSocialAccount | undefined
  >(undefined);
  const [togglePopup, setTogglePopup] = useState(false);
  const [popupContent, setPopupContent] = useState({} as PopupContentProps);
  const animationRef = React.createRef<HTMLDivElement>();

  const onCallbackSuccessConnection = (status: string) => {
    if (status === 'success') {
      getSocialAccounts();
    } else {
      setPopupContent({
        header: 'Attention!',
        body: status,
        action: [
          {
            name: 'Ok',
            level: 'primary',
          },
        ],
      });
      setTogglePopup(true);
    }
  };

  const isConnectedContextHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    if (prWebLinkedinAccount === undefined) {
      LinkedInConnectModal.Start({ config, onCallbackSuccessConnection });
    } else {
      if (buttonState === SocialMediaState.Removable) {
        trashButtonHandler();
      } else if (buttonState === SocialMediaState.Selectable) {
        selectLinkedinHandler();
      }
    }
  };

  const trashButtonHandler = () => {
    setIsLoading(true);
    const linkedinAccount = PRWebSocial.getSocialAccount(PRWEB_LINKEDIN);
    const checkActiveReleasesUrl = `${prwebApi}/social/doesprweboauthhaveactivereleaseses/${linkedinAccount?.Id}`;
    const activePressReleaseMessage = `There are/is an active Press Release(s) associated with this Social Media Account. If you continue, active release(s) will have your social media account removed. If you've already submitted the release to editorial, you can call the Editorial team to gain access to edit your release and reconnect your Social Media Account. Do you wish to continue?`;

    axios
      .get(checkActiveReleasesUrl, {
        params: { socialMedia: SOCIAL_MEDIA_LINKEDIN },
      })
      .then(response => {
        setIsLoading(false);
        if (response && response.status === 200) {
          let expireDate = null;
          if (
            linkedinAccount &&
            linkedinAccount.TokenExpireDate &&
            isLinkedinExpiring(moment(linkedinAccount.TokenExpireDate))
          ) {
            expireDate = moment(linkedinAccount.TokenExpireDate).local();
          }
          const modalContent = {
            header: 'Attention!',
            body: activePressReleaseMessage,
            action: [
              {
                name: 'No',
                level: 'secondary',
              },
              {
                name: 'Yes',
                level: 'primary',
              },
            ],
          };
          if (response.data === true) {
            if (expireDate) {
              modalContent.body = activePressReleaseMessage;
            }
            setPopupContent(modalContent);
            setTogglePopup(true);
          } else {
            modalContent.body = `Your social media account will be disconnected. If our connection to your social media account is expired or is expiring soon, please reconnect your account after it's been removed. Do you wish to continue?`;
            setPopupContent(modalContent);
            setTogglePopup(true);
          }
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('trashButtonHandler: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  const selectLinkedinHandler = () => {
    if (linkedinHandler && prWebLinkedinAccount) {
      linkedinHandler({
        type: prWebLinkedinAccount.Type,
        accountName: prWebLinkedinAccount.AccountName,
        avatar: prWebLinkedinAccount.Avatar,
        selected: prWebLinkedinAccount.Selected,
      } as SocialMediaAccount);
    }
  };

  const removeSocialAccount = () => {
    setIsLoading(true);

    const linkedinAccount = PRWebSocial.getSocialAccount(PRWEB_LINKEDIN);
    const removeLinkedinAccountsUrl = `${prwebApi}/social/account/${linkedinAccount?.Id}`;

    axios
      .delete(removeLinkedinAccountsUrl, {
        params: { socialMedia: SOCIAL_MEDIA_LINKEDIN },
      })
      .then(response => {
        if (response && (response.status === 200 || response.status === 201)) {
          setPrWebLinkedinAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('loadcache removing linkedin acct: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  const getSocialAccounts = () => {
    setIsLoading(true);
    if (PRWebSocial.isConnectedToSocialMedia(PRWEB_LINKEDIN)) {
      const linkedinAccount = PRWebSocial.getSocialAccount(PRWEB_LINKEDIN);
      setPrWebLinkedinAccount(linkedinAccount);
      setIsLoading(false);
      callback && callback();
    } else {
      const getSocialAccountsUrl = `${prwebApi}/social/account`;
      axios
        .get(getSocialAccountsUrl, {
          params: { socialMedia: SOCIAL_MEDIA_LINKEDIN },
        })
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const linkedinAccount = {
              Id: response.data.id,
              Type: response.data.type,
              AccountName: response.data.userLoginName,
              Avatar: response.data.avatarLink,
              Authorized: response.data.authorized,
              ExternalApplicationId: response.data.linkedinUserId,
              MaxContentLength: 280, // hardcode
              TokenExpireDate: response.data.tokenExpireDate,
            } as PRWebSocialAccount;
            if (linkedinAccount && linkedinAccount.Id) {
              setPrWebLinkedinAccount(linkedinAccount);
              PRWebSocial.setSocialAccount(PRWEB_LINKEDIN, linkedinAccount);
            } else {
              setPrWebLinkedinAccount(undefined);
              PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
            }
            callback && callback();
          } else {
            console.log('invalid response loading social account: ', response);
            setPrWebLinkedinAccount(undefined);
            PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
          }
        })
        .catch(error => {
          console.log('loadcache error retrieving data: ', error);
          setPrWebLinkedinAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getLinkedinExpiration = () => {
    let tooltipText = `We've noticed our connection to your social media account is expired or is expiring soon. Please reconnect your account after it's been removed.`;
    if (buttonState === SocialMediaState.Selectable) {
      tooltipText = `Our connection to your social media account is expired or is expiring soon. Please save your release draft and navigate to Settings to reconnect.`;
    }
    const tooltip = (
      <Tooltip
        content={<span style={{ color: 'gray' }}>{tooltipText}</span>}
        tooltipWidth={'200px'}
        direction="top"
        showOnHover
      >
        <InfoSvg
          fill={buttonState === SocialMediaState.Selectable ? '#c9d8de' : 'red'}
          width={15}
          height={15}
        />
      </Tooltip>
    );

    if (prWebLinkedinAccount && prWebLinkedinAccount.TokenExpireDate) {
      const tokenExpire = moment(prWebLinkedinAccount.TokenExpireDate);
      if (tokenExpire.isBefore(moment().utc())) {
        return (
          <span className={styles.withTooltip}>
            Authorization Expired&nbsp;{tooltip}
          </span>
        );
      } else if (isLinkedinExpiring(tokenExpire)) {
        return (
          <span className={styles.withTooltip}>
            Expires {tokenExpire.format('MM/DD/YYYY')}&nbsp;{tooltip}
          </span>
        );
      } else {
        return (
          <span style={{ lineHeight: 0, opacity: 0 }}>
            Expires {tokenExpire.format('MM/DD/YYYY')}
          </span>
        );
      }
    }
    return (
      <span className={styles.withTooltip}>
        Authorization Expired&nbsp;{tooltip}
      </span>
    );
  };

  const updateDataFieldByValue = (key: string, value?: string) => {
    if (key === 'linkedinModal' && String(value).length > 0) {
      if (value === 'Yes') {
        removeSocialAccount();
      }
      setTogglePopup(false);
    }
  };

  useEffect(() => {
    getSocialAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.buttonContainer}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <button
            className={styles.toggleButton}
            onClick={isConnectedContextHandler}
          >
            {prWebLinkedinAccount ? (
              <div
                ref={animationRef}
                className={`${styles.slider} ${isLinkedinSelected &&
                  styles.sliderTransition}`}
              >
                <div
                  className={`${styles.innerContent} ${buttonState ===
                    SocialMediaState.Selectable && styles.enableBackground}`}
                >
                  <div className={styles.avatar}>
                    {prWebLinkedinAccount.Avatar ? (
                      <img
                        className={`${styles.avatarIcon} ${styles.avatarRounded}`}
                        src={prWebLinkedinAccount.Avatar}
                        alt={prWebLinkedinAccount.AccountName}
                      />
                    ) : (
                      <LinkedinSvg className={styles.linkedinSvg} />
                    )}
                  </div>
                  <div className={styles.buttonContent}>
                    <span>{prWebLinkedinAccount.AccountName}</span>
                    <span>{prWebLinkedinAccount.Type}</span>
                    {getLinkedinExpiration()}
                  </div>
                  {buttonState === SocialMediaState.Selectable && (
                    <>
                      <div
                        className={`${styles.toggleNotch} ${
                          !isLinkedinSelected ? styles.notchTransition : ''
                        }`}
                      ></div>
                      <div className={styles.sliderContent}>
                        Use Linkedin Account
                      </div>
                    </>
                  )}
                  {buttonState === SocialMediaState.Removable && (
                    <TrashSvg className={styles.trashSvg}></TrashSvg>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.innerContent}>
                <div className={styles.avatar}>
                  <LinkedinSvg className={styles.linkedinSvg} />
                </div>
                <div className={styles.buttonContent}>
                  <div>Connect to Linkedin</div>
                </div>
                <LinkSvg className={styles.linkSvg}></LinkSvg>
              </div>
            )}
          </button>
          <PopupModal
            id={'linkedinModal'}
            isOpen={togglePopup}
            content={popupContent}
            onActionClick={updateDataFieldByValue}
          />
        </>
      )}
    </div>
  );
};

export default LinkedinToggle;
