import React, { useState, useEffect } from 'react';

import { Button, Modal, Loader } from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as PDFSvg } from '../../../../assets/third-party/pdf-logo.svg';
import TextInputField from '../../../../components/text-input-field';
import AttachmentValidator, {
  validateAttachment,
  AttachmentValidation,
  AttachmentErrors,
} from '../../../../validators/AttachmentDistributionValidator';

import styles from './attachmentmodal.module.css';

interface Props {
  config: PRWebConfig;
  addAttachment: (arg0: Attachment) => void;
  toggle: (arg0: boolean) => void;
  isOpen: boolean;
  attachment?: Attachment;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface C3File {
  Size: number;
  Path: string;
  Name: string;
  Base64Preview: string;
  AssetID?: number;
  UploadKey?: string;
}

const AttachmentModal = ({
  config,
  addAttachment,
  toggle,
  isOpen,
  attachment,
}: Props) => {
  const [caption, setCaption] = useState('');
  const [altText, setAltText] = useState('');
  const [c3File, setC3File] = useState<C3File>();
  const [isLoading, setIsLoading] = useState(false);
  const [showInputFile, setshowInputFile] = useState(true);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [showPdfIcon, setShowPdfIcon] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbnailAlt, setThumbnailAlt] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [frontEndFormErrorsValue, setFrontEndFormErrorsValue] = useState<
    AttachmentErrors
  >();
  const [saveClickedOnce, setSaveClickedOnce] = useState(false);

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const getAttachmentValidation = (data: Attachment): AttachmentValidation => {
    return validateAttachment(data);
  };

  const getAttachmentFromState = (): Attachment => {
    return {
      caption,
      altText: altText,
      fileName: c3File?.Name || '',
      filePath: c3File?.Path || '',
      fileSize: c3File?.Size || 0,
      base64Preview: c3File?.Base64Preview || '',
      assetId: c3File?.AssetID || 0,
      uploadKey: c3File?.UploadKey || undefined,
    };
  };

  const confirmHandler = () => {
    const attachment: Attachment = getAttachmentFromState();
    const attachmentValidation = getAttachmentValidation(attachment);
    setSaveClickedOnce(true);

    if (attachmentValidation.valid) {
      addAttachment(attachment);
      close();
    }
  };

  const filenameIsPdf = (fileName: string): boolean => {
    return (
      fileName
        ?.toLowerCase()
        .split('.')
        .pop() === 'pdf'
    );
  };

  const handleErrors = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (
      (error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 404 ||
        error.response.status === 413) &&
      error.response.data.errors
    ) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  const changeAttachmentFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setFormErrorSummaryValue([]);
    setIsLoading(true);
    setThumbnailSrc('');
    setThumbnailAlt('');
    setShowThumbnail(false);
    setFormIsValid(false);

    const files = (event && event.target && event.target.files) || [];
    const file = files.length === 1 ? files[0] : null;

    if (!file) {
      return;
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('myFile', file);

    try {
      const postResponse = await axios.post(
        `${prwebApi}/file`,
        formData,
        config,
      );

      const isPdf = filenameIsPdf(postResponse.data.Name);

      const thumbnailSrc = isPdf ? '' : postResponse.data.Base64Preview;

      setC3File(postResponse.data as C3File);
      setThumbnailSrc(thumbnailSrc);
      setThumbnailAlt(postResponse.data.Name);
      setShowPdfIcon(isPdf);
      setShowThumbnail(!isPdf);
      setIsLoading(false);
      setFormIsValid(true);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateField = (key: string, value = '') => {
    if (key === 'caption') {
      setCaption(value);
    }
  };

  const close = () => {
    toggle(false);
  };

  useEffect(() => {
    const attachment = getAttachmentFromState();
    if (saveClickedOnce) {
      setFrontEndFormErrorsValue(validateAttachment(attachment));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption, c3File, saveClickedOnce]);

  useEffect(() => {
    const loadAttachment = async (): Promise<void> => {
      if (attachment) {
        setshowInputFile(false);
        setCaption(attachment.caption);
        setAltText(attachment.altText);
        setThumbnailSrc(attachment.base64Preview || '');
        setThumbnailAlt(attachment.fileName);
        setFormIsValid(true);
        const isPdf = filenameIsPdf(attachment.fileName);
        setShowThumbnail(!isPdf);
        setShowPdfIcon(isPdf);
        const file: C3File = {
          Name: attachment.fileName || '',
          Path: attachment.filePath || '',
          Size: attachment.fileSize || 0,
          Base64Preview: attachment.base64Preview || '',
          AssetID: attachment.assetId || 0,
          UploadKey: attachment.uploadKey || undefined,
        };
        setC3File(file);
      } else {
        setshowInputFile(true);
        setCaption('');
        setAltText('');
        setThumbnailSrc('');
        setThumbnailAlt('');
        setFormIsValid(false);
      }
    };

    loadAttachment();
  }, [attachment]);

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>Release Attachments</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <br />
        <div>{isLoading && <Loader />}</div>
        <div>
          {showInputFile && (
            <input
              id="attachment-input-file"
              type="file"
              accept="application/pdf, image/png, image/x-png, image/jpeg, image/gif, .pdf, .jpg, .jpeg, .gif, .png"
              onChange={changeAttachmentFileInput}
            />
          )}
          {showThumbnail && (
            <img
              src={thumbnailSrc}
              alt={thumbnailAlt}
              className={styles.thumbnail}
            />
          )}
          {showPdfIcon && <PDFSvg className={styles.thumbnail} />}
        </div>
        {formErrorSummaryValue.length ? (
          <div className={styles.errorMessage}>
            Error uploading file
            <ul>
              {formErrorSummaryValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        ) : (
          undefined
        )}
        <br />
        <div>
          <TextInputField
            label={'Caption'}
            id={'caption'}
            placeholder={''}
            onChangeValue={updateField}
            value={caption}
            issues={frontEndFormErrorsValue?.captionErrors || []}
            validate={AttachmentValidator.validateCaption}
            tooltip={'Please provide a descriptive caption for accessibility.'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.rightFooter}>
        <Button level="tertiary" onClick={close}>
          Close
        </Button>
        <Button
          disabled={!formIsValid}
          level="primary"
          onClick={confirmHandler}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AttachmentModal;
