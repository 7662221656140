import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Paper,
  Button,
  Typography,
  Responsive,
  Loader,
} from '@cision/rover-ui';

import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as MinusSvg } from '../../assets/minus.svg';
import { ReactComponent as PlusSvg } from '../../assets/plus.svg';
import DatePicker from '../../components/date-picker';
import Footer from '../../components/footer';
import Header from '../../components/header';
import NavigationBackwardArrow from '../../components/navigation-backward-arrow/navigation-backward-arrow';
import PopupModal, { PopupContentProps } from '../../components/popup-modal';

import styles from './bundle-details.module.css';

interface Props {
  config: PRWebConfig;
}

interface BundleOrderDetails {
  bundleOrderId: number;
  total: string;
  lineItems: BundleOrderLineItem[];
  payments: BundlePayment[];
  userBundle: UserPackageBundleDetails[];
  refunds: RefundOrderLineItem[];
}

interface RefundOrderLineItem {
  amount: string;
  bundleOrderId: number;
  bundleOrderRefundId: number;
  bundleOrderLineItemId: number;
  createdDate: string;
  userAccountId: number;
  description?: string;
}

interface BundleOrderLineItem {
  bundleOrderLineItemId: number;
  bundleOrderId: number;
  type: string;
  description: string;
  quantity: number;
  amount: string;
}

interface BundlePayment {
  paymentId: number;
  bundleOrderId: number;
  type: string;
  description: string;
  amount: string;
  confirmationCode: string;
  createdDate: string;
}

interface UserPackageBundleDetails {
  userPackageBundleId: number;
  bundleOrderLineItemId: number;
  description: string;
  quantity: number;
  remaining: number;
  expirationDate: string;
}

const BundleDetails: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const history = useHistory();
  const { id: bundleIdParam } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [bundleDetails, setBundleDetails] = useState({} as BundleOrderDetails);

  const [selectLineItems, setSelectLineItems] = useState(
    [] as (boolean | null)[],
  );
  const [editBundles, setEditBundles] = useState(
    [] as UserPackageBundleDetails[],
  );

  const [togglePopup, setTogglePopup] = useState(false);
  const [popupContent, setPopupContent] = useState({} as PopupContentProps);

  const goToPreviousPage = () => history.goBack();

  const getBundleDetails = (bundleId: string) => {
    const bundleDetailsUrl = `${prwebApi}/admin/bundleorder/${bundleId}`;
    const userPackageBundleUrl = `${prwebApi}/admin/bundleorder/${bundleId}/userpackagebundles`;
    const bundleDetailsReq = axios.get(bundleDetailsUrl);
    const userPackageBundleReq = axios.get(userPackageBundleUrl);

    Promise.all([bundleDetailsReq, userPackageBundleReq]).then(responses => {
      const bundleDetailsRes = responses[0];
      const userPackageBundleRes = responses[1];
      if (
        bundleDetailsRes &&
        userPackageBundleRes &&
        bundleDetailsRes.status === 200 &&
        userPackageBundleRes.status === 200
      ) {
        const details = {
          bundleOrderId: bundleDetailsRes.data.bundleOrderId,
          total: bundleDetailsRes.data.total,
          lineItems: bundleDetailsRes.data.lineItems,
          payments: bundleDetailsRes.data.payments,
          userBundle: userPackageBundleRes.data,
          refunds: bundleDetailsRes.data.refunds,
        } as BundleOrderDetails;

        details.refunds = mapRefundDescription(details);
        details.userBundle = sortUserPackageBundles(details);
        const selectState = canRefundLineItem(details);
        setBundleDetails(details);
        setEditBundles(userPackageBundleRes.data);
        setSelectLineItems(selectState);
        setIsLoading(false); // make sure all http request responses before showing UI
      }
    });
  };

  const canRefundLineItem = (bundleOrderDetails: BundleOrderDetails) => {
    const selectState = Array(bundleOrderDetails.lineItems.length).fill(false);
    bundleOrderDetails.lineItems.forEach(
      (item: BundleOrderLineItem, idx: number) => {
        const isRefunded = bundleOrderDetails.refunds.find(
          refunded =>
            item.bundleOrderLineItemId === refunded.bundleOrderLineItemId,
        );
        const isConsumed = bundleOrderDetails.userBundle.find(
          consumed =>
            item.bundleOrderLineItemId === consumed.bundleOrderLineItemId &&
            consumed.remaining < consumed.quantity,
        );
        if (isRefunded || isConsumed) {
          selectState[idx] = null;
        }
      },
    );
    return selectState;
  };

  const sortUserPackageBundles = (bundleOrderDetails: BundleOrderDetails) => {
    return bundleOrderDetails.userBundle.sort(
      (a: UserPackageBundleDetails, b: UserPackageBundleDetails) =>
        a.bundleOrderLineItemId - b.bundleOrderLineItemId,
    );
  };

  const mapRefundDescription = (bundleOrderDetails: BundleOrderDetails) => {
    bundleOrderDetails.refunds.forEach(
      (item: RefundOrderLineItem, idx: number) => {
        const lineItem = bundleOrderDetails.lineItems.find(
          lineItem =>
            lineItem.bundleOrderLineItemId === item.bundleOrderLineItemId,
        );
        if (lineItem) {
          bundleOrderDetails.refunds[idx].description = lineItem.description;
        }
      },
    );
    return bundleOrderDetails.refunds;
  };

  const requestRefund = () => {
    const refundUrl = `${prwebApi}/admin/bundleorder/refund`;
    const refundPayload = bundleDetails.lineItems
      .filter((lineItem, idx: number) => selectLineItems[idx])
      .map((lineItem: BundleOrderLineItem) => lineItem.bundleOrderLineItemId);

    axios
      .post(refundUrl, refundPayload)
      .then(response => {
        if (response && response.status === 200) {
          getBundleDetails(bundleIdParam as string);
          setSelectLineItems((prev: (boolean | null)[]) => {
            return prev.map((update: boolean | null) =>
              update ? null : update,
            );
          });
          setPopupContent({
            header: 'Refunded',
            body: `Refunded completed`,
            action: [{ name: 'Ok', level: 'primary' }],
          });
          setTogglePopup(true);
        }
      })
      .catch(error => {
        let errorMsg = 'Something went wrong could not refund bundle order.';
        if (
          'errors' in error.response.data &&
          error.response.data.errors.length > 0
        ) {
          errorMsg = error.response.data.errors[0];
        }
        setPopupContent({
          header: 'Error',
          body: errorMsg,
          action: [{ name: 'Ok', level: 'primary' }],
        });
        setTogglePopup(true);
      });
  };

  const onRefundClick = () => {
    setPopupContent({
      header: 'Refund Bundle Order',
      body: 'Continue with refund?',
      action: [
        { name: 'Cancel', level: 'secondary' },
        { name: 'Confirm', level: 'primary' },
      ],
    });
    setTogglePopup(true);
  };

  const updateDataFieldByValue = (key: string, value?: string) => {
    if (key === 'bundleDetailModal') {
      if (value === 'Cancel' || value === 'Ok') {
        setTogglePopup(false);
      }
      if (value === 'Confirm') {
        requestRefund();
        setTogglePopup(false);
      }
    }
  };

  const onApplyChangesClick = () => {
    const payload = editBundles
      .filter((bundle: UserPackageBundleDetails, index: number) => {
        const currentBundle = bundleDetails.userBundle[index];
        const currentDate = moment(currentBundle.expirationDate);
        const changedDate = moment(bundle.expirationDate);
        return (
          bundle.quantity !== currentBundle.quantity ||
          !changedDate.isSame(currentDate, 'day')
        );
      })
      .map((bundle: UserPackageBundleDetails) => {
        const { userPackageBundleId, expirationDate, quantity } = bundle;
        return { userPackageBundleId, expirationDate, quantity };
      });

    axios
      .patch(`${prwebApi}/admin/bundleorder/userpackagebundles`, payload)
      .then(response => {
        if (response && response.status === 200) {
          setPopupContent({
            header: 'Changes Applied',
            body: `User Package Bundle Changes Applied`,
            action: [{ name: 'Ok', level: 'primary' }],
          });
          getBundleDetails(bundleIdParam as string);
          setTogglePopup(true);
        }
      })
      .catch(error => {
        let errorMsg =
          'Something went wrong applying User Package Bundle Changes.';
        if (
          'errors' in error.response.data &&
          error.response.data.errors.length > 0
        ) {
          errorMsg = error.response.data.errors[0];
        }
        setPopupContent({
          header: 'Error',
          body: errorMsg,
          action: [{ name: 'Ok', level: 'primary' }],
        });
        setTogglePopup(true);
      });
  };

  const onExpirationDateBlur = (idx: number) => (
    selectedDate: moment.Moment,
  ) => {
    const editingBundles = [...editBundles];
    const bundleIdx =
      Number(idx) >= 0 && Number(idx) < editingBundles.length
        ? Number(idx)
        : undefined;
    if (bundleIdx !== undefined) {
      const updatedBundleItem = {
        ...editingBundles[bundleIdx],
        expirationDate: selectedDate
          .utc()
          .endOf('day')
          .toISOString(),
      } as UserPackageBundleDetails;
      editingBundles.splice(bundleIdx, 1, updatedBundleItem);
      setEditBundles(editingBundles);
    }
  };

  const incrementClickHandler = (event: React.MouseEvent) => {
    const bundleIdx = Number(
      (event.target as HTMLElement).getAttribute('data-key'),
    );
    const editingBundles = [...editBundles];
    const updatedBundleItem = {
      ...editingBundles[bundleIdx],
      quantity: editingBundles[bundleIdx].quantity + 1,
      remaining: editingBundles[bundleIdx].remaining + 1,
    } as UserPackageBundleDetails;
    editingBundles.splice(bundleIdx, 1, updatedBundleItem);
    setEditBundles(editingBundles);
  };

  const decrementClickHandler = (event: React.MouseEvent) => {
    const bundleIdx = Number(
      (event.target as HTMLElement).getAttribute('data-key'),
    );
    const editingBundles = [...editBundles];
    if (editingBundles[bundleIdx].remaining > 0) {
      const updatedBundleItem = {
        ...editingBundles[bundleIdx],
        quantity: editingBundles[bundleIdx].quantity - 1,
        remaining: editingBundles[bundleIdx].remaining - 1,
      } as UserPackageBundleDetails;
      editingBundles.splice(bundleIdx, 1, updatedBundleItem);
      setEditBundles(editingBundles);
    }
  };

  const hasMadeChanges = () => {
    return editBundles.some(
      (changes: UserPackageBundleDetails, index: number) => {
        const currentBundle = bundleDetails.userBundle[index];
        const currentDate = moment(currentBundle.expirationDate);
        const changedDate = moment(changes.expirationDate);
        return (
          changes.quantity !== currentBundle.quantity ||
          !changedDate.isSame(currentDate, 'day')
        );
      },
    );
  };

  const checkboxHandler = (event: React.ChangeEvent) => {
    const selectIdx =
      Number((event.target as HTMLButtonElement).getAttribute('data-key')) ??
      NaN;
    if (!isNaN(selectIdx) && selectLineItems[selectIdx] !== null) {
      setSelectLineItems((prev: (boolean | null)[]) => {
        const updateSelection = [...prev];
        updateSelection[selectIdx] = !updateSelection[selectIdx];
        return updateSelection;
      });
    }
  };

  useEffect(() => {
    getBundleDetails(bundleIdParam as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      <Paper className={styles.bundleDetailsContainer}>
        <Button
          id={'details-back-button'}
          level={'tertiary'}
          onClick={goToPreviousPage}
        >
          <div className={styles.backButton}>
            <NavigationBackwardArrow />
            <span>back</span>
          </div>
        </Button>
        {isLoading && <Loader />}
        {!isLoading && bundleDetails && (
          <>
            <div className={styles.rowSpacing}>
              <Typography weight="bold" color="black" size="xl2">
                Bundle Order Id {bundleDetails.bundleOrderId}
              </Typography>
            </div>

            <div className={styles.orderDetailsContainer}>
              <Typography weight="bold" color="black" size="lg">
                Bundle Order Details
              </Typography>
              <div className={styles.rowSpacing}>
                <Typography size="lg">
                  Order Total:{' '}
                  {Number(bundleDetails.total).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </div>
              <table className={styles.orderDetailsTable}>
                <thead>
                  <tr>
                    <td></td>
                    <td>
                      <strong>Line Items</strong>
                    </td>
                    <td>
                      <strong>Quantity</strong>
                    </td>
                    <td>
                      <strong>Amount</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {bundleDetails.lineItems?.length > 0 &&
                    bundleDetails.lineItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {selectLineItems[index] !== null && (
                              <Checkbox
                                data-key={index}
                                checked={selectLineItems[index] ?? false}
                                onChange={checkboxHandler}
                              ></Checkbox>
                            )}
                          </td>
                          <td>{item.description}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {Number(item.amount).toLocaleString('en-US', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 0,
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className={styles.detailsContainer}>
                {selectLineItems.some((item: boolean | null) => item) && (
                  <Button
                    id={'bundle-refund-button'}
                    level="danger"
                    onClick={onRefundClick}
                  >
                    Refund
                  </Button>
                )}
              </div>

              <Typography weight="bold" color="black" size="lg">
                Payment Information
              </Typography>
              <div className={styles.detailsContainer}>
                {bundleDetails.payments.map((payment, index) => {
                  return (
                    <div key={index}>
                      <div>Confirmation Code: {payment.confirmationCode}</div>
                      <div>
                        Amount Paid{' '}
                        {Number(payment.amount).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </div>
                      <div>
                        Paid with {payment.description} on{' '}
                        {moment
                          .utc(payment.createdDate)
                          .format('M/D/YYYY HH:mm:ss')}{' '}
                        UTC
                      </div>
                    </div>
                  );
                })}
              </div>

              {Array.isArray(bundleDetails.refunds) &&
                bundleDetails.refunds.length > 0 && (
                  <>
                    <Typography weight="bold" color="black" size="lg">
                      Bundle Refunds
                    </Typography>
                    <table className={styles.orderDetailsTable}>
                      <thead>
                        <tr>
                          <td>
                            <strong>Line Items</strong>
                          </td>
                          <td>
                            <strong>Amount</strong>
                          </td>
                          <td>
                            <strong>Refund Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {bundleDetails.refunds.map(
                          (refunds: RefundOrderLineItem, idx: number) => {
                            return (
                              <tr key={idx}>
                                <td>{refunds.description}</td>
                                <td>
                                  {Number(refunds.amount).toLocaleString(
                                    'en-US',
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 0,
                                      style: 'currency',
                                      currency: 'USD',
                                    },
                                  )}
                                </td>
                                <td>
                                  {moment
                                    .utc(refunds.createdDate)
                                    .format('M/D/YYYY HH:mm:ss')}{' '}
                                  UTC
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          </>
        )}
      </Paper>

      {bundleDetails &&
        bundleDetails?.refunds?.length < bundleDetails?.userBundle?.length && (
          <Paper className={styles.bundleDetailsContainer}>
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <Typography weight="bold" color="black" size="xl">
                  User Package Bundles
                </Typography>
                <table className={styles.lineItemTable}>
                  <thead>
                    <tr>
                      <td>Package</td>
                      <td>Remaining</td>
                      <td>Quantity</td>
                      <td>Expiration Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {editBundles?.length > 0 &&
                      editBundles.map((item, index) => {
                        return (
                          <>
                            {item.remaining > 0 && (
                              <tr key={index}>
                                <td>{item.description}</td>
                                <td>{item.remaining}</td>
                                <td>
                                  <div className={styles.quantityControl}>
                                    <MinusSvg
                                      data-key={index}
                                      className={styles.minus}
                                      onClick={decrementClickHandler}
                                    />
                                    <Typography color="black">
                                      {item.quantity}
                                    </Typography>
                                    <PlusSvg
                                      data-key={index}
                                      className={styles.plus}
                                      onClick={incrementClickHandler}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className={styles.dateInputContainer}>
                                    <DatePicker
                                      id={index.toString()}
                                      date={moment.utc(item.expirationDate)}
                                      onChange={onExpirationDateBlur(index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}

            {hasMadeChanges() && (
              <Button
                id={'bundle-applychanges-button'}
                level="primary"
                onClick={onApplyChangesClick}
              >
                Apply Changes
              </Button>
            )}
          </Paper>
        )}

      <PopupModal
        id={'bundleDetailModal'}
        isOpen={togglePopup}
        content={popupContent}
        onActionClick={updateDataFieldByValue}
      ></PopupModal>
      <Footer />
    </Responsive.Container>
  );
};

export default BundleDetails;
