import React, { useState, useEffect } from 'react';

import { Input, ExpansionPanel, Tooltip } from '@cision/rover-ui';

import { ReactComponent as DownArrow } from '../../assets/angle-down-solid.svg';
import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import FieldValidationIssue from '../field-validation-issue';

import styles from './dropdownfield.module.css';

export enum PanelMapperAttribute {
  PanelId = 'panelId',
  PanelContent = 'panelContent',
  PanelData = 'panelData',
}

interface DropdownPanelProps {
  id: string;
  panelList?: [];
  panelMapper: (key: string) => string;
  panelClick?: (key: string, value?: string) => void;
  setHighlightIndex?: (idx: number) => void;
  highlightIndex?: number;
}

const getStyles = (styleList: string[]) => {
  if (styleList.some(text => text === 'centered')) {
    return styles.centered;
  }
};

const DropdownPanel: React.FC<DropdownPanelProps> = ({
  id,
  panelList,
  panelMapper,
  panelClick,
  setHighlightIndex,
  highlightIndex = undefined,
}: DropdownPanelProps) => {
  const handlePanelClick = (event: React.MouseEvent) => {
    const panelData = (event.target as HTMLInputElement).getAttribute(
      'data-key',
    );
    if (panelClick) {
      panelClick(id, panelData || undefined);
    }
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    const panelData = (event.target as HTMLInputElement).getAttribute(
      'data-key',
    );
    if (
      setHighlightIndex &&
      panelData !== undefined &&
      !isNaN(Number(panelData))
    ) {
      setHighlightIndex(Number(panelData));
    }
  };

  return (
    <div className={styles.dropdownContainer}>
      <ExpansionPanel>
        <ExpansionPanel.Header className={styles.panelStyle}>
          {panelList &&
            panelList.map((item, index) => {
              return (
                <div
                  key={item[panelMapper(PanelMapperAttribute.PanelId)]}
                  id={id + index}
                  className={
                    highlightIndex !== undefined &&
                    highlightIndex > -1 &&
                    highlightIndex < panelList.length &&
                    highlightIndex === index
                      ? `${styles.dropdownBtn} ${styles.highlighted}`
                      : styles.dropdownBtn
                  }
                  data-key={index}
                  onClick={handlePanelClick}
                  onMouseOver={handleMouseOver}
                >
                  {item[panelMapper(PanelMapperAttribute.PanelContent)]}
                </div>
              );
            })}
        </ExpansionPanel.Header>
      </ExpansionPanel>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
  modifiers?: string[];
  id: string;
  label?: string;
  canOpen?: boolean;
  canEdit?: boolean;
  disabled?: boolean;
  value?: string;
  // eslint-disable-next-line
  dropdownList: Array<any>;
  placeholder?: string;
  issues: string[];
  validate?: (value?: string) => Array<string>;
  onChangeValue: (key: string, value?: string) => void;
  autoComplete?: boolean;
  tooltip?: string;
}

const DropdownField: React.FC<Props> = ({
  children,
  modifiers = [],
  id,
  label = '',
  canOpen = true,
  canEdit = true,
  disabled = false,
  value,
  dropdownList,
  issues,
  placeholder = '',
  validate,
  onChangeValue,
  autoComplete = false,
  tooltip,
}: Props) => {
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [isFocusedEvent, setFocusedEvent] = useState<boolean>(false);
  const [currentIssues, updateCurrentIssues] = useState<string[]>(issues);
  const [currentValue, updateCurrentValue] = useState<string | undefined>(
    value,
  );

  const childWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        panelList: dropdownList,
        panelClick: onChangeValue,
        highlightIndex,
        setHighlightIndex,
      });
    }
    return child;
  });

  const changeHandler = (e: React.FormEvent): void => {
    if (!canEdit) return;
    const newValue = (e.target as HTMLInputElement).value;
    updateCurrentValue(newValue);
    if (newValue !== currentValue) {
      if (validate !== undefined) {
        const newIssues = validate(newValue);
        updateCurrentIssues(newIssues);
      }
      if (onChangeValue !== undefined) {
        onChangeValue(id, newValue);
      }
    }
  };

  const keyDownHandler = (e: React.KeyboardEvent): void => {
    if (canOpen && isFocusedEvent && dropdownList.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setHighlightIndex(prevIndex =>
          prevIndex < dropdownList.length - 1 ? highlightIndex + 1 : prevIndex,
        );
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setHighlightIndex(prevIndex =>
          prevIndex === 0 ? prevIndex : highlightIndex - 1,
        );
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        if (-1 < highlightIndex && highlightIndex < dropdownList.length) {
          onChangeValue(id, dropdownList[highlightIndex].email);
        }
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        setHighlightIndex(prevIndex =>
          prevIndex < dropdownList.length - 1 ? highlightIndex + 1 : prevIndex,
        );
      }
      if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        setHighlightIndex(prevIndex =>
          prevIndex === 0 ? prevIndex : highlightIndex - 1,
        );
      }
    }
  };

  const onBlurHandler = () => setTimeout(() => setFocusedEvent(false), 500);
  const onFocusHandler = () => setFocusedEvent(true);

  useEffect(() => {
    updateCurrentIssues(issues);
  }, [issues]);

  useEffect(() => {
    updateCurrentValue(value);
  }, [value]);

  useEffect(() => {
    setHighlightIndex(-1);
  }, [dropdownList]);

  return (
    <div
      id={`${id}-dropdown_field_component_container`}
      className={getStyles(modifiers)}
    >
      {label && (
        <label htmlFor={id}>
          <div style={{ marginBottom: '6px' }}>
            {label}&nbsp;
            {tooltip && (
              <Tooltip
                style={{ verticalAlign: 'middle' }}
                tooltipWidth="300px"
                direction="right"
                content={tooltip}
                showOnHover
              >
                <InfoSvg className={styles.icon} />
              </Tooltip>
            )}
          </div>
        </label>
      )}
      <Input
        className={
          canEdit
            ? styles.fullWidth
            : `${styles.fullWidth} ${styles.dropdownSelect}`
        }
        id={id}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onKeyDown={keyDownHandler}
        value={currentValue}
        disabled={disabled}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
      {!canEdit && <DownArrow className={styles.arrowIcon} />}
      <div
        className={
          canOpen && isFocusedEvent
            ? styles.dropdownPanelContainer
            : `${styles.dropdownDisplay} ${styles.dropdownPanelContainer}`
        }
      >
        {childWithProps}
      </div>
      {currentIssues && currentIssues.length > 0 && (
        <div className={getStyles(modifiers)}>
          <FieldValidationIssue issues={currentIssues} id={id} />
        </div>
      )}
    </div>
  );
};

export { DropdownPanel };
export default DropdownField;
