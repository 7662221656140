import React, { useEffect } from 'react';

import { Loader, Paper, Typography } from '@cision/rover-ui';
import axios from 'axios';

import { useParams } from 'react-router-dom';

interface Props {
  config: PRWebConfig;
}

const Verified: React.FC<Props> = (props: Props) => {
  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const [isVerified, setIsVerified] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [message, setMessage] = React.useState('');

  const { encryptedToken } = useParams();

  useEffect(() => {
    axios
      .post(`${PRWEB_API_BASE_URL}/verified/${encryptedToken}`)
      .then(response => {
        if (response.status === 200) {
          setIsVerified(true);
        }
      })
      .catch(e => {
        setMessage('unable to verify ' + e.message);
      });

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      {!isLoading && (
        <div>
          {isVerified ? (
            <div>
              <Typography size="xl2">Your email has been verified</Typography>
            </div>
          ) : (
            <div>{message}</div>
          )}
        </div>
      )}
      {isLoading ? <Loader /> : null}
    </Paper>
  );
};

export default Verified;
