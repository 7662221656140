import React, { useEffect } from 'react';

import moment from 'moment-timezone';

import { ReactComponent as MinusSvg } from '../../assets/minus.svg';
import { ReactComponent as PlusSvg } from '../../assets/plus.svg';
import WizardContext from '../../components/wizard/wizard-context';
import PRWebPackage from '../distribution-wizard/PRWebPackages';

import BundleSummary from './BundleSummary';

import './store-checkout-steps.scss';
import styles from './store-checkout-steps.module.css';

interface Props {
  config: PRWebConfig;
  storeCart: BundlePackagePrice[];
  cartChangeHandler: (bundleId: number, type: string) => void;
}

const StoreCheckoutStep = ({ config, storeCart, cartChangeHandler }: Props) => {
  const wizardData = React.useContext(WizardContext);

  const [cartObject, setCartObject] = React.useState({} as ShoppingCartObject);

  const incrementClickHandler = (event: React.MouseEvent) => {
    const buttonData = Number(
      (event.target as HTMLElement).getAttribute('data-key'),
    );
    cartChangeHandler(buttonData, 'increment');
  };
  const decrementClickHandler = (event: React.MouseEvent) => {
    const buttonData = Number(
      (event.target as HTMLElement).getAttribute('data-key'),
    );
    cartChangeHandler(buttonData, 'decrement');
  };

  const getTableRows = (item: ShoppingCartItem, key: number) => {
    const prwebPackage = PRWebPackage.getPRWebPackage(
      item.packageId.toString(),
    );
    return (
      <tr key={key}>
        <td style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={styles.quantityControl}>
            <MinusSvg
              data-key={item.bundlePackagePriceId}
              className={styles.minus}
              onClick={decrementClickHandler}
            />
            <div color="dark">{item.itemQuantity}</div>
            <PlusSvg
              data-key={item.bundlePackagePriceId}
              className={styles.plus}
              onClick={incrementClickHandler}
            />
          </div>
        </td>
        <td>
          <div color="dark">
            {prwebPackage?.name} Release x{item.quantity}
          </div>
          <div className={styles.italic}>
            expires{' '}
            {moment
              .utc()
              .add(1, 'year')
              .add(1, 'day')
              .endOf('day')
              .format('M/D/YYYY')}{' '}
            (end of day UTC)
          </div>
        </td>
        <td color="dark">
          {Number(prwebPackage?.price).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'currency',
            currency: 'USD',
          })}{' '}
          per release
        </td>
        <td>
          <div color="dark">
            {Number(item.itemSubtotal).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'USD',
            })}
          </div>
          {prwebPackage && (
            <div className={styles.strikethrough}>
              {Number(
                prwebPackage.price * item.quantity * item.itemQuantity,
              ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          )}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    wizardData.setProgressWhileEditingState({
      ...wizardData.progressWhileEditingState,
      position: 0,
    });
    wizardData.setStepIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeCart.length > 0) {
      wizardData.setStepIsValid(true);
    } else {
      wizardData.setStepIsValid(false);
    }
    const cart = {} as ShoppingCartObject;
    storeCart.forEach(bundle => {
      if (bundle.bundlePackagePriceId in cart) {
        cart[bundle.bundlePackagePriceId].itemQuantity += 1;
        cart[bundle.bundlePackagePriceId].itemSubtotal += Number(bundle.amount);
      } else {
        cart[bundle.bundlePackagePriceId] = {
          ...bundle,
          itemQuantity: 1,
          itemSubtotal: Number(bundle.amount),
        } as ShoppingCartItem;
      }
    });
    setCartObject(cart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCart]);

  return (
    <div className={styles.checkoutContainer}>
      <div className={styles.cartContainer}>
        <table>
          <thead>
            <tr>
              <th>QUANTITY</th>
              <th>ITEM</th>
              <th> </th>
              <th>SUBTOTAL</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(cartObject).length > 0 ? (
              Object.values(cartObject).map((bundle, index) => {
                return getTableRows(bundle, index);
              })
            ) : (
              <tr>
                <td></td>
                <td>Nothing in cart</td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div style={{ width: '700px' }}>
        <BundleSummary storeCart={storeCart}></BundleSummary>
      </div>
    </div>
  );
};

export default StoreCheckoutStep;
