import React, { useState, useEffect } from 'react';

import { Typography, Button } from '@cision/rover-ui';
import axios from 'axios';
import qs from 'qs';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ReactComponent as DownArrow } from '../../assets/angle-down-solid.svg';
import { ReactComponent as UpArrow } from '../../assets/angle-up-solid.svg';
import InputWithButton from '../../components/input-with-button';
import { USER_GROUP } from '../../constants';

import styles from './users.module.css';

interface PRWebUser {
  id: number;
  oktaLogin: string;
  email: string;
  showLegacyLoginLink: boolean;
  group: string;
}

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

const UserList: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const args = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const prwebApi = props.config.prwebApi.url;
  const searchFieldQueryParam = args?.search ? args.search : '';
  const limit = args.perPage ? args.perPage : 25;

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState('email');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [UserList, setUserList] = useState([] as PRWebUser[]);
  const [validationMessage, setValidationMessage] = useState('');
  const [searchField, setSearchField] = useState(searchFieldQueryParam);

  const getSearchResults = async (): Promise<void> => {
    await searchUsers(1, sortBy, sortDirection, searchField);
  };

  const searchUsers = async (
    pageNumber: number,
    sortBy: string,
    sortDirection: string,
    searchToken?: string,
  ) => {
    const userSearchUrl = `${prwebApi}/admin/users/search`;
    const userParams = {
      params: {
        userSearchToken: searchToken?.trim() || '',
        pageNumber: pageNumber.toString(),
        limit: limit.toString(),
        sortBy,
        sortDirection,
      },
    };
    history.push({
      pathname: '/admin/users',
      search: `?search=${searchToken}`,
    });
    await axios
      .get(userSearchUrl, userParams)
      .then(response => {
        if (response && response.status >= 200 && response.status < 300) {
          setUserList(response.data.users);
          setTotalRecords(response.data.rowCount);
          setCurrentPageIndex(pageNumber);
          setSortBy(sortBy);
          setSortDirection(sortDirection);
        }
      })
      .catch(error => {
        if ('message' in error) {
          setValidationMessage(error.message);
        }
        console.error('Error retrieving data: ', error);
      });
  };

  const getNextUsers = async (): Promise<void> => {
    await searchUsers(currentPageIndex + 1, sortBy, sortDirection, searchField);
  };

  const getPreviousUsers = async (): Promise<void> => {
    if (currentPageIndex > 1) {
      await searchUsers(
        currentPageIndex - 1,
        sortBy,
        sortDirection,
        searchField,
      );
    }
  };

  const Next = () => {
    const showNextButton = currentPageIndex * limit < totalRecords;
    return (
      <div>
        {showNextButton && (
          <Button level="primary" onClick={getNextUsers}>
            Next
          </Button>
        )}
      </div>
    );
  };

  const Previous = () => {
    const showPrevButton = currentPageIndex > 1;
    return (
      <div>
        {showPrevButton && (
          <Button level="primary" onClick={getPreviousUsers}>
            Previous
          </Button>
        )}
      </div>
    );
  };

  const showProfile = (userId: number) => () => history.push(`users/${userId}`);

  const sortCoupons = (sortBy: string, sortDirection: string) => async () => {
    await searchUsers(1, sortBy, sortDirection, searchField);
  };

  const getSortColumn = (localSortBy: string, columnLabel: string) => {
    let localSortDirection = 'ASC';
    if (localSortBy === sortBy) {
      if (sortDirection === 'ASC') localSortDirection = 'DESC';
    }

    return (
      <label
        onClick={sortCoupons(localSortBy, localSortDirection)}
        className={styles.mousePointer}
      >
        {columnLabel}
        {localSortBy === sortBy && (
          <>
            {sortDirection === 'ASC' ? (
              <UpArrow
                onClick={sortCoupons(localSortBy, localSortDirection)}
                className={styles.arrowIcon}
              />
            ) : (
              <DownArrow
                onClick={sortCoupons(localSortBy, localSortDirection)}
                className={styles.arrowIcon}
              />
            )}
          </>
        )}
      </label>
    );
  };

  useEffect(() => {
    if (searchFieldQueryParam !== '') {
      setSearchField(searchFieldQueryParam);
      searchUsers(1, sortBy, sortDirection, searchFieldQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ minWidth: '625px' }}>
        <InputWithButton
          id={'userSearchField'}
          buttonId={'userSearchButton'}
          initialValue={searchField}
          onChangeValue={setSearchField}
          onSubmitHandler={getSearchResults}
          issues={[]}
        />

        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th>{getSortColumn('oktaLogin', 'Login')}</th>
              <th>{getSortColumn('email', 'Email')}</th>
              <th>{getSortColumn('group', 'Group')}</th>
            </tr>
          </thead>
          <tbody>
            {UserList.map(user => (
              <tr key={user.id}>
                <td>
                  <Button onClick={showProfile(user.id)} level="link">
                    {user.oktaLogin}
                  </Button>
                </td>
                <td>{user.email}</td>
                <td>
                  <span className={styles.groupText}>
                    {user?.group || USER_GROUP}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.navigationContainer}>
          <Previous />
          <Next />
        </div>
        {validationMessage && (
          <div className={styles.errorMessage}>{validationMessage}</div>
        )}
      </div>
    </div>
  );
};

const Users: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div>
        <Typography color="black" weight="bold" size="xl" tag="h3">
          Users List&nbsp;
          <Typography color="inherit" size="lg">
            (<a href="/register">Register new user</a>)
          </Typography>
        </Typography>
        <div className={styles.listContainer}>
          <UserList {...props} />
        </div>
      </div>
    </>
  );
};

export default Users;
