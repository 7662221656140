import React, { useEffect, useState } from 'react';

import { Loader } from '@cision/rover-ui';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import axios from 'axios';

import WizardContext from '../../components/wizard/wizard-context';

import BundleSummary from './BundleSummary';
import styles from './store-payment-steps.module.css';

interface Props {
  children: React.ReactNode;
  config: PRWebConfig;
  stripePayment: { intentId: string; clientSecret: string };
  setStripePayment: React.Dispatch<
    React.SetStateAction<{ intentId: string; clientSecret: string }>
  >;
  storeCart: BundlePackagePrice[];
  stripePromise: Promise<Stripe | null>;
}

const StorePaymentStripeStep = ({
  children,
  config,
  stripePayment,
  setStripePayment,
  storeCart,
  stripePromise,
}: Props) => {
  const wizardData = React.useContext(WizardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedTermsAndCond, setCheckedTermsAndCond] = useState(false);
  const [checkedSavePayment, setCheckedSavePayment] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const clickedSavePayment = () => {
    setCheckedSavePayment((prev: boolean) => !prev);
  };

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children, {
        children: (
          <>
            <input
              type="checkbox"
              id="savePayment"
              checked={checkedSavePayment}
              onClick={clickedSavePayment}
            />
            <label htmlFor="savePayment">
              Save payment information for future purchases.
            </label>
          </>
        ),
      })
    : children;

  const handleTermsAndConditions = () => {
    setCheckedTermsAndCond((prev: boolean) => {
      wizardData.setStepIsValid(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    wizardData.setStepIsValid(checkedTermsAndCond);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTermsAndCond]);

  useEffect(() => {
    const getPaymentIntent = async (paymentIntent: string) => {
      let getPaymentIntentUrl = `${config.prwebApi.url}/bundleorder/getPaymentIntent`;
      if (paymentIntent) getPaymentIntentUrl += `/${paymentIntent}`;
      await axios
        .post(getPaymentIntentUrl, {
          cart: storeCart,
          savePayment: checkedSavePayment,
        })
        .then((response: any) => {
          setStripePayment({
            intentId: response.data.intentId,
            clientSecret: response.data.clientSecret,
          });
        })
        .catch((error: any) => {
          if (error?.response?.status === 401) {
            setValidationMsg('Your Session has expired, please login again.');
          }
          setIsLoading(false);
        });
    };
    getPaymentIntent(stripePayment?.intentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedSavePayment]);

  useEffect(() => {
    wizardData.setProgressWhileEditingState({
      ...wizardData.progressWhileEditingState,
      position: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.storePaymentStepContainer}>
        <div className={styles.storePaymentForm}>
          {stripePayment?.clientSecret && (
            <Elements
              options={{
                clientSecret: stripePayment.clientSecret,
                appearance: { theme: 'stripe' },
              }}
              stripe={stripePromise}
            >
              {childrenWithProps}
            </Elements>
          )}
          {validationMsg && (
            <div className={styles.errorMessage}>{validationMsg}</div>
          )}
        </div>
        <div className={styles.storePaymentSummary}>
          <BundleSummary storeCart={storeCart}></BundleSummary>
          <div className={styles.termsAndConditions}>
            <input
              id={'termsAndConditionsCheckbox'}
              type="checkbox"
              checked={checkedTermsAndCond}
              onClick={handleTermsAndConditions}
            />
            <label htmlFor="termsAndConditionsCheckbox">
              I acknowledge that I have read and accepted the PRWeb{' '}
              <a
                href={'https://service.prweb.com/legal/terms-of-service/'}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://service.prweb.com/legal/privacy-policy/'}
                rel="noopener noreferrer"
                target={'_blank'}
              >
                Privacy Notice
              </a>
              .
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorePaymentStripeStep;
