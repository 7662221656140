import React, { FC, useState, useEffect } from 'react';

import { Typography, Tooltip } from '@cision/rover-ui';

import { ReactComponent as InfoSvg } from '../../../../assets/info.svg';
import SelectMenuField from '../../../../components/select-menu-field';
import TextInputField from '../../../../components/text-input-field';
import SubmitDistributionValidator, {
  PrivateContactValidation,
} from '../../../../validators/SubmitDistributionValidator';
import { COUNTRY_CODES } from '../contact-panel/countryCodes';

import styles from './private-contact-panel.module.css';
import './private-contact-panel.scss';

interface Props {
  privateContact: PrivateContact | null;
  validation: PrivateContactValidation | null;
  onChangeFieldValue?: (key: string, value?: string) => void;
}

const PrivateContactPanel: FC<Props> = ({
  privateContact,
  validation,
  onChangeFieldValue,
}: Props) => {
  const formFieldData = privateContact;

  const [
    formValidation,
    setFormValidation,
  ] = useState<PrivateContactValidation | null>(validation);

  const formFieldChangeHandler = (key: string, value?: string) => {
    onChangeFieldValue && onChangeFieldValue(key, value);
  };

  useEffect(() => setFormValidation(validation), [validation]);
  return (
    <div id={'private-contact-panel'}>
      <div>
        <Typography size="md" weight="bold">
          Private Contact for Editorial
        </Typography>
        &nbsp;
        <Tooltip
          showOnHover
          direction="right"
          tooltipWidth="250px"
          content={
            'Editorial will use this contact information for questions and correspondence about the release. It will not be posted publicly.'
          }
        >
          <InfoSvg className={styles.icon} />
        </Tooltip>
      </div>

      <div>
        <div>
          <TextInputField
            id={'user'}
            value={formFieldData?.user}
            issues={formValidation?.userErrors || []}
            label={'Name'}
            validate={SubmitDistributionValidator.validateContactName}
            onChangeValue={formFieldChangeHandler}
          />
        </div>

        <div>
          <TextInputField
            id={'userEmail'}
            value={formFieldData?.userEmail}
            issues={formValidation?.userEmailErrors || []}
            label={'Email'}
            onChangeValue={formFieldChangeHandler}
            validate={SubmitDistributionValidator.validateContactEmail}
          />
        </div>

        <div>
          <SelectMenuField
            id={'userPhoneCountryCode'}
            className={styles.countryCodeMenu}
            label={'Phone Number'}
            placeholder={'Country Code'}
            value={formFieldData?.userPhoneCountryCode}
            issues={formValidation?.userPhoneCountryCodeErrors || []}
            options={COUNTRY_CODES}
            onChangeValue={formFieldChangeHandler}
            validate={
              SubmitDistributionValidator.validateContactPhoneCountryCode
            }
          />
        </div>

        <div>
          <TextInputField
            id={'userPhone'}
            placeholder={'Number'}
            value={formFieldData?.userPhone}
            issues={formValidation?.userPhoneErrors || []}
            onChangeValue={formFieldChangeHandler}
            validate={SubmitDistributionValidator.validateContactPhone}
          />
        </div>

        <div>
          <TextInputField
            id={'userPhoneExtension'}
            value={formFieldData?.userPhoneExtension}
            issues={formValidation?.userPhoneExtensionErrors || []}
            placeholder={'Ext.'}
            onChangeValue={formFieldChangeHandler}
            validate={SubmitDistributionValidator.validateContactPhoneExtension}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivateContactPanel;
