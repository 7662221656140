import React from 'react';

import { Responsive, Typography } from '@cision/rover-ui';
import cookie from 'js-cookie';
import {
  useParams,
  NavLink,
  RouteComponentProps,
  Route,
  Switch,
} from 'react-router-dom';

import Footer from '../../components/footer';
import Header from '../../components/header';
import ViewTitleBar from '../../components/view-title-bar/ViewTitleBar';
import {
  USER_GROUP_COOKIE,
  ADMIN_GROUP,
  FINANCE_GROUP,
  VPO_GROUP,
  EDITOR_GROUP,
} from '../../constants';
import PartnerSplash from '../account/PartnerSplash';

import styles from './admin.module.css';
import Bundles from './Bundles';
import Coupons from './Coupons';
import Finance from './Finance';
import Orders from './Orders';
import Users from './Users';

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

const Admin: React.FC<Props> = (props: Props) => {
  const userGroup = cookie.get(USER_GROUP_COOKIE);
  const USERS_PAGE = 'USERS';
  const COUPONS_PAGE = 'COUPONS';
  const BUNDLES_PAGE = 'BUNDLES';
  const ORDERS_PAGE = 'ORDERS';
  const FINANCE_PAGE = 'FINANCE';
  const PARTNER_PAGE = 'PARTNERS';

  const { page } = useParams();

  const isUsersPage = page && page.toUpperCase() === USERS_PAGE;
  const isCouponsPage = page && page.toUpperCase() === COUPONS_PAGE;
  const isBundlesPage = page && page.toUpperCase() === BUNDLES_PAGE;
  const isOrdersPage = page && page.toUpperCase() === ORDERS_PAGE;
  const isFinancePage = page && page.toUpperCase() === FINANCE_PAGE;
  const isPartnerPage = page && page.toUpperCase() === PARTNER_PAGE;

  const isEditor = userGroup === ADMIN_GROUP || userGroup === EDITOR_GROUP;
  const isVPO =
    userGroup === ADMIN_GROUP ||
    userGroup === EDITOR_GROUP ||
    userGroup === VPO_GROUP;
  const isFinance =
    userGroup === ADMIN_GROUP ||
    userGroup === EDITOR_GROUP ||
    userGroup === FINANCE_GROUP;

  return (
    <Responsive.Container>
      <Header prwebApiUrl={props.config.prwebApi.url} />
      <ViewTitleBar title={'Admin'} />
      <div className={styles.columnContainer}>
        <div className={styles.leftPageColumn}>
          {isEditor && (
            <div className={isUsersPage ? styles.selected : ''}>
              <Typography weight={isUsersPage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/users'}>
                  Users
                </NavLink>
              </Typography>
            </div>
          )}
          {isEditor && (
            <div className={isCouponsPage ? styles.selected : ''}>
              <Typography weight={isCouponsPage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/coupons'}>
                  Coupons
                </NavLink>
              </Typography>
            </div>
          )}
          {isEditor && (
            <div className={isBundlesPage ? styles.selected : ''}>
              <Typography weight={isBundlesPage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/bundles'}>
                  Bundles
                </NavLink>
              </Typography>
            </div>
          )}
          {isFinance && (
            <div className={isOrdersPage ? styles.selected : ''}>
              <Typography weight={isOrdersPage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/orders'}>
                  Orders
                </NavLink>
              </Typography>
            </div>
          )}
          {isFinance && (
            <div className={isFinancePage ? styles.selected : ''}>
              <Typography weight={isFinancePage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/finance'}>
                  Finance
                </NavLink>
              </Typography>
            </div>
          )}
          {isVPO && (
            <div className={isPartnerPage ? styles.selected : ''}>
              <Typography weight={isPartnerPage ? 'bold' : 'normal'}>
                <NavLink className={styles.links} to={'/admin/partners'}>
                  Partners
                </NavLink>
              </Typography>
            </div>
          )}
        </div>
        <Switch>
          <Route exact path="/admin/users">
            <Users {...props} config={props.config}></Users>
          </Route>
          <Route exact path="/admin/coupons">
            <Coupons {...props} config={props.config}></Coupons>
          </Route>
          <Route exact path="/admin/orders">
            <Orders {...props} config={props.config}></Orders>
          </Route>
          <Route exact path="/admin/bundles">
            <Bundles {...props} config={props.config}></Bundles>
          </Route>
          <Route exact path="/admin/finance">
            <Finance {...props} config={props.config}></Finance>
          </Route>
          <Route exact path="/admin/partners">
            <PartnerSplash showEditControl={true} config={props.config} />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Responsive.Container>
  );
};

export default Admin;
