import { Quill } from 'react-quill';

function sanitize(url, protocols) {
  const anchor = document.createElement('a');
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}

const Link = Quill.import('formats/link');

// easy extension / override of Quill's Link class
class PRWLink extends Link {
  static create(link) {
    if (!link) return;
    const node = super.create(link.href);
    node.setAttribute('href', this.sanitize(link.href));
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    node.setAttribute('title', link.title);
    return node;
  }

  static formats(domNode) {
    const formats = {
      href: domNode.getAttribute('href'),
      title: domNode.getAttribute('title'),
    };
    return formats;
  }
  static value(domNode) {
    const val = { href: domNode.getAttribute('href') };
    if (domNode.hasAttribute('title')) {
      val.title = domNode.getAttribute('title');
    }

    return val;
  }

  static sanitize(url) {
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }

  format(name, value) {
    if (value) {
      super.format(name, value.href);
      if (value.title) {
        this.domNode.setAttribute('title', value.title);
      } else {
        this.domNode.removeAttribute('title');
      }
    } else {
      super.format(name, value);
    }
  }
}
PRWLink.blotName = 'link';
PRWLink.tagName = 'A';
PRWLink.SANITIZED_URL = 'about:blank';
PRWLink.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];
// console.trace(Quill.imports);

export { PRWLink as default, sanitize };
