import { PRWEB_PARTNER } from '../constants';

import CacheService from './cache-service';

const PRWebPartner = {
  getPRWebPartner: (): PRWebPartner => {
    if (CacheService.exists(PRWEB_PARTNER)) {
      return CacheService.get(PRWEB_PARTNER) as PRWebPartner;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  },
};

export default PRWebPartner;
