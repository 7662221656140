import React from 'react';

import { Paper, Typography } from '@cision/rover-ui';

import { ReactComponent as CheckmarkSvg } from '../../assets/checkmark.svg';
import {
  BASIC_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
} from '../../constants';
import PRWebPackage from '../../pages/distribution-wizard/PRWebPackages';

import styles from './package-card.module.css';

interface FeatureProps {
  feature: string;
  description?: string;
}

const PackageFeature = (props: FeatureProps): JSX.Element => {
  return (
    <div className={styles.packageFeature}>
      <div>
        <CheckmarkSvg className={styles.checkmark} width={16} height={16} />
      </div>
      <div>
        <Typography color="black" weight="bold">
          {props.feature}
          {props.description && (
            <div>
              <Typography color="primary">{props.description}</Typography>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
};

interface PackageCardProps {
  children?: {
    basic?: React.ReactNode;
    standard?: React.ReactNode;
    advanced: React.ReactNode;
    premium: React.ReactNode;
  };
  showBasic?: boolean;
  showStandard?: boolean;
}

const PackageCard = ({
  children,
  showBasic = true,
  showStandard = true,
}: PackageCardProps) => {
  const basicPkg = PRWebPackage.getPRWebPackage(
    BASIC_PACKAGE_ID,
  ) as PRWebPackage;
  const standardPkg = PRWebPackage.getPRWebPackage(
    STANDARD_PACKAGE_ID,
  ) as PRWebPackage;
  const advancedPkg = PRWebPackage.getPRWebPackage(
    ADVANCED_PACKAGE_ID,
  ) as PRWebPackage;
  const premiumPkg = PRWebPackage.getPRWebPackage(
    PREMIUM_PACKAGE_ID,
  ) as PRWebPackage;

  return (
    <>
      {showBasic && basicPkg && (
        <Paper className={styles.packageContainer}>
          <div className={styles.packageLabel}>
            <Typography color="black" weight="bold" size="xl2">
              Basic
            </Typography>
            <Typography color="primary" size="md">
              Increased Search Engine Visibility
            </Typography>
            <Typography
              color="black"
              weight="bold"
              style={{ fontSize: '18px' }}
            >
              {Number(basicPkg.price).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'USD',
              })}
              &nbsp;per release
            </Typography>
            {children && children.basic}
          </div>
          <div className={styles.separator}></div>
          <Typography weight="bold" color="black">
            <PackageFeature feature="Unlimited Media Assets" />
            <PackageFeature
              feature="Increased Search Visibility"
              description="Relevant content keyword tagging by our expert editorial team"
            />
            <PackageFeature feature="48-hour turn around time" />
          </Typography>
        </Paper>
      )}
      {showStandard && standardPkg && (
        <Paper className={styles.packageContainer}>
          <div className={styles.packageLabel}>
            <Typography color="black" weight="bold" size="xl2">
              Standard
            </Typography>
            <Typography color="primary" size="md">
              Increased Online Visibility
            </Typography>
            <Typography
              color="black"
              weight="bold"
              style={{ fontSize: '18px' }}
            >
              {Number(standardPkg.price).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'USD',
              })}
              &nbsp;per release
            </Typography>
            {children && children.standard}
          </div>
          <div className={styles.separator}></div>
          <Typography weight="bold" color="black">
            <PackageFeature feature="Unlimited Media Assets" />
            <PackageFeature
              feature="Increased Search Visibility"
              description="Relevant content keyword tagging by our expert editorial team"
            />
            <PackageFeature
              feature="Increased Online Visibility"
              description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
            />
            <PackageFeature feature="48-hour turn around time" />
          </Typography>
        </Paper>
      )}
      <Paper className={styles.packageContainer}>
        <div className={styles.packageLabel}>
          <Typography color="black" weight="bold" size="xl2">
            Advanced
          </Typography>
          <Typography color="primary" size="md">
            Increased Influencer Visibility
          </Typography>
          <Typography color="black" weight="bold" style={{ fontSize: '18px' }}>
            {Number(advancedPkg.price).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'USD',
            })}
            &nbsp;per release
          </Typography>
          {children && children.advanced}
        </div>
        <div className={styles.separator}></div>
        <Typography weight="bold" color="black">
          <PackageFeature feature="Unlimited Media Assets" />
          <PackageFeature
            feature="Increased Search Visibility"
            description="Relevant content keyword tagging by our expert editorial team"
          />
          <PackageFeature
            feature="Increased Online Visibility"
            description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
          />
          <PackageFeature
            feature="Email distribution to ONE of our industry-curated journalist lists"
            description="Proofreading from our expert editorial staff included"
          />
          <PackageFeature feature="24-hour turn around time" />
        </Typography>
      </Paper>
      <Paper className={styles.packageContainer}>
        <div
          className={`${styles.packageLabel} ${styles.premiumPackageColors}`}
        >
          <Typography color="inherit" weight="bold" size="xl2">
            Premium
          </Typography>
          <Typography color="inherit" size="md">
            Largest Influencer Visibility
          </Typography>
          <Typography
            color="inherit"
            weight="bold"
            style={{ fontSize: '18px' }}
          >
            {Number(premiumPkg.price).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'USD',
            })}
            &nbsp;per release
          </Typography>
          {children && children.premium}
        </div>
        <div className={styles.separator}></div>
        <Typography weight="bold" color="black">
          <PackageFeature feature="Unlimited Media Assets" />
          <PackageFeature
            feature="Increased Search Visibility"
            description="Relevant content keyword tagging by our expert editorial team"
          />
          <PackageFeature
            feature="Increased Online Visibility"
            description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
          />
          <PackageFeature
            feature="Email distribution to TWO of our industry-curated journalist lists"
            description="Proofreading from our expert editorial staff included"
          />
          <PackageFeature
            feature="Distribution to Sovrn's content recommended network"
            description="Connects your message with influential bloggers"
          />
          <PackageFeature feature="24-hour turn around time" />
        </Typography>
      </Paper>
    </>
  );
};

export { PackageFeature };
export default PackageCard;
