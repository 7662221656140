import React, { ReactElement } from 'react';

import { Loader, Typography, Kite, Button } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import moment from 'moment-timezone';
import { Route, Switch, useLocation } from 'react-router-dom';

import { ReactComponent as WarningSvg } from './assets/warning.svg';

import Responsive from './components/rover-plus/Responsive';
import WelcomeMessage from './components/welcome-message';
import {
  PRW_SID,
  PRWEB_PACKAGES,
  PRWEB_FEATURES,
  LOGIN_AS,
  PRWEB_PARTNER,
  PARTNER_ID,
  ADMIN_GROUP,
  VPO_GROUP,
  USER_GROUP_COOKIE,
  NOTIFY_SESSION_TIME_IN_SEC,
  NO_REFRESH_TIME_IN_SEC,
  FINANCE_GROUP,
  IS_NG_USER,
  EDITOR_GROUP,
  PRWEB_USER_INFO,
} from './constants';
import OAuthWindow from './oauthWindow/oauth.jsx';
import AuthorizationCode from './pages/account/AuthorizationCode';
// import ForgotPassword from './pages/account/ForgotPassword';
import Logout from './pages/account/Logout';
import PartnerSplash from './pages/account/PartnerSplash';
import Register from './pages/account/Register';
import SendActivation from './pages/account/SendActivation';
import Verified from './pages/account/Verified';
import Admin from './pages/admin/Admin';
import BundleDetails from './pages/admin/BundleDetails';
import CouponDetails from './pages/admin/CouponDetails';
import OrderDetails from './pages/admin/OrderDetails';
import UniversalCouponDetails from './pages/admin/UniversalCouponDetails';
import UserDetails from './pages/admin/UserDetails';
import ArchivedReleases from './pages/ArchivedReleases';
import DistributionRoute from './pages/distribution-wizard/DistributionRoute';
import Distributions from './pages/Distributions';
import NotAuthorized from './pages/NotAuthorized';
import NotFound from './pages/NotFound';
import Publication from './pages/Publication';
import Publications from './pages/Publications';
import PurchaseEditRoute from './pages/purchase-edit-wizard/PurchaseEditRoute';
import RedirectFromLegacy from './pages/RedirectFromLegacy';
import Settings from './pages/settings/Settings';
import Store from './pages/store/Store';
import StoreCheckoutWizard from './pages/store/StoreCheckoutWizard';
import UpgradeRoute from './pages/upgrade-wizard/UpgradeRoute';

import Welcome from './pages/Welcome';
import CacheService from './utils/cache-service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const rg4js: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pendo: any;

interface Props {
  config: PRWebConfig;
}
const LOGOUT_PATH = '/logout';
const FORGOT_PWD_PATH = '/forgot-password';
const VERIFIED_PATH = '/verified';
const AUTH_CODE_PATH = '/authorizationcode';
const REGISTER_PATH = '/register';
const PARTNER_REGISTER_PATH = '/prwebpartner';
const OAUTH_CONFIRM_PATH = '/oauthconfirmcallback';
const REDIRECT_FROM_LEGACY_PATH = '/redirectfromlegacy';
const SEND_ACTIVATION_PATH = '/sendactivation';
const WELCOME_PATH = '/welcome';

const needsSession = () =>
  window.location.pathname !== AUTH_CODE_PATH &&
  window.location.pathname !== LOGOUT_PATH &&
  window.location.pathname !== FORGOT_PWD_PATH &&
  window.location.pathname !== SEND_ACTIVATION_PATH &&
  !window.location.pathname.startsWith(VERIFIED_PATH) &&
  !window.location.pathname.startsWith(REGISTER_PATH) &&
  !window.location.pathname.startsWith(PARTNER_REGISTER_PATH) &&
  !window.location.pathname.startsWith(REDIRECT_FROM_LEGACY_PATH) &&
  !window.location.pathname.startsWith(OAUTH_CONFIRM_PATH) &&
  !window.location.pathname.startsWith(WELCOME_PATH);

const checkSession = async (
  envConfig: PRWebConfig,
  setSessionTimeout: Function,
) => {
  if (!needsSession()) return;
  const prwebApi = envConfig.prwebApi.url;
  const prwebFrontEnd = envConfig.prwebFrontEnd.url;
  const oktaUrl = envConfig.prwebOkta.url;
  const oktaClientId = envConfig.prwebOkta.clientId;

  const token = JSON.parse(cookie.get(PRW_SID) || '{}');

  let redirectUrl = `${oktaUrl}/oauth2/default/v1/authorize?&response_type=code&scope=openid profile email&state=state&redirect_uri=${encodeURI(
    `${prwebFrontEnd}${AUTH_CODE_PATH}`,
  )}&client_id=${oktaClientId}`;

  const isNgUser = cookie.get(`${IS_NG_USER}`);
  if (!isNgUser) {
    redirectUrl = '/welcome';
  }

  const logoutUrl = LOGOUT_PATH;

  if (token.sid) {
    const checkSessionUrl = `${prwebApi}/checksession`;
    return await axios
      .post(checkSessionUrl, {})
      .then(async response => {
        const sessionIsValid = response && response.status === 200;

        if (!sessionIsValid) {
          window.location.replace(redirectUrl);
          return;
        }

        rg4js('setUser', {
          identifier: response.data.oktaLogin,
        });

        await loadCache(envConfig);

        const userInfo: any = CacheService.get(PRWEB_USER_INFO);

        pendo.initialize({
          visitor: {
            id: response.data.oktaLogin, // Required if user is logged in, default creates anonymous ID

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            oktalogin: response.data.oktaLogin,
            createddate: userInfo?.createdDate,
            firstname: userInfo?.firstName,
            lastname: userInfo?.lastName,
            email: userInfo?.email,
            country: userInfo?.country,
            state: userInfo?.state,
            organization: userInfo?.organization,
            partnerid: userInfo?.partnerId,
          },

          account: {
            id: response.data.oktaLogin, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            oktalogin: response.data.oktaLogin,
            createddate: userInfo?.createdDate,
            firstname: userInfo?.firstName,
            lastname: userInfo?.lastName,
            email: userInfo?.email,
            country: userInfo?.country,
            state: userInfo?.state,
            organization: userInfo?.organization,
            partnerid: userInfo?.partnerId,
          },
        });

        const feature: any = await CacheService.get(PRWEB_FEATURES);
        if ('sessionTimeout' in feature) {
          setSessionTimeout((prev: any) => {
            const timer = feature.sessionTimeout.split(',');
            if (timer.length === 2) {
              return {
                warning: Number(timer[0]),
                noRefresh: Number(timer[1]),
              };
            }
            return prev;
          });
        }
        if (response.data.sessionTTL) {
          return response.data.sessionTTL;
        }
      })
      .catch(error => {
        // handle error
        //use react router location object, not window object Seba
        if (error.response && error.response.status === 401) {
          window.location.replace(logoutUrl);
          return;
        }

        console.error(error);
      });
  } else {
    window.location.replace(redirectUrl);
  }
};

const addAxiosInterceptor = (): void => {
  // Add a request interceptor
  axios.interceptors.request.use(config => {
    const prwSidCookie = JSON.parse(cookie.get(PRW_SID) || '{}');
    if (prwSidCookie && prwSidCookie.sid) {
      config.headers.Authorization = `Bearer ${prwSidCookie.sid}`;
    }

    return config;
  });
};
addAxiosInterceptor();

const loadCache = (envConfig: PRWebConfig): Promise<unknown> => {
  const prwebApi = envConfig.prwebApi.url;
  const getPackagesUrl = `${prwebApi}/package`;
  const getFeaturesUrl = `${prwebApi}/features`;
  const getPRWebPartnerUrl = `${prwebApi}/partner`;
  const userInfoUrl = `${prwebApi}/users/me`;

  const promiseArray = [
    loadCacheValue(getPackagesUrl, PRWEB_PACKAGES),
    loadCacheValue(getFeaturesUrl, PRWEB_FEATURES),
    loadCacheValue(userInfoUrl, PRWEB_USER_INFO),
  ];
  const partnerIdCookie = cookie.get(PARTNER_ID);
  if (partnerIdCookie) {
    promiseArray.push(loadCacheValue(getPRWebPartnerUrl, PRWEB_PARTNER));
  }

  return Promise.all(promiseArray);
};

const loadCacheValue = (url: string, cacheName: string): Promise<void> => {
  if (CacheService.exists(cacheName)) {
    return Promise.resolve();
  }

  return axios
    .get(url, {})
    .then(response => {
      if (response && (response.status === 200 || response.status === 201)) {
        CacheService.set(cacheName, response.data);
      } else {
        console.log(
          'invalid response loading cache data: ',
          response,
          cacheName,
        );
      }
    })
    .catch(error => {
      console.log('loadcache error retrieving data: ', error, cacheName);
    });
};

const App: React.FC<Props> = (props: Props) => {
  const [toggleWelcomeMessage, setToggleWelcomeMessage] = React.useState(false);
  const [sessionTTL, setSessionTTL] = React.useState('');
  const [timer, setTimer] = React.useState(new Date(0));
  const [showTimer, setShowTimer] = React.useState(false);
  const [sessionTimeout, setSessionTimeout] = React.useState({
    warning: NOTIFY_SESSION_TIME_IN_SEC,
    noRefresh: NO_REFRESH_TIME_IN_SEC,
  });
  const config = props.config;
  const prwebApi = config.prwebApi.url;
  const location = useLocation();
  const currentPath = location.pathname;
  const oktaForgotPwdLocation = config.prwebOkta.forgotPasswordUrl;

  const onCloseWelcomeMessage = () => {
    const postUserParameterUrl = `${prwebApi}/users/parameter`;
    axios
      .put(`${postUserParameterUrl}`, {
        parameterName: 'WelcomeMessage',
        parameterValue: 'false',
      })
      .then(response => {
        if (response.status !== 200) {
          console.log('invalid response post welcome message: ', response);
        }
      })
      .catch(error => {
        console.log('post welcome message error retrieving data: ', error);
      })
      .finally(() => {
        setToggleWelcomeMessage(false);
      });
  };

  const allowAdmin = () => {
    const adminTabCookie = cookie.get(USER_GROUP_COOKIE);
    return (
      adminTabCookie === ADMIN_GROUP ||
      adminTabCookie === EDITOR_GROUP ||
      adminTabCookie === VPO_GROUP ||
      adminTabCookie === FINANCE_GROUP
    );
  };

  React.useEffect(() => {
    const getWelcomeMessageParameter = async (): Promise<void> => {
      const impersonating = cookie.get(LOGIN_AS) === 'true';

      if (!impersonating) {
        const getUserParametersUrl = `${prwebApi}/users/parameters`;
        axios
          .get(getUserParametersUrl, {})
          .then(response => {
            if (response && response.status === 200) {
              if ('WelcomeMessage' in response.data) {
                setToggleWelcomeMessage(response.data.WelcomeMessage);
              }
            }
          })
          .catch(error => {
            console.log(
              'getWelcomeMessageParameter error retrieving data: ',
              error,
            );
          });
      }
    };

    if (needsSession()) {
      getWelcomeMessageParameter();
    }
  }, [prwebApi]);

  const toggleSessionTimeoutKite = () => {
    setShowTimer(!showTimer);
  };

  const updateSessionTTL = async () => {
    const ttl = await checkSession(config, setSessionTimeout);
    setSessionTTL(ttl);
    if (showTimer) setShowTimer(false);
  };

  React.useEffect(() => {
    updateSessionTTL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, currentPath]);

  React.useEffect(() => {
    let interval: any;
    if (showTimer) {
      interval = setInterval(() => {
        const seconds = moment.utc(sessionTTL).diff(moment.utc(), 'seconds');
        setTimer(new Date(seconds * 1000));
        if (seconds <= 0) {
          setTimer(new Date(0));
          clearInterval(interval);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimer]);

  React.useEffect(() => {
    let interval: any;
    if (needsSession()) {
      interval = setInterval(() => {
        const seconds = moment.utc(sessionTTL).diff(moment.utc(), 'seconds');
        if (seconds <= sessionTimeout.warning) {
          setTimer(new Date(seconds * 1000));
          setShowTimer(true);
          clearInterval(interval);
        }
      }, 60000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTTL]);

  return (
    <Responsive.Container>
      <Kite
        canBeDismissed
        visible={showTimer}
        icon={<WarningSvg width="30px" height="30px" fill="#de4543" />}
        onClose={toggleSessionTimeoutKite}
      >
        <Kite.Header>
          <Typography color="black" size="lg" weight="bold">
            {timer.valueOf() === 0
              ? 'Session expired'
              : `Session timeout in ${timer.toISOString().substring(14, 19)}`}
          </Typography>
        </Kite.Header>
        <Kite.Body>
          {timer.valueOf() > sessionTimeout.noRefresh * 1000 ? (
            <Button level="link" onClick={updateSessionTTL}>
              Extend session
            </Button>
          ) : (
            <span>Please refresh the page</span>
          )}
        </Kite.Body>
      </Kite>

      <Switch>
        <Route exact path="/settings/:page">
          <Settings {...props} config={config} />
        </Route>

        <Route exact path="/store">
          <Store {...props} config={config} />
        </Route>

        <Route exact path="/welcome">
          <Welcome {...props} config={config} />
        </Route>

        <Route exact path="/store/checkout">
          <StoreCheckoutWizard {...props} config={config} />
        </Route>

        <Route
          exact
          path="/admin/:page"
          render={(props): ReactElement =>
            (allowAdmin() && <Admin {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/admin/users/:id"
          render={(): ReactElement =>
            (allowAdmin() && <UserDetails {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/admin/coupons/create"
          render={(): ReactElement =>
            (allowAdmin() && <CouponDetails {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/admin/universalcoupons/create"
          render={(): ReactElement =>
            (allowAdmin() && (
              <UniversalCouponDetails {...props} config={config} />
            )) || <NotAuthorized />
          }
        />

        <Route
          exact
          path="/admin/coupons/edit/:id"
          render={(): ReactElement =>
            (allowAdmin() && <CouponDetails {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/admin/universalcoupons/edit/:id"
          render={(): ReactElement =>
            (allowAdmin() && (
              <UniversalCouponDetails {...props} config={config} />
            )) || <NotAuthorized />
          }
        />

        <Route
          exact
          path="/admin/orders/:id"
          render={(): ReactElement =>
            (allowAdmin() && <OrderDetails {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/admin/bundles/:id"
          render={(): ReactElement =>
            (allowAdmin() && <BundleDetails {...props} config={config} />) || (
              <NotAuthorized />
            )
          }
        />

        <Route
          exact
          path="/authorizationcode"
          render={(props): ReactElement => (
            <AuthorizationCode {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/redirectfromlegacy"
          render={(props): ReactElement => (
            <RedirectFromLegacy {...props} config={config} />
          )}
        ></Route>

        <Route
          exact
          path="/register"
          render={(props): ReactElement => (
            <Register {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/prwebpartner/*/register"
          render={(props): ReactElement => (
            <Register {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/prwebpartner/*"
          render={(props): ReactElement => (
            <PartnerSplash {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/distribution/:id?"
          render={(props): ReactElement => (
            <DistributionRoute {...props} config={config} />
          )}
        />
        <Route
          exact
          path="/distribution/create/:id?"
          render={(props): ReactElement => (
            <DistributionRoute {...props} config={config} />
          )}
        />
        <Route
          exact
          path="/distribution/edit/:id"
          render={(props): ReactElement => (
            <DistributionRoute {...props} config={config} />
          )}
        />
        <Route
          exact
          path="/distribution/details/:id"
          render={(): ReactElement => (
            <DistributionRoute {...props} config={config} />
          )}
        />
        <Route
          exact
          path="/distribution/upgrade/:id"
          render={(): ReactElement => (
            <UpgradeRoute {...props} config={config} />
          )}
        />
        <Route
          exact
          path="/distribution/purchaseedit/:id"
          render={(): ReactElement => (
            <PurchaseEditRoute {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/archive"
          render={(props): ReactElement => (
            <ArchivedReleases {...props} config={config} />
          )}
        ></Route>

        <Route
          exact
          path="/"
          render={(props): ReactElement =>
            !sessionTTL || sessionTTL === '' ? (
              <Loader style={{ marginTop: '40vh' }} />
            ) : (
              <Distributions {...props} config={config} />
            )
          }
        />

        <Route
          exact
          path="/logout"
          render={(props): ReactElement => (
            <Logout {...props} config={config} />
          )}
        />

        <Route
          exact
          path="/sendactivation"
          render={(props): ReactElement => (
            <SendActivation {...props} config={config}></SendActivation>
          )}
        ></Route>

        <Route
          exact
          path="/forgot-password"
          render={() => {
            window.location.href = oktaForgotPwdLocation;
            return <></>;
          }}
        />

        <Route
          exact
          path="/verified/:encryptedToken"
          render={(props): ReactElement => (
            <Verified {...props} config={config} />
          )}
        />

        <Route
          exact
          path={`${OAUTH_CONFIRM_PATH}/:socialMedia`}
          render={() => <OAuthWindow />}
        ></Route>

        <Route exact path="/publications/:days?">
          {(allowAdmin() && <Publications {...props} />) || <NotAuthorized />}
        </Route>
        <Route exact path="/publication/:id">
          {(allowAdmin() && <Publication {...props} />) || <NotAuthorized />}
        </Route>
        <Route component={NotFound} />
      </Switch>

      <WelcomeMessage
        isOpen={toggleWelcomeMessage}
        closeMessage={onCloseWelcomeMessage}
      />
    </Responsive.Container>
  );
};

export default App;
