import React, { useState } from 'react';

import { Button } from '@cision/rover-ui';
import { useHistory } from 'react-router-dom';

import PRWebFeatures from '../../utils/PRWebFeatures';
import PaginationControls from '../pagination-controls';

import styles from './distribution-list.module.css';
import DistributionRowStatus from './distribution-row-status';

interface Props {
  distributionList: Array<PRWebReleaseDistribution>;
  pageSize: number;
  lockControl?: boolean;
}

const DistributionList = ({
  distributionList,
  pageSize,
  lockControl = false,
}: Props) => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const distributionListCount = distributionList.length;
  const PAGE_SIZE = pageSize;
  const startIndex = (pageNum - 1) * PAGE_SIZE;
  const endIndex = Math.min(pageNum * PAGE_SIZE, distributionListCount);
  const paginatedLists = distributionList.slice(startIndex, endIndex);
  const showArchivedReleases = PRWebFeatures.userShowArchivedRelease();

  const getListControlsInfo = () => {
    const start = pageNum === 1 ? 1 : (pageNum - 1) * PAGE_SIZE + 1;
    const finish =
      pageNum * PAGE_SIZE < distributionListCount
        ? pageNum * PAGE_SIZE
        : distributionListCount;
    return `Showing ${start}-${finish} of ${distributionListCount}`;
  };

  const loadArchiveReleasePage = () => history.push(`/archive`);

  const getGrid = () => {
    const convertUTCtoEST = (date: string): string => {
      let newDate = '-';
      if (date) {
        newDate = new Date(date).toDateString();
      }
      return newDate;
    };

    const handleRowClick = (distributionId: number) => () => {
      history.push({
        pathname: `/distribution/${distributionId}`,
      });
    };

    const getRowHeader = () => {
      return (
        <div className={styles.gridRow}>
          <div className={styles.gridItem}>NAME </div>
          <div className={styles.gridItem}>CREATION DATE </div>
          <div className={styles.gridItem}>SUBMITTED DATE </div>
          <div className={styles.gridItem}>RELEASE DATE </div>
          <div className={styles.gridItem}>STATUS</div>
        </div>
      );
    };

    const getRows = (distro: PRWebReleaseDistribution, idx: number) => {
      return (
        <div key={idx} className={`${styles.gridRow} ${styles.gridHover}`}>
          <div
            style={{ cursor: 'pointer' }}
            className={styles.gridItem}
            onClick={handleRowClick(distro.DistributionID)}
          >
            <strong className={styles.releaseHeadline}>
              {distro.HeadLine}
            </strong>
            <br />
            <span className={styles.releaseRow}>
              {distro.PRWebPressReleaseID}
            </span>
          </div>
          <div className={styles.gridItem}>
            <p className={styles.releaseRow}>
              {convertUTCtoEST(distro.CreationDate)}
            </p>
          </div>
          <div className={styles.gridItem}>
            <p className={styles.releaseRow}>
              {convertUTCtoEST(distro.SubmittedDate)}
            </p>
          </div>
          <div className={styles.gridItem}>
            <p className={styles.releaseRow}>
              {convertUTCtoEST(distro.ReleaseDate)}
            </p>
          </div>
          <div className={styles.gridItem}>
            <DistributionRowStatus
              ReleaseStatusID={distro.ReleaseStatusID}
              ReleaseStatusDescription={distro.ReleaseStatusDescription}
            ></DistributionRowStatus>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className={styles.info}>
          <div className={styles.listControls}>
            <div className={styles.listControlsItem}>
              {getListControlsInfo()}
            </div>
            <div className={styles.listControlsBody} />
            <div className={styles.listControlsItem}>
              <PaginationControls
                currentPage={pageNum}
                totalItems={distributionListCount}
                itemsPerPage={PAGE_SIZE}
                onForward={() => setPageNum(pageNum + 1)}
                onBackwards={() => setPageNum(pageNum - 1)}
                pageLabelType={PaginationControls.LABEL_TYPE_ITEMS_RANGE}
              />
            </div>
          </div>
        </div>
        {lockControl && (
          <div className={`${styles.info} ${styles.lockInfo}`}>
            <div className={styles.listControls}>
              <div className={styles.listControlsItem}>
                {getListControlsInfo()}
              </div>
              <div className={styles.listControlsBody} />
              <div className={styles.listControlsItem}>
                <PaginationControls
                  currentPage={pageNum}
                  totalItems={distributionListCount}
                  itemsPerPage={PAGE_SIZE}
                  onForward={() => setPageNum(pageNum + 1)}
                  onBackwards={() => setPageNum(pageNum - 1)}
                  pageLabelType={PaginationControls.LABEL_TYPE_ITEMS_RANGE}
                />
              </div>
            </div>
            {getRowHeader()}
          </div>
        )}
        <div>
          {getRowHeader()}
          {paginatedLists.map((distro, idx) => getRows(distro, idx))}
        </div>
        {showArchivedReleases && (
          <div className={styles.archivedWrapper}>
            <Button onClick={loadArchiveReleasePage}>
              View My Archived Releases
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };

  return getGrid();
};

export default DistributionList;
