import * as React from 'react';

import { Button, Modal } from '@cision/rover-ui';

import style from './welcome-message.module.css';

interface Props {
  isOpen: boolean;
  closeMessage: () => void;
}

const WelcomeMessage = ({ isOpen, closeMessage }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={style.modalWrapper} id={'welcomeMessageModal'}>
        <Modal.Header>
          <strong>Welcome to our New Platform! [Beta]</strong>
        </Modal.Header>
        <Modal.Body>
          You`ve successfully signed up to try the beta version of <br />
          our new platform. We`d love your feedback and will be <br />
          in touch soon to find out what you think.
        </Modal.Body>
        <Modal.Footer>
          <Button
            level="primary"
            id={'welcomeMessageGotItButton'}
            onClick={closeMessage}
          >
            Got it, thanks!
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default WelcomeMessage;
