import React, { FC, useEffect, useState, useCallback } from 'react';

import { Button, Tooltip, Typography, Paper } from '@cision/rover-ui';

import moment from 'moment-timezone';

import { ReactComponent as CloseSvg } from '../../../../assets/close.svg';
import { ReactComponent as InfoSvg } from '../../../../assets/info.svg';
import TextInputField from '../../../../components/text-input-field';
import { CJL_PRICE, CSP_PRICE, PRIORITY_PRICE } from '../../../../constants';
import DistributionWizardContext from '../../DistributionWizardContext';
import OrderUtility from '../../OrderUtility';
import PayPalButton from '../../PayPalButton';
import PRWebPackages from '../../PRWebPackages';

import styles from './order-summary.module.css';
interface Props {
  config: PRWebConfig;
  universalCoupon: UniversalCoupon | undefined;
  appliedCoupon: CouponDetails | undefined;
  changeCoupon: (
    couponCode: string,
    isApply: boolean,
  ) => Promise<{ errors: string[] }>;
  prepaidPackages: UserPackageBundleGroup[];
  prepaidPackageMatch?: UserPackageBundleGroup;
  applyBundledPackage: boolean;
  toggleBundle: () => void;
  isCreditCard: boolean;
  updateIsLoading: (status: boolean) => void;
  handlePaymentError: (error: any) => void;
}

const formatUSD = (price: number) => {
  return Number(price).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const OrderSummary: FC<Props> = ({
  config,
  universalCoupon,
  appliedCoupon,
  changeCoupon,
  prepaidPackages,
  prepaidPackageMatch,
  applyBundledPackage,
  toggleBundle,
  isCreditCard,
  updateIsLoading,
  handlePaymentError,
}: Props) => {
  const { distributionData } = React.useContext(DistributionWizardContext);
  const selectedPackage = PRWebPackages.getPRWebPackage(
    distributionData.package,
  );
  const showCJL = distributionData.cjl ? distributionData.cjl > 0 : false;
  const showCSP = distributionData.hasCisionSocialPost;
  const showPriorityRelease = distributionData.hasPriorityRelease;
  const showAmplify = showCJL || showCSP || showPriorityRelease;
  const cjlSelected = distributionData.cjlSelected?.length ?? 0;

  const [subTotal, setSubTotal] = useState(0);
  const [appliedCouponAmt, setAppliedCouponAmt] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [availableCjlCount, setAvailableCjlCount] = useState(
    distributionData.cjl ? distributionData.cjl : 0,
  );
  const [couponError, setCouponError] = useState([] as string[]);
  const [couponCode, setCouponCode] = useState('');

  const updateCouponField = (key: string, value?: string): void => {
    if (key !== 'applyCoupon') return;
    setCouponError([] as string[]);
    setCouponCode(value || '');
  };

  const removeCouponByCode = (code: string) => async () => {
    await changeCoupon(code, false);
    setCouponCode('');
  };

  const applyCouponCode = useCallback(async () => {
    if (couponCode.length > 0) {
      const error = await changeCoupon(couponCode, true);
      setCouponError(error.errors);
      setCouponCode('');
    }
  }, [changeCoupon, couponCode]);

  const getUniversalCouponAmount = () => {
    const discount = OrderUtility.getUniversalCouponDiscount(
      distributionData,
      universalCoupon,
      applyBundledPackage && !!prepaidPackageMatch,
    );
    return formatUSD(discount);
  };

  const getExpirationTooltip = (bundleGroup: UserPackageBundleGroup) => {
    return bundleGroup.bundles?.map((bundle, index) => {
      return (
        <div key={index}>
          {bundle.quantity} Expiring{' '}
          {moment.utc(bundle.expirationDate).format('M/D/YYYY')}
        </div>
      );
    });
  };

  const getAvailableBundlePackage = () => {
    return prepaidPackages
      .filter(bundle => +bundle.quantity > 0)
      .map((bundle, index) => {
        return (
          <span key={index}>
            <br />
            <span>
              {bundle.quantity} {bundle.name}: {bundle.bundles[0].quantity}{' '}
              Expiring{' '}
              {moment.utc(bundle.bundles[0].expirationDate).format('M/D/YYYY')}
              {bundle.bundles.length > 1 && (
                <Tooltip
                  showOnHover
                  tooltipWidth={'400px'}
                  content={getExpirationTooltip(bundle)}
                >
                  <InfoSvg width={15} height={15}></InfoSvg>
                </Tooltip>
              )}
            </span>
          </span>
        );
      });
  };

  useEffect(() => {
    if (appliedCoupon !== undefined) {
      setCouponError([] as string[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedCoupon]);

  useEffect(() => {
    const releaseAmount = OrderUtility.getOrderSubtotal(distributionData);
    setSubTotal(releaseAmount);

    const finalAmount = OrderUtility.getOrderTotal(
      distributionData,
      universalCoupon,
      appliedCoupon,
      applyBundledPackage && !!prepaidPackageMatch,
    );
    setFinalTotal(finalAmount);

    const couponAmount = OrderUtility.getOrderCouponDiscount(
      distributionData,
      universalCoupon,
      appliedCoupon,
      applyBundledPackage && !!prepaidPackageMatch,
    );
    setAppliedCouponAmt(couponAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyBundledPackage,
    prepaidPackageMatch,
    universalCoupon,
    appliedCoupon,
    distributionData.package,
    distributionData.cjl,
    distributionData.hasCisionSocialPost,
    distributionData.hasPriorityRelease,
  ]);

  useEffect(() => {
    if (PRWebPackages.isAdvancedPackage(distributionData.package)) {
      setAvailableCjlCount(availableCjlCount + 1);
    } else if (PRWebPackages.isPremiumPackage(distributionData.package)) {
      setAvailableCjlCount(availableCjlCount + 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={styles.orderSummaryContainer}>
      <div className={styles.sectionTitle}>
        <Typography weight="bold" color="black" className={styles.font16}>
          Order Summary
        </Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <Typography>Selected Package</Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName} id="qa-selected-package-info">
          {selectedPackage ? selectedPackage.name : ''}
        </span>
        <span id="qa-selected-package-amount">
          {selectedPackage ? formatUSD(selectedPackage.price) : ''}
        </span>
      </div>

      {showAmplify && (
        <div id="qa-subtotal-additions">
          <br />
          <div className={styles.orderSummaryRow}>
            <Typography>Additions</Typography>
          </div>
          {showCJL && distributionData.cjl && (
            <div className={styles.orderSummaryRow} id="qa-cjl-price-info">
              <span className={styles.orderSummaryName}>
                {distributionData.cjl} Cision Journalist Lists
              </span>
              <span id="qa-cjl-price-amount">
                {formatUSD(distributionData.cjl * CJL_PRICE)}
              </span>
            </div>
          )}
          {showCSP && (
            <div className={styles.orderSummaryRow} id="qa-csp-price-info">
              <span className={styles.orderSummaryName}>Cision SocialPost</span>
              <span id="qa-csp-price-amount">{formatUSD(CSP_PRICE)}</span>
            </div>
          )}
          {showPriorityRelease && (
            <div className={styles.orderSummaryRow} id="qa-priority-price-info">
              <span className={styles.orderSummaryName}>
                Priority Distribution
              </span>
              <span id="qa-priority-price-amount">
                {formatUSD(PRIORITY_PRICE)}
              </span>
            </div>
          )}
        </div>
      )}

      {(PRWebPackages.isAdvancedPackage(distributionData.package) ||
        PRWebPackages.isPremiumPackage(distributionData.package)) && (
        <div>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
          <div className={styles.orderSummaryRow}>
            <span
              className={styles.orderSummaryName}
              id="qa-included-cjl-count-info"
            >
              {(PRWebPackages.isAdvancedPackage(distributionData.package) && (
                <span>1 included Cision Journalist List</span>
              )) || <span>2 included Cision Journalist Lists</span>}
            </span>
          </div>
        </div>
      )}

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName}>Subtotal</span>
        <span>{formatUSD(subTotal)}</span>
      </div>

      {!!prepaidPackageMatch && (finalTotal > 0 || applyBundledPackage) && (
        <div
          className={styles.orderSummaryRow}
          id="qa-prepaid-package-subtotal-line-item"
        >
          <span className={styles.orderSummaryName}>
            Prepaid Package{' '}
            <Button level="link" onClick={toggleBundle}>
              ({applyBundledPackage ? 'remove' : 'apply'})
            </Button>
          </span>
          <span id="qa-prepaid-package-subtotal-line-item-amount">
            -
            {formatUSD(
              Math.min(applyBundledPackage ? selectedPackage?.price || 0 : 0),
            )}
          </span>
        </div>
      )}
      {universalCoupon && (
        <div
          className={styles.orderSummaryRow}
          id="qa-universal-coupon-subtotal-line-item"
        >
          <span className={styles.orderSummaryName}>
            <CloseSvg
              id="removeUniversalCoupon"
              aria-label="Remove Coupon"
              onClick={removeCouponByCode(universalCoupon.couponCode)}
              className={styles.closeSVG}
            />
            <span>Universal Coupon</span>
          </span>
          <span id="qa-coupon-subtotal-line-item-amount">
            -{getUniversalCouponAmount()}
          </span>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
        </div>
      )}

      {appliedCoupon && (
        <div
          className={styles.orderSummaryRow}
          id="qa-coupon-subtotal-line-item"
        >
          <span className={styles.orderSummaryName}>
            <CloseSvg
              id="removeUserCoupon"
              aria-label="Remove Coupon"
              onClick={removeCouponByCode(appliedCoupon.couponCode)}
              className={styles.closeSVG}
            />
            <span>Coupon</span>
          </span>
          <span id="qa-coupon-subtotal-line-item-amount">
            -{appliedCoupon ? formatUSD(appliedCouponAmt) : '$ 0'}
          </span>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
        </div>
      )}

      {(finalTotal > 0 || appliedCoupon) && (
        <div className={styles.couponInput}>
          <div>
            <div
              className={styles.flexInputContainer}
              id="qa-coupon-code-field"
            >
              <TextInputField
                id={'applyCoupon'}
                value={couponCode}
                onChangeValue={updateCouponField}
                onEnterPressed={applyCouponCode}
                placeholder={'Coupon Code'}
                issues={couponError}
              />
            </div>
            <div
              className={styles.flexFloatRight}
              id="qa-apply-remove-coupon-button"
            >
              <Button
                style={{ borderRadius: '4px' }}
                onClick={applyCouponCode}
                level="primary"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={`${styles.orderSummaryName} ${styles.orderTotal}`}>
          <Typography weight="bold" color="black">
            Order Total
          </Typography>
        </span>
        <span id="qa-final-order-total">
          <Typography color="black" weight="bold" size="md">
            {formatUSD(finalTotal)}
          </Typography>
        </span>
      </div>
      {distributionData.hasPaid && (
        <div className={styles.successMessage}>
          The release is already Paid, please go forward to Submit
        </div>
      )}
      {prepaidPackages.length > 0 && (
        <div id="qa-prepaid-reminders">
          <div className={styles.infoMessage}>
            {applyBundledPackage && !!prepaidPackageMatch && (
              <span id="qa-prepaid-package-usage-info">
                You are using one of your {prepaidPackageMatch.quantity}{' '}
                remaining bundled {prepaidPackageMatch.name} packages.
              </span>
            )}
            {!applyBundledPackage && !!prepaidPackageMatch && (
              <span id="qa-unused-prepaid-package-info">
                You have prepaid packages you could use.
                {getAvailableBundlePackage()}
              </span>
            )}
          </div>
          <div>
            <br />
          </div>
        </div>
      )}
      {availableCjlCount > cjlSelected && (
        <div className={styles.infoMessage}>
          You have unused Cision Journalist Lists
        </div>
      )}
      {!isCreditCard && finalTotal > 0 && (
        <PayPalButton
          config={config}
          applyBundledPackage={applyBundledPackage}
          universalCoupon={universalCoupon}
          appliedCoupon={appliedCoupon}
          updateIsLoading={updateIsLoading}
          handlePaymentError={handlePaymentError}
        />
      )}
    </Paper>
  );
};
export default OrderSummary;
