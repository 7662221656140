import Cookies from 'js-cookie';

import { PRWEB_FEATURES, PRWEB_PUBLIC_FEATURES } from '../constants';

import CacheService from './cache-service';

const PRWebFeatures = {
  getFeatures: (): PRWebFeature => {
    if (CacheService.exists(PRWEB_FEATURES)) {
      return CacheService.get(PRWEB_FEATURES) as PRWebFeature;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  },

  getPublicFeatures: () => {
    if (CacheService.exists(PRWEB_PUBLIC_FEATURES)) {
      return CacheService.get(PRWEB_PUBLIC_FEATURES) as {
        redirectCountDown: number;
      };
    }
    return null;
  },

  userHasLegacyLogin: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.userHasLegacyLogin : false;
  },

  userHasStripeApi: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.userHasStripeApi : false;
  },

  userShowArchivedRelease: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.userShowArchiveReleases : false;
  },

  enableOAuthLinkedId: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.hasEnableOAuthLinkedIn : false;
  },

  enableOAuthFacebook: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.hasEnableOAuthFacebook : false;
  },

  enableBundleStorePage: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.hasEnableBundleStorePage : false;
  },

  legacyRedirectCountDown: (): number => {
    const features = PRWebFeatures.getPublicFeatures();
    return features && features.redirectCountDown
      ? Number(features.redirectCountDown)
      : 0;
  },

  userSendDistributionToIris: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return features ? features.userSendDistributionToIris : false;
  },

  enablePurchaseEdit: (): boolean => {
    const features = PRWebFeatures.getFeatures();
    return (
      features?.hasEnablePurchaseEdit ||
      Cookies.get('showPurchaseEdit')?.toLowerCase() === 'true'
    );
  },
};

export default PRWebFeatures;
