import React, { useState, useEffect } from 'react';

import { Loader } from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as FacebookSvg } from '../../assets/facebook-brands.svg';
import { ReactComponent as LinkSvg } from '../../assets/link-solid.svg';
import { ReactComponent as TrashSvg } from '../../assets/trash-solid.svg';
import { PRWEB_FACEBOOK, SOCIAL_MEDIA_FACEBOOK } from '../../constants';
import PRWebSocial from '../../utils/PRWebSocial';
import DropdownField, {
  DropdownPanel,
  PanelMapperAttribute,
} from '../dropdown-field';
import PopupModal, { PopupContentProps } from '../popup-modal';

import FacebookConnectModal from './FacebookConnectModal';
import { SocialMediaState } from './social-media-container';
import styles from './social-media-container.module.css';

interface FacebookProps {
  config?: PRWebConfig;
  buttonState?: SocialMediaState;
  isFacebookSelected?: boolean;
  facebookHandler?: (Facebook: SocialMediaAccount) => void;
  callback?: () => void;
}

interface FacebookPageList {
  id: string;
  name: string;
}

enum PopupModalState {
  Hidden,
  Alert,
  Input,
}

const FacebookToggle = ({
  config = {} as PRWebConfig,
  buttonState = SocialMediaState.Default,
  isFacebookSelected = true,
  facebookHandler,
  callback,
}: FacebookProps) => {
  const prwebApi = config.prwebApi.url;
  const [isLoading, setIsLoading] = useState(true);
  const [prWebFacebookAccount, setPrWebFacebookAccount] = useState<
    PRWebSocialAccount | undefined
  >(undefined);
  const [togglePopup, setTogglePopup] = useState(PopupModalState.Hidden);
  const [popupContent, setPopupContent] = useState({} as PopupContentProps);
  const [facebookPageList, setFacebookPageList] = useState(
    [] as FacebookPageList[],
  );
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedPageValidation, setSelectedPageValidation] = useState(
    [] as string[],
  );
  const animationRef = React.createRef<HTMLDivElement>();

  const onCallbackSuccessConnection = (status: string) => {
    if (status === 'success') {
      getFacebookPages();
      setPopupContent({
        header: 'Facebook Pages',
        body: '',
        action: [
          {
            name: 'Cancel',
            level: 'secondary',
          },
          {
            name: 'Confirm',
            level: 'primary',
          },
        ],
      });
      setTogglePopup(PopupModalState.Input);
    } else {
      setPopupContent({
        header: 'Attention!',
        body: status,
        action: [
          {
            name: 'Ok',
            level: 'primary',
          },
        ],
      });
      setTogglePopup(PopupModalState.Alert);
    }
  };

  const isConnectedContextHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    if (prWebFacebookAccount === undefined) {
      FacebookConnectModal.Start({ config, onCallbackSuccessConnection });
    } else {
      if (buttonState === SocialMediaState.Removable) {
        trashButtonHandler();
      } else if (buttonState === SocialMediaState.Selectable) {
        selectFacebookHandler();
      }
    }
  };

  const trashButtonHandler = () => {
    setIsLoading(true);
    const facebookAccount = PRWebSocial.getSocialAccount(PRWEB_FACEBOOK);
    const checkActiveReleasesUrl = `${prwebApi}/social/doesprweboauthhaveactivereleaseses/${facebookAccount?.Id}`;
    axios
      .get(checkActiveReleasesUrl, {
        params: { socialMedia: SOCIAL_MEDIA_FACEBOOK },
      })
      .then(response => {
        setIsLoading(false);
        if (response && response.status === 200) {
          const modalContent = {
            header: 'Attention!',
            body:
              'There are/is an active Press Release(s) associated with this Social Media Account. If you continue active release(s) will have your social media account removed. Do you wish to continue?',
            action: [
              {
                name: 'No',
                level: 'secondary',
              },
              {
                name: 'Yes',
                level: 'primary',
              },
            ],
          };
          if (response.data === true) {
            setPopupContent(modalContent);
            setTogglePopup(PopupModalState.Alert);
          } else {
            modalContent.body =
              'Your social media account will be deleted. Do you wish to continue?';
            setPopupContent(modalContent);
            setTogglePopup(PopupModalState.Alert);
          }
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('trashButtonHandler: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  const selectFacebookHandler = () => {
    if (facebookHandler && prWebFacebookAccount) {
      facebookHandler({
        type: prWebFacebookAccount.Type,
        accountName: prWebFacebookAccount.AccountName,
        avatar: prWebFacebookAccount.Avatar,
        selected: prWebFacebookAccount.Selected,
      } as SocialMediaAccount);
    }
  };
  const removeSocialAccount = () => {
    setIsLoading(true);

    const facebookAccount = PRWebSocial.getSocialAccount(PRWEB_FACEBOOK);
    const removeFacebookAccountsUrl = `${prwebApi}/social/account/${facebookAccount?.Id}`;

    axios
      .delete(removeFacebookAccountsUrl, {
        params: { socialMedia: SOCIAL_MEDIA_FACEBOOK },
      })
      .then(response => {
        if (response && (response.status === 200 || response.status === 201)) {
          setPrWebFacebookAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_FACEBOOK);
        } else {
          console.log('invalid response loading social account: ', response);
        }
      })
      .catch(error => {
        console.log('loadcache removing facebook acct: ', error);
      })
      .finally(() => setIsLoading(false));
  };

  const getFacebookPages = () => {
    setIsLoading(true);
    const getFacebookPagesUrl = `${prwebApi}/social/facebookpage`;
    axios
      .get(getFacebookPagesUrl)
      .then(response => {
        if (response && response.status === 200 && response.data) {
          setFacebookPageList(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const setFacebookPages = (pageId: string) => {
    setIsLoading(true);
    const setFacebookPagesUrl = `${prwebApi}/social/facebookpage`;

    axios
      .post(setFacebookPagesUrl, { pageId })
      .then(response => {
        if (response && response.status === 200 && response.data) {
          const facebookAccount = {
            Id: response.data.id,
            Type: response.data.type,
            AccountName: response.data.pageName,
            Avatar: response.data.avatar,
            Authorized: response.data.authorized,
            ExternalApplicationId: response.data.pageId,
            MaxContentLength: 280,
          } as PRWebSocialAccount;
          if (facebookAccount && !isNaN(Number(facebookAccount.Id))) {
            setPrWebFacebookAccount(facebookAccount);
            PRWebSocial.setSocialAccount(PRWEB_FACEBOOK, facebookAccount);
          } else {
            setPrWebFacebookAccount(undefined);
            PRWebSocial.removeSocialAccount(PRWEB_FACEBOOK);
          }
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const getSocialAccounts = () => {
    setIsLoading(true);
    if (PRWebSocial.isConnectedToSocialMedia(PRWEB_FACEBOOK)) {
      const FacebookAccount = PRWebSocial.getSocialAccount(PRWEB_FACEBOOK);
      setPrWebFacebookAccount(FacebookAccount);
      setIsLoading(false);
      callback && callback();
    } else {
      const getSocialAccountsUrl = `${prwebApi}/social/account`;

      axios
        .get(getSocialAccountsUrl, {
          params: { socialMedia: SOCIAL_MEDIA_FACEBOOK },
        })
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const facebookAccount = {
              Id: response.data.id,
              Type: response.data.type,
              AccountName: response.data.pageName,
              Avatar: response.data.avatar,
              Authorized: response.data.authorized,
              ExternalApplicationId: response.data.pageId,
              MaxContentLength: 280,
            } as PRWebSocialAccount;

            if (facebookAccount && !isNaN(Number(facebookAccount.Id))) {
              setPrWebFacebookAccount(facebookAccount);
              PRWebSocial.setSocialAccount(PRWEB_FACEBOOK, facebookAccount);
            } else {
              setPrWebFacebookAccount(undefined);
              PRWebSocial.removeSocialAccount(PRWEB_FACEBOOK);
            }
            callback && callback();
          } else {
            console.log('invalid response loading social account: ', response);
            setPrWebFacebookAccount(undefined);
            PRWebSocial.removeSocialAccount(PRWEB_FACEBOOK);
          }
        })
        .catch(error => {
          console.log('loadcache error retrieving data: ', error);
          setPrWebFacebookAccount(undefined);
          PRWebSocial.removeSocialAccount(PRWEB_FACEBOOK);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const facebookPageListMapper = (key: string) => {
    if (
      key === PanelMapperAttribute.PanelId ||
      key === PanelMapperAttribute.PanelData
    ) {
      return 'id';
    } else if (key === PanelMapperAttribute.PanelContent) {
      return 'name';
    }
    return '';
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    if (key === 'facebookPagePanel' && !isNaN(Number(value))) {
      setSelectedPage(facebookPageList[Number(value)].name);
    }
    if (key === 'facebookModal' && String(value).length > 0) {
      if (value === 'Yes') {
        removeSocialAccount();
      }
      if (value === 'Confirm') {
        onModalConfirm();
      } else {
        onModalCancel();
      }
    }
  };

  const onModalConfirm = () => {
    const selectedPageObj = facebookPageList.find(page => {
      return 'name' in page && 'id' in page && page.name === selectedPage;
    });
    if (selectedPageObj !== undefined) {
      setSelectedPageValidation([]);
      setFacebookPages(selectedPageObj.id);
      setTogglePopup(PopupModalState.Hidden);
    } else {
      setSelectedPageValidation(['Please select a facebook page to use']);
    }
  };

  const onModalCancel = () => {
    setSelectedPageValidation([]);
    setSelectedPage('');
    setTogglePopup(PopupModalState.Hidden);
  };

  useEffect(() => {
    getSocialAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.buttonContainer}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <button
            className={styles.toggleButton}
            onClick={isConnectedContextHandler}
          >
            {prWebFacebookAccount ? (
              <div
                ref={animationRef}
                className={`${styles.slider} ${isFacebookSelected &&
                  styles.sliderTransition}`}
              >
                <div
                  className={`${styles.innerContent} ${buttonState ===
                    SocialMediaState.Selectable && styles.enableBackground}`}
                >
                  <div className={styles.avatar}>
                    {prWebFacebookAccount.Avatar ? (
                      <img
                        className={`${styles.avatarIcon} ${styles.avatarRounded}`}
                        src={prWebFacebookAccount.Avatar}
                        alt={prWebFacebookAccount.AccountName}
                      />
                    ) : (
                      <FacebookSvg className={styles.facebookSvg} />
                    )}
                  </div>
                  <div className={styles.buttonContent}>
                    <span>{prWebFacebookAccount.AccountName}</span>
                    <span>{prWebFacebookAccount.Type}</span>
                    <span></span>
                  </div>
                  {buttonState === SocialMediaState.Selectable && (
                    <>
                      <div
                        className={`${styles.toggleNotch} ${
                          !isFacebookSelected ? styles.notchTransition : ''
                        }`}
                      ></div>
                      <div className={styles.sliderContent}>
                        Use Facebook Account
                      </div>
                    </>
                  )}
                  {buttonState === SocialMediaState.Removable && (
                    <TrashSvg className={styles.trashSvg}></TrashSvg>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.innerContent}>
                <div className={styles.avatar}>
                  <FacebookSvg className={styles.facebookSvg} />
                </div>
                <div className={styles.buttonContent}>
                  <div>Connect to Facebook</div>
                </div>
                <LinkSvg className={styles.linkSvg}></LinkSvg>
              </div>
            )}
          </button>
          <PopupModal
            id={'facebookModal'}
            isOpen={togglePopup !== PopupModalState.Hidden}
            content={popupContent}
            onActionClick={updateDataFieldByValue}
          >
            {togglePopup === PopupModalState.Input && (
              <DropdownField
                id={'facebookPage'}
                modifiers={['centered']}
                value={selectedPage}
                placeholder={'Select a Facebook page:'}
                canEdit={false}
                dropdownList={facebookPageList}
                issues={selectedPageValidation}
                onChangeValue={updateDataFieldByValue}
              >
                <DropdownPanel
                  id={'facebookPagePanel'}
                  panelMapper={facebookPageListMapper}
                />
              </DropdownField>
            )}
          </PopupModal>
        </>
      )}
    </div>
  );
};

export default FacebookToggle;
