import React, { FC, useEffect, useState, useCallback } from 'react';

import { Button, Paper, Typography } from '@cision/rover-ui';

import { ReactComponent as CloseSvg } from '../../assets/close.svg';
import TextInputField from '../../components/text-input-field';
import { PRIORITY_PRICE } from '../../constants';

import PRWebPackages from '../distribution-wizard/PRWebPackages';

import PayPalButton from './PayPalButton';
import styles from './upgrade-summary.module.css';
import UpgradeUtility from './UpgradeUtility';
import UpgradeWizardContext from './UpgradeWizardContext';

interface Props {
  config: PRWebConfig;
  universalCoupon: UniversalCoupon | undefined;
  appliedCoupon: CouponDetails | undefined;
  changeCoupon: (
    couponCode: string,
    isApply: boolean,
  ) => Promise<{ errors: string[] }>;
  isCreditCard: boolean;
  updateIsLoading: (status: boolean) => void;
  handlePaymentError: (error: any) => void;
}

const formatUSD = (price: number) => {
  return Number(price).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const UpgradeSummary: FC<Props> = ({
  config,
  universalCoupon,
  appliedCoupon,
  changeCoupon,
  isCreditCard,
  updateIsLoading,
  handlePaymentError,
}: Props) => {
  const { upgradeData } = React.useContext(UpgradeWizardContext);
  const previousPackage = PRWebPackages.getPRWebPackage(
    upgradeData.initialPackage,
  );
  const selectedPackage = PRWebPackages.getPRWebPackage(upgradeData.package);

  const showAmplify = upgradeData.priority;
  const showPriorityRelease = upgradeData.priority;

  const [adjustedPackageAmt, setAdjustedPackageAmt] = useState(0);
  const [appliedCouponAmt, setAppliedCouponAmt] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [couponError, setCouponError] = useState([] as string[]);
  const [couponCode, setCouponCode] = useState('');

  const updateCouponField = (key: string, value?: string): void => {
    if (key !== 'applyCoupon') return;
    setCouponError([] as string[]);
    setCouponCode(value || '');
  };

  const removeCouponByCode = (code: string) => async () => {
    setCouponCode('');
    await changeCoupon(code, false);
  };

  const applyCouponCode = useCallback(async () => {
    if (couponCode.length > 0) {
      const error = await changeCoupon(couponCode, true);
      setCouponError(error.errors);
      setCouponCode('');
    }
  }, [changeCoupon, couponCode]);

  const getUniversalCouponAmount = () => {
    const discount = UpgradeUtility.getUniversalCouponDiscount(
      upgradeData,
      universalCoupon,
    );
    return formatUSD(discount);
  };

  // const getAvailableBundlePackage = () => {
  //   return prepaidPackages
  //     .filter(bundle => +bundle.quantity > 0)
  //     .map((bundle, index) => {
  //       return (
  //         <span key={index}>
  //           <br />
  //           <span>
  //             {bundle.quantity} {bundle.name}: {bundle.bundles[0].quantity}{' '}
  //             Expiring{' '}
  //             {moment.utc(bundle.bundles[0].expirationDate).format('M/D/YYYY')}
  //             {bundle.bundles.length > 1 && (
  //               <Tooltip
  //                 className={styles.hoverTooltip}
  //                 content={getExpirationTooltip(bundle)}
  //                 contentWidth={200}
  //                 position="bottom"
  //               >
  //                 <InfoSvg width={15} height={15}></InfoSvg>
  //               </Tooltip>
  //             )}
  //           </span>
  //         </span>
  //       );
  //     });
  // };

  useEffect(() => {
    if (appliedCoupon !== undefined) {
      setCouponError([] as string[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedCoupon]);

  useEffect(() => {
    const upgradeAmount = UpgradeUtility.getOrderSubtotal(upgradeData);
    const packageAmount = UpgradeUtility.getAdjustedUpgradeAmount(upgradeData);
    const couponAmount = UpgradeUtility.getOrderCouponDiscount(
      upgradeData,
      universalCoupon,
      appliedCoupon,
    );
    const finalAmount = UpgradeUtility.getOrderTotal(
      upgradeData,
      universalCoupon,
      appliedCoupon,
    );

    setAdjustedPackageAmt(packageAmount);
    setSubTotal(upgradeAmount);
    setAppliedCouponAmt(couponAmount);
    setFinalTotal(finalAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    universalCoupon,
    appliedCoupon,
    upgradeData.package,
    upgradeData.priority,
  ]);

  return (
    <Paper className={styles.orderSummaryContainer}>
      <div className={styles.sectionTitle}>
        <Typography weight="bold" color="black" className={styles.font16}>
          Upgrade Summary
        </Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <Typography>PREVIOUS ORDER</Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName}>
          {previousPackage ? previousPackage.name : ''}
        </span>
        <span>{previousPackage ? formatUSD(previousPackage.price) : ''}</span>
      </div>
      <div className={styles.orderSummaryRow}>
        <Typography>UPGRADES</Typography>
      </div>
      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName} id="qa-selected-package-info">
          {selectedPackage ? selectedPackage.name : ''}
        </span>
        <span id="qa-selected-package-amount">
          {selectedPackage ? formatUSD(adjustedPackageAmt) : ''}
        </span>
      </div>

      {showAmplify && (
        <div id="qa-subtotal-additions">
          <div className={styles.orderSummaryRow}>
            <Typography>ADDITIONS</Typography>
          </div>
          {/* {showCJL && distributionData.cjl && (
            <div className={styles.orderSummaryRow} id="qa-cjl-price-info">
              <span className={styles.orderSummaryName}>
                {distributionData.cjl} Cision Journalist Lists
              </span>
              <span id="qa-cjl-price-amount">
                ${distributionData.cjl * CJL_PRICE}
              </span>
            </div>
          )}
          {showCSP && (
            <div className={styles.orderSummaryRow} id="qa-csp-price-info">
              <span className={styles.orderSummaryName}>Cision SocialPost</span>
              <span id="qa-csp-price-amount">${CSP_PRICE}</span>
            </div>
          )} */}
          {showPriorityRelease && (
            <div className={styles.orderSummaryRow} id="qa-priority-price-info">
              <span className={styles.orderSummaryName}>
                Priority Distribution
              </span>
              <span id="qa-priority-price-amount">${PRIORITY_PRICE}</span>
            </div>
          )}
        </div>
      )}

      {(PRWebPackages.isAdvancedPackage(upgradeData.package) ||
        PRWebPackages.isPremiumPackage(upgradeData.package)) && (
        <div>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
          <div className={styles.orderSummaryRow}>
            <span
              className={styles.orderSummaryName}
              id="qa-included-cjl-count-info"
            >
              {(PRWebPackages.isAdvancedPackage(upgradeData.package) && (
                <span>1 included Cision Journalist List</span>
              )) || <span>2 included Cision Journalist Lists</span>}
            </span>
          </div>
        </div>
      )}

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={styles.orderSummaryName}>Subtotal</span>
        <span>{formatUSD(subTotal)}</span>
      </div>

      {universalCoupon && (
        <div
          className={styles.orderSummaryRow}
          id="qa-universal-coupon-subtotal-line-item"
        >
          <span className={styles.orderSummaryName}>
            <CloseSvg
              id="removeUniversalCoupon"
              aria-label="Remove Coupon"
              onClick={removeCouponByCode(universalCoupon.couponCode)}
              className={styles.closeSVG}
            />
            <span>Universal Coupon</span>
          </span>
          <span id="qa-coupon-subtotal-line-item-amount">
            -{getUniversalCouponAmount()}
          </span>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
        </div>
      )}

      {appliedCoupon && (
        <div
          className={styles.orderSummaryRow}
          id="qa-coupon-subtotal-line-item"
        >
          <CloseSvg
            id="removeUserCoupon"
            aria-label="Remove Coupon"
            onClick={removeCouponByCode(appliedCoupon.couponCode)}
            className={styles.closeSVG}
          />
          <span className={styles.orderSummaryName}>Coupon</span>
          <span id="qa-coupon-subtotal-line-item-amount">
            -{appliedCoupon ? formatUSD(appliedCouponAmt) : '$ 0'}
          </span>
          <div
            className={`${styles.separator} ${styles.orderSummaryRow}`}
          ></div>
        </div>
      )}

      {(finalTotal > 0 || appliedCoupon) && (
        <div className={styles.couponInput}>
          <div>
            <div
              className={styles.flexInputContainer}
              id="qa-coupon-code-field"
            >
              <TextInputField
                id={'applyCoupon'}
                value={couponCode}
                onChangeValue={updateCouponField}
                onEnterPressed={applyCouponCode}
                placeholder={'Coupon Code'}
                issues={couponError}
              />
            </div>
            <div
              className={styles.flexFloatRight}
              id="qa-apply-remove-coupon-button"
            >
              <Button
                style={{ borderRadius: '4px' }}
                onClick={applyCouponCode}
                level="primary"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.separator} ${styles.orderSummaryRow}`}></div>

      <div className={styles.orderSummaryRow}>
        <span className={`${styles.orderSummaryName} ${styles.orderTotal}`}>
          <Typography weight="bold" color="black">
            Order Total
          </Typography>
        </span>
        <span id="qa-final-order-total">
          <Typography size="xl" weight="bold" color="black">
            {formatUSD(finalTotal)}
          </Typography>
        </span>
      </div>
      {/* {availableCjlCount > cjlSelected && (
        <div className={styles.infoMessage}>
          You have unused Cision Journalist Lists
        </div>
      )} */}
      {!isCreditCard && finalTotal > 0 && (
        <PayPalButton
          config={config}
          universalCoupon={universalCoupon}
          appliedCoupon={appliedCoupon}
          updateIsLoading={updateIsLoading}
          handlePaymentError={handlePaymentError}
        />
      )}
    </Paper>
  );
};
export default UpgradeSummary;
