import GenericDistributionValidator from './GenericDistributionValidator';

export const validateCaption = (value?: string): Array<string> => {
  const MAX_LENGTH = 200;
  const FIELD_LABEL = 'Caption';
  const MORE_THAN_MAX_LENGTH_MESSAGE = `The ${FIELD_LABEL} cannot exceed ${MAX_LENGTH} characters.`;
  const REQUIRED_MESSAGE = `The ${FIELD_LABEL} field is required`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(REQUIRED_MESSAGE);
    return errorMsgs;
  }

  GenericDistributionValidator.isMoreThanMaxLength(value, MAX_LENGTH) &&
    errorMsgs.push(MORE_THAN_MAX_LENGTH_MESSAGE);

  return errorMsgs;
};

export const validateFileName = (value?: string): Array<string> => {
  const MISSING_MESSAGE = `Please select a file.`;
  const errorMsgs: Array<string> = [];

  if (!value) {
    errorMsgs.push(MISSING_MESSAGE);
    return errorMsgs;
  }

  return errorMsgs;
};

const FileValidator = {
  validateCaption,
  validateFileName,
};

export default FileValidator;
