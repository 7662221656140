import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  ChangeEvent,
} from 'react';

import { Loader, Typography, Paper } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import moment from 'moment-timezone';

import DatePicker from '../../../components/date-picker';
import Kite from '../../../components/kite';
import WizardContext from '../../../components/wizard/wizard-context';
import {
  ADMIN_GROUP,
  EDITOR_GROUP,
  LOGIN_AS,
  USER_GROUP_COOKIE,
} from '../../../constants';
import SubmitDistributionValidator, {
  PrivateContactValidation,
} from '../../../validators/SubmitDistributionValidator';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import OnHoldReasonsList from '../components/on-hold-reasons-list';
import PrivateContactPanel from '../components/private-contact-panel';
import DistributionWizardContext from '../DistributionWizardContext';

import PRWebPackages from '../PRWebPackages';
import SaveUtility from '../SaveUtility';

import styles from './styles/schedule-step.module.css';

interface MeridianProps {
  validate: (value: string) => void;
  onChange: (value: string) => void;
  initialValue: string;
}
const Meridian = ({ validate, onChange, initialValue }: MeridianProps) => {
  const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value || AM;
    validate(value);
    onChange(value);
  };
  const AM = 'AM';
  const PM = 'PM';

  return (
    <select
      id="meridian"
      name="meridian"
      className={styles.timeSelection}
      onChange={changeHandler}
      value={initialValue}
    >
      <option key="AM" id="AM" value={AM}>
        AM
      </option>
      <option key="PM" id="PM" value={PM}>
        PM
      </option>
    </select>
  );
};
interface MinutesProps {
  validate: (value: number) => void;
  onChange: (value: number) => void;
  initialValue: number;
}
const Minutes = ({ validate, onChange, initialValue }: MinutesProps) => {
  const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const idx = event.target.selectedIndex;
    validate(minutes[idx]);
    onChange(minutes[idx]);
  };
  const roundValue = Math.ceil(initialValue / 5) * 5;
  return (
    <select
      id="minutes"
      name="minutes"
      className={styles.timeSelection}
      onChange={changeHandler}
      defaultValue={roundValue}
    >
      {minutes.map((m, i) => {
        return (
          <option key={i} value={m} id={`minutes-${m}`}>
            {m.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
          </option>
        );
      })}
    </select>
  );
};
interface HoursProps {
  validate: (value: number) => void;
  onChange: (value: number) => void;
  initialValue: number;
}
const Hours = ({ validate, onChange, initialValue }: HoursProps) => {
  const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value || '1');
    validate(value);
    onChange(value);
  };

  return (
    <select
      id="hours"
      name="hours"
      className={styles.timeSelection}
      onChange={changeHandler}
      value={initialValue}
    >
      {[...Array.from(Array(12).keys())].map(i => {
        return (
          <option key={i} value={i + 1} id={`hours-${i + 1}`}>
            {i + 1}
          </option>
        );
      })}
    </select>
  );
};
interface StepProps {
  config: PRWebConfig;
}

const ScheduleStep = ({ config }: StepProps): JSX.Element => {
  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const [isLoading, setIsLoading] = useState(false);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [dateTimeErrorValue, setDateTimeErrorValue] = useState<string>();
  const {
    distributionData,
    updateDistributionData,
    onHoldReasons,
  } = useContext(DistributionWizardContext);
  const wizardContext = React.useContext(WizardContext);
  const [hasSavedDraft, setHasSavedDraft] = useState(false);
  const [validateReleaseDate, setValidateReleaseDate] = useState(true);

  const AM = 'AM';
  const PM = 'PM';
  const [releaseDateState, setReleaseDateState] = useState(
    moment.utc(distributionData.releaseDate),
  );
  const [hoursState, setHoursState] = useState(() => {
    const hour = releaseDateState.hour();

    // for 24 hour time conversion to 12 hour time
    // 12 is 12, 13-23 need to be 1-11
    return hour > 12 ? hour - 12 : hour;
  });

  const [minutesState, setMinutesState] = useState(releaseDateState.minute());
  const [meridianState, setMeridianState] = useState(
    releaseDateState.hour() >= 12 ? PM : AM,
  );
  const [timezoneState] = useState('US/Eastern');

  const [privateContactData, setPrivateContactData] = useState({
    user: distributionData.user,
    userPhoneCountryCode: distributionData.userPhoneCountryCode,
    userPhone: distributionData.userPhone,
    userPhoneExtension: distributionData.userPhoneExtension,
    userEmail: distributionData.userEmail,
  });

  const [
    privateContactValidation,
    setPrivateContactValidation,
  ] = useState<PrivateContactValidation | null>();

  const [isDirty, setIsDirty] = useState(false);

  const getPackageBasedMinDate = (): moment.Moment => {
    return PRWebPackages.getPackageBasedMinTurnaroundDate(
      distributionData.package,
      timezoneState,
    );
  };

  const getPriorityReleaseMinDate = (): moment.Moment => {
    return PRWebPackages.getPriorityReleaseTurnaroundDate(timezoneState);
  };

  const handleApiValidationError = (error: ErrorType): void => {
    const errors: Array<string> = SaveUtility.handleApiErrors(error);
    setFormErrorSummaryValue(errors);
  };

  const handleFeValidationErrors = (minimumDate: moment.Moment): void => {
    setDateTimeErrorValue(
      `Please select a date and time on or after ${minimumDate.format(
        'MM/DD/YYYY h:mm A',
      )}`,
    );
  };

  const validateMeridian = (value: string) => {
    validateNewReleaseDate({
      meridian: value,
    });
  };
  const validateMinutes = (value: number) => {
    validateNewReleaseDate({
      minutes: value,
    });
  };
  const validateHours = (value: number) => {
    validateNewReleaseDate({
      hours: value,
    });
  };

  const onReleaseDateBlur = (selectedDate: moment.Moment) => {
    // convert to timezonestate without changing the datetime value
    const dt = moment(selectedDate.format('YYYY-MM-DDTHH:mm:ss')).tz(
      timezoneState,
      true,
    );

    validateNewReleaseDate({
      releaseDate: dt,
    });
  };

  const validateNewReleaseDate = (props: NewReleaseDateValidationProps) => {
    const value = getSelectedDateTime(props);
    const minimumDate = distributionData.hasPriorityRelease
      ? getPriorityReleaseMinDate()
      : getPackageBasedMinDate();
    const valid = value.isSameOrAfter(minimumDate);

    if (!valid && validateReleaseDate) {
      handleFeValidationErrors(minimumDate);
    } else {
      setDateTimeErrorValue(undefined);
    }
    setReleaseDateState(value);
    setIsDirty(true);
    if (validateReleaseDate) {
      return valid;
    }
    return true;
  };

  interface NewReleaseDateValidationProps {
    releaseDate?: moment.Moment | undefined;
    hours?: number;
    minutes?: number;
    meridian?: string;
  }

  /**
   * Pass any one or of the props that is being changed
   * The rest of the props will be pulled from state
   * returns the new dateTime with the changes applied
   *
   * @param {NewReleaseDateValidationProps} props
   * @returns {moment.Moment}
   */
  const getSelectedDateTime = (
    props: NewReleaseDateValidationProps,
  ): moment.Moment => {
    const _releaseDate =
      props.releaseDate === undefined ? releaseDateState : props.releaseDate;
    const _hours = props.hours === undefined ? hoursState : props.hours;
    const _minutes = props.minutes === undefined ? minutesState : props.minutes;
    const _meridian =
      props.meridian === undefined ? meridianState : props.meridian;

    if (!_releaseDate.isValid())
      throw new Error(
        `could not get dateTime moment from selectedDateTime: ${props}`,
      );

    return _releaseDate
      .set('hours', _meridian === AM || _hours === 12 ? _hours : _hours + 12)
      .set('minutes', _minutes);
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    setPrivateContactData({ ...privateContactData, [key]: value });
    updateDistributionData({ ...distributionData, [key]: value });
    setIsDirty(true);
  };

  const saveDraft = useCallback(
    async () => {
      if (isLoading) {
        return false;
      }

      setHasSavedDraft(false);
      setIsLoading(true);
      setFormErrorSummaryValue([]);

      const dataToSend = SaveUtility.getDataToSend(distributionData);

      try {
        const postResponse = await axios.post(
          `${prwebApi}/distribution/item/saveDraft`,
          dataToSend,
        );

        if (postResponse.status === 200 || postResponse.status === 201) {
          updateDistributionData({
            ...distributionData,
            prwebPressReleaseId:
              postResponse.data.result.prwebPressReleaseId || 0,
            distributionId: postResponse.data.result.distributionId || 0,
            distributionVersion:
              postResponse.data.result.distributionVersion || 0,
            distributionPRWebVersion:
              postResponse.data.result.distributionPRWebVersion || 0,
            releaseDate: postResponse.data.result.releaseDate,
          });

          setHasSavedDraft(true);
          setIsLoading(false);
          setIsDirty(false);
          return true;
        } else {
          handleApiValidationError(postResponse.data);
        }
      } catch (error) {
        handleApiValidationError(error);
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setIsLoading,
      isLoading,
      releaseDateState,
      hoursState,
      minutesState,
      meridianState,
      distributionData.releaseDate,
      privateContactData,
    ],
  );

  const saveAndBack = useCallback(async () => {
    if (!isDirty) return true;
    if (!wizardContext.saveRequired) {
      return true;
    }

    return await saveDraft();
  }, [isDirty, wizardContext.saveRequired, saveDraft]);

  const saveAndContinue = useCallback(async () => {
    if (!wizardContext.saveRequired) {
      return true;
    }
    if (!validateNewReleaseDate({})) {
      return false;
    }

    const privateContactValidation = SubmitDistributionValidator.validatePrivateContactData(
      privateContactData,
    );

    if (!privateContactValidation?.valid) {
      setPrivateContactValidation(privateContactValidation);
      return false;
    }

    return await saveDraft();

    //test
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setIsLoading,
    wizardContext.saveRequired,
    isLoading,
    releaseDateState,
    hoursState,
    minutesState,
    meridianState,
    distributionData.releaseDate,
    privateContactData,
  ]);

  useEffect(() => {
    const convertedHours =
      meridianState === AM || hoursState === 12 ? hoursState : hoursState + 12;

    const dateTimeMoment = moment.tz(releaseDateState, timezoneState);
    dateTimeMoment.set({ h: convertedHours, m: minutesState });

    const utcDateTimeString = moment
      .tz(dateTimeMoment, 'UTC')
      .format('YYYY-MM-DDTHH:mm:ss');

    updateDistributionData({
      ...distributionData,
      releaseDate: utcDateTimeString,
      releaseDateTimezone: timezoneState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursState, minutesState, meridianState, releaseDateState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepForward(saveAndContinue), [
    saveAndContinue,
  ]);

  // Update progress
  useEffect(() => {
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);

    let distributionReleaseDateTime = moment.utc(distributionData.releaseDate);

    distributionReleaseDateTime = distributionReleaseDateTime.tz(timezoneState);

    setHoursState(parseInt(distributionReleaseDateTime.format('hh')));
    setMinutesState(distributionReleaseDateTime.get('minute'));
    setMeridianState(distributionReleaseDateTime.format('A'));
    setReleaseDateState(distributionReleaseDateTime);

    const impersonating = cookie.get(LOGIN_AS) === 'true';
    const userGroup = cookie.get(USER_GROUP_COOKIE);
    if (
      impersonating ||
      userGroup === ADMIN_GROUP ||
      userGroup === EDITOR_GROUP
    ) {
      setValidateReleaseDate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wizardContext.setSaveDraftButtonHandler(saveDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveDraft]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepBackward(saveAndBack), [
    saveAndBack,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setPreviewData(distributionData), [
    distributionData,
  ]);

  return (
    <div className={styles.stepContainer}>
      <Kite status="success" shouldShow={hasSavedDraft} ttl={8000}>
        Draft saved
      </Kite>
      {distributionData.isOnHold && (
        <OnHoldReasonsList reasons={onHoldReasons} />
      )}
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Schedule
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
      <Paper>
        <div className={styles.columnContainer}>
          <div className={styles.leftColumn}>
            <div>
              {formErrorSummaryValue.length > 0 && (
                <div className={styles.errorMessage}>
                  Error saving draft:
                  <ul>
                    {formErrorSummaryValue.map((err, i) => (
                      <li key={i}>{err}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={styles.errorMessage}>
                <ul>
                  {dateTimeErrorValue && dateTimeErrorValue !== '' && (
                    <li key={dateTimeErrorValue}>{dateTimeErrorValue}</li>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <Typography weight="bold" color="black">
                Date and Time
              </Typography>
            </div>
            <div className={styles.gridContainer}>
              <div className={styles.dateInputContainer}>
                <DatePicker
                  id={'date'}
                  label="Date"
                  date={moment(
                    releaseDateState.format('YYYY-MM-DDTHH:mm:ss'),
                  ).tz('UTC', true)}
                  onChange={onReleaseDateBlur}
                />
              </div>
              <div className={styles.dateInputContainer}>
                <label htmlFor="hours">Time</label>
                <div className={styles.flexContainer}>
                  <Hours
                    validate={validateHours}
                    onChange={setHoursState}
                    initialValue={hoursState}
                  />
                  <Minutes
                    validate={validateMinutes}
                    onChange={setMinutesState}
                    initialValue={minutesState}
                  />
                  <Meridian
                    validate={validateMeridian}
                    onChange={setMeridianState}
                    initialValue={meridianState}
                  />
                  <div className={styles.labelOffset}>
                    <label htmlFor="hours">Time Zone</label>
                    <div className={styles.timezoneContainer}>
                      {timezoneState}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightColumn}>
            <div className={styles.contactPanelContainer}>
              <PrivateContactPanel
                privateContact={privateContactData}
                validation={privateContactValidation || null}
                onChangeFieldValue={updateDataFieldByValue}
              />
            </div>
          </div>
        </div>
      </Paper>
      <ExitWizardPrompt />
    </div>
  );
};

export default ScheduleStep;
