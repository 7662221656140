import React from 'react';

import styles from './field-validation.module.css';

interface Props {
  id: string;
  issues: string[];
}

const FieldValidationIssue: React.FC<Props> = ({ id, issues }: Props) => {
  return (
    <div
      className={styles.errorMessage}
      id={`${id}-field-validation-issues-container`}
    >
      <ul>
        {issues.map((issue, i) => (
          <li key={i}>{issue}</li>
        ))}
      </ul>
    </div>
  );
};
export default FieldValidationIssue;
