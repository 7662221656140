import queryString from 'querystring';

import axios from 'axios';
import cookie from 'js-cookie';

import { SOCIAL_MEDIA_TWITTER } from '../../constants';

interface Props {
  config: PRWebConfig;
  onCallbackSuccessConnection?: () => void;
}

const TwitterConnectModal = {
  Start: async ({ config, onCallbackSuccessConnection }: Props) => {
    const callBackUrl = `${window.location.protocol}//${window.location.host}/oauthconfirmcallback`;
    const url = new URL(`${config.prwebApi.url}/social/authorizationprweb`);
    const params = new URLSearchParams({
      clientReturnUrl: callBackUrl,
      clientCallbackUrl: callBackUrl,
      socialMedia: SOCIAL_MEDIA_TWITTER,
    });

    url.search = params.toString();
    const twitterLoginUrl = await (await axios.get(url.toString())).data;

    const authWindow = window.open(
      twitterLoginUrl,
      'twitterLogin',
      `width=600,height=600`,
    );

    if (!authWindow) {
      alert(
        'please enable popups in order to authorize PRWeb on your twitter account',
      );
      return;
    }

    // start checking for authComplete and authSuccess cookies
    const poller = setInterval(() => {
      const oauthCompleteCookieKey = 'twitterOAuthComplete';
      const statusCookieKey = 'twitterOAuthStatus';
      try {
        if (authWindow.closed) {
          clearInterval(poller);
        }
        const authComplete =
          cookie.get(oauthCompleteCookieKey)?.toLowerCase() === 'true';
        if (authWindow.location.host === window.location.host && authComplete) {
          clearInterval(poller);
          const status = cookie.get(statusCookieKey) || '';
          const q = queryString.parse(status) || {};

          if (q['?state'] === 'success') {
            onCallbackSuccessConnection && onCallbackSuccessConnection();
          }
          cookie.remove(oauthCompleteCookieKey);
          cookie.remove(statusCookieKey);
          authWindow.close();
        } else {
          //  deferred.reject(angular.extend({reason: 'User denied access or we got an error'}, q));
        }
      } catch (err) {
        const regex = /(blocked a frame with origin)|(Permission denied to access property "host"|(^Permission Denied))/gi;
        if (!err.message.match(regex)) {
          console.error(err);
        }
      }
    }, 500);
    authWindow.focus();
  },
};
export default TwitterConnectModal;
