import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';

import Wizard from '../../../components/wizard/wizard';
import WizardContext from '../../../components/wizard/wizard-context';
import WizardProgress from '../../../components/wizard/wizard-progress';
import WizardStep from '../../../components/wizard/wizard-step';
import {
  PRWEB_PACKAGES,
  PRWEB_FEATURES,
  PREMIUM_PACKAGE_ID,
} from '../../../constants';
import CacheService from '../../../utils/cache-service';
import Prid from '../components/prid';
import DistributionWizardProvider from '../DistributionWizardProvider';
import AmplifyStep from '../steps/AmplifyStep';
import CreateReleaseStep from '../steps/CreateReleaseStep';
import PublishingStep from '../steps/PublishingStep';
import ScheduleStep from '../steps/ScheduleStep';
import SummaryStep from '../steps/SummaryStep';
import VerificationStep from '../steps/VerificationStep';

interface Props {
  envConfig: PRWebConfig;
  savedDistro?: Distribution;
  onHoldReasons: Array<string>;
  initialProgress: Array<string>;
  editorAction?: string;
}

const EditWizardSteps: FC<Props> = ({
  envConfig,
  savedDistro,
  onHoldReasons,
  initialProgress,
  editorAction,
}: Props) => {
  const history = useHistory();

  const cacheIsLoaded = (): boolean => {
    return (
      CacheService.exists(PRWEB_FEATURES) && CacheService.exists(PRWEB_PACKAGES)
    );
  };

  if (!cacheIsLoaded()) {
    console.log('Cache unexpectedly empty CreateWizardSteps, redirecting to /');
    history.push({ pathname: '/' });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCommonWizardWihAmplify = (wizardData: any) => {
    const canShowUpgrade = savedDistro && savedDistro?.hasPaid;
    return (
      <Wizard>
        <>
          <DistributionWizardProvider
            distro={savedDistro}
            onHold={onHoldReasons}
          >
            <Prid />
            <WizardStep
              order={1}
              nextLabel="Save &amp; Continue"
              previewButton
              saveDraftButton
              upgradeButton={canShowUpgrade}
            >
              <CreateReleaseStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={2}
              backLabel="Back to Create Your Release"
              nextLabel="Save &amp; Continue"
              previewButton
              saveDraftButton
            >
              <AmplifyStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={3}
              nextLabel="Save &amp; Continue"
              backLabel="Back to Amplify"
              previewButton
              saveDraftButton
            >
              <ScheduleStep config={envConfig} />
            </WizardStep>
            <WizardStep order={4} nextLabel="Next" backLabel="Back to Release">
              <VerificationStep config={envConfig} />
            </WizardStep>
            <WizardStep order={5} previewButton submit upgradeButton>
              <SummaryStep config={envConfig} />
            </WizardStep>
          </DistributionWizardProvider>

          <WizardProgress
            title={'Release Summary'}
            initialProgress={initialProgress}
            maxVisibleCount={2}
          />
        </>
      </Wizard>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOnHoldWizardWithAmplify = (wizardData: any) => {
    return (
      <Wizard>
        <>
          <DistributionWizardProvider
            distro={savedDistro}
            onHold={onHoldReasons}
          >
            <Prid />
            <WizardStep
              order={1}
              saveRequired={false}
              previewButton
              nextLabel="Next"
              upgradeButton={savedDistro?.hasPaid}
            >
              <CreateReleaseStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={2}
              saveRequired={false}
              backLabel="Back to Create Your Release"
              nextLabel="Next"
            >
              <AmplifyStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={3}
              saveRequired={false}
              nextLabel="Next"
              backLabel="Back to Amplify"
            >
              <ScheduleStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={4}
              saveRequired={false}
              nextLabel="Next"
              backLabel="Back to Release"
            >
              <VerificationStep config={envConfig} />
            </WizardStep>
            <WizardStep order={5} previewButton submit upgradeButton>
              <SummaryStep config={envConfig} />
            </WizardStep>
          </DistributionWizardProvider>

          <WizardProgress
            title={'Release Summary'}
            initialProgress={initialProgress}
            maxVisibleCount={2}
          />
        </>
      </Wizard>
    );
  };

  const getRepublishWizard = () => {
    return (
      <Wizard>
        <>
          <DistributionWizardProvider
            distro={savedDistro}
            onHold={onHoldReasons}
          >
            <Prid />
            <WizardStep
              order={1}
              nextLabel="Next"
              previewButton
              saveRequired={false}
            >
              <CreateReleaseStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={2}
              backLabel="Back to Create Your Release"
              nextLabel="Next"
              saveRequired={false}
            >
              <AmplifyStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={3}
              nextLabel="Next"
              backLabel="Back to Edit Release"
              saveRequired={false}
            >
              <ScheduleStep config={envConfig} />
            </WizardStep>
            <WizardStep
              order={4}
              nextLabel="Next"
              backLabel="Back"
              saveRequired={false}
            >
              <VerificationStep config={envConfig} />
            </WizardStep>
            <WizardStep order={5} previewButton submit>
              <PublishingStep config={envConfig} />
            </WizardStep>
          </DistributionWizardProvider>
          <WizardProgress
            title={'Release Summary'}
            initialProgress={initialProgress}
            maxVisibleCount={2}
          />
        </>
      </Wizard>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getEditSteps = (wizardData: any) => {
    if (editorAction === 'republish') {
      return getRepublishWizard();
    }
    if (savedDistro?.isOnHold || editorAction === 'edit') {
      return getOnHoldWizardWithAmplify(wizardData);
    }

    return getCommonWizardWihAmplify(wizardData);
  };

  return (
    <WizardContext.Consumer>
      {wizardData => {
        return getEditSteps(wizardData);
      }}
    </WizardContext.Consumer>
  );
};

export default EditWizardSteps;
