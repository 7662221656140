import React, { useEffect, useState } from 'react';

import { Responsive, Paper, Modal, Button, Checkbox } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';

import { ReactComponent as BackwardArrow } from '../../assets/arrow-backward.svg';
import { ReactComponent as ClipboardSvg } from '../../assets/clipboard.svg';
import DatePicker from '../../components/date-picker';
import FieldValidationIssue from '../../components/field-validation-issue/FieldValidationIssue';
import Footer from '../../components/footer';
import Header from '../../components/header';
import TextAreaField from '../../components/text-area-field';
import TextInputField from '../../components/text-input-field';
import { TO_DECIMAL, TO_PERCENT } from '../../constants';
import HttpStatusCode from '../../httpStatusCodes';
import {
  validateCouponAmount,
  validateCouponCode,
  validateCouponNotes,
  validateCouponDetails,
  validateExpirationDate,
  CouponDetailsErrors,
} from '../../validators/AdminUniversalCouponValidator';

import styles from './coupon-details.module.css';

interface Props {
  config: PRWebConfig;
}

interface PropsCouponModal {
  message: { header: string; body: string };
  isOpen: boolean;
  closeMessage: () => void;
}

const savedCouponMessage = {
  header: 'Coupon Saved',
  body: 'Coupon has been saved',
} as PropsCouponModal['message'];

const CouponSavedMessage = (props: PropsCouponModal) => {
  return (
    <Modal isOpen={props.isOpen}>
      <Modal.Header>
        <strong>{props.message.header}</strong>
      </Modal.Header>
      <Modal.Body>{props.message.body}</Modal.Body>
      <Modal.Footer>
        <Button
          id="couponSavedModalButton"
          level="primary"
          onClick={props.closeMessage}
        >
          Got it, thanks!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UniversalCouponDetails: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const { id: couponIdParam } = useParams();
  const isEditCoupon = couponIdParam ? true : false;
  const timezoneState = 'US/Eastern';
  const history = useHistory();

  const [couponId, setCouponId] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [amount, setAmount] = useState('');
  const [isPercent, setIsPercent] = useState(false);
  const [createdDate, setCreatedDate] = useState(moment.utc());
  const [startDate, setStartDate] = useState(moment.utc());
  const [expireDate, setExpireDate] = useState(moment.utc().add(1, 'year'));
  const [notes, setNotes] = useState('');

  const [isExitModal, setIsExitModal] = useState(false);
  const [exitModalMessage, setExitModalMessage] = useState(
    {} as PropsCouponModal['message'],
  );
  const [formValidationMsg, setFormValidationMsg] = useState<
    CouponDetailsErrors
  >();
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);

  const goToPreviousPage = () => {
    setIsExitModal(false);
    history.goBack();
  };

  const togglePercentage = () => {
    const msg = validateCouponAmount(!isPercent)(amount);
    setIsPercent(!isPercent);
    setFormValidationMsg((prev: any) => {
      return prev
        ? { ...prev, couponAmountErrors: msg }
        : { couponAmountErrors: msg };
    });
  };

  const clipboardCouponCode = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(couponCode);
    }
  };

  const isValidCouponForm = (): boolean => {
    const validationResult = validateCouponDetails({
      couponCode: couponCode,
      couponAmount: amount,
      couponIsPercent: isPercent,
      couponStartDate: startDate.format('M/D/YYYY'),
      couponExpirationDate: expireDate.format('M/D/YYYY'),
      couponNotes: notes,
    });
    setFormValidationMsg(validationResult);
    return validationResult.valid;
  };

  const saveCoupon = async () => {
    const isValid = isValidCouponForm();
    if (!isValid) {
      return;
    }
    const payload = {
      universalCouponId: isEditCoupon ? couponId : undefined,
      couponCode: couponCode,
      percent: isPercent ? Number(amount) * TO_DECIMAL : undefined,
      amount: !isPercent ? Number(amount) : undefined,
      isFixed: !isPercent,
      createdDate: createdDate.utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
      startDate: startDate
        .utc()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss.SSS'),
      expirationDate: expireDate
        .utc()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss.SSS'),
      notes: notes,
    };

    await axios
      .post(`${prwebApi}/admin/universalcoupons/save`, payload)
      .then(response => {
        if (response.status === HttpStatusCode.OK) {
          setIsExitModal(() => {
            setExitModalMessage(savedCouponMessage);
            return true;
          });
        }
      })
      .catch(error => {
        handleCouponError(error);
      });
  };

  const datePickerBlur = (field: string) => (selectedDate: moment.Moment) => {
    let msg: Array<string> = [];
    if (field === 'dateStarted') {
      const dt = moment(selectedDate.format('YYYY-MM-DDTHH:mm:ss')).tz(
        timezoneState,
        true,
      );
      setStartDate(dt);
      msg = validateExpirationDate(
        expireDate.format('M/D/YYYY'),
        dt.format('M/D/YYYY'),
      );
    } else if (field === 'dateExpired') {
      const dt = moment(selectedDate.format('YYYY-MM-DDTHH:mm:ss')).tz(
        timezoneState,
        true,
      );
      setExpireDate(dt);
      msg = validateExpirationDate(
        dt.format('M/D/YYYY'),
        startDate.format('M/D/YYYY'),
      );
    }
    setFormValidationMsg((prev: any) => {
      return prev
        ? { ...prev, couponExpirationDateErrors: msg }
        : { couponExpirationDateErrors: msg };
    });
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    switch (key) {
      case 'couponCode':
        setCouponCode(value || '');
        break;
      case 'couponAmount':
        setAmount(value || '');
        break;
      case 'notes':
        setNotes(value || '');
        break;
    }
  };

  const getUniversalCoupon = async (couponId: string) => {
    return await axios
      .get(`${prwebApi}/admin/universalcoupon/${couponId}`)
      .then(response => {
        if (response.status === 200 && response.data) {
          setCouponId((response.data.universalCouponId || '').toString());
          setCouponCode(response.data.couponCode);
          if (response.data.isFixed) {
            setAmount(response.data.amount.toString());
          } else {
            setAmount((Number(response.data.percent) * TO_PERCENT).toString());
          }
          setIsPercent(!response.data.isFixed);
          setCreatedDate(
            moment(response.data.createdDate).tz(timezoneState, true),
          );
          setStartDate(moment(response.data.startDate).tz(timezoneState, true));
          setExpireDate(
            moment(response.data.expirationDate).tz(timezoneState, true),
          );
          setNotes(response.data.notes || '');
        }
      })
      .catch(error => {
        handleCouponError(error);
      });
  };

  const handleCouponError = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: {
      status: number;
      data: { errors: any; message: any };
      statusText: string;
    };
    message: string;
  }) => {
    let errors: Array<string> = [];

    if (
      error?.response?.status === HttpStatusCode.BAD_REQUEST &&
      error?.response?.data.errors
    ) {
      errors = [...error.response.data.errors];
    } else if (error?.response?.data?.message) {
      errors.push(error.response.data.message);
    } else if (error?.response && error?.response?.statusText) {
      errors.push(error?.response?.statusText);
    } else {
      errors.push(error?.message);
    }
    setErrorMessage(errors);
  };

  useEffect(() => {
    const init = async () => {
      if (isEditCoupon && couponIdParam) {
        await getUniversalCoupon(couponIdParam.toString());
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      <Paper className={styles.couponDetailsContainer}>
        <Button id={'details-back-button'} onClick={goToPreviousPage}>
          <BackwardArrow width="24" height="24" viewBox="0 0 24 24" />
          <span>back</span>
        </Button>
        <div>
          {errorMessage.length > 0 && (
            <div className={styles.errorMessage}>
              Error:
              <ul>
                {errorMessage.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <br />
        <span className={styles.flexContainer}>
          <div className={styles.formTextInputContainer}>
            <TextInputField
              id={'couponCode'}
              label={'Coupon Code'}
              disabled={isEditCoupon}
              value={couponCode}
              onChangeValue={updateDataFieldByValue}
              issues={formValidationMsg?.couponCodeErrors || []}
              validate={validateCouponCode}
            />
          </div>
          <div style={{ paddingTop: '8px' }}>
            <ClipboardSvg
              className={styles.clipboard}
              onClick={clipboardCouponCode}
            />
          </div>
        </span>
        <span className={styles.flexContainer}>
          <div className={styles.formTextInputContainer}>
            <TextInputField
              id={'couponAmount'}
              label={'Coupon Amount'}
              value={amount}
              onChangeValue={updateDataFieldByValue}
              issues={formValidationMsg?.couponAmountErrors || []}
              validate={validateCouponAmount(isPercent)}
            />
          </div>
          <label
            htmlFor="isPercentDiscount"
            aria-disabled={isEditCoupon}
            className={`${styles.flexContainer} ${styles.checkboxContainer}`}
          >
            <Checkbox
              id={'isPercentDiscount'}
              disabled={isEditCoupon}
              checked={isPercent}
              onClick={togglePercentage}
            />
            <span>&nbsp;Percent Discount</span>
          </label>
        </span>
        <div className={styles.formTextInputContainer}>
          <DatePicker
            id={'startDate'}
            label={'Start Date'}
            date={startDate.tz('UTC', true)}
            onChange={datePickerBlur('dateStarted')}
          />
          <br />
        </div>
        {formValidationMsg?.couponExpirationDateErrors && (
          <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
            <FieldValidationIssue
              id={'expireDateValidation'}
              issues={formValidationMsg?.couponExpirationDateErrors || []}
            />
          </div>
        )}
        <div className={styles.formTextInputContainer}>
          <DatePicker
            id={'expireDate'}
            label={'Expire Date'}
            date={expireDate.tz('UTC', true)}
            onChange={datePickerBlur('dateExpired')}
          />
          <br />
        </div>
        <div className={styles.formTextInputContainer}>
          <TextAreaField
            id={'notes'}
            label={'Notes'}
            value={notes || ''}
            onChangeValue={updateDataFieldByValue}
            issues={formValidationMsg?.couponNotesErrors || []}
            validate={validateCouponNotes}
          />
        </div>
        <div>
          <Button
            id="submitUniversalCoupon"
            level="primary"
            onClick={saveCoupon}
          >
            {isEditCoupon ? 'Save Coupon' : 'Create Coupon'}
          </Button>
        </div>
      </Paper>
      <Footer />
      <CouponSavedMessage
        message={exitModalMessage}
        isOpen={isExitModal}
        closeMessage={goToPreviousPage}
      />
    </Responsive.Container>
  );
};

export default UniversalCouponDetails;
