import CacheService from './cache-service';

const PRWebSocial = {
  getSocialAccount: (
    socialMediaType: string,
  ): PRWebSocialAccount | undefined => {
    if (CacheService.exists(socialMediaType)) {
      return CacheService.get(socialMediaType) as PRWebSocialAccount;
    } else {
      return undefined;
    }
  },
  setSocialAccount: (
    socialMediaType: string,
    socialAccount: PRWebSocialAccount,
  ): void => {
    PRWebSocial.removeSocialAccount(socialMediaType);
    CacheService.set(socialMediaType, socialAccount);
  },
  removeSocialAccount: (socialMediaType: string): void => {
    if (CacheService.exists(socialMediaType)) {
      CacheService.remove(socialMediaType);
    }
  },
  isConnectedToSocialMedia: (socialMediaType: string): boolean => {
    const socialAccount = PRWebSocial.getSocialAccount(socialMediaType);
    return socialAccount !== undefined;
  },
};

export default PRWebSocial;
