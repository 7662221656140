import React, { FC, useState, useEffect, useCallback, useContext } from 'react';

import { Button, Paper, Loader, Typography } from '@cision/rover-ui';
import axios, { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ForwardSvg } from '../../../assets/arrow-forward.svg';
import ReceiptModal from '../../../components/receipt-modal';
import WizardContext from '../../../components/wizard/wizard-context';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import OnHoldReasonsList from '../components/on-hold-reasons-list';
import DistributionWizardContext from '../DistributionWizardContext';

import SaveUtility from '../SaveUtility';

import styles from './styles/summary-step.module.css';
interface Props {
  config: PRWebConfig;
}

interface DistributionApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface SubmissionError {
  response: {
    status: number;
    data: DistributionApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const SummaryStep: FC<Props> = ({ config }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const { distributionData, onHoldReasons } = useContext(
    DistributionWizardContext,
  );
  const wizardContext = React.useContext(WizardContext);
  const [submitLabel, setSubmitLabel] = useState('Submit to Editorial');
  const [showReceipt, setShowReceipt] = useState(false);

  const history = useHistory();

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const openModal = () => {
    setShowReceipt(true);
  };

  const closeModal = () => {
    setShowReceipt(false);
  };

  const handleSubmitError = (error: SubmissionError): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  const handleSubmit = useCallback(
    async (): Promise<void> => {
      if (isLoading) return;
      const distributionId = distributionData.distributionId;

      const dataToSend = SaveUtility.getDataToSend(distributionData);

      try {
        setIsLoading(true);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let postResponse: AxiosResponse<any>;

        if (distributionData.isOnHold || !wizardContext.saveRequired) {
          postResponse = await axios.post(
            `${prwebApi}/distribution/item/resubmit`,
            dataToSend,
          );
        } else {
          postResponse = await axios.put(
            `${prwebApi}/distribution/item/${distributionId}/submit`,
          );
        }

        setIsLoading(false);
        if (postResponse.status === 200 || postResponse.status === 201) {
          history.push({ pathname: '/', state: { noConfirm: true } });
        } else {
          handleSubmitError(postResponse.data);
        }
      } catch (error) {
        setIsLoading(false);
        handleSubmitError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  // Update progress
  useEffect(() => {
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wizardContext.setSubmit(handleSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, distributionData]);

  useEffect(() => {
    wizardContext.setPreviewData(distributionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionData]);

  useEffect(() => {
    if (distributionData.isOnHold) {
      setSubmitLabel('Resubmit');
    }
  }, [distributionData.isOnHold]);

  return (
    <div className={styles.stepContainer}>
      <div>
        {distributionData.isOnHold && (
          <OnHoldReasonsList reasons={onHoldReasons} />
        )}
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Summary
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
      <div>
        <Paper className={styles.creditCardContainer}>
          {formErrorSummaryValue.length > 0 && (
            <div className={styles.errorMessage}>
              Error submitting distribution:
              <ul>
                {formErrorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          <div>
            <p>
              Your Order number is{' '}
              <strong>{distributionData.orderNumber}</strong>
            </p>
            <Button
              id={'showReleaseReceiptButton'}
              onClick={openModal}
              level="link"
            >
              <ForwardSvg widths="24" height="24" />
              <span>Show My Receipt</span>
            </Button>
            <ReceiptModal
              config={config}
              distributionId={Number(distributionData.distributionId)}
              isOpen={showReceipt}
              closeModal={closeModal}
            />
          </div>
          <div>
            <strong>
              You are submitting your press release for editorial review.
            </strong>
            <p>
              By clicking “Submit”, I hereby acknowledge and agree to the
              following:{' '}
            </p>
            <ul>
              <li>This is the final version of my press release.</li>
              <li>Once I submit, I will not be able to make edits.</li>
            </ul>
            <p>
              By clicking “Submit”, I acknowledge that I have read and accept
              the{' '}
              <a href="https://www.cision.com/legal/msa/">
                Master Subscription Agreement
              </a>
              ,{' '}
              <a href="https://www.cision.com/legal/service-appendices/pr-services-appendix/">
                PR Services Appendix
              </a>
              , and{' '}
              <a href="https://www.cision.com/legal/service-appendices/pr-services-appendix/">
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <Button
            id={'wizard-next-button'}
            level="primary"
            onClick={handleSubmit}
          >
            {submitLabel}
          </Button>
        </Paper>
      </div>
      <ExitWizardPrompt
        confirmMessage={
          'You have not submitted your release to Editorial yet. Are you sure you want to leave?'
        }
      />
    </div>
  );
};

export default SummaryStep;
