import React, { useState, useEffect } from 'react';

import { Button, Typography } from '@cision/rover-ui';

import FeaturedImageModal from '../featured-image-modal';

import styles from './featured-image.module.css';

interface FeaturedImageProps {
  config: PRWebConfig;
  newsImage?: NewsImage | undefined;
  removeImage: () => void;
  addImage: (image: NewsImage) => void;
}

const FeaturedImage = ({
  config,
  newsImage,
  removeImage,
  addImage,
}: FeaturedImageProps) => {
  const [toggleNewsImageModal, setToggleNewsImageModal] = useState(false);
  const [currentNewsImage, setCurrentNewsImage] = useState<
    NewsImage | undefined
  >(undefined);
  const [showAddButton, setshowAddButton] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const onOpenNewsImageModal = (newsImage?: NewsImage) => {
    setCurrentNewsImage(newsImage);
    setToggleNewsImageModal(true);
  };

  const newsImageModalToggle = () => setToggleNewsImageModal(prev => !prev);

  useEffect(() => {
    const loadImage = async (): Promise<void> => {
      setCurrentNewsImage(newsImage);
      if (newsImage && newsImage.base64Preview) {
        setshowAddButton(false);
        setShowEditButton(true);
        setShowRemoveButton(true);
      } else {
        setshowAddButton(true);
        setShowEditButton(false);
        setShowRemoveButton(false);
      }
    };

    loadImage();
  }, [newsImage]);

  return (
    <div>
      <Typography for="featuredImages">Featured&nbsp;Image</Typography>
      <div className={styles.featuredEditControlsContainer}>
        {showAddButton && (
          <Button
            level="link"
            onClick={() => {
              onOpenNewsImageModal();
            }}
            id={'add-features-image-button'}
            className={styles.featuredImageButton}
          >
            <span>+ Add</span>
          </Button>
        )}
        {showEditButton && (
          <Button
            level="link"
            onClick={() => {
              onOpenNewsImageModal(newsImage);
            }}
            id={'edit-features-image-button'}
            className={styles.featuredImageButton}
          >
            <span>Edit</span>
          </Button>
        )}
        {showRemoveButton && (
          <Button
            level="link"
            onClick={() => {
              removeImage();
            }}
            id={'remove-features-image-button'}
            className={styles.featuredRemoveImageButton}
          >
            <span>-&nbsp;Remove</span>
          </Button>
        )}
      </div>
      {newsImage && newsImage.base64Preview && (
        <div className={styles.newsImageThumbnailContainer}>
          <img
            src={newsImage?.base64Preview}
            alt={newsImage?.altText}
            className={styles.featuredImage}
          />
        </div>
      )}

      {toggleNewsImageModal && (
        <FeaturedImageModal
          config={config}
          isOpen={toggleNewsImageModal}
          addNewsImage={addImage}
          toggle={newsImageModalToggle}
          newsImage={currentNewsImage}
        />
      )}
    </div>
  );
};

export default FeaturedImage;
