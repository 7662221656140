import React, { useEffect, useState } from 'react';

import { Typography, Kite, Loader } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';

import { ReactComponent as WarningSvg } from '../../assets/warning.svg';
import {
  PRWEB_NOTIFY_LINKEDIN,
  PRWEB_LINKEDIN,
  SOCIAL_MEDIA_LINKEDIN,
  SHOW_LINKEDIN_EXPIRY_DAY,
} from '../../constants';
import CacheService from '../../utils/cache-service';
import PRWebSocial from '../../utils/PRWebSocial';

interface SocialWarningProps {
  prwebApiUrl: string;
  prwebSocialMedia: string;
}

const SocialMediaWarning = ({
  prwebApiUrl,
  prwebSocialMedia,
}: SocialWarningProps) => {
  const [showToast, setShowToast] = React.useState(false);
  const [tokenRemainingDays, setTokenRemainingDays] = React.useState(0);
  const prwebApi = prwebApiUrl;
  const [isLoading, setIsLoading] = useState(true);
  const handleCloseToast = () => {
    setShowToast(false);
    if (prwebSocialMedia === SOCIAL_MEDIA_LINKEDIN) {
      const notifyExpiringLinkedin = CacheService.exists(PRWEB_NOTIFY_LINKEDIN);
      if (!notifyExpiringLinkedin) {
        CacheService.set(PRWEB_NOTIFY_LINKEDIN, true);
      }
    }
  };

  const hasExpiringToken = (socialAccount: PRWebSocialAccount) => {
    if (socialAccount != null && socialAccount.TokenExpireDate != null) {
      if (
        moment(socialAccount.TokenExpireDate).isBefore(
          moment().add(SHOW_LINKEDIN_EXPIRY_DAY, 'day'),
        )
      ) {
        setTokenRemainingDays(
          moment(socialAccount.TokenExpireDate).diff(moment(), 'days'),
        );
        setShowToast(true);
      }
    }
  };

  useEffect(() => {
    const initToast = async () => {
      const account = (await getSocialAccount(
        prwebSocialMedia,
      )) as PRWebSocialAccount;
      hasExpiringToken(account);
    };
    initToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSocialAccount = (socialMedia: string) => {
    setIsLoading(true);

    const getSocialAccountsUrl = `${prwebApi}/social/account`;
    if (socialMedia === SOCIAL_MEDIA_LINKEDIN)
      return axios
        .get(getSocialAccountsUrl, {
          params: { socialMedia: socialMedia },
        })
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const socialAccount = {
              Id: response.data.id,
              Type: response.data.type,
              AccountName: response.data.userLoginName,
              Avatar: response.data.avatarLink,
              Authorized: response.data.authorized,
              ExternalApplicationId: response.data.linkedinUserId,
              MaxContentLength: 280, // hardcode
              TokenExpireDate: response.data.tokenExpireDate,
            } as PRWebSocialAccount;
            if (socialAccount && socialAccount.Id) {
              PRWebSocial.setSocialAccount(PRWEB_LINKEDIN, socialAccount);
              return socialAccount;
            } else {
              PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
              return undefined;
            }
          } else {
            console.log('invalid response loading social account: ', response);
            PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
            return undefined;
          }
        })
        .catch(error => {
          console.log('loadcache error retrieving data: ', error);
          PRWebSocial.removeSocialAccount(PRWEB_LINKEDIN);
          return undefined;
        })
        .finally(() => setIsLoading(false));
  };

  const getMessageKite = (
    tokenRemainingDays: number,
    showBundleToast: boolean,
  ) => {
    const expiringMessage =
      tokenRemainingDays >= 0
        ? ' is expiring soon (' + tokenRemainingDays + ' days). '
        : ' has expired. ';

    return (
      <Kite
        style={{ width: '500px' }}
        icon={<WarningSvg width="30px" height="30px" fill="#de4543" />}
        canBeDismissed
        visible={showToast}
        onClose={handleCloseToast}
      >
        <Typography color="black" size="xl" weight="bold">
          Our connection to your {prwebSocialMedia} {expiringMessage}
          Please reconnect your account on&nbsp;
          <a href="/settings/social">settings</a> screen.
        </Typography>
      </Kite>
    );
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && <>{getMessageKite(tokenRemainingDays, showToast)}</>}
    </div>
  );
};

export default SocialMediaWarning;
