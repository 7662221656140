import React, { useState } from 'react';

import DistributionWizardContext from './DistributionWizardContext';
import PRWebPackages from './PRWebPackages';

interface Props {
  children: React.ReactNode;
  distro?: any;
  onHold?: string[];
}

function DistributionWizardProvider({ children, distro, onHold }: Props) {
  const getDefaultDate = () => {
    // default date gives the user a little padding (1 hour) so that
    // they don't get silly validation errors if they just go with the
    // default after sitting on the schedule page for minutes.
    const min = PRWebPackages.getPackageBasedMinTurnaroundDate().add(1, 'hour');
    // console.trace('getDefaultDate min', min);
    return min;
  };

  const [state, setState] = useState<Distribution>({
    orderNumber: distro?.orderNumber ?? '',
    transactionId: distro?.transactionId ?? '',
    distributionId: distro?.distributionId ?? 0,
    prwebPressReleaseId: distro?.prwebPressReleaseId ?? 0,
    distributionVersion: distro?.distributionVersion ?? 0,
    distributionPRWebVersion: distro?.distributionPRWebVersion ?? 0,
    package: distro?.package ?? '',
    headline: distro?.headline ?? '',
    summary: distro?.summary ?? '',
    cityState: distro?.cityState ?? '',
    body: distro?.body ?? '',
    pulloutQuote: distro?.pulloutQuote ?? '',
    primaryContactName: distro?.primaryContactName ?? '',
    primaryContactCompany: distro?.primaryContactCompany ?? '',
    primaryContactWebsite: distro?.primaryContactWebsite ?? '',
    primaryContactEmail: distro?.primaryContactEmail ?? '',
    primaryContactPhoneCountryCode:
      distro?.primaryContactPhoneCountryCode ?? '',
    primaryContactPhone: distro?.primaryContactPhone ?? '',
    primaryContactPhoneExtension: distro?.primaryContactPhoneExtension ?? '',
    secondaryContactName: distro?.secondaryContactName ?? '',
    secondaryContactCompany: distro?.secondaryContactCompany ?? '',
    secondaryContactWebsite: distro?.secondaryContactWebsite ?? '',
    secondaryContactEmail: distro?.secondaryContactEmail ?? '',
    secondaryContactPhoneCountryCode:
      distro?.secondaryContactPhoneCountryCode ?? '',
    secondaryContactPhone: distro?.secondaryContactPhone ?? '',
    secondaryContactPhoneExtension:
      distro?.secondaryContactPhoneExtension ?? '',
    releaseDate:
      distro?.releaseDate ?? getDefaultDate().format('YYYY-MM-DDTHH:mm:ss'),
    releaseDateTimezone: distro?.releaseDateTimezone ?? 'US/Eastern',
    order: {
      package: distro?.order?.package ?? '',
      cjl: distro?.order?.cjl ?? undefined,
      hasCisionSocialPost: distro?.order?.hasCisionSocialPost ?? false,
      priorityReleaseEnabled: distro?.order?.priorityReleaseEnabled ?? false,
    },
    attachments: distro?.attachments ?? [],
    isOnHold: distro?.isOnHold ?? false,
    publicationState: distro?.publicationState ?? 0,
    hasPaid: distro?.hasPaid ?? false,
    cjl: distro?.cjl ?? undefined,
    newsImage: distro?.newsImage ?? undefined,
    cjlSelected: distro?.cjlSelected ?? [],
    hasCisionSocialPost: distro?.hasCisionSocialPost ?? false,
    videoUrl: distro?.videoUrl ?? '',
    cisionSocialPost: distro?.cisionSocialPost ?? '',
    twitterMessage: distro?.twitterMessage ?? '',
    xPostHandle: distro?.xPostHandle ?? '',
    user: distro?.user ?? '',
    userPhone: distro?.userPhone ?? '',
    userPhoneCountryCode: distro?.userPhoneCountryCode ?? '',
    userPhoneExtension: distro?.userPhoneExtension ?? '',
    userEmail: distro?.userEmail ?? '',
    hasPriorityRelease: distro?.hasPriorityRelease ?? false,
    twitterSocialHandle: distro?.twitterSocialHandle ?? undefined,
    linkedinSocialHandle: distro?.linkedinSocialHandle ?? undefined,
    facebookSocialHandle: distro?.facebookSocialHandle ?? undefined,
  });
  // console.trace('default state.releaseDate', state.releaseDate);
  const [onHoldReasons, setOnHoldReasons] = useState<string[]>(onHold ?? []);

  return (
    <DistributionWizardContext.Provider
      value={{
        distributionData: state,
        updateDistributionData: updatedState => {
          setState((prevState: Distribution) => {
            return { ...prevState, ...updatedState };
          });
        },
        // For situations when the app have a partial distribution object and only want to update certain attributes.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateDistributionByField: (topLevelKey: string, value: any) => {
          setState((prevState: Distribution) => {
            return {
              ...prevState,
              [topLevelKey]: value,
            };
          });
        },
        onHoldReasons: onHoldReasons,
      }}
    >
      {children}
    </DistributionWizardContext.Provider>
  );
}

export default DistributionWizardProvider;
