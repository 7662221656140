import React from 'react';

import { Progress } from './wizard-types';

export default React.createContext({
  currentStep: 1,
  isValid: false,
  nextLabel: 'Next',
  backLabel: 'Back',
  isSubmitStep: false,
  saveRequired: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitState: {} as any,
  hasSaveDraftButton: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveDraftButtonState: {} as any,
  hasPreviewButton: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasUpgradeButton: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backButtonIsVisible: true,
  progressState: [] as Progress[],
  progressWhileEditingState: {
    position: 1,
    labelsArray: [] as string[],
    valuesArray: [] as string[],
  },
  isDirty: false,
  loading: false,
  setStepIsValid: (valid: boolean) => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  canMoveStepForwardState: {} as any,
  moveStepForward: () => {
    return;
  },
  moveStepBackward: () => {
    return;
  },
  setNextLabel: (nextLabel: string) => {
    return;
  },
  setBackLabel: (backLabel: string) => {
    return;
  },
  setIsSubmitStep: (submit: boolean) => {
    return;
  },
  setSaveRequired: (isRequired: boolean) => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  setSubmit: ({}: any) => {
    return;
  },
  setHasSaveDraftButton: (hasSaveDraftButton: boolean) => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  setSaveDraftButtonHandler: ({}: any) => {
    return;
  },
  setHasPreviewButton: (hasPreviewButton: boolean) => {
    return;
  },
  setHasUpgradeButton: (hasUpgradeButton: boolean) => {
    return;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  setCanMoveStepForward: ({}: any) => {
    return;
  },
  setProgress: (position: number, progress: Progress) => {
    return;
  },
  setProgressWhileEditingState: ({
    position,
    valuesArray,
    labelsArray = [],
  }: {
    position: number;
    valuesArray: string[];
    labelsArray: string[];
  }) => {
    return;
  },
  setIsDirty: (isDirty: boolean) => {
    return;
  },
  setLoading: (loading: boolean) => {
    return;
  },
  setProgressWhileEditing: (valuesArray: string[], labelsArray?: string[]) => {
    return;
  },
  setBackButtonIsVisible: (isVisible: boolean) => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  canMoveStepBackwardState: {} as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty-pattern
  setCanMoveStepBackward: ({}: any) => {
    return;
  },
  previewDataState: {} as Distribution,
  setPreviewData: (distribution: Distribution) => {
    return;
  },
});
