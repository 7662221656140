import React, { FC, useEffect, useState } from 'react';

import { Button, Typography } from '@cision/rover-ui';

import SelectMenuField from '../../../../components/select-menu-field';
import TextInputField from '../../../../components/text-input-field';
import SubmitDistributionValidator, {
  GenericContactData,
  PrimaryContactValidation,
  SecondaryContactValidation,
  validatePrimaryContactData,
  validateSecondaryContactData,
} from '../../../../validators/SubmitDistributionValidator';

import styles from './contact-panel.module.css';
import { COUNTRY_CODES } from './countryCodes';
import GenericContactPanel from './GenericContactPanel';

interface PrimaryContactProps {
  distributionData: Distribution;
  saveContactData: (contact: GenericContactData | null) => void;
  validation: PrimaryContactValidation | null;
}

interface SecondaryContactProps {
  distributionData: Distribution;
  saveContactData: (contact: GenericContactData | null) => void;
  validation: SecondaryContactValidation | null;
}

const PrimaryContactPanel: FC<PrimaryContactProps> = ({
  distributionData,
  saveContactData,
  validation,
}: PrimaryContactProps) => {
  const [previousSave, setPreviousSave] = useState<GenericContactData>({
    primaryContactName: distributionData.primaryContactName,
    primaryContactCompany: distributionData.primaryContactCompany,
    primaryContactWebsite: distributionData.primaryContactWebsite,
    primaryContactEmail: distributionData.primaryContactEmail,
    primaryContactPhoneCountryCode:
      distributionData.primaryContactPhoneCountryCode,
    primaryContactPhone: distributionData.primaryContactPhone,
    primaryContactPhoneExtension: distributionData.primaryContactPhoneExtension,
  });

  const [
    validationData,
    setValidationData,
  ] = useState<PrimaryContactValidation | null>(validation);

  const [isEditMode, setIsEditMode] = useState(false);

  const openEditMode = () => {
    if (!isEditMode) setIsEditMode(true);
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    const contactData = {
      primaryContactName: distributionData.primaryContactName?.trim(),
      primaryContactCompany: distributionData.primaryContactCompany?.trim(),
      primaryContactWebsite: distributionData.primaryContactWebsite?.trim(),
      primaryContactEmail: distributionData.primaryContactEmail?.trim(),
      primaryContactPhoneCountryCode: distributionData.primaryContactPhoneCountryCode?.trim(),
      primaryContactPhone: distributionData.primaryContactPhone?.trim(),
      primaryContactPhoneExtension: distributionData.primaryContactPhoneExtension?.trim(),
      [key]: value,
    } as GenericContactData;
    saveContactData(contactData);
  };

  const saveData = () => {
    const contactData = {
      primaryContactName: distributionData.primaryContactName?.trim(),
      primaryContactCompany: distributionData.primaryContactCompany?.trim(),
      primaryContactWebsite: distributionData.primaryContactWebsite?.trim(),
      primaryContactEmail: distributionData.primaryContactEmail?.trim(),
      primaryContactPhoneCountryCode: distributionData.primaryContactPhoneCountryCode?.trim(),
      primaryContactPhone: distributionData.primaryContactPhone?.trim(),
      primaryContactPhoneExtension: distributionData.primaryContactPhoneExtension?.trim(),
    } as GenericContactData;

    const validPrimaryContact = validatePrimaryContactData(
      contactData,
    ) as PrimaryContactValidation;

    if (validPrimaryContact !== undefined && validPrimaryContact.valid) {
      setPreviousSave(contactData);
      setValidationData(null);
      setIsEditMode(false);
    } else {
      setValidationData(validPrimaryContact);
    }
  };

  const resetData = () => {
    saveContactData(previousSave);
    setValidationData(null);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (validation !== null && !validation.valid) {
      setIsEditMode(true);
      setValidationData(validation);
    }
  }, [validation]);

  return (
    <GenericContactPanel
      contactNameLabel={distributionData.primaryContactName}
      contactCompanyLabel={distributionData.primaryContactCompany}
      contactWebsiteLabel={distributionData.primaryContactWebsite}
      contactEmailLabel={distributionData.primaryContactEmail}
      contactPhoneCountryCodeLabel={
        distributionData.primaryContactPhoneCountryCode
      }
      contactPhoneLabel={distributionData.primaryContactPhone}
      contactPhoneExtensionLabel={distributionData.primaryContactPhoneExtension}
      isEditMode={isEditMode}
    >
      {{
        contactName: (
          <TextInputField
            id={'primaryContactName'}
            value={distributionData.primaryContactName}
            issues={validationData?.primaryContactNameErrors || []}
            label={'Contact Name (Required)'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactName}
          />
        ),
        contactCompany: (
          <TextInputField
            id={'primaryContactCompany'}
            value={distributionData.primaryContactCompany}
            issues={validationData?.primaryContactCompanyErrors || []}
            label={'Source Company (Required)'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactCompany}
            tooltip={
              "The Source Company field identifies the authoring company or organization that is responsible for issuing the release. For public relations agencies issuing a release on another company's behalf, the Source Company would be your client."
            }
          />
        ),
        contactWebsite: (
          <TextInputField
            id={'primaryContactWebsite'}
            value={distributionData.primaryContactWebsite}
            issues={validationData?.primaryContactWebsiteErrors || []}
            label={'Company URL'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactWebsite}
          />
        ),
        contactEmail: (
          <TextInputField
            id={'primaryContactEmail'}
            value={distributionData.primaryContactEmail}
            issues={validationData?.primaryContactEmailErrors || []}
            label={'Email (Required)'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactEmail}
          />
        ),
        contactPhoneCountryCode: (
          <SelectMenuField
            id={'primaryContactPhoneCountryCode'}
            className={styles.countryCodeMenu}
            label={'Phone Number (Required)'}
            placeholder={'Country Code'}
            value={distributionData.primaryContactPhoneCountryCode}
            issues={validationData?.primaryContactPhoneCountryCodeErrors || []}
            options={COUNTRY_CODES}
            onChangeValue={updateDataFieldByValue}
            validate={
              SubmitDistributionValidator.validateContactPhoneCountryCode
            }
          />
        ),
        contactPhone: (
          <TextInputField
            id={'primaryContactPhone'}
            placeholder={'Enter phone number'}
            value={distributionData.primaryContactPhone}
            issues={validationData?.primaryContactPhoneErrors || []}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactPhone}
          />
        ),
        contactPhoneExtension: (
          <TextInputField
            id={'primaryContactPhoneExtension'}
            value={distributionData.primaryContactPhoneExtension}
            issues={validationData?.primaryContactPhoneExtensionErrors || []}
            placeholder={'Ext.'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactPhoneExtension}
          />
        ),
        cancelContactButton: (
          <Button
            level="tertiary"
            onClick={resetData}
            style={{
              width: '48.5%',
              flex: '0 1 auto',
              margin: '0 .5rem 1rem 0',
              paddingLeft: '40px',
            }}
            id={'primaryContactPanelCancelButton'}
          >
            Cancel
          </Button>
        ),
        saveContactButton: (
          <Button
            onClick={saveData}
            level="primary"
            style={{
              width: '48.5%',
              flex: '0 1 auto',
              margin: '0 0px 1rem 0',
              paddingLeft: '50px',
            }}
            id={`primaryContactPanelSaveButton`}
          >
            Save
          </Button>
        ),
        contactLabel: (
          <div
            id={'primaryContactPanelEditButton'}
            className={styles.contactTitle}
            onClick={openEditMode}
            style={{ cursor: isEditMode ? 'default' : 'pointer' }}
          >
            <Typography weight="bold" size="md">
              Public Contact
            </Typography>
            {!isEditMode && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <title>edit</title>
                <path
                  fill="#677078"
                  d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                />
              </svg>
            )}
          </div>
        ),
      }}
    </GenericContactPanel>
  );
};

const SecondaryContactPanel: FC<SecondaryContactProps> = ({
  distributionData,
  saveContactData,
  validation,
}: SecondaryContactProps) => {
  const [previousSave, setPreviousSave] = useState<GenericContactData>({
    secondaryContactName: distributionData.secondaryContactName,
    secondaryContactCompany: distributionData.secondaryContactCompany,
    secondaryContactWebsite: distributionData.secondaryContactWebsite,
    secondaryContactEmail: distributionData.secondaryContactEmail,
    secondaryContactPhoneCountryCode:
      distributionData.secondaryContactPhoneCountryCode,
    secondaryContactPhone: distributionData.secondaryContactPhone,
    secondaryContactPhoneExtension:
      distributionData.secondaryContactPhoneExtension,
  });

  const [
    validationData,
    setValidationData,
  ] = useState<SecondaryContactValidation | null>(validation);

  const isFieldEmpty = () => {
    if (
      distributionData.secondaryContactName ||
      distributionData.secondaryContactCompany ||
      distributionData.secondaryContactWebsite ||
      distributionData.secondaryContactEmail ||
      distributionData.secondaryContactPhoneCountryCode ||
      distributionData.secondaryContactPhone ||
      distributionData.secondaryContactPhoneExtension
    ) {
      return false;
    }
    return true;
  };

  const [isEditMode, setIsEditMode] = useState<boolean>(isFieldEmpty());

  const updateDataFieldByValue = (key: string, value?: string): void => {
    const contactData = {
      secondaryContactName: distributionData.secondaryContactName?.trim(),
      secondaryContactCompany: distributionData.secondaryContactCompany?.trim(),
      secondaryContactWebsite: distributionData.secondaryContactWebsite?.trim(),
      secondaryContactEmail: distributionData.secondaryContactEmail?.trim(),
      secondaryContactPhoneCountryCode: distributionData.secondaryContactPhoneCountryCode?.trim(),
      secondaryContactPhone: distributionData.secondaryContactPhone?.trim(),
      secondaryContactPhoneExtension: distributionData.secondaryContactPhoneExtension?.trim(),
      [key]: value,
    } as GenericContactData;
    saveContactData(contactData);
  };

  const openEditMode = () => {
    if (!isEditMode) setIsEditMode(true);
  };

  const saveData = () => {
    const contactData = {
      secondaryContactName: distributionData.secondaryContactName?.trim(),
      secondaryContactCompany: distributionData.secondaryContactCompany?.trim(),
      secondaryContactWebsite: distributionData.secondaryContactWebsite?.trim(),
      secondaryContactEmail: distributionData.secondaryContactEmail?.trim(),
      secondaryContactPhoneCountryCode: distributionData.secondaryContactPhoneCountryCode?.trim(),
      secondaryContactPhone: distributionData.secondaryContactPhone?.trim(),
      secondaryContactPhoneExtension: distributionData.secondaryContactPhoneExtension?.trim(),
    } as GenericContactData;

    const validSecondaryContact = validateSecondaryContactData(
      contactData,
    ) as SecondaryContactValidation;

    if (validSecondaryContact !== undefined && validSecondaryContact.valid) {
      setPreviousSave(contactData);
      setValidationData(null);
      setIsEditMode(false);
    } else {
      setValidationData(validSecondaryContact);
    }
  };

  const resetData = () => {
    saveContactData(previousSave);
    setValidationData(null);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (validation !== null && !validation.valid) {
      setIsEditMode(true);
      setValidationData(validation);
    }
  }, [validation]);

  return (
    <GenericContactPanel
      contactNameLabel={distributionData.secondaryContactName}
      contactCompanyLabel={distributionData.secondaryContactCompany}
      contactWebsiteLabel={distributionData.secondaryContactWebsite}
      contactEmailLabel={distributionData.secondaryContactEmail}
      contactPhoneCountryCodeLabel={
        distributionData.secondaryContactPhoneCountryCode
      }
      contactPhoneLabel={distributionData.secondaryContactPhone}
      contactPhoneExtensionLabel={
        distributionData.secondaryContactPhoneExtension
      }
      isEditMode={isEditMode}
    >
      {{
        contactName: (
          <TextInputField
            id={'secondaryContactName'}
            value={distributionData.secondaryContactName}
            issues={validationData?.secondaryContactNameErrors || []}
            label={'Contact Name'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateOptionalContactName}
          />
        ),
        contactCompany: (
          <TextInputField
            id={'secondaryContactCompany'}
            value={distributionData.secondaryContactCompany}
            issues={validationData?.secondaryContactCompanyErrors || []}
            label={'Company'}
            onChangeValue={updateDataFieldByValue}
            validate={
              SubmitDistributionValidator.validateOptionalContactCompany
            }
          />
        ),
        contactWebsite: (
          <TextInputField
            id={'secondaryContactWebsite'}
            value={distributionData.secondaryContactWebsite}
            issues={validationData?.secondaryContactWebsiteErrors || []}
            label={'Company URL'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactWebsite}
          />
        ),
        contactEmail: (
          <TextInputField
            id={'secondaryContactEmail'}
            value={distributionData.secondaryContactEmail}
            issues={validationData?.secondaryContactEmailErrors || []}
            label={'Email'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateOptionalContactEmail}
          />
        ),
        contactPhoneCountryCode: (
          <SelectMenuField
            id={'secondaryContactPhoneCountryCode'}
            className={styles.countryCodeMenu}
            label={'Phone Number'}
            placeholder={'Country Code'}
            value={distributionData.secondaryContactPhoneCountryCode}
            issues={
              validationData?.secondaryContactPhoneCountryCodeErrors || []
            }
            options={COUNTRY_CODES}
            onChangeValue={updateDataFieldByValue}
            validate={
              SubmitDistributionValidator.validateOptionalContactPhoneCountryCode
            }
          />
        ),
        contactPhone: (
          <TextInputField
            id={'secondaryContactPhone'}
            placeholder={'Enter phone number'}
            value={distributionData.secondaryContactPhone}
            issues={validationData?.secondaryContactPhoneErrors || []}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateOptionalContactPhone}
          />
        ),
        contactPhoneExtension: (
          <TextInputField
            id={'secondaryContactPhoneExtension'}
            value={distributionData.secondaryContactPhoneExtension}
            issues={validationData?.secondaryContactPhoneExtensionErrors || []}
            placeholder={'Ext.'}
            onChangeValue={updateDataFieldByValue}
            validate={SubmitDistributionValidator.validateContactPhoneExtension}
          />
        ),
        cancelContactButton: (
          <Button
            level="tertiary"
            onClick={resetData}
            style={{
              width: '48.5%',
              flex: '0 1 auto',
              margin: '0 .5rem 1rem 0',
              paddingLeft: '40px',
            }}
            id={'secondaryContactPanelCancelButton'}
          >
            Cancel
          </Button>
        ),
        saveContactButton: (
          <Button
            onClick={saveData}
            level="primary"
            style={{
              width: '48.5%',
              flex: '0 1 auto',
              margin: '0 0px 1rem 0',
              paddingLeft: '50px',
            }}
            id={`secondaryContactPanelSaveButton`}
          >
            Save
          </Button>
        ),
        contactLabel: (
          <div
            id={'secondaryContactPanelEditButton'}
            className={styles.contactTitle}
            onClick={openEditMode}
            style={{ cursor: isEditMode ? 'default' : 'pointer' }}
          >
            <Typography weight="bold" size="md">
              Additional Public Contact
            </Typography>
            {!isEditMode && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <title>edit</title>
                <path
                  fill="#677078"
                  d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                />
              </svg>
            )}
          </div>
        ),
      }}
    </GenericContactPanel>
  );
};

export { PrimaryContactPanel, SecondaryContactPanel };
