import React, { useState, useEffect } from 'react';

import {
  Responsive,
  Typography,
  Modal,
  Button,
  Loader,
  Paper,
  EasyDropdown,
} from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import { useParams, useHistory } from 'react-router-dom';

import Confirm from '../../components/confirm';
import Footer from '../../components/footer';
import Header from '../../components/header';
import NavigationBackwardArrow from '../../components/navigation-backward-arrow/navigation-backward-arrow';
import {
  PRW_SID,
  C3_AVAILABLE,
  USER_GROUP_COOKIE,
  LOGIN_AS,
  USER_GROUP,
  VPO_GROUP,
  FINANCE_GROUP,
  EDITOR_GROUP,
  ADMIN_GROUP,
} from '../../constants';
import HttpStatusCode from '../../httpStatusCodes';
import CacheService from '../../utils/cache-service';
import { UserAccount } from '../../validators/SettingsAccountValidator';

import styles from './user-details.module.css';

interface Props {
  config: PRWebConfig;
}

interface EmailVerificationProp {
  id: number;
  value: string;
  isVerified: boolean;
}

const UserDetails: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const prwebApi = props.config.prwebApi.url;
  const currentGroup = cookie.get(USER_GROUP_COOKIE);
  const { id: userId } = useParams();
  const [user, setUser] = useState<UserAccount | undefined>(undefined);
  const [emailVerify, setEmailVerify] = useState<
    EmailVerificationProp[] | undefined
  >(undefined);
  const [errorSummaryValue, setErrorSummaryValue] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [
    toggleConfirmAlreadyLoggedIn,
    setToggleConfirmAlreadyLoggedIn,
  ] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [
    confirmMessageAlreadyLoggedIn,
    setConfirmMessageAlreadyLoggedIn,
  ] = useState('');
  const [userGroup, setUserGroup] = useState<string>('');

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [archiveReleaseCounts, setArchiveReleaseCounts] = useState([]);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [passwordValidationMsg, setPasswordValidationMsg] = useState('');

  const [showOktaMfaModal, setShowOktaMfaModal] = useState(false);
  const [oktaMfaValidationMsg, setOktaMfaValidationMsg] = useState('');
  const [openUserGroup, setOpenUserGroup] = useState(false);

  const changePasswordResetModal = (isOpen: boolean) => () => {
    setIsModalLoading(false);
    setPasswordValidationMsg('');
    setShowPasswordResetModal(isOpen);
  };

  const resetOktaMFAModal = (isOpen: boolean) => () => {
    setIsModalLoading(false);
    setOktaMfaValidationMsg('');
    setShowOktaMfaModal(isOpen);
  };

  const oktaMfaReset = async () => {
    setIsModalLoading(true);
    const resetOktaMfaUrl = `${prwebApi}/admin/resetmfa`;
    await axios
      .post(resetOktaMfaUrl, {
        userId: user?.id,
      })
      .then(response => {
        if (response.status === 200) {
          setShowOktaMfaModal(false);
        }
      })
      .catch(error => {
        setIsModalLoading(false);
        if (error.message) {
          setOktaMfaValidationMsg(error.message);
        } else {
          setOktaMfaValidationMsg('Unable to reset Okta Multi-factor security');
        }
      });
  };

  const emailPasswordReset = async () => {
    setIsModalLoading(true);
    const resetPasswordUrl = `${prwebApi}/admin/temppassword`;
    await axios
      .post(resetPasswordUrl, {
        userId: user?.id,
      })
      .then(response => {
        if (response.status === 200) {
          setShowPasswordResetModal(false);
        }
      })
      .catch(error => {
        setIsModalLoading(false);
        if (error.message) {
          setPasswordValidationMsg(error.message);
        } else {
          setPasswordValidationMsg('Unable to reset password at this time.');
        }
      });
  };

  const handleError = (error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: { status: number; data: { errors: any }; statusText: string };
    message: string;
  }): void => {
    let errors: Array<string> = [];

    if (error.response && typeof error.response.data === 'string') {
      errors.push(error.response.data);
    } else if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setErrorSummaryValue(errors);
  };

  const handleBackButtonClick = (): void => {
    history.goBack();
  };

  const onImpersonate = (): void => {
    setConfirmMessage(
      `Are you sure you want to Login as the User ${user?.oktaLogin}?`,
    );
    setToggleConfirm(true);
    setUserGroup('');
  };

  const onChangeUserGroup = (usergroup: string) => () => {
    setConfirmMessage(
      `Are you sure you want to change the group to ${usergroup} for user ${user?.oktaLogin}?`,
    );
    setToggleConfirm(true);
    setUserGroup(usergroup);
  };

  const handleUserGroup = (): void => {
    setOpenUserGroup(!openUserGroup);
  };

  const onConfirmYes = async () => {
    setToggleConfirm(false);
    setIsLoading(true);

    if (userGroup) {
      await changeUserGroup(userGroup);
    } else {
      await impersonate(false);
    }
  };

  const onConfirmToggleAlreadyLoggedInYes = async () => {
    setToggleConfirmAlreadyLoggedIn(false);
    setIsLoading(true);

    await impersonate(true);
  };

  const changeUserGroup = async (usergroup: string) => {
    const makeAdminUrl = `${prwebApi}/admin/users/setgroup`;

    const payload = { id: Number(userId), group: usergroup };

    await axios
      .post(makeAdminUrl, payload)
      .then(async response => {
        if (response && response.status === 200) {
          await getUser();
        } else {
          handleError(response.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        handleError(error);
        setIsLoading(false);
      });
  };

  const getMenuItems = () => {
    const items = [
      {
        label: 'User',
        onClick: onChangeUserGroup('USER'),
      },
      {
        label: 'VirtualPressOffice',
        onClick: onChangeUserGroup(VPO_GROUP),
      },
      {
        label: 'Accounting',
        onClick: onChangeUserGroup(FINANCE_GROUP),
      },
      {
        label: 'Editor',
        onClick: onChangeUserGroup(EDITOR_GROUP),
      },
    ];
    if (currentGroup === EDITOR_GROUP) {
      return items;
    } else if (currentGroup === ADMIN_GROUP) {
      items.push({
        label: 'Admin',
        onClick: onChangeUserGroup(ADMIN_GROUP),
      });
      return items;
    }
    return [];
  };

  const impersonate = async (force: boolean) => {
    let impersonateUrl = `${prwebApi}/admin/users/impersonate/${userId}`;
    if (force) {
      impersonateUrl += '?force=true';
    }

    await axios
      .post(impersonateUrl, {})
      .then(response => {
        if (response && response.status === 200) {
          cookie.set(
            PRW_SID,
            JSON.stringify({
              sid: response.data.sid,
              oktaLogin: response.data.oktaLogin,
            }),
          );

          cookie.set(C3_AVAILABLE, response.data.c3available);

          cookie.set(USER_GROUP_COOKIE, response.data.group);

          cookie.set(LOGIN_AS, response.data.impersonating);

          CacheService.removeAll();

          history.push({
            pathname: '/',
          });
        } else {
          handleError(response.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === HttpStatusCode.LOCKED) {
          const sessionHasBeenActive = error.response.data || 0;
          const sessionHasBeenActiveStr =
            sessionHasBeenActive < 60
              ? `${sessionHasBeenActive} seconds`
              : `${Math.floor(sessionHasBeenActive / 60)} minutes`;
          setConfirmMessageAlreadyLoggedIn(
            `This user was last active ${sessionHasBeenActiveStr} ago. Impersonating them and entering the Create Release workflow without first asking the user to log out may cause issues within the press release. Do you wish to continue with impersonation?`,
          );
          setToggleConfirmAlreadyLoggedIn(true);
        } else {
          handleError(error);
        }
      });
  };

  const onConfirmNo = () => {
    setToggleConfirm(false);
    setUserGroup('');
  };

  const onConfirmAlreadyLoggedInNo = () => {
    setToggleConfirmAlreadyLoggedIn(false);
  };

  const getUser = async () => {
    const getResponse = await axios.get(`${prwebApi}/admin/user/${userId}`);

    if (getResponse.status === 200 || getResponse.status === 201) {
      const user = getResponse.data;
      if (!user || !user.id) {
        setErrorSummaryValue(['The requested user could not be retrieved.']);
      } else {
        setUser({ ...user });
      }
    } else {
      handleError(getResponse.data);
    }
  };

  const setEmailVerification = (verifyId: number) => async () => {
    const response = await axios.post(`${prwebApi}/admin/users/verify`, {
      id: verifyId,
    });

    if (response.status === 200) {
      await getEmailVerifications();
    }
  };

  const getEmailVerifications = async () => {
    const response = await axios.get(
      `${prwebApi}/admin/users/verify?userId=${userId}`,
    );

    if (response.status === 200 && response.data) {
      setEmailVerify(response.data);
    }
  };
  const getArchiveReleaseCounts = async () => {
    const getResponse = await axios.get(
      `${prwebApi}/admin/user/${userId}/archivereleasecounts`,
    );

    if (getResponse.status === 200 || getResponse.status === 201) {
      const counts = getResponse.data;
      if (counts) {
        setArchiveReleaseCounts(counts);
      }
    } else {
      handleError(getResponse.data);
    }
  };
  useEffect(() => {
    const loadUser = async () => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        await getUser();
        await getEmailVerifications();
        await getArchiveReleaseCounts();
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Responsive.Container>
      <Header prwebApiUrl={prwebApi} />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Paper className={styles.userDetailContainer}>
            <Button
              id={'details-back-button'}
              modifiers={['tertiary', 'round', 'with-icon']}
              onClick={handleBackButtonClick}
            >
              <div className={styles.backButton}>
                <NavigationBackwardArrow />
                <span className={styles.buttonMargin}>{'back'}</span>
              </div>
            </Button>
            {errorSummaryValue.length > 0 && (
              <div className={styles.errorMessage}>
                Error:
                <ul>
                  {errorSummaryValue.map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className={styles.spacingWrapper}>
              <p className={styles.loginUsername}>
                <Typography size="md" color="black">
                  Login Username:
                </Typography>{' '}
                {user?.oktaLogin}
              </p>
              <p>
                <Typography size="md" color="black">
                  Email:
                </Typography>{' '}
                {user?.email}
              </p>
              <p>
                <Typography size="md" color="black">
                  Name:
                </Typography>{' '}
                {user?.firstName} {user?.lastName}
              </p>
              <p>
                <Typography size="md" color="black">
                  State:
                </Typography>{' '}
                {user?.state}
              </p>
              <p>
                <Typography size="md" color="black">
                  Country:
                </Typography>{' '}
                {user?.country}
              </p>
              <p>
                <Typography size="md" color="black">
                  Organization:
                </Typography>{' '}
                {user?.organization}
              </p>
              <p>
                <Typography size="md" color="black">
                  Phone:
                </Typography>{' '}
                {user?.phone}
              </p>
              <p>
                <Typography size="md" color="black">
                  Group:
                </Typography>{' '}
                {user?.group || USER_GROUP}
              </p>
              <p>
                <Typography size="md" color="black">
                  c3CompanyId:
                </Typography>{' '}
                {user?.c3CompanyId}
              </p>
              {user?.userSendDistributionToIris && (
                <p>
                  <Typography size="xl2" weight="bold">
                    <div style={{ color: 'red' }}>DirectToIris</div>
                  </Typography>
                </p>
              )}
            </div>
          </Paper>

          <Paper className={styles.userDetailContainer}>
            <Typography size="xl" color="black" tag="h2">
              Email Verifications
            </Typography>
            <table className={styles.lineItemTable} id="emailVerificationList">
              <tbody>
                {(emailVerify || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.value}</td>
                      <td style={{ textAlign: 'right', height: '32px' }}>
                        {item.isVerified ? (
                          <Typography weight="bold" color="black" tag="span">
                            Verified
                          </Typography>
                        ) : (
                          <Button
                            className={styles.rowButtons}
                            onClick={setEmailVerification(item.id)}
                          >
                            Verify
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Paper>
          <Paper className={styles.userDetailContainer}>
            <Typography size="xl" color="black">
              Archived Releases
            </Typography>
            <p>(archived releases are imported from Legacy E-Commerce)</p>
            <div>
              <table style={{ textAlign: 'left', width: '70%' }}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th># Releases Imported</th>
                  </tr>
                </thead>
                <tbody>
                  {archiveReleaseCounts.map((c: any, i) => (
                    <tr key={i}>
                      <td>{c.companyLoginName}</td>
                      <td>{c.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Paper>
          <Paper className={styles.userDetailContainer}>
            <Typography size="xl" color="black">
              Security
            </Typography>
            <div className={styles.spacingWrapper}>
              <Button
                level="secondary"
                id="reset-password-button"
                onClick={changePasswordResetModal(true)}
                style={{ marginRight: '15px' }}
              >
                Reset Password
              </Button>
              <Button
                level="secondary"
                id="reset-okta-mfa"
                onClick={resetOktaMFAModal(true)}
                style={{ marginRight: '15px' }}
              >
                Reset Okta Multi-factor
              </Button>
              {user?.group !== ADMIN_GROUP && (
                <Button
                  level="primary"
                  id={'impersonate-button'}
                  style={{
                    marginLeft: '15px',
                  }}
                  onClick={onImpersonate}
                >
                  <span>Impersonate</span>
                </Button>
              )}
              <EasyDropdown
                id={'userGroup'}
                style={{
                  marginLeft: '15px',
                }}
                menuItems={getMenuItems()}
                onClick={handleUserGroup}
                isOpen={openUserGroup}
              >
                Change User Group
              </EasyDropdown>
            </div>
          </Paper>
        </div>
      )}
      <Modal size="sm" isOpen={showOktaMfaModal}>
        <Modal.Header>
          <Typography weight="bold" color="black" size="xl" tag="p">
            Reset User&apos;s Okta MFA
          </Typography>
          <Typography color="black" size="md" tag="p">
            Reset all okta multi-factor authentication for user with login:{' '}
            <Typography weight="bold" color="black" size="md" tag="span">
              {user?.oktaLogin}
            </Typography>
            {oktaMfaValidationMsg && (
              <div className={styles.errorMessage}>{oktaMfaValidationMsg}</div>
            )}
          </Typography>
        </Modal.Header>
        <Modal.Footer className={styles.modalFooter}>
          {isModalLoading ? (
            <Loader />
          ) : (
            <>
              <Button
                id="cancel-okta-mfa-reset"
                onClick={resetOktaMFAModal(false)}
              >
                Cancel
              </Button>
              <Button
                id="confirm-okta-mfa-reset"
                level="danger"
                onClick={oktaMfaReset}
              >
                Confirm
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal size="sm" isOpen={showPasswordResetModal}>
        <Modal.Header>
          <Typography weight="bold" color="black" size="xl" tag="p">
            Send reset password email
          </Typography>
          <Typography color="black" size="md" tag="p">
            Reset password for user with login:{' '}
            <Typography weight="bold" color="black" size="md" tag="span">
              {user?.oktaLogin}
            </Typography>
            {passwordValidationMsg && (
              <div className={styles.errorMessage}>{passwordValidationMsg}</div>
            )}
          </Typography>
        </Modal.Header>
        <Modal.Footer className={styles.modalFooter}>
          {isModalLoading ? (
            <Loader />
          ) : (
            <>
              <Button
                id="cancel-password-reset"
                onClick={changePasswordResetModal(false)}
              >
                Cancel
              </Button>
              <Button
                id="confirm-password-reset"
                level="danger"
                onClick={emailPasswordReset}
              >
                Confirm
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Footer />
      <Confirm
        isOpen={toggleConfirm}
        text={confirmMessage}
        yes={onConfirmYes}
        no={onConfirmNo}
      ></Confirm>
      <Confirm
        isOpen={toggleConfirmAlreadyLoggedIn}
        text={confirmMessageAlreadyLoggedIn}
        yes={onConfirmToggleAlreadyLoggedInYes}
        no={onConfirmAlreadyLoggedInNo}
      ></Confirm>
    </Responsive.Container>
  );
};

export default UserDetails;
