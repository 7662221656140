import React, { useEffect, useCallback } from 'react';

import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import WizardContext from '../../../../components/wizard/wizard-context';

import styles from './payment-step.module.css';

interface StripeProps {
  isHandlingPayment: boolean;
  isDisabled: boolean;
  onChange: (key: string, value?: string) => void;
  stripePay: (error: any) => void;
  handleError?: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const StripePaymentForm = ({
  isHandlingPayment,
  isDisabled,
  onChange,
  stripePay,
  handleError,
}: StripeProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const wizardData = React.useContext(WizardContext);

  const handleSubmit = useCallback(
    async (): Promise<boolean> => {
      if (isHandlingPayment || !stripe || !elements) return false;
      onChange('handlingPayment', 'true');

      return await stripe
        .confirmPayment({
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          elements, // has credit info
          confirmParams: {
            // Make sure to change this to your payment completion page
            // eslint-disable-next-line @typescript-eslint/camelcase
            return_url: 'http://localhost:3334/',
          },
          redirect: 'if_required',
        })

        .then((response: any) => {
          if ('error' in response && 'payment_intent' in response.error) {
            handleStripeError(response);
            return Promise.reject(false);
          }
          return response;
        })
        .then(stripePay)
        .then((response: any) => {
          console.log(response);
          return response;
        })
        .catch((error: Error) => {
          const msg = error.message || 'unable to process payment';
          console.log(msg);
          return false;
        })
        .finally(() => {
          onChange('handlingPayment', 'false');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, stripe, wizardData.loading, stripePay, isHandlingPayment],
  );

  const handleStripeError = (response: any) => {
    if (handleError) {
      handleError([
        response.error.message || 'Unable to confirm payment information.',
      ]);
    }
  };

  const handlePaymentChange = (event: any) => {
    if (event.complete) {
      onChange('stripeFormComplete', 'true');
    } else {
      onChange('stripeFormComplete', 'false');
    }
  };

  useEffect(() => {
    wizardData.setCanMoveStepForward(handleSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  return (
    <PaymentElement
      id="payment-element"
      className={isDisabled ? styles.disableStripeElement : ''}
      onChange={handlePaymentChange}
    ></PaymentElement>
  );
};

export default StripePaymentForm;
