import React from 'react';

import { Accordion, ExpansionPanel } from '@cision/rover-ui';

import { ReactComponent as DownArrow } from '../../../../assets/arrow-down.svg';

import styles from './onhold-reasons.module.css';

interface Props {
  reasons: Array<string>;
}

const OnHoldReasonsList = ({ reasons }: Props) => {
  return (
    <div id={'onHoldReasonsList'}>
      <Accordion defaultExpandedPanel={0} className={styles.onholdList}>
        <ExpansionPanel>
          <ExpansionPanel.Header
            className={styles.onholdHeader}
            expandIcon={
              <DownArrow
                width="10"
                height="10"
                viewBox="0 0 640 640"
                fill="white"
              />
            }
          >
            Your release is on hold. Revisions are required.
          </ExpansionPanel.Header>
          <ExpansionPanel.Body className={styles.onholdReason}>
            {(reasons || []).map((reason, idx) => {
              return (
                <div className={styles.onholdItems} key={idx}>
                  <li>{reason}</li>
                </div>
              );
            })}
          </ExpansionPanel.Body>
        </ExpansionPanel>
      </Accordion>
    </div>
  );
};

export default OnHoldReasonsList;
