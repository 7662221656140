import cloneDeep from 'lodash/cloneDeep';

const SaveUtility = {
  getDataToSend: (distribution: Distribution): Distribution => {
    const dataToSend = cloneDeep(distribution) as Distribution;

    if (dataToSend.body) {
      dataToSend.body = dataToSend.body
        .replace(/(&nbsp;)+/g, ' ')
        .replace(/(&amp;)+/g, '&');
    }

    if (dataToSend.primaryContactPhone) {
      dataToSend.primaryContactPhone = dataToSend.primaryContactPhone.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }
    if (dataToSend.primaryContactPhoneCountryCode) {
      dataToSend.primaryContactPhoneCountryCode = dataToSend.primaryContactPhoneCountryCode.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }
    if (dataToSend.primaryContactPhoneExtension) {
      dataToSend.primaryContactPhoneExtension = dataToSend.primaryContactPhoneExtension.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }

    if (dataToSend.secondaryContactPhone) {
      dataToSend.secondaryContactPhone = dataToSend.secondaryContactPhone.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }
    if (dataToSend.secondaryContactPhoneCountryCode) {
      dataToSend.secondaryContactPhoneCountryCode = dataToSend.secondaryContactPhoneCountryCode.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }
    if (dataToSend.secondaryContactPhoneExtension) {
      dataToSend.secondaryContactPhoneExtension = dataToSend.secondaryContactPhoneExtension.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }

    if (dataToSend.userPhone) {
      dataToSend.userPhone = dataToSend.userPhone.replace(/[^\x20-\x7F]/g, '');
    }
    if (dataToSend.userPhoneCountryCode) {
      dataToSend.userPhoneCountryCode = dataToSend.userPhoneCountryCode.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }
    if (dataToSend.userPhoneExtension) {
      dataToSend.userPhoneExtension = dataToSend.userPhoneExtension.replace(
        /[^\x20-\x7F]/g,
        '',
      );
    }

    if (dataToSend.newsImage && dataToSend.newsImage.base64Preview) {
      dataToSend.newsImage.base64Preview = undefined;
    }

    if (dataToSend.attachments) {
      dataToSend.attachments.forEach(
        attach => (attach.base64Preview = undefined),
      );
    }

    return dataToSend;
  },

  handleApiErrors: (error: ErrorType): Array<string> => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
      if ('data' in error.response) {
        errors.push(error.response.data.toString());
      }
    } else {
      errors.push(error.message);
      if ('data' in error.response) {
        errors.push(error.response.data.toString());
      }
    }

    return errors;
  },
};

export default SaveUtility;
