import moment from 'moment-timezone';
import validator from 'validator';

interface Validation {
  required?: (...params: string[]) => string;
  notNumeric?: (...params: string[]) => string;
  isNumeric?: (...params: string[]) => string;
  isBetween?: (...params: string[]) => string;
  minLength?: (...params: string[]) => string;
  maxLength?: (...params: string[]) => string;
  allLowercase?: (...params: string[]) => string;
  allUppercase?: (...params: string[]) => string;
  isEmail?: (...params: string[]) => string;
  noHtml?: (...params: string[]) => string;
  noSpaces?: (...params: string[]) => string;
  isAfterOrSame?: (...params: string[]) => string;
  matchRegex?: (...params: string[]) => string;
  twoDecimalPlaces?: (...params: string[]) => string;
}

interface ErrorProp {
  value?: string;
  invalidMsg?: Validation;
  results?: string[];
}

const Validator = (
  { value = '', invalidMsg = {}, results = [] } = {} as ErrorProp,
) => ({
  value,
  invalidMsg,
  results,
  required() {
    if (validator.isEmpty(this.value || '')) {
      const msg = this.invalidMsg?.required
        ? this.invalidMsg.required()
        : 'Field is Required.';
      this.results.push(msg);
    }
    return this;
  },
  notNumeric() {
    if (
      !validator.isEmpty(this.value || '') &&
      validator.isNumeric(this.value)
    ) {
      const msg = this.invalidMsg?.notNumeric
        ? this.invalidMsg.notNumeric()
        : 'Field cannot be all digits.';
      this.results.push(msg);
    }
    return this;
  },
  isNumeric() {
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.isNumeric(this.value)
    ) {
      const msg = this.invalidMsg?.isNumeric
        ? this.invalidMsg.isNumeric()
        : 'Field must be digits.';
      this.results.push(msg);
    }
    return this;
  },
  isBetween(start: number, end: number, isInclusive?: boolean) {
    if (
      !validator.isEmpty(this.value || '') &&
      validator.isNumeric(this.value)
    ) {
      const num = Number(this.value);
      if (isInclusive && !(start <= num && num <= end)) {
        const msg = this.invalidMsg?.isBetween
          ? this.invalidMsg.isBetween(start.toString(), end.toString())
          : `Field must be between ${start} and ${end}`;
        this.results.push(msg);
      } else if (!(start < num && num < end)) {
        const msg = this.invalidMsg?.isBetween
          ? this.invalidMsg.isBetween(start.toString(), end.toString())
          : `Field must be between ${start} and ${end}`;
        this.results.push(msg);
      }
    }
    return this;
  },
  minLength(minLength?: number) {
    const min = minLength !== undefined ? minLength : 55;
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.isLength(this.value, { min })
    ) {
      const msg = this.invalidMsg?.minLength
        ? this.invalidMsg.minLength(`${min}`)
        : `Field is too short, please revise with at least ${min} characters.`;
      this.results.push(msg);
    }
    return this;
  },
  maxLength(maxLength?: number) {
    const max = maxLength !== undefined ? maxLength : 170;
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.isLength(this.value, { max })
    ) {
      const msg = this.invalidMsg?.maxLength
        ? this.invalidMsg.maxLength(`${max}`)
        : `Field is too long, please stay within ${max} characters.`;
      this.results.push(msg);
    }
    return this;
  },
  allLowerCase() {
    if (
      !validator.isEmpty(this.value || '') &&
      validator.isLowercase(this.value)
    ) {
      const msg = this.invalidMsg?.allLowercase
        ? this.invalidMsg.allLowercase()
        : 'Field cannot be exclusively lower case characters.';
      this.results.push(msg);
    }
    return this;
  },
  allUpperCase() {
    if (
      !validator.isEmpty(this.value || '') &&
      validator.isUppercase(this.value)
    ) {
      const msg = this.invalidMsg?.allUppercase
        ? this.invalidMsg.allUppercase()
        : 'Field cannot be exclusively upper case characters.';
      this.results.push(msg);
    }
    return this;
  },
  isEmail() {
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.isEmail(this.value)
    ) {
      const msg = this.invalidMsg?.isEmail
        ? this.invalidMsg.isEmail()
        : 'Field must be a valid email format.';
      this.results.push(msg);
    }
    return this;
  },
  noHtml() {
    const regExp = new RegExp(/(<\/*.*\/*>*|[<>])/);
    if (
      !validator.isEmpty(this.value || '') &&
      validator.matches(this.value, regExp)
    ) {
      const msg = this.invalidMsg?.noHtml
        ? this.invalidMsg.noHtml()
        : 'Field cannot contain html markup.';
      this.results.push(msg);
    }
    return this;
  },
  noSpaces() {
    const regExp = new RegExp(/\s/);
    if (
      !validator.isEmpty(this.value || '') &&
      validator.matches(this.value, regExp)
    ) {
      const msg = this.invalidMsg?.noSpaces
        ? this.invalidMsg.noSpaces()
        : 'Field cannot contain spaces.';
      this.results.push(msg);
    }
    return this;
  },
  matchRegex(regex: string | RegExp) {
    const regExp = new RegExp(regex);
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.matches(this.value, regExp)
    ) {
      const msg = this.invalidMsg?.matchRegex
        ? this.invalidMsg.matchRegex()
        : 'Field text is not a valid format.';
      this.results.push(msg);
    }
    return this;
  },
  isAfterOrSame(after?: string) {
    const afterDate = after || '';
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.isEmpty(afterDate) &&
      !moment(this.value).isSameOrAfter(moment(afterDate))
    ) {
      const msg = this.invalidMsg?.isAfterOrSame
        ? this.invalidMsg.isAfterOrSame(moment(afterDate).format('M/D/YYYY'))
        : `Field must be after ${moment(afterDate).format('M/D/YYYY')}`;
      this.results.push(msg);
    }
    return this;
  },
  twoDecimalPlaces() {
    const regExp = new RegExp(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/);
    if (
      !validator.isEmpty(this.value || '') &&
      !validator.matches(this.value, regExp)
    ) {
      const msg = this.invalidMsg?.twoDecimalPlaces
        ? this.invalidMsg.twoDecimalPlaces()
        : 'Field cannot have more than 2 decimal places';
      this.results.push(msg);
    }
    return this;
  },
  customFormat(callback: (val: string) => string) {
    const msg = callback(this.value);
    if (msg) {
      this.results.push(msg);
    }
    return this;
  },
});

export { Validation };
export default Validator;
