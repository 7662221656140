import React, { FC, useState, useEffect, useCallback, useContext } from 'react';

import { Button, Paper, Loader, Typography } from '@cision/rover-ui';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import WizardContext from '../../../components/wizard/wizard-context';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import DistributionWizardContext from '../DistributionWizardContext';

import SaveUtility from '../SaveUtility';

import styles from './styles/summary-step.module.css';
interface Props {
  config: PRWebConfig;
}

interface DistributionApiValidationIssues {
  message: string;
  errors: Array<string>;
}

interface SubmissionError {
  response: {
    status: number;
    data: DistributionApiValidationIssues;
    statusText: string;
  };
  message: string;
}

const PublishingStep: FC<Props> = ({ config }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const { distributionData } = useContext(DistributionWizardContext);
  const wizardContext = React.useContext(WizardContext);
  const history = useHistory();

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;

  const handleSubmitError = (error: SubmissionError): void => {
    let errors: Array<string> = [];

    if (error.response.status === 400 && error.response.data.errors) {
      errors = [...error.response.data.errors];
    } else if (error.response && error.response.statusText) {
      errors.push(error.response.statusText);
    } else {
      errors.push(error.message);
    }
    setFormErrorSummaryValue(errors);
  };

  const handleRepublish = useCallback(
    async (): Promise<void> => {
      if (isLoading) return;
      setIsLoading(true);

      const distributionId = distributionData.distributionId;
      const dataToSend = SaveUtility.getDataToSend(distributionData);

      const saveResponse = await axios.post(
        `${prwebApi}/distribution/item/saveDraft`,
        dataToSend,
      );

      if (saveResponse.status === 200 || saveResponse.status === 201) {
        const publishResponse = await axios.post(
          `${prwebApi}/distribution/item/publish`,
          {
            distributionId,
          },
        );

        if (publishResponse.status === 200) {
          history.push({ pathname: '/', state: { noConfirm: true } });
        } else {
          handleSubmitError(publishResponse.data);
          setIsLoading(false);
        }
      } else {
        handleSubmitError(saveResponse.data);
        setIsLoading(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  // Update progress
  useEffect(() => {
    wizardContext.setHasPreviewButton(true);
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wizardContext.setSubmit(handleRepublish);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRepublish, distributionData]);

  useEffect(() => {
    wizardContext.setPreviewData(distributionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionData]);

  return (
    <div className={styles.stepContainer}>
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Publishing
          </Typography>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
      <div>
        <Paper className={styles.creditCardContainer}>
          {formErrorSummaryValue.length > 0 && (
            <div className={styles.errorMessage}>
              Error republishing distribution:
              <ul>
                {formErrorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          <div>
            <strong>
              By clicking &quot;Republish&quot; you are republishing this press
              release to PRWeb.com.
            </strong>
            <br />
            <br />
          </div>
          <Button
            id={'republish-button'}
            level="primary"
            onClick={handleRepublish}
          >
            Republish
          </Button>
        </Paper>
      </div>
      <ExitWizardPrompt
        confirmMessage={
          'You have not republished your release yet. Are you sure you want to leave?'
        }
      />
    </div>
  );
};

export default PublishingStep;
