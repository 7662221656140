import React, { useState, FormEvent, useEffect } from 'react';

import { Input, Typography, Tooltip } from '@cision/rover-ui';

import { ReactComponent as InfoSvg } from '../../assets/info.svg';
import FieldValidationIssue from '../field-validation-issue';

import styles from './textinputfield.module.css';
import './textinputfield.scss';

interface Props {
  id: string;
  placeholder?: string;
  value?: string;
  issues: string[];
  label?: string;
  validate?: (value?: string) => Array<string>;
  onChangeValue?: (key: string, value?: string) => void;
  onEnterPressed?: () => void;
  tabIndex?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  tooltip?: string;
  extraInfoLabel?: string;
}
const TextInputField: React.FC<Props> = ({
  id,
  placeholder,
  value,
  issues,
  label,
  validate,
  onChangeValue,
  onEnterPressed,
  tabIndex,
  disabled = false,
  autoFocus = false,
  tooltip,
  extraInfoLabel,
}: Props) => {
  const [currentIssues, updateCurrentIssues] = useState<string[]>(issues);
  const [currentValue, updateCurrentValue] = useState<string | undefined>(
    value,
  );

  const changeHandler = (e: FormEvent): void => {
    const newValue = (e.target as HTMLInputElement).value;
    updateCurrentValue(newValue);
    if (newValue !== currentValue) {
      if (validate !== undefined) {
        const newIssues = validate(newValue);
        updateCurrentIssues(newIssues);
      }
      if (onChangeValue !== undefined) {
        onChangeValue(id, newValue);
      }
    }
  };

  const pressedHandler = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && onEnterPressed) {
      onEnterPressed();
    }
  };

  useEffect(() => {
    updateCurrentIssues(issues);
  }, [issues]);

  useEffect(() => {
    updateCurrentValue(value);
  }, [value]);

  return (
    <div
      id={`${id}-text_input_field_component_container`}
      className={styles.formGroup}
    >
      {label && (
        <Typography
          className={styles.inputLabel}
          htmlFor={id}
          size="md"
          tag="label"
        >
          {label}
          {tooltip && (
            <Tooltip
              showOnHover
              direction="right"
              tooltipWidth="300px"
              content={<div>{tooltip}</div>}
            >
              <InfoSvg className={styles.icon} />
            </Tooltip>
          )}
        </Typography>
      )}
      <Input
        id={id}
        className={styles.inputField}
        placeholder={placeholder || ''}
        onChange={changeHandler}
        onKeyPress={pressedHandler}
        value={currentValue}
        tabIndex={tabIndex}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {currentIssues && currentIssues.length > 0 && (
        <FieldValidationIssue issues={currentIssues} id={id} />
      )}
      {extraInfoLabel && (
        <Typography htmlFor={'login'} size="sm" className={styles.formText}>
          {extraInfoLabel}
        </Typography>
      )}
    </div>
  );
};

export default TextInputField;
