import React from 'react';

import { Paper } from '@cision/rover-ui';

import styles from './radio-select-card.module.css';

interface StylableCardProps {
  children: React.ReactNode[];
  id?: string;
  index?: number;
  selectable?: boolean;
  className?: string;
  onClick?: (index: number) => void;
}
const StylableCard = ({
  children,
  id,
  index,
  selectable = true,
  className,
  onClick,
}: StylableCardProps) => {
  let cardStyle = `${styles.cardContainer}`;
  if (className) {
    cardStyle += ` ${className}`;
  }
  if (selectable) {
    cardStyle += ` ${styles.selectableCard}`;
  }

  const handleCardClick = (event: React.MouseEvent) => {
    onClick && onClick(index ?? -1);
  };

  return (
    <Paper className={cardStyle}>
      <div id={id} onClick={handleCardClick}>
        {children}
      </div>
    </Paper>
  );
};

interface RadioSelectField {
  children: React.ReactNode[];
  label: string;
  selectedIndex?: number;
  onChangeValue: (key: string, value?: number) => void;
}
const RadioSelectField = (props: RadioSelectField) => {
  const handleCardClick = (index: number) => {
    props.onChangeValue(props.label, index);
  };

  const childWithProps = React.Children.map(props.children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: index,
        className:
          index === props.selectedIndex
            ? `${styles.centeredCard} ${styles.selectedCardBorder} ${styles.selectedCardColor}`
            : styles.centeredCard,
        onClick: handleCardClick,
      });
    }
    return child;
  });

  return <>{childWithProps}</>;
};

export { StylableCard };
export default RadioSelectField;
