import React, { useEffect, useContext, useCallback, useState } from 'react';

import { Paper, Loader, Button, Typography } from '@cision/rover-ui';
import axios from 'axios';

import { ReactComponent as ShareSvg } from '../../../assets/share.svg';
import { ReactComponent as TrophySvg } from '../../../assets/trophy-solid.svg';
import { ReactComponent as XSvg } from '../../../assets/x-brands.svg';
import Kite from '../../../components/kite';
import WizardContext from '../../../components/wizard/wizard-context';
import { PRWEB_TWITTER, SOCIAL_MEDIA_TWITTER } from '../../../constants';
import PRWebSocial from '../../../utils/PRWebSocial';
import CJLModal from '../components/amplify-cjl-modal';
import CSPModal from '../components/amplify-csp-modal';
import TwitterModal from '../components/amplify-twitter-modal';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import OnHoldReasonsList from '../components/on-hold-reasons-list';
import DistributionWizardContext from '../DistributionWizardContext';
import PRWebPackages from '../PRWebPackages';
import SaveUtility from '../SaveUtility';

import styles from './styles/amplify-step.module.css';
import './styles/AmplifyStep.scss';

interface Props {
  config: PRWebConfig;
}

const AmplifyStep: React.FC<Props> = ({ config }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);

  const {
    distributionData,
    updateDistributionData,
    onHoldReasons,
  } = useContext(DistributionWizardContext);
  const wizardContext = React.useContext(WizardContext);

  const [hasSavedDraft, setHasSavedDraft] = useState(false);

  const [availableCjlCount, setAvailableCjlCount] = useState(
    distributionData.cjl || 0,
  );

  const [isConnectedToTwitter, setIsConnectedToTwitter] = useState(
    PRWebSocial.isConnectedToSocialMedia(PRWEB_TWITTER),
  );

  const [toggleCJLModal, setToggleCJLModal] = useState(false);
  const [toggleCSPModal, setToggleCSPModal] = useState(false);
  const [toggleTwitterModal, setToggleTwitterModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const prwebApi = config.prwebApi.url;

  const cjlModalToggle = () => setToggleCJLModal(prev => !prev);
  const cspModalToggle = () => setToggleCSPModal(prev => !prev);
  const twitterModalToggle = () => setToggleTwitterModal(prev => !prev);

  const onOpenCJLModal = () => {
    setToggleCJLModal(true);
  };

  const addCJLSelected = (cjlSelected: Array<PRWebIndustryOutletCatgory>) => {
    updateDistributionData({
      ...distributionData,
      cjlSelected,
    });
    setIsDirty(true);
  };

  const onOpenSocialPostModal = () => {
    setToggleCSPModal(true);
  };

  const addSocialPost = (cisionSocialPost: string) => {
    updateDistributionData({
      ...distributionData,
      cisionSocialPost,
    });
    setIsDirty(true);
  };

  const onShareOnTwitterModal = () => {
    setToggleTwitterModal(true);
  };

  const addTwitterMessage = (twitterMessage: string) => {
    let xPostHandle = '';
    if (twitterMessage.trim() !== '') {
      const twitterAccount = PRWebSocial.getSocialAccount(PRWEB_TWITTER);
      xPostHandle = twitterAccount?.AccountName || '';
    }
    updateDistributionData({
      ...distributionData,
      twitterMessage,
      xPostHandle,
    });
    setIsDirty(true);
  };

  const onCloseTwitterModal = () => {
    setIsConnectedToTwitter(
      PRWebSocial.isConnectedToSocialMedia(PRWEB_TWITTER),
    );
  };

  const handleApiValidationError = (error: ErrorType): void => {
    const errors: Array<string> = SaveUtility.handleApiErrors(error);
    setFormErrorSummaryValue(errors);
  };

  const saveDraft = useCallback(
    async () => {
      if (isLoading) {
        return false;
      }

      setHasSavedDraft(false);
      setIsLoading(true);

      const dataToSend = SaveUtility.getDataToSend(distributionData);

      try {
        const postResponse = await axios.post(
          `${prwebApi}/distribution/item/saveDraft`,
          dataToSend,
        );

        if (postResponse.status === 200 || postResponse.status === 201) {
          updateDistributionData({
            ...distributionData,
            prwebPressReleaseId:
              postResponse.data.result.prwebPressReleaseId || 0,
            distributionId: postResponse.data.result.distributionId || 0,
            distributionVersion:
              postResponse.data.result.distributionVersion || 0,
            distributionPRWebVersion:
              postResponse.data.result.distributionPRWebVersion || 0,
            releaseDate: postResponse.data.result.releaseDate,
          });

          setHasSavedDraft(true);
          setIsLoading(false);
          setIsDirty(false);
          return true;
        } else {
          handleApiValidationError(postResponse.data);
        }
      } catch (error) {
        handleApiValidationError(error);
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setIsLoading,
      isLoading,
      distributionData.cjlSelected,
      distributionData.cisionSocialPost,
      distributionData.twitterMessage,
    ],
  );

  const saveAndBack = useCallback(async () => {
    if (!isDirty) return true;
    if (!wizardContext.saveRequired) {
      return true;
    }

    return await saveDraft();
  }, [isDirty, wizardContext.saveRequired, saveDraft]);

  const saveAndContinue = useCallback(async () => {
    if (!wizardContext.saveRequired) {
      return true;
    }

    return await saveDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setIsLoading,
    isLoading,
    wizardContext.saveRequired,
    distributionData.cjlSelected,
    distributionData.cisionSocialPost,
    distributionData.twitterMessage,
  ]);

  useEffect(() => {
    wizardContext.setStepIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepForward(saveAndContinue), [
    saveAndContinue,
  ]);

  // Update progress
  useEffect(() => {
    wizardContext.setProgressWhileEditing([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wizardContext.setSaveDraftButtonHandler(saveDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveDraft]);

  useEffect(() => {
    if (PRWebPackages.isAdvancedPackage(distributionData.package)) {
      setAvailableCjlCount(availableCjlCount + 1);
    } else if (PRWebPackages.isPremiumPackage(distributionData.package)) {
      setAvailableCjlCount(availableCjlCount + 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isConnectedToTwitter) {
      setIsLoading(true);
      const getSocialAccountsUrl = `${prwebApi}/social/account?socialMedia=Twitter`;

      axios
        .get(getSocialAccountsUrl, {})
        .then(response => {
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const twitterAccount = response.data.find(
              (t: PRWebSocialAccount) => t.Type === SOCIAL_MEDIA_TWITTER,
            );

            if (twitterAccount) {
              setIsConnectedToTwitter(true);
            } else {
              setIsConnectedToTwitter(false);
            }
          } else {
            console.log('invalid response loading social account: ', response);
            setIsConnectedToTwitter(false);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.log('loadcache error retrieving data: ', error);
          setIsConnectedToTwitter(false);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepBackward(saveAndBack), [
    saveAndBack,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setPreviewData(distributionData), [
    distributionData,
  ]);

  return (
    <div className={styles.stepContainer}>
      <Kite status="success" shouldShow={hasSavedDraft} ttl={8000}>
        Draft saved
      </Kite>
      {distributionData.isOnHold && (
        <OnHoldReasonsList reasons={onHoldReasons} />
      )}
      <div>
        <div className={styles.stepTitle}>
          <Typography size="xl2" color="black" weight="bold">
            Amplify
          </Typography>
        </div>
        <div className={styles.stepSubtitle}>
          <Typography className={styles.font16}>
            <span className={styles.font16}>
              Target specific industry influencers across multiple channels
            </span>
          </Typography>
        </div>
      </div>
      {isLoading ? <Loader /> : null}
      <div>
        {formErrorSummaryValue.length > 0 && (
          <div className={styles.errorMessage}>
            Error saving draft:
            <ul>
              {formErrorSummaryValue.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div id={'amplifyContainer'}>
        {/* CJL */}
        <Paper className={styles.amplifyContainer}>
          <TrophySvg className={styles.icon} />
          <div
            className={`${styles.amplifyNameContainer} ${styles.amplifyName} ${styles.amplifyColors}`}
          >
            <div className={styles.amplifyNameTitle}>
              <Typography size="lg" color="inherit" weight="bold">
                Cision Journalist Lists
              </Typography>
            </div>
            <div className={styles.amplifyDescriptionContainer}>
              <Typography color="inherit">
                Gain opportunities for earned media pick up. email your press
                release directly to top industry journalists with Cision&apos;s
                curated industry lists.
              </Typography>
            </div>
          </div>
          <div className={styles.amplifySelection}>
            <Typography color="black">
              You have selected{' '}
              <strong>
                {distributionData.cjlSelected
                  ? distributionData.cjlSelected.length
                  : 0}
                /{availableCjlCount} items
              </strong>
            </Typography>
            <div className={styles.amplifyButton}>
              <Button
                id={'add-cjl-button'}
                level="secondary"
                disabled={availableCjlCount === 0}
                onClick={onOpenCJLModal}
              >
                {distributionData.cjlSelected &&
                distributionData.cjlSelected.length > 0
                  ? 'Edit'
                  : 'Add'}
              </Button>
            </div>
          </div>
        </Paper>
        {/* Twitter */}
        <Paper className={styles.amplifyContainer}>
          <ShareSvg className={styles.icon} />
          <div
            className={`${styles.amplifyNameContainer} ${styles.amplifyName} ${styles.amplifyColors}`}
          >
            <div className={styles.amplifyNameTitle}>
              <Typography size="lg" color="inherit" weight="bold">
                Share on X
              </Typography>
            </div>
            <div className={styles.amplifyDescriptionContainer}>
              <Typography color="inherit">
                Easily engage your friends and followers by automatically
                posting your press release to your brand&apos;s owned social
                media networks.
              </Typography>
            </div>
          </div>
          <div className={styles.amplifySelection}>
            <Typography color="black">
              {!isConnectedToTwitter
                ? 'Connect your account'
                : 'Craft your post'}
            </Typography>
            <div className={styles.amplifyButton}>
              <Button
                id={'share-on-twitter-add-button'}
                level="secondary"
                onClick={onShareOnTwitterModal}
              >
                {!isConnectedToTwitter
                  ? 'Connect'
                  : distributionData.twitterMessage
                  ? 'Edit'
                  : 'Add'}
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      <CJLModal
        config={config}
        addCJLSelected={addCJLSelected}
        toggle={cjlModalToggle}
        isOpen={toggleCJLModal}
        availableCjlCount={availableCjlCount}
        cjlSelected={distributionData.cjlSelected}
      ></CJLModal>
      <CSPModal
        addSocialPost={addSocialPost}
        toggle={cspModalToggle}
        isOpen={toggleCSPModal}
        socialPost={distributionData.cisionSocialPost}
      ></CSPModal>
      <TwitterModal
        config={config}
        addTwitterMessage={addTwitterMessage}
        toggle={twitterModalToggle}
        isOpen={toggleTwitterModal}
        twitterMessage={distributionData.twitterMessage}
        onClose={onCloseTwitterModal}
      ></TwitterModal>
      <ExitWizardPrompt />
    </div>
  );
};

export default AmplifyStep;
