import React, { FC, useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import Wizard from '../../components/wizard/wizard';
import WizardContext from '../../components/wizard/wizard-context';
import WizardProgress from '../../components/wizard/wizard-progress';
import WizardStep from '../../components/wizard/wizard-step';
import { PRWEB_PACKAGES, PRWEB_FEATURES } from '../../constants';
import CacheService from '../../utils/cache-service';
import PRWebFeatures from '../../utils/PRWebFeatures';

import Prid from '../distribution-wizard/components/prid';

import AddOnStep from './steps/AdditionsStep';
import PaymentStep from './steps/PaymentStep';
import SelectPackageStep from './steps/SelectPackageStep';
import StripePaymentStep from './steps/StripePaymentStep';
import SummaryStep from './steps/SummaryStep';

import UpgradeWizardProvider from './UpgradeWizardProvider';

interface Props {
  envConfig: PRWebConfig;
  savedUpgrade?: Upgrade;
  initialProgress: Array<string>;
}

const UpgradeWizardSteps: FC<Props> = ({
  envConfig,
  savedUpgrade,
  initialProgress,
}: Props) => {
  const history = useHistory();
  const [stripePromise, setStripePromise] = useState<any>(undefined);

  const cacheIsLoaded = (): boolean => {
    return (
      CacheService.exists(PRWEB_FEATURES) && CacheService.exists(PRWEB_PACKAGES)
    );
  };

  if (!cacheIsLoaded()) {
    console.log(
      'Cache unexpectedly empty UpgradeWizardSteps, redirecting to /',
    );
    history.push({ pathname: '/' });
  }

  useEffect(() => {
    const stripePromise = loadStripe(envConfig.stripe.publishableKey);
    setStripePromise(stripePromise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getWizard = (wizardData: any) => {
    return (
      <Wizard>
        <>
          <UpgradeWizardProvider distro={savedUpgrade}>
            <Prid />
            <WizardStep order={1} nextLabel="Next">
              <SelectPackageStep />
            </WizardStep>
            <WizardStep order={2}>
              <AddOnStep />
            </WizardStep>
            <WizardStep order={3} nextLabel="Pay">
              <StripePaymentStep
                config={envConfig}
                stripePromise={stripePromise}
              />
            </WizardStep>
            <WizardStep order={4} backButtonIsVisible={false} submit>
              <SummaryStep config={envConfig} />
            </WizardStep>
          </UpgradeWizardProvider>

          <WizardProgress
            title={'Release Upgrade'}
            initialProgress={initialProgress}
            maxVisibleCount={4}
          />
        </>
      </Wizard>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getUpgradeSteps = (wizardData: any) => {
    return getWizard(wizardData);
  };

  return (
    <WizardContext.Consumer>
      {wizardData => {
        return getUpgradeSteps(wizardData);
      }}
    </WizardContext.Consumer>
  );
};

export default UpgradeWizardSteps;
