import React from 'react';

import { Paper, Typography, Checkbox } from '@cision/rover-ui';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';

import { ReactComponent as PayPalSvg } from '../../../../assets/third-party/PayPal.svg';

import styles from './payment-step.module.css';

interface Props {
  children: React.ReactElement;
  isDisabled?: boolean | undefined;
  isStripe?: boolean | undefined;
  stripeSecret: { intentId: string; clientSecret: string };
  stripePromise: Promise<Stripe | null>;
  onChange: (key: string, value?: string) => void;
}

const PaymentSelector = ({
  children,
  isDisabled,
  isStripe,
  stripeSecret,
  stripePromise,
  onChange,
}: Props) => {
  const handleCheckboxClick = () => {
    if (isStripe !== undefined) {
      onChange('isStripe');
    }
  };

  return (
    <>
      <Paper style={{ margin: '8px' }}>
        <div className={styles.columns}>
          <Checkbox
            id={'creditcard-checkbox'}
            height="50"
            width="50"
            checked={isStripe}
            disabled={isDisabled}
            onClick={handleCheckboxClick}
          ></Checkbox>
          <Typography color="black" weight="bold" size="lg">
            &nbsp;Credit Card and Billing Information
          </Typography>
        </div>
        <div
          className={styles.storePaymentForm}
          style={{ display: isStripe ? 'inherit' : 'none' }}
        >
          {stripeSecret?.clientSecret && (
            <Elements
              key={stripeSecret.intentId}
              options={{
                clientSecret: stripeSecret.clientSecret,
                appearance: { theme: 'stripe' },
              }}
              stripe={stripePromise}
            >
              {children}
            </Elements>
          )}
        </div>
      </Paper>

      <Paper className={styles.creditCardContainer}>
        <span className={styles.spaceRight}>
          <Checkbox
            id={'paypal-checkbox'}
            disabled={isDisabled}
            checked={!isStripe}
            onClick={handleCheckboxClick}
          ></Checkbox>
        </span>
        <PayPalSvg width="100" height="24" />
        {!isStripe && (
          <div className={styles.spaceTop}>
            {!isDisabled && (
              <Typography>
                Click the &quot;PayPal button&quot; to be taken to PayPal to
                complete your payment.
              </Typography>
            )}
          </div>
        )}
      </Paper>
    </>
  );
};

export default PaymentSelector;
