import { Validation } from './GenericValidator';

const InvalidMsg = {
  headlineValidation: {
    required: () => 'Please provide a press release headline.',
    notNumeric: () =>
      'Your press release headline cannot be exclusively digits.',
    noHtml: () => 'Your press release headline cannot have html markup.',
    minLength: min =>
      `Your press release headline must have at least ${min} characters.`,
    maxLength: max =>
      `Your press release headline cannot exceed ${max} characters.`,
    allLowercase: () =>
      'Your press release headline cannot be exclusively lower case characters.',
    allUppercase: () =>
      'Your press release headline cannot be exclusively upper case characters.',
  } as Validation,

  summaryValidation: {
    required: () => 'Please provide a press release summary.',
    notNumeric: () =>
      'Your press release summary cannot be exclusively digits.',
    noHtml: () => 'Your press release summary cannot have html markup.',
    minLength: min =>
      `Your press release summary must have at least ${min} characters.`,
    maxLength: max =>
      `Your press release summary cannot exceed ${max} characters.`,
    allLowercase: () =>
      'Your press release summary cannot be exclusively lower case characters.',
    allUppercase: () =>
      'Your press release summary cannot be exclusively upper case characters.',
  } as Validation,

  cityStateValiation: {
    required: () => 'Please provide a press release dateline.',
    notNumeric: () =>
      'Your press release city and state cannot be exclusively digits.',
    noHtml: () => 'Your press release city and state cannot have html markup.',
    maxLength: max =>
      `Your press release city and state cannot exceed ${max} characters.`,
  } as Validation,

  videoUrlValidation: {
    matchRegex: () => 'Please provide a valid YouTube URL.',
  } as Validation,

  bodyValidation: {
    required: () => 'Please provide a press release body.',
    notNumeric: () => 'Your press release body cannot be exclusively digits.',
    minLength: min =>
      `Your press release body must have at least ${min} characters.`,
    allLowercase: () =>
      'Your press release body cannot be exclusively lower case characters.',
    allUppercase: () =>
      'Your press release body cannot be exclusively upper case characters.',
  } as Validation,

  pulloutQuoteValidation: {
    required: () => 'Please provide a press release quote.',
    maxLength: max => `Your pull out quote cannot exceed ${max} characters.`,
    noHtml: () => 'Your pull out quote cannot have html markup.',
  } as Validation,

  contactNameValidation: {
    required: () => 'Please provide a contact name.',
    notNumeric: () => 'Contact name cannot be exclusively digits.',
    noHtml: () => 'Contact name cannot have html markup.',
    maxLength: max => `Contact name cannot exceed ${max} characters.`,
  } as Validation,

  contactCompanyValidation: {
    required: () => 'Please provide a contact company.',
    notNumeric: () => 'Contact company cannot be exclusively digits.',
    noHtml: () => 'Contact company cannot have html markup.',
    maxLength: max => `Contact company cannot exceed ${max} characters.`,
  } as Validation,

  contactWebsiteValidation: {
    maxLength: max => `Contact website cannot exceed ${max} characters.`,
    noHtml: () => 'Contact website cannot have html markup.',
  } as Validation,

  contactEmailValidation: {
    required: () => 'Please provide a contact email.',
    isEmail: () => 'Please provide a valid email address format.',
    noHtml: () => 'Contact email cannot have html markup.',
    maxLength: max => `Contact email cannot exceed ${max} characters.`,
  } as Validation,

  contactPhoneCountryCodeValidation: {
    required: () => 'Please select a country code.',
    maxLength: max => `Country code cannot exceed ${max} characters.`,
  } as Validation,

  contactPhoneValidation: {
    required: () => 'Please provide a contact phone.',
    noHtml: () => 'Contact phone cannot have html markup.',
    minLength: min => `Contact phone must have at least ${min} characters.`,
    maxLength: max => `Contact phone cannot exceed ${max} characters.`,
  } as Validation,

  contactPhoneExtensionValidation: {
    maxLength: max =>
      `Contact phone extension cannot exceed ${max} characters.`,
    noHtml: () => 'Contact phone extension cannot have html markup.',
  } as Validation,
};

export default InvalidMsg;
