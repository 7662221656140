export const PRW_SID = 'prw.sid';
export const C3_AVAILABLE = 'c3.available';
export const USER_GROUP_COOKIE = 'userGroup';
export const LOGIN_AS = 'loginAs';
export const PARTNER_ID = 'partnerId';
export const IS_NG_USER = 'isNgUser';
export const PRWEB_PACKAGES = 'prweb.packages';
export const PRWEB_BUNDLES = 'prweb.bundles';
export const PRWEB_PARTNER = 'prweb.partner';
export const PRWEB_NOTIFY_BUNDLES = 'prweb.notifyBundles';
export const PRWEB_NOTIFY_LINKEDIN = 'prweb.notifyLikedin';
export const VALID_EMAIL_PATTERN = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+.[A-Za-z]{2,4}$/;
export const PRWEB_VERIFIED_EMAILS = 'prweb.verified-emails';
export const PRWEB_FEATURES = 'prweb.features';
export const PRWEB_PUBLIC_FEATURES = 'prweb.publicFeatures';
export const SHOW_LINKEDIN_EXPIRY_DAY = 30;
export const CJL_PRICE = 225;
export const CSP_PRICE = 110;
export const PRIORITY_PRICE = 110;
export const BASIC_PACKAGE_ID = '80';
export const STANDARD_PACKAGE_ID = '140';
export const ADVANCED_PACKAGE_ID = '200';
export const PREMIUM_PACKAGE_ID = '360';
export const PRWEB_CJL = 'prweb.cjl';
export const CSP_MAX_LENGTH = 90;
export const PRWEB_TWITTER = 'prweb.twitter';
export const PRWEB_FACEBOOK = 'prweb.facebook';
export const PRWEB_LINKEDIN = 'prweb.linkedin';
export const SOCIAL_MEDIA_TWITTER = 'Twitter';
export const SOCIAL_MEDIA_FACEBOOK = 'Facebook';
export const SOCIAL_MEDIA_LINKEDIN = 'Linkedin';
export const ADMIN_GROUP = 'ADMIN';
export const EDITOR_GROUP = 'EDITOR';
export const VPO_GROUP = 'VIRTUALPRESSOFFICE';
export const FINANCE_GROUP = 'FINANCE';
export const USER_GROUP = 'USER';
export const COUPON_STATUS_EXPIRED = 'EXPIRED';
export const NOTIFY_SESSION_TIME_IN_SEC = 600;
export const NO_REFRESH_TIME_IN_SEC = 60;
export const TO_PERCENT = 100;
export const TO_DECIMAL = 0.01;
export const PRWEB_USER_INFO = 'prweb.user.info';
export enum DISTRO_STATUS {
  DRAFT_MODE = 'Draft mode',
  PENDING_EDITORIAL_REVIEW = 'Pending editorial review',
  PENDING_DISTRIBUTION = 'Pending distribution',
  ONHOLD = 'On hold',
  PUBLISHED = 'Published',
}

export enum COUPON_STATUS {
  UNUSED = 'unused',
  REVOKED = 'revoked',
  USED = 'used',
}
