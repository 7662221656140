import React, { useEffect } from 'react';

import { Paper, Typography, Button, Modal, Input } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ForwardSvg } from '../../assets/arrow-forward.svg';
import { ReactComponent as AmexSvg } from '../../assets/third-party/amex-logo.svg';
import { ReactComponent as DiscoverSvg } from '../../assets/third-party/discover-logo.svg';
import { ReactComponent as MCSvg } from '../../assets/third-party/mc-logo.svg';
import { ReactComponent as VisaSvg } from '../../assets/third-party/visa-logo.svg';

import WizardContext from '../../components/wizard/wizard-context';
import {
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
  STANDARD_PACKAGE_ID,
} from '../../constants';
import ExitWizardPrompt from '../../pages/distribution-wizard/components/exit-wizard-prompt';
import useStateWithCallback from '../../utils/useStateWithCallback';

import BundleSummary from './BundleSummary';
import styles from './store-confirmation-steps.module.css';

interface Props {
  config: PRWebConfig;
  orderNumber: string;
  bundleOrderId: number;
  storeCart: BundlePackagePrice[];
  payment: Payment;
  stripePayment: { intentId: string; clientSecret: string };
  isStripePayment: boolean;
}

const StoreConfirmationStep = ({
  config,
  orderNumber,
  bundleOrderId,
  storeCart,
  payment,
  stripePayment,
  isStripePayment,
}: Props) => {
  const prwebApi = config.prwebApi.url;
  const wizardData = React.useContext(WizardContext);
  const [showRenewalModal, setShowRenewalModal] = React.useState(false);
  const [renewalPermission, setRenewalPermission] = React.useState(false);
  // stripe state varaibles
  const [subscriptionName, setSubscriptionName] = React.useState('');
  const [subscribeSuccess, setSubscribeSuccess] = React.useState('');
  const [subscribeValidationMsg, setSubscribeValidationMsg] = React.useState(
    '',
  );
  // authnet state variables
  const [paymentProfileName, setPaymentProfileName] = React.useState('');
  const [paymentProfileSuccess, setPaymentProfileSuccess] = React.useState('');
  const [
    saveProfileValidationMsg,
    setSaveProfileValidationMsg,
  ] = React.useState('');

  const [createReleaseBtn, setCreateReleaseBtn] = React.useState({
    [STANDARD_PACKAGE_ID]: 0,
    [ADVANCED_PACKAGE_ID]: 0,
    [PREMIUM_PACKAGE_ID]: 0,
  });
  const history = useHistory();

  const goToMyRelease = () => {
    history.push({ pathname: '/', state: { noConfirm: true } });
  };

  const goToCreateRelease = (packageId: string) => () => {
    history.push({
      pathname: '/distribution/create',
      state: { packageId, noConfrim: true },
    });
  };

  const changeRenewalModal = (display: boolean) => () =>
    setShowRenewalModal(display);

  const changeRenewalPermission = () => {
    setRenewalPermission(!renewalPermission);
  };

  const changeSubscriptionName = (event: React.ChangeEvent) => {
    const newValue = (event.target as HTMLInputElement).value;
    setSubscriptionName(newValue);
  };

  const changePaymentProfileName = (event: React.ChangeEvent) => {
    const newValue = (event.target as HTMLInputElement).value;
    setPaymentProfileName(newValue);
  };

  const confirmStripeRenewal = async () => {
    if (subscriptionName === '') {
      setSubscribeValidationMsg(
        'Please provide a nickname for this subscription.',
      );
      return;
    } else if (!renewalPermission) {
      setSubscribeValidationMsg(
        'Please check renew my subscription to continue.',
      );
      return;
    } else {
      setSubscribeValidationMsg('');
    }
    await axios
      .post(`${prwebApi}/users/subscribe`, {
        intentId: stripePayment.intentId,
        name: subscriptionName,
        bundleOrderId: bundleOrderId,
      })
      .then(response => {
        console.log(response);
        const successText = `Your Subscription has been setup.`;
        setSubscribeSuccess(successText);
        setShowRenewalModal(false);
      })
      .catch(error => {
        console.log(error);
        const msg =
          error.response?.data?.message ||
          'There was a problem creating your subscription.';
        setSubscribeValidationMsg(msg);
      });
  };

  const confirmAuthnetRenewal = async () => {
    if (paymentProfileName === '') {
      setSaveProfileValidationMsg(
        'Please provide a nickname for this payment profile.',
      );
      return;
    } else if (!renewalPermission) {
      setSaveProfileValidationMsg(
        'Please check renew my subscription to continue.',
      );
      return;
    } else {
      setSaveProfileValidationMsg('');
    }
    await axios
      .post(`${prwebApi}/users/paymentprofile`, {
        creditCard: payment,
        profileName: paymentProfileName,
        bundleOrderId: bundleOrderId,
      })
      .then(response => {
        const successText = `Your subscription has been setup, subscription id is ${
          response.data.authnetSubscriptionId
        }. Your first renewal will be on date ${moment(
          response.data.startDate,
        ).format('MM/DD/YYYY')}`;

        setPaymentProfileSuccess(successText);
        setShowRenewalModal(false);
      })
      .catch(error => {
        const msg =
          error.response?.data?.message ||
          'There was a problem creating your payment profile.';
        setSaveProfileValidationMsg(msg);
      });
  };

  useEffect(() => {
    wizardData.setProgressWhileEditingState({
      ...wizardData.progressWhileEditingState,
      position: 2,
    });
    wizardData.setSubmit(goToMyRelease);
    wizardData.setStepIsValid(true);
    const count = storeCart.reduce((counter, current) => {
      if (current.packageId === 140) counter[STANDARD_PACKAGE_ID] += 1;
      if (current.packageId === 200) counter[ADVANCED_PACKAGE_ID] += 1;
      if (current.packageId === 360) counter[PREMIUM_PACKAGE_ID] += 1;
      return counter;
    }, createReleaseBtn);
    setCreateReleaseBtn(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.flexContainer}>
      <div className={styles.leftContainer}>
        <Paper>
          <div className={styles.titlePadding}>
            <Typography color="black" weight="bold" size="xl2">
              You&lsquo;re all set!
            </Typography>
          </div>
          <div className={`${styles.flexContainer} ${styles.flexWrap}`}>
            <div className={styles.textContainer}>
              <Typography
                color="black"
                weight="bold"
                size="xl"
                className={styles.titlePadding}
              >
                Order Confirmation
              </Typography>
              <Typography color="black">Your order number is </Typography>
              <Typography color="black" weight="bold">
                {orderNumber}
              </Typography>
              <div>
                {isStripePayment ? (
                  <>
                    {subscribeSuccess ? (
                      <Typography color="black">{subscribeSuccess}</Typography>
                    ) : (
                      <Button
                        id={'showSub'}
                        onClick={changeRenewalModal(true)}
                        level="link"
                      >
                        <ForwardSvg widths="24" height="24" />
                        <span>Setup Yearly Renewal</span>
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {paymentProfileSuccess ? (
                      <Typography color="black">
                        {paymentProfileSuccess}
                      </Typography>
                    ) : (
                      <Button
                        id={'showSub'}
                        onClick={changeRenewalModal(true)}
                        level="link"
                      >
                        <ForwardSvg widths="24" height="24" />
                        <span>Setup Yearly Renewal</span>
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles.textContainer}>
              <Typography
                color="black"
                weight="bold"
                size="xl"
                className={styles.titlePadding}
              >
                What&lsquo;s next?
              </Typography>
              <Typography color="black">
                Ready to get started on a release? Create one now!
              </Typography>
              <div className={styles.buttonPadding}>
                {createReleaseBtn[STANDARD_PACKAGE_ID] > 0 && (
                  <Button onClick={goToCreateRelease(STANDARD_PACKAGE_ID)}>
                    Create Standard
                  </Button>
                )}
                {createReleaseBtn[ADVANCED_PACKAGE_ID] > 0 && (
                  <Button onClick={goToCreateRelease(ADVANCED_PACKAGE_ID)}>
                    Create Advanced
                  </Button>
                )}
                {createReleaseBtn[PREMIUM_PACKAGE_ID] > 0 && (
                  <Button onClick={goToCreateRelease(PREMIUM_PACKAGE_ID)}>
                    Create Premium
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <div className={styles.orderSummaryContainer}>
        <BundleSummary storeCart={storeCart}></BundleSummary>
      </div>
      <ExitWizardPrompt
        confirmMessage={
          'Cannot go back to payment step, exit to my relase page?'
        }
      />
      {isStripePayment ? (
        <Modal isOpen={showRenewalModal} size="md">
          <Modal.Header>
            <Typography weight="bold" color="black" size="xl" tag="p">
              Create Subscription
            </Typography>
            <AmexSvg width="23" height="24" />
            <MCSvg width="39" height="24" />
            <VisaSvg width="43" height="24" />
            <DiscoverSvg width="38" height="24" />
            <Typography weight="bold" color="black" size="lg" tag="p">
              Subscription Name
            </Typography>
            <Input
              placeholder="Ex. Premium 12x"
              value={subscriptionName}
              onChange={changeSubscriptionName}
            ></Input>
            <div className={styles.buttonPadding}>
              <input
                id="autoSubscriptionRenewal"
                type="checkbox"
                checked={renewalPermission}
                onClick={changeRenewalPermission}
              />
              <label htmlFor="autoSubscriptionRenewal">
                Automatically renew my subscription in 12 months using this
                payment profile.
              </label>
            </div>
            {subscribeValidationMsg !== '' && (
              <div className={styles.errorMessage}>
                {subscribeValidationMsg}
              </div>
            )}
          </Modal.Header>
          <Modal.Footer className={styles.modalFooter}>
            <Button onClick={changeRenewalModal(false)}>Cancel</Button>
            <Button onClick={confirmStripeRenewal}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal isOpen={showRenewalModal} size="md">
          <Modal.Header>
            <Typography weight="bold" color="black" size="xl" tag="p">
              Save my payment information
            </Typography>
            <AmexSvg width="23" height="24" />
            <MCSvg width="39" height="24" />
            <VisaSvg width="43" height="24" />
            <DiscoverSvg width="38" height="24" />
            <Typography weight="bold" color="black" size="lg" tag="p">
              Payment Profile Name
            </Typography>
            <Input
              placeholder="Ex. My Visa"
              value={paymentProfileName}
              onChange={changePaymentProfileName}
            ></Input>
            <div className={styles.buttonPadding}>
              <input
                id="autoSubscriptionRenewal"
                type="checkbox"
                checked={renewalPermission}
                onClick={changeRenewalPermission}
              />
              <label htmlFor="autoSubscriptionRenewal">
                Automatically renew my subscription in 12 months using this
                payment profile.
              </label>
            </div>
            {saveProfileValidationMsg !== '' && (
              <div className={styles.errorMessage}>
                {saveProfileValidationMsg}
              </div>
            )}
          </Modal.Header>
          <Modal.Footer className={styles.modalFooter}>
            <Button onClick={changeRenewalModal(false)}>Cancel</Button>
            <Button onClick={confirmAuthnetRenewal}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default StoreConfirmationStep;
