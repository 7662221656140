export const COUNTRY_CODES = [
  { label: 'USA/Canada (+1)', id: '1' },
  { label: 'UK (+44)', id: '44' },
  { label: 'Algeria (+213)', id: '213' },
  { label: 'Andorra (+376)', id: '376' },
  { label: 'Angola (+244)', id: '244' },
  { label: 'Anguilla (+1264)', id: '1264' },
  {
    label: 'Antigua & Barbuda (+1268)',
    id: '1268',
  },
  { label: 'Argentina (+54)', id: '54' },
  { label: 'Armenia (+374)', id: '374' },
  { label: 'Aruba (+297)', id: '297' },
  { label: 'Australia (+61)', id: '61' },
  { label: 'Austria (+43)', id: '43' },
  {
    label: 'Azerbaijan (+994)',
    id: '994',
  },
  { label: 'Bahamas (+1242)', id: '1242' },
  { label: 'Bahrain (+973)', id: '973' },
  {
    label: 'Bangladesh (+880)',
    id: '880',
  },
  { label: 'Barbados (+1246)', id: '1246' },
  { label: 'Belarus (+375)', id: '375' },
  { label: 'Belgium (+32)', id: '32' },
  { label: 'Belize (+501)', id: '501' },
  { label: 'Benin (+229)', id: '229' },
  { label: 'Bermuda (+1441)', id: '1441' },
  { label: 'Bhutan (+975)', id: '975' },
  { label: 'Bolivia (+591)', id: '591' },
  {
    label: 'Bosnia Herzegovina (+387)',
    id: '387',
  },
  { label: 'Botswana (+267)', id: '267' },
  { label: 'Brazil (+55)', id: '55' },
  { label: 'Brunei (+673)', id: '673' },
  { label: 'Bulgaria (+359)', id: '359' },
  {
    label: 'Burkina Faso (+226)',
    id: '226',
  },
  { label: 'Burundi (+257)', id: '257' },
  { label: 'Cambodia (+855)', id: '855' },
  { label: 'Cameroon (+237)', id: '237' },
  {
    label: 'Cape Verde Islands (+238)',
    id: '238',
  },
  {
    label: 'Cayman Islands (+1345)',
    id: '1345',
  },
  {
    label: 'Central African Republic (+236)',
    id: '236',
  },
  { label: 'Chile (+56)', id: '56' },
  { label: 'China (+86)', id: '86' },
  { label: 'Colombia (+57)', id: '57' },
  { label: 'Comoros (+269)', id: '269' },
  { label: 'Congo (+242)', id: '242' },
  {
    label: 'Costa Rica (+506)',
    id: '506',
  },
  { label: 'Croatia (+385)', id: '385' },
  { label: 'Cuba (+53)', id: '53' },
  {
    label: 'Cyprus North (+90392)',
    id: '90392',
  },
  {
    label: 'Cyprus South (+357)',
    id: '357',
  },
  {
    label: 'Czech Republic (+42)',
    id: '42',
  },
  { label: 'Denmark (+45)', id: '45' },
  { label: 'Djibouti (+253)', id: '253' },
  { label: 'Dominica (+1767)', id: '1767' },
  {
    label: 'Dominican Republic (+1809)',
    id: '1809',
  },
  { label: 'Ecuador (+593)', id: '593' },
  { label: 'Egypt (+20)', id: '20' },
  {
    label: 'El Salvador (+503)',
    id: '503',
  },
  {
    label: 'Equatorial Guinea (+240)',
    id: '240',
  },
  { label: 'Eritrea (+291)', id: '291' },
  { label: 'Estonia (+372)', id: '372' },
  { label: 'Ethiopia (+251)', id: '251' },
  { label: 'Fiji (+679)', id: '679' },
  { label: 'Finland (+358)', id: '358' },
  { label: 'France (+33)', id: '33' },
  {
    label: 'French Guiana (+594)',
    id: '594',
  },
  {
    label: 'French Polynesia (+689)',
    id: '689',
  },
  { label: 'Gabon (+241)', id: '241' },
  { label: 'Gambia (+220)', id: '220' },
  { label: 'Georgia (+7880)', id: '7880' },
  { label: 'Germany (+49)', id: '49' },
  { label: 'Ghana (+233)', id: '233' },
  { label: 'Gibraltar (+350)', id: '350' },
  { label: 'Greece (+30)', id: '30' },
  { label: 'Greenland (+299)', id: '299' },
  { label: 'Grenada (+1473)', id: '1473' },
  {
    label: 'Guadeloupe (+590)',
    id: '590',
  },
  { label: 'Guam (+671)', id: '671' },
  { label: 'Guatemala (+502)', id: '502' },
  { label: 'Guinea (+224)', id: '224' },
  {
    label: 'Guinea - Bissau (+245)',
    id: '245',
  },
  { label: 'Guyana (+592)', id: '592' },
  { label: 'Haiti (+509)', id: '509' },
  { label: 'Honduras (+504)', id: '504' },
  { label: 'Hong Kong (+852)', id: 'Hong +852)' },
  { label: 'Hungary (+36)', id: '36' },
  { label: 'Iceland (+354)', id: '354' },
  { label: 'India (+91)', id: '91' },
  { label: 'Indonesia (+62)', id: '62' },
  { label: 'Iran (+98)', id: '98' },
  { label: 'Iraq (+964)', id: '964' },
  { label: 'Ireland (+353)', id: '353' },
  { label: 'Israel (+972)', id: '972' },
  { label: 'Italy (+39)', id: '39' },
  { label: 'Jamaica (+1876)', id: '1876' },
  { label: 'Japan (+81)', id: '81' },
  { label: 'Jordan (+962)', id: '962' },
  { label: 'Kenya (+254)', id: '254' },
  { label: 'Kiribati (+686)', id: '686' },
  {
    label: 'Korea South (+82)',
    id: '82',
  },
  { label: 'Kuwait (+965)', id: '965' },
  {
    label: 'Kyrgyzstan (+996)',
    id: '996',
  },
  { label: 'Laos (+856)', id: '856' },
  { label: 'Latvia (+371)', id: '371' },
  { label: 'Lebanon (+961)', id: '961' },
  { label: 'Lesotho (+266)', id: '266' },
  { label: 'Liberia (+231)', id: '231' },
  { label: 'Libya (+218)', id: '218' },
  {
    label: 'Liechtenstein (+417)',
    id: '417',
  },
  { label: 'Lithuania (+370)', id: '370' },
  {
    label: 'Luxembourg (+352)',
    id: '352',
  },
  { label: 'Macao (+853)', id: '853' },
  { label: 'Macedonia (+389)', id: '389' },
  {
    label: 'Madagascar (+261)',
    id: '261',
  },
  { label: 'Malawi (+265)', id: '265' },
  { label: 'Malaysia (+60)', id: '60' },
  { label: 'Maldives (+960)', id: '960' },
  { label: 'Mali (+223)', id: '223' },
  { label: 'Malta (+356)', id: '356' },
  {
    label: 'Marshall Islands (+692)',
    id: '692',
  },
  {
    label: 'Martinique (+596)',
    id: '596',
  },
  {
    label: 'Mauritania (+222)',
    id: '222',
  },
  { label: 'Mexico (+52)', id: '52' },
  {
    label: 'Micronesia (+691)',
    id: '691',
  },
  { label: 'Moldova (+373)', id: '373' },
  { label: 'Monaco (+377)', id: '377' },
  { label: 'Mongolia (+976)', id: '976' },
  {
    label: 'Montserrat (+1664)',
    id: '1664',
  },
  { label: 'Morocco (+212)', id: '212' },
  {
    label: 'Mozambique (+258)',
    id: '258',
  },
  { label: 'Myanmar (+95)', id: '95' },
  { label: 'Namibia (+264)', id: '264' },
  { label: 'Nepal (+977)', id: '977' },
  {
    label: 'Netherlands (+31)',
    id: '31',
  },
  {
    label: 'New Caledonia (+687)',
    id: '687',
  },
  {
    label: 'New Zealand (+64)',
    id: '64',
  },
  { label: 'Nicaragua (+505)', id: '505' },
  { label: 'Niger (+227)', id: '227' },
  { label: 'Nigeria (+234)', id: '234' },
  {
    label: 'Northern Marianas (+670)',
    id: '670',
  },
  { label: 'Norway (+47)', id: '47' },
  { label: 'Oman (+968)', id: '968' },
  { label: 'Palau (+680)', id: '680' },
  { label: 'Panama (+507)', id: '507' },
  {
    label: 'Papua New Guinea (+675)',
    id: '675',
  },
  { label: 'Paraguay (+595)', id: '595' },
  { label: 'Peru (+51)', id: '51' },
  {
    label: 'Philippines (+63)',
    id: '63',
  },
  { label: 'Poland (+48)', id: '48' },
  { label: 'Portugal (+351)', id: '351' },
  {
    label: 'Puerto Rico (+1787)',
    id: '1787',
  },
  { label: 'Qatar (+974)', id: '974' },
  { label: 'Reunion (+262)', id: '262' },
  { label: 'Romania (+40)', id: '40' },
  { label: 'Russia (+7)', id: '7' },
  { label: 'Rwanda (+250)', id: '250' },
  {
    label: 'San Marino (+378)',
    id: '378',
  },
  {
    label: 'Sao Tome &amp; Principe (+239)',
    id: '239',
  },
  {
    label: 'Saudi Arabia (+966)',
    id: '966',
  },
  { label: 'Senegal (+221)', id: '221' },
  { label: 'Serbia (+381)', id: '381' },
  {
    label: 'Seychelles (+248)',
    id: '248',
  },
  {
    label: 'Sierra Leone (+232)',
    id: '232',
  },
  { label: 'Singapore (+65)', id: '65' },
  {
    label: 'Slovak Republic (+421)',
    id: '421',
  },
  { label: 'Slovenia (+386)', id: '386' },
  {
    label: 'Solomon Islands (+677)',
    id: '677',
  },
  { label: 'Somalia (+252)', id: '252' },
  {
    label: 'South Africa (+27)',
    id: '27',
  },
  { label: 'Spain (+34)', id: '34' },
  { label: 'Sri Lanka (+94)', id: '94)' },
  {
    label: 'St. Kitts (+1869)',
    id: '1869',
  },
  {
    label: 'St. Lucia (+1758)',
    id: '1758',
  },
  { label: 'Sudan (+249)', id: '249' },
  { label: 'Suriname (+597)', id: '597' },
  { label: 'Swaziland (+268)', id: '268' },
  { label: 'Sweden (+46)', id: '46' },
  {
    label: 'Switzerland (+41)',
    id: '41',
  },
  { label: 'Syria (+963)', id: '963' },
  { label: 'Taiwan (+886)', id: '886' },
  { label: 'Thailand (+66)', id: '66' },
  { label: 'Togo (+228)', id: '228' },
  { label: 'Tonga (+676)', id: '676' },
  {
    label: 'Trinidad &amp; Tobago (+1868)',
    id: '1868',
  },
  { label: 'Tunisia (+216)', id: '216' },
  { label: 'Turkey (+90)', id: '90' },
  {
    label: 'Turkmenistan (+993)',
    id: '993',
  },
  {
    label: 'Turks &amp; Caicos Islands (+1649)',
    id: '1649',
  },
  { label: 'Uganda (+256)', id: '256' },
  { label: 'Ukraine (+380)', id: '380' },
  {
    label: 'United Arab Emirates (+971)',
    id: '971',
  },
  { label: 'Uruguay (+598)', id: '598' },
  { label: 'Vanuatu (+678)', id: '678' },
  { label: 'Venezuela (+58)', id: '58' },
  { label: 'Vietnam (+84)', id: '84' },
  {
    label: 'Virgin Islands - British (+1284)',
    id: '1284',
  },
  {
    label: 'Virgin Islands - US (+1340)',
    id: '1340',
  },
  {
    label: 'Yemen (North)(+969)',
    id: '969',
  },
  {
    label: 'Yemen (South)(+967)',
    id: '967',
  },
  { label: 'Zambia (+260)', id: '260' },
  { label: 'Zimbabwe (+263)', id: '263' },
];
