import * as React from 'react';

import { Button, Modal } from '@cision/rover-ui';

import styles from './popup-modal.module.css';

// This interface would be exported from popup-modal in Typescript v3.8.3

interface ActionObject {
  name: string;
  level?: string;
}
interface PopupContentProps {
  header: string;
  body: string;
  action: ActionObject[];
}
interface Props {
  children?: React.ReactNode;
  id: string;
  isOpen: boolean;
  content: PopupContentProps;
  onActionClick: (key: string, value?: string) => void;
}

const PopupModal = ({
  children,
  id,
  isOpen,
  content,
  onActionClick,
}: Props) => {
  const { header, body, action = [] } = content;

  const buttonClick = (actionName: string) => () => {
    if (actionName) {
      onActionClick(id, actionName);
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modalOverlay}>
      <div id={id}>
        <Modal.Header className={styles.modalHeader}>
          <strong className={styles.modalTitle}>{header}</strong>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <div>{body}</div>
          {children}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          {action.map((clickable: ActionObject, index: number) => {
            const styleModifier = (clickable.level?.toString() ||
              'primary') as any;
            return (
              <Button
                key={index}
                className={styles.modalButtons}
                level={styleModifier}
                onClick={buttonClick(clickable.name)}
              >
                {clickable.name}
              </Button>
            );
          })}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export { PopupContentProps };
export default PopupModal;
