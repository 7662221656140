import React from 'react';

import { Loader, Typography } from '@cision/rover-ui';
import axios from 'axios';
import cookie from 'js-cookie';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';

import {
  PRW_SID,
  C3_AVAILABLE,
  USER_GROUP_COOKIE,
  LOGIN_AS,
  IS_NG_USER,
  PARTNER_ID,
} from '../../constants';

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

const setNGRedirectCookies = (config: PRWebConfig, oktaLogin: string) => {
  const cookieOption: { path: string; domain?: string; expires: number } = {
    path: '/',
    expires: 365,
  };

  if (config.environment === 'production') {
    const domain = config.prwebFrontEnd.url.match(
      /^https:\/\/\w+(\.\w+\.\w+)$/,
    );
    if (domain) cookieOption.domain = domain[1];
  }

  cookie.set(`${IS_NG_USER}`, `${oktaLogin}`, cookieOption);
};

const AuthorizationCode: React.FC<Props> = (routeProperties: Props) => {
  const query: {
    code: string;
    error: string;
    error_description: string;
  } = qs.parse(routeProperties.location.search, {
    ignoreQueryPrefix: true,
  });

  const prwebOktaBaseUrl = routeProperties.config.prwebOkta.url;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [message, setMessage] = React.useState<any>(null);

  const renderErrorDescription = () => {
    const hasNgSessionCreationError = message !== null;
    if (hasNgSessionCreationError) {
      return (
        <>
          Unable to login, an error has occurred:
          <br />${message}
        </>
      );
    }

    const hasOktaError = query.error !== undefined;
    const hasOktaMessage = query.error_description !== undefined;

    if (hasOktaError && hasOktaMessage) {
      if (
        query.error_description ===
        'User is not assigned to the client application.'
      )
        return (
          <div>
            <p>
              Although your login to{' '}
              <a href={prwebOktaBaseUrl}>Cision&apos;s SSO portal</a> has
              succeeded
              <br />
              <strong>YOU DO NOT HAVE ACCESS</strong> to PRWeb using this
              account.
            </p>
            <p>
              If you have another login you would like to try,
              <br />
              {
                <em>
                  be sure to{' '}
                  <a href={prwebOktaBaseUrl}>
                    log out of Cision&apos;s SSO portal
                  </a>
                </em>
              }{' '}
              before you try again.
            </p>
          </div>
        );
      return query.error_description;
    }
    return 'unknown error';
  };

  React.useEffect(() => {
    if (query.code !== undefined && query.code.trim() !== '') {
      const envConfig: PRWebConfig = routeProperties.config;
      const prwebApi = envConfig.prwebApi.url;

      axios
        .post(`${prwebApi}/createsession`, {
          code: query.code,
        })
        .then(response => {
          console.log('response: ', response);
          cookie.set(
            PRW_SID,
            JSON.stringify({
              sid: response.data.sid,
              oktaLogin: response.data.oktaLogin,
            }),
          );

          setNGRedirectCookies(routeProperties.config, response.data.oktaLogin);

          cookie.set(C3_AVAILABLE, response.data.c3available);
          cookie.set(USER_GROUP_COOKIE, response.data.group);
          cookie.set(LOGIN_AS, response.data.impersonating);
          if (response.data.partnerId) {
            cookie.set(PARTNER_ID, response.data.partnerId);
          }

          window.location.replace('/');
        })
        .catch(error => {
          if (error.response) {
            setMessage(error?.response?.data);
          } else {
            setMessage(error.message);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !message && query.error === undefined ? (
    <Loader style={{ marginTop: '40vh' }} />
  ) : (
    <div>
      <Typography size="xl">{renderErrorDescription()}</Typography>
    </div>
  );
};

export default AuthorizationCode;
