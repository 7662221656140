import React from 'react';

import { Responsive, Typography } from '@cision/rover-ui';
import { useParams, NavLink } from 'react-router-dom';

import Footer from '../../components/footer';
import Header from '../../components/header';
import ViewTitleBar from '../../components/view-title-bar/ViewTitleBar';

import Account from './Account';
import styles from './settings.module.css';
import Social from './Social';

interface Props {
  config: PRWebConfig;
}

const Settings: React.FC<Props> = (props: Props) => {
  const ACCOUNT_PAGE = 'ACCOUNT';
  const SOCIAL_PAGE = 'SOCIAL';

  const { page } = useParams();

  const isAccountPage = page && page.toUpperCase() === ACCOUNT_PAGE;
  const isSocialPage = page && page.toUpperCase() === SOCIAL_PAGE;

  return (
    <Responsive.Container>
      <Header prwebApiUrl={props.config.prwebApi.url} />
      <ViewTitleBar title={'Settings'} />
      <div className={styles.columnContainer}>
        <div className={styles.leftPageColumn}>
          <div className={isAccountPage ? styles.selected : ''}>
            <Typography weight={isAccountPage ? 'bold' : 'normal'}>
              <NavLink className={styles.links} to={'/settings/account'}>
                Account
              </NavLink>
            </Typography>
          </div>
          <div className={isSocialPage ? styles.selected : ''}>
            <Typography weight={isSocialPage ? 'bold' : 'normal'}>
              <NavLink className={styles.links} to={'/settings/social'}>
                Social
              </NavLink>
            </Typography>
          </div>
        </div>
        {isAccountPage && <Account config={props.config}></Account>}
        {isSocialPage && <Social config={props.config}></Social>}
      </div>
      <Footer />
    </Responsive.Container>
  );
};

export default Settings;
