import React, { useState } from 'react';

import { Button, Paper, Typography } from '@cision/rover-ui';

import axios from 'axios';
import moment from 'moment-timezone';
import { RouteComponentProps } from 'react-router-dom';

import styles from './finance.module.css';

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

const Finance: React.FC<Props> = (props: Props) => {
  const prwebApi = props.config.prwebApi.url;
  const [monthField, setMonthField] = useState(
    String(moment(Date.now()).month() + 1),
  );
  const [yearField, setYearField] = useState(String(moment(Date.now()).year()));

  const [reportKeys, setReportKey] = useState<string[]>([]);

  const yearOptions = () => {
    const date = new Date();
    const initialYr = date.getFullYear();
    return Array.from(Array(20).keys()).map(function(y) {
      return <option key={initialYr - y}>{initialYr - y}</option>;
    });
  };

  const onMonthChange = (value: string): void => {
    setMonthField(value);
  };

  const onYearChange = (value: string): void => {
    setYearField(value);
  };

  const getRevenueReportList = () => {
    const params = new URLSearchParams({
      month: monthField.toString().padStart(2, '0'),
      year: yearField,
    });
    const getReportKeyUrl = `${prwebApi}/admin/finance/deferredrevenue?${params.toString()}`;
    axios.get(getReportKeyUrl).then(response => {
      if (response.status === 200 && Array.isArray(response.data)) {
        const keys = response.data.map(item => item.Key);
        setReportKey(keys);
      }
    });
  };

  const downloadReport = (key: string) => () => {
    const params = new URLSearchParams({ key });
    const getReportKeyUrl = `${prwebApi}/admin/finance/deferredrevenue?${params.toString()}`;
    axios.get(getReportKeyUrl, { responseType: 'blob' }).then(response => {
      if (response && response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', key);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    });
  };

  return (
    <div className={styles.financeReportContainer}>
      <Paper className={styles.financeReportContainer}>
        <Typography weight="bold" color="black" size="xl">
          Deferred revenue
        </Typography>
        <br />
        <br />
        <Typography htmlFor={'month'} className={styles.horizontalFormField}>
          <select
            className={styles.selector}
            id="month"
            name="month"
            onChange={(e: React.FormEvent): void =>
              onMonthChange((e.target as HTMLInputElement).value)
            }
            value={monthField}
          >
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </Typography>

        <Typography htmlFor={'year'} className={styles.horizontalFormField}>
          <select
            className={styles.selector}
            id="year"
            name="year"
            onChange={(e: React.FormEvent): void =>
              onYearChange((e.target as HTMLInputElement).value)
            }
            value={yearField}
          >
            {yearOptions()}
          </select>
        </Typography>

        <Button
          level="secondary"
          onClick={getRevenueReportList}
          id={'GenerateActivityFinanceReportButton'}
        >
          Get Reports
        </Button>

        <br />
        <br />
        {reportKeys && (
          <>
            {reportKeys.map((item, idx) => {
              return (
                <div key={idx}>
                  <Button level="link" onClick={downloadReport(item)}>
                    {item}
                  </Button>
                </div>
              );
            })}
          </>
        )}
      </Paper>
    </div>
  );
};

export default Finance;
