import React, { useEffect } from 'react';

import { Loader, Responsive } from '@cision/rover-ui';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface Props {
  config: PRWebConfig;
}

const Publications: React.FC<Props> = (props: Props) => {
  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const [isLoading, setIsLoading] = React.useState(true);
  const [releaseList, setReleaseList] = React.useState<
    Array<PRWebPublishReleaseItem>
  >([]);
  const { days } = useParams<{ days?: string | undefined }>();

  if (days !== undefined && (isNaN(Number(days)) || parseInt(days, 10) < 1)) {
    throw new Error('days param must be a positive number greater than zero');
  }

  const getReleases = (): Promise<PRWebPublishReleaseItem[]> => {
    return axios
      .get(
        `${PRWEB_API_BASE_URL}/publish/getsenttoiriscomplete/${
          days !== undefined ? days : 7
        }`,
      )
      .then(response => {
        if (response.data) {
          return response.data;
        }
        return [];
      })
      .catch(e => {
        console.log('something went wrong with get releases endpoint call', e);
        return [];
      });
  };
  useEffect(() => {
    const initList = async (): Promise<void> => {
      const result = await getReleases();
      setReleaseList(result);
      setIsLoading(false);
    };
    initList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Responsive.Container>
      <h1>Releases</h1>
      {isLoading ? (
        <Loader />
      ) : (
        <table>
          <tbody>
            <tr>
              <th>PRID</th>
              <th>StoryDate</th>
              <th>StoryNumber</th>
              <th>EraId</th>
            </tr>
            {releaseList.length
              ? releaseList.map(release => {
                  return (
                    <tr key={release.ReleaseID}>
                      <td>{release.ReleaseID}</td>
                      <td>{release.StoryDate}</td>
                      <td>{release.StoryNumber}</td>
                      <td>
                        <a
                          title="era details"
                          href={`/publication/${release.EraId}`}
                        >
                          {release.EraId}
                        </a>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      )}
    </Responsive.Container>
  );
};

export default Publications;
