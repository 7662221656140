import React, { useEffect, useState } from 'react';

import {
  Loader,
  Typography,
  Paper,
  Button,
  EasyDropdown,
} from '@cision/rover-ui';
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import { NavLink, useHistory } from 'react-router-dom';

import Confirm from '../../components/confirm';
import { PackageFeature } from '../../components/package-card/PackageCard';
import PRWLink from '../../components/rich-text-editor/prw-link';
import PRWTheme from '../../components/rich-text-editor/prw-theme';

import styles from './partnersplash.module.css';

Quill.register(PRWLink);
Quill.register('themes/prw', PRWTheme);
interface Props {
  showEditControl?: boolean;
  config: PRWebConfig;
}

const PartnerSplash: React.FC<Props> = ({
  showEditControl = false,
  config,
}: Props) => {
  const prwebApi = config.prwebApi.url;
  const history = useHistory();
  const isPartnerUrl = history.location.pathname.startsWith('/prwebpartner');

  const [partnerSlugs, setPartnerSlugs] = useState<any[]>([]);
  const [prwebPartner, setPRWebPartner] = useState<PRWebPartner>();
  const [partnerPkgs, setPartnerPkgs] = useState<PRWebPackage[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showPartnerDropdown, setShowPartnerDropdown] = useState(false);

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ list: 'bullet' }],
      ['bold', 'italic', 'link'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['clean'],
    ],
  };

  const allowableFormats = [
    'list',
    'bold',
    'break',
    'header',
    'link',
    'italic',
    'align',
  ];

  const toggleSelectPartner = () => {
    setShowPartnerDropdown(!showPartnerDropdown);
  };

  const getPackagePriceById = (pkgId: number) => {
    let price = '';
    if (partnerPkgs) {
      const pkg = partnerPkgs.find(pkg => Number(pkg.packageId) === pkgId);
      price = pkg
        ? Number(pkg.price).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'currency',
            currency: 'USD',
          })
        : '';
    }
    return price;
  };

  const changeReactQuillEditor = (field: string) => (
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delta: any,
    source: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editor: any,
  ) => {
    if (source === 'user' || source === 'api') {
      switch (field) {
        case 'megaphone':
          setPRWebPartner((prev: any) => {
            if (prev) return { ...prev, splashdescriptioncontent: value };
          });
          break;
        case 'panel':
          setPRWebPartner((prev: any) => {
            if (prev) return { ...prev, spashrightpanelcontent: value };
          });
          break;
        case 'resource':
          setPRWebPartner((prev: any) => {
            if (prev) return { ...prev, splashresourcecontent: value };
          });
          break;
      }
    }
  };

  const toggleShowConfirm = (show: boolean) => () => {
    setShowConfirmSave(show);
  };

  const savePartnerChanges = async () => {
    setShowConfirmSave(false);
    if (prwebPartner) {
      await axios.post(
        `${prwebApi}/admin/partner/${prwebPartner.partnerId}`,
        prwebPartner,
      );
    }
  };

  const changePartner = (slug: string) => async () => {
    setIsLoading(true);
    await Promise.all([getPartner(slug), getPartnerPackage(slug)])
      .then(([partnerData, partnerPkgs]) => {
        if (partnerData && partnerPkgs) {
          if (partnerData.redirect && isPartnerUrl) {
            history.replace({ pathname: '/register' });
          }
          let packageDescription = [];
          try {
            packageDescription = JSON.parse(partnerData.packageDescription);
          } catch (error) {}
          setPRWebPartner({ ...partnerData, packageDescription });
          setPartnerPkgs(partnerPkgs);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPartner = (slug: string) => {
    return axios
      .get(`${prwebApi}/partner/search?slug=${encodeURIComponent(slug)}`)
      .then(response => {
        if (response.status === 200 && response.data) {
          return response.data;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getAllPartnerSlugs = () => {
    if (!history.location.pathname.startsWith('/admin')) {
      return Promise.resolve([]);
    }
    return axios.get(`${prwebApi}/admin/partners`).then(response => {
      if (
        response.status === 200 &&
        response.data &&
        Array.isArray(response.data)
      ) {
        return response.data.map(item => ({
          label: item.slug,
          onClick: changePartner(item.slug),
        }));
      }
    });
  };

  const getPartnerPackage = (slug: string) => {
    return axios
      .get(`${prwebApi}/package/partner?slug=${encodeURIComponent(slug)}`)
      .then(response => {
        if (response.status === 200 && response.data) {
          return response.data;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getAdvancedPkgPoints = () => {
    return (
      <>
        <PackageFeature feature="Unlimited Media Assets" />
        <PackageFeature
          feature="Increased Search Visibility"
          description="Relevant content keyword tagging by our expert editorial team"
        />
        <PackageFeature
          feature="Increased Online Visibility"
          description="Syndication to our enhanced network of 1200+ websites powered by PR Newswire"
        />
      </>
    );
  };

  const getBasicPkgPoints = () => {
    return (
      <>
        <PackageFeature feature="Unlimited Media Assets" />
        <PackageFeature
          feature="Increased Search Visibility"
          description="Relevant content keyword tagging by our expert editorial team"
        />
        <PackageFeature feature="48-hour turn around time" />
      </>
    );
  };

  const getPackageDescription = (pkg: number) => {
    return (
      <>
        {prwebPartner &&
          Array.isArray(prwebPartner.packageDescription) &&
          !!prwebPartner.packageDescription[pkg] && (
            <div
              style={{ color: '#888' }}
              dangerouslySetInnerHTML={{
                __html: prwebPartner.packageDescription[pkg] || '',
              }}
            ></div>
          )}
      </>
    );
  };

  useEffect(() => {
    let slug = history.location.pathname.split('/').pop() || '';
    const init = async () => {
      await getAllPartnerSlugs().then(menuItem => {
        if (menuItem && Array.isArray(menuItem) && menuItem.length > 0) {
          slug = menuItem[0].label; // override slug if we are on admin page
          setPartnerSlugs(menuItem);
        }
      });
      await changePartner(slug)();
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader className={styles.loaderIcon} />;
  } else if (!(prwebPartner || partnerPkgs)) {
    return (
      <div
        style={{ textAlign: 'center', height: '220px' }}
        className={styles.partnersplashfont}
      >
        <br />
        <Typography weight="bold" size="xl3">
          404 - Not Found
        </Typography>
        <Typography>
          <p>We don&apos;t know where you are!</p>
          <p>
            Be safe. <a href="/">Go Home</a>.
          </p>
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.partnersplashfont}>
      {showEditControl && (
        <div className={styles.editControl}>
          <div>
            <Typography color="black" weight="bold">
              Partner Description
            </Typography>
            <ReactQuill
              id={'descriptionContentEditor'}
              value={prwebPartner?.splashdescriptioncontent || ''}
              onChange={changeReactQuillEditor('megaphone')}
              modules={modules}
              formats={allowableFormats}
              theme={'prw'}
            />
            <br />
            <Typography color="black" weight="bold">
              Partner Right Panel
            </Typography>
            <ReactQuill
              id={'rightPanelContentEditor'}
              value={prwebPartner?.spashrightpanelcontent || ''}
              onChange={changeReactQuillEditor('panel')}
              modules={modules}
              formats={allowableFormats}
              theme={'prw'}
            />
            <br />
            <Typography color="black" weight="bold">
              Partner Resource Center
            </Typography>
            <ReactQuill
              id={'resourceContentEditor'}
              value={prwebPartner?.splashresourcecontent || ''}
              onChange={changeReactQuillEditor('resource')}
              modules={modules}
              formats={allowableFormats}
              theme={'prw'}
            />
          </div>
          <span className={styles.navbarBtnContainer}>
            <Typography size="xl" color="black">
              Edit Mode &nbsp;
            </Typography>
            <EasyDropdown
              isOpen={showPartnerDropdown}
              onClick={toggleSelectPartner}
              menuItems={partnerSlugs}
            >
              Change Partner
            </EasyDropdown>
            <Button level="primary" onClick={toggleShowConfirm(true)}>
              Save Changes
            </Button>
          </span>
          <Confirm
            isOpen={showConfirmSave}
            text={'Are you sure you want make these changes?'}
            yes={savePartnerChanges}
            no={toggleShowConfirm(false)}
          ></Confirm>
        </div>
      )}
      <nav className={styles.navbar}>
        <a
          className={styles.container}
          rel="index"
          title="Go to homepage"
          href="https://www.prweb.com"
        >
          <img
            src={prwebPartner?.logoPath}
            id="PartnerLogo"
            height="45"
            alt="logo"
          />
        </a>
        <div className={styles.navbarBtnContainer}>
          <NavLink id="partnerLoginButton" to={'/'} className={styles.btn}>
            Login
          </NavLink>
          <NavLink
            id="partnerRegisterButton"
            to={`/prwebpartner/${prwebPartner?.slug}/Register`}
            className={styles.btn}
          >
            Register
          </NavLink>
        </div>
      </nav>

      <section className={styles.bg_home}>
        <div className={styles.megaphoneContent}>
          <img
            src="/img/partner/PRMegaphone-recolored.png"
            alt="PRMegaphone"
            style={{ width: '350px' }}
          />
          <div
            className={styles.splashDescriptionContent}
            dangerouslySetInnerHTML={{
              __html: prwebPartner?.splashdescriptioncontent || '',
            }}
          />
          <NavLink
            to={`/prwebpartner/${prwebPartner?.slug}/Register`}
            id="callToActionLink"
            className={styles.orangeBtn}
          >
            Claim Your Discount!
          </NavLink>
          <br />
        </div>
        <Paper className={styles.splashPanel}>
          <img
            className={styles.socialNotifyIcon}
            src="/img/partner/SocialNotify.png"
            height="125"
            alt="Distribute"
          />
          <br />
          <div
            className={styles.rightPanelContent}
            dangerouslySetInnerHTML={{
              __html: prwebPartner?.spashrightpanelcontent || '',
            }}
          />
        </Paper>
      </section>

      <section>
        <h1 className={styles.resourceCenterTitle}>
          Save on PRWeb&apos;s Top
          <br /> Press Release Packages
        </h1>
        <div className={styles.resourceCenterBox}>
          <div
            className={styles.splashResourceContent}
            dangerouslySetInnerHTML={{
              __html: prwebPartner?.splashresourcecontent || '',
            }}
          />
        </div>
      </section>

      <section className={styles.partnerPackageGrid}>
        {getPackagePriceById(80) && (
          <div id="partnerPriceBoxBasic" className={styles.partnerPricingBox}>
            <img
              src="/img/partner/cloud.png"
              alt="Basic Package Icon"
              className={styles.img_thumbnail}
            />
            <h2>Basic</h2>
            <h4 id="partnerPriceBasic" style={{ color: '#008480' }}>
              {getPackagePriceById(80)}
            </h4>
            {getPackageDescription(0)}
            <Typography color="inherit" size="lg" tag="p" weight="bold">
              Increased Search Engine Visibility
            </Typography>
            <br />
            <NavLink
              to={`/prwebpartner/${prwebPartner?.slug}/Register`}
              id="advancedRegistrationLink"
              className={styles.darkBtn}
            >
              Sign Up &amp; Save
            </NavLink>
            <div className={styles.partnerPricingBullets}>
              {getBasicPkgPoints()}
            </div>
          </div>
        )}

        <div id="partnerPriceBoxAdvanced" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/cloud.png"
            alt="Advanced Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Advanced</h2>
          <h4 id="partnerPriceAdvanced" style={{ color: '#008480' }}>
            {getPackagePriceById(200)}
          </h4>
          {getPackageDescription(2)}
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Increased Influencer Visibility
          </Typography>
          <br />
          <NavLink
            to={`/prwebpartner/${prwebPartner?.slug}/Register`}
            id="advancedRegistrationLink"
            className={styles.darkBtn}
          >
            Sign Up &amp; Save
          </NavLink>
          <div className={styles.partnerPricingBullets}>
            {getAdvancedPkgPoints()}
            <PackageFeature
              feature="Email distribution to ONE of our industry-curated journalist lists"
              description="Proofreading from our expert editorial staff included"
            />
            <PackageFeature feature="24-hour turn around time" />
          </div>
        </div>

        <div id="partnerPriceBoxPremium" className={styles.partnerPricingBox}>
          <img
            src="/img/partner/starcloud.png"
            alt="Premium Package Icon"
            className={styles.img_thumbnail}
          />
          <h2>Premium</h2>
          <h4 id="partnerPricePremium" style={{ color: '#008480' }}>
            {getPackagePriceById(360)}
          </h4>
          {getPackageDescription(3)}
          <Typography color="inherit" size="lg" tag="p" weight="bold">
            Largest Influencer Visibility
          </Typography>
          <br />
          <NavLink
            to={`/prwebpartner/${prwebPartner?.slug}/Register`}
            id="premiumRegistrationLink"
            className={styles.orangeBtn}
          >
            Sign Up &amp; Save
          </NavLink>
          <div className={styles.partnerPricingBullets}>
            {getAdvancedPkgPoints()}
            <PackageFeature
              feature="Email distribution to TWO of our industry-curated journalist lists"
              description="Proofreading from our expert editorial staff included"
            />
            <PackageFeature
              feature="Distribution to Sovrn's content recommended network"
              description="Connects your message with influential bloggers"
            />
            <PackageFeature feature="24-hour turn around time" />
          </div>
        </div>
      </section>

      <section className={styles.footer}>
        <p>
          {new Date().getFullYear()} © PRWeb
          <br />
          Cision US
        </p>
        <p>
          866-640-6397
          <br />
          <a href="https://service.prweb.com/contact-us/?nav_location=footer">
            Contact Us
          </a>
        </p>
        <p>
          <a href="https://www.prweb.com/">www.prweb.com</a>
          <br />
          <a href="https://www.cision.com/">www.cision.com</a>
        </p>
        <p>
          12051 Indian Creek Ct
          <br />
          Beltsville, MD 20705
        </p>
      </section>
    </div>
  );
};

export default PartnerSplash;
