import React, { useEffect, useContext, useCallback, FC, useState } from 'react';

import { Toggle, Loader, Paper, Typography } from '@cision/rover-ui';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import Kite from '../../../components/kite';
import PRWebRichTextEditor from '../../../components/rich-text-editor';
import SocialMediaContainer, {
  SocialMediaState,
  TwitterToggle,
  LinkedinToggle,
  FacebookToggle,
} from '../../../components/social-media-container';
import TextAreaField from '../../../components/text-area-field';
import TextInputField from '../../../components/text-input-field';
import WizardContext from '../../../components/wizard/wizard-context';
import {
  BASIC_PACKAGE_ID,
  ADVANCED_PACKAGE_ID,
  PREMIUM_PACKAGE_ID,
  SOCIAL_MEDIA_FACEBOOK,
  SOCIAL_MEDIA_LINKEDIN,
  SOCIAL_MEDIA_TWITTER,
} from '../../../constants';
import SubmitDistributionValidator, {
  DistributionErrors,
  GenericContactData,
  PrimaryContactValidation,
  SecondaryContactValidation,
  validatePrimaryContactData,
  validateSecondaryContactData,
} from '../../../validators/SubmitDistributionValidator';
import Attachments from '../components/attachments';
import {
  PrimaryContactPanel,
  SecondaryContactPanel,
} from '../components/contact-panel';
import ExitWizardPrompt from '../components/exit-wizard-prompt';
import FeaturedImage from '../components/featured-image';
import OnHoldReasonsList from '../components/on-hold-reasons-list';
import VideoUrl from '../components/video-url';
import DistributionWizardContext from '../DistributionWizardContext';
import PRWebPackages from '../PRWebPackages';
import SaveUtility from '../SaveUtility';

import styles from './styles/create-release-step.module.css';
import './styles/CreateReleaseStep.scss';

interface Props {
  config: PRWebConfig;
}

const CreateReleaseStep: FC<Props> = ({ config }: Props) => {
  const history = useHistory();
  const {
    distributionData,
    updateDistributionData,
    updateDistributionByField,
    onHoldReasons,
  } = useContext(DistributionWizardContext);
  const wizardContext = React.useContext(WizardContext);

  const [isLoading, setIsLoading] = useState(false);
  const [frontEndFormErrorsValue, setFrontEndFormErrorsValue] = useState<
    DistributionErrors
  >();
  const [
    primaryContactValidation,
    setPrimaryContactValidation,
  ] = useState<PrimaryContactValidation | null>();
  const [
    secondaryContactValidation,
    setSecondaryContactValidation,
  ] = useState<SecondaryContactValidation | null>();
  const [twitterSelected, setTwitterSelected] = useState(false);
  const [linkedinSelected, setLinkedinSelected] = useState(false);
  const [facebookSelected, setFacebookSelected] = useState(false);

  const [withAdditionalContact, setWithAdditionalContact] = useState(false);

  const [formErrorSummaryValue, setFormErrorSummaryValue] = useState<
    Array<string>
  >([]);
  const [
    attachmentsErrorSummaryValue,
    setAttachmentsErrorSummaryValue,
  ] = useState<Array<string>>([]);
  const [hasSavedDraft, setHasSavedDraft] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const envConfig: PRWebConfig = config;
  const prwebApi = envConfig.prwebApi.url;
  const currentPackage = PRWebPackages.getPRWebPackageWithAbilities(
    distributionData.package,
  );

  const showFeaturedImagePanel =
    currentPackage?.packageId === ADVANCED_PACKAGE_ID ||
    currentPackage?.packageId === PREMIUM_PACKAGE_ID;

  const showPullQuote = currentPackage?.packageId !== BASIC_PACKAGE_ID;

  const showVideoUrlField = currentPackage?.packageId === PREMIUM_PACKAGE_ID;

  const toggleAdditionalContact = () => {
    setWithAdditionalContact(prev => {
      if (prev) {
        updateDistributionByField('secondaryContactName', '');
        updateDistributionByField('secondaryContactCompany', '');
        updateDistributionByField('secondaryContactWebsite', '');
        updateDistributionByField('secondaryContactEmail', '');
        updateDistributionByField('secondaryContactPhoneCountryCode', '');
        updateDistributionByField('secondaryContactPhone', '');
        updateDistributionByField('secondaryContactPhoneExtension', '');
      }
      return !prev;
    });
  };

  const socialMediaHandler = (socialMediaAccount: SocialMediaAccount) => {
    if (socialMediaAccount.type === SOCIAL_MEDIA_TWITTER) {
      setTwitterSelected(prev => {
        if (distributionData.twitterSocialHandle) {
          distributionData.twitterSocialHandle.selected = !prev;
        } else {
          distributionData.twitterSocialHandle = {
            ...socialMediaAccount,
            selected: !prev,
          };
        }
        updateDistributionData({ ...distributionData });
        return !prev;
      });
    } else if (socialMediaAccount.type === SOCIAL_MEDIA_LINKEDIN) {
      setLinkedinSelected(prev => {
        if (distributionData.linkedinSocialHandle) {
          distributionData.linkedinSocialHandle.selected = !prev;
        } else {
          distributionData.linkedinSocialHandle = {
            ...socialMediaAccount,
            selected: !prev,
          };
        }
        updateDistributionData({ ...distributionData });
        return !prev;
      });
    } else if (socialMediaAccount.type === SOCIAL_MEDIA_FACEBOOK) {
      setFacebookSelected(prev => {
        if (distributionData.facebookSocialHandle) {
          distributionData.facebookSocialHandle.selected = !prev;
        } else {
          distributionData.facebookSocialHandle = {
            ...socialMediaAccount,
            selected: !prev,
          };
        }
        updateDistributionData({ ...distributionData });
        return !prev;
      });
    }
  };

  const updateDataFieldByValue = (key: string, value?: string): void => {
    updateDistributionByField(key, value);
    setIsDirty(true);
  };

  const updateBodyField = (value: string) => {
    updateDataFieldByValue('body', value);
    setIsDirty(true);
  };

  const removeAttachment = (filePath: string, assetId?: number) => {
    setAttachmentsErrorSummaryValue([]);
    const tempAttachments =
      distributionData.attachments?.filter(
        p => (assetId && p.assetId !== assetId) || p.filePath !== filePath,
      ) || [];
    updateDistributionData({
      ...distributionData,
      attachments: tempAttachments,
    });
    setIsDirty(true);
  };

  const removeNewsImage = () => {
    updateDistributionData({
      ...distributionData,
      newsImage: undefined,
    });
    setIsDirty(true);
  };

  const addAttachment = (attachment: Attachment) => {
    setAttachmentsErrorSummaryValue([]);
    if (
      distributionData.attachments &&
      distributionData.attachments.length > 0 &&
      distributionData.attachments.some(a => a.fileName === attachment.fileName)
    ) {
      setAttachmentsErrorSummaryValue([
        'An attachment with the same name already exists. If you wish to attach duplicate items they must have different names.',
      ]);
      return;
    }

    if (distributionData.attachments) {
      const index = distributionData.attachments.findIndex(
        a => a.filePath === attachment.filePath,
      );

      if (index !== -1) {
        distributionData.attachments.splice(index, 1);
      }

      updateDistributionData({
        ...distributionData,
        attachments: [...distributionData.attachments, attachment],
      });
    } else {
      updateDistributionData({
        ...distributionData,
        attachments: [attachment],
      });
    }
    setIsDirty(true);
  };

  const addNewsImage = (newsImage: NewsImage) => {
    updateDistributionData({
      ...distributionData,
      newsImage: newsImage,
    });
    setIsDirty(true);
  };

  const saveContact = (contactData: GenericContactData | null) => {
    updateDistributionData({ ...distributionData, ...contactData });
    setIsDirty(true);
  };

  const saveAndContinue = useCallback(async () => {
    const newDistroValidation = SubmitDistributionValidator.validateDistributionData(
      distributionData,
    );
    const distroValid =
      newDistroValidation !== undefined &&
      newDistroValidation.valid !== undefined
        ? newDistroValidation.valid
        : false;

    const newContactData = {
      primaryContactName: distributionData.primaryContactName,
      primaryContactCompany: distributionData.primaryContactCompany,
      primaryContactWebsite: distributionData.primaryContactWebsite,
      primaryContactEmail: distributionData.primaryContactEmail,
      primaryContactPhoneCountryCode:
        distributionData.primaryContactPhoneCountryCode,
      primaryContactPhone: distributionData.primaryContactPhone,
      primaryContactPhoneExtension:
        distributionData.primaryContactPhoneExtension,
      secondaryContactName: distributionData.secondaryContactName,
      secondaryContactCompany: distributionData.secondaryContactCompany,
      secondaryContactWebsite: distributionData.secondaryContactWebsite,
      secondaryContactEmail: distributionData.secondaryContactEmail,
      secondaryContactPhoneCountryCode:
        distributionData.secondaryContactPhoneCountryCode,
      secondaryContactPhone: distributionData.secondaryContactPhone,
      secondaryContactPhoneExtension:
        distributionData.secondaryContactPhoneExtension,
    } as GenericContactData;

    const newPrimaryContactValidation = validatePrimaryContactData(
      newContactData,
    ) as PrimaryContactValidation;
    const newSecondaryContractValidation = validateSecondaryContactData(
      newContactData,
    ) as SecondaryContactValidation;

    const primaryContactValid =
      newPrimaryContactValidation !== undefined &&
      newPrimaryContactValidation.valid !== undefined &&
      newPrimaryContactValidation.valid;
    const secondaryContactValid = withAdditionalContact
      ? newSecondaryContractValidation !== undefined &&
        newSecondaryContractValidation.valid !== undefined &&
        newSecondaryContractValidation.valid
      : true;

    if (!primaryContactValid) {
      setPrimaryContactValidation(newPrimaryContactValidation);
    }
    if (!secondaryContactValid) {
      setSecondaryContactValidation(newSecondaryContractValidation);
    }
    setFrontEndFormErrorsValue({ ...newDistroValidation });

    if (distroValid && secondaryContactValid) {
      if (!wizardContext.saveRequired) {
        return true;
      }
      return await saveDraft();
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wizardContext.saveRequired,
    distributionData.package,
    distributionData.body,
    distributionData.pulloutQuote,
    distributionData.cityState,
    distributionData.primaryContactCompany,
    distributionData.primaryContactEmail,
    distributionData.primaryContactName,
    distributionData.primaryContactPhone,
    distributionData.primaryContactPhoneCountryCode,
    distributionData.primaryContactPhoneExtension,
    distributionData.primaryContactWebsite,
    distributionData.secondaryContactCompany,
    distributionData.secondaryContactEmail,
    distributionData.secondaryContactName,
    distributionData.secondaryContactPhone,
    distributionData.secondaryContactPhoneCountryCode,
    distributionData.secondaryContactPhoneExtension,
    distributionData.secondaryContactWebsite,
    distributionData.headline,
    distributionData.summary,
    distributionData.distributionId,
    distributionData.prwebPressReleaseId,
    distributionData.distributionVersion,
    distributionData.distributionPRWebVersion,
    distributionData.attachments,
    distributionData.newsImage,
    distributionData.isOnHold,
    distributionData.videoUrl,
    distributionData.twitterSocialHandle,
    distributionData.linkedinSocialHandle,
    distributionData.facebookSocialHandle,
    setIsLoading,
    isLoading,
    withAdditionalContact,
  ]);

  const handleApiValidationError = (error: ErrorType): void => {
    const errors: Array<string> = SaveUtility.handleApiErrors(error);
    setFormErrorSummaryValue(errors);
  };

  const updateURLQueryParams = (distributionId: number) => {
    // function should skip execution at pages:
    // /distribution/create/[distributionId] and /distribution/edit/[distributionId]
    if (history.location.pathname.match(/\/distribution\/create(\?.*)?$/)) {
      const currentSearch = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      if ('distributionId' in currentSearch) {
        return;
      }
      const updateSearch = qs.stringify({
        ...currentSearch,
        distributionId: distributionId,
      });
      history.replace({
        pathname: '/distribution/create',
        search: `?${updateSearch}`,
        state: { noConfirm: true },
      });
    }
  };

  const saveDraft = useCallback(
    async () => {
      if (isLoading) {
        return false;
      }

      setHasSavedDraft(false);
      setIsLoading(true);
      setFormErrorSummaryValue([]);
      setFrontEndFormErrorsValue(undefined);

      const dataToSend = SaveUtility.getDataToSend(distributionData);

      try {
        const postResponse = await axios.post(
          `${prwebApi}/distribution/item/saveDraft`,
          dataToSend,
        );

        if (postResponse.status === 200 || postResponse.status === 201) {
          const newsImage = {
            ...distributionData.newsImage,
            assetId:
              postResponse.data.result?.newsImage?.assetId ||
              distributionData.newsImage?.assetId,
            uploadKey: postResponse.data.result?.newsImage?.uploadKey,
          } as Attachment;
          const attachments: Attachment[] = [];
          (postResponse.data.result.attachments || []).forEach(
            (image: Attachment, index: number) => {
              const existingData = (distributionData.attachments || []).find(
                data => data.fileName === image.fileName,
              );
              if (existingData && image.uploadKey) {
                attachments.push({
                  ...existingData,
                  assetId: image.assetId || existingData.assetId,
                  uploadKey: image.uploadKey,
                });
              } else if (existingData) {
                attachments.push(existingData);
              }
            },
          );
          updateDistributionData({
            ...distributionData,
            prwebPressReleaseId:
              postResponse.data.result.prwebPressReleaseId || 0,
            distributionId: postResponse.data.result.distributionId || 0,
            distributionVersion:
              postResponse.data.result.distributionVersion || 0,
            distributionPRWebVersion:
              postResponse.data.result.distributionPRWebVersion || 0,
            releaseDate: postResponse.data.result.releaseDate,
            attachments: attachments,
            newsImage: newsImage,
          });
          updateURLQueryParams(postResponse.data.result.distributionId);
          setHasSavedDraft(true);
          setIsLoading(false);
          setIsDirty(false);
          return true;
        } else {
          handleApiValidationError(postResponse.data);
        }
      } catch (error) {
        handleApiValidationError(error);
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      distributionData.package,
      distributionData.body,
      distributionData.pulloutQuote,
      distributionData.cityState,
      distributionData.primaryContactCompany,
      distributionData.primaryContactEmail,
      distributionData.primaryContactName,
      distributionData.primaryContactPhone,
      distributionData.primaryContactPhoneCountryCode,
      distributionData.primaryContactPhoneExtension,
      distributionData.primaryContactWebsite,
      distributionData.secondaryContactCompany,
      distributionData.secondaryContactEmail,
      distributionData.secondaryContactName,
      distributionData.secondaryContactPhone,
      distributionData.secondaryContactPhoneCountryCode,
      distributionData.secondaryContactPhoneExtension,
      distributionData.secondaryContactWebsite,
      distributionData.headline,
      distributionData.summary,
      distributionData.distributionId,
      distributionData.prwebPressReleaseId,
      distributionData.distributionVersion,
      distributionData.distributionPRWebVersion,
      distributionData.attachments,
      distributionData.newsImage,
      distributionData.videoUrl,
      distributionData.twitterSocialHandle,
      distributionData.linkedinSocialHandle,
      distributionData.facebookSocialHandle,
      setIsLoading,
      isLoading,
    ],
  );

  const saveAndBack = useCallback(async () => {
    if (!isDirty) return true;
    if (!wizardContext.saveRequired) {
      return true;
    }

    return await saveDraft();
  }, [isDirty, wizardContext.saveRequired, saveDraft]);

  useEffect(() => {
    setTwitterSelected(prev => {
      if (distributionData.twitterSocialHandle) {
        return distributionData.twitterSocialHandle.selected;
      }
      return prev;
    });
  }, [distributionData.twitterSocialHandle]);

  useEffect(() => {
    setLinkedinSelected(prev => {
      if (distributionData.linkedinSocialHandle) {
        return distributionData.linkedinSocialHandle.selected;
      }
      return prev;
    });
  }, [distributionData.linkedinSocialHandle]);

  useEffect(() => {
    setFacebookSelected(prev => {
      if (distributionData.facebookSocialHandle) {
        return distributionData.facebookSocialHandle.selected;
      }
      return prev;
    });
  }, [distributionData.facebookSocialHandle]);

  useEffect(() => {
    wizardContext.setStepIsValid(true);
    wizardContext.setProgressWhileEditing([]);
    if (
      distributionData.secondaryContactName ||
      distributionData.secondaryContactCompany ||
      distributionData.secondaryContactWebsite ||
      distributionData.secondaryContactEmail ||
      distributionData.secondaryContactPhoneCountryCode ||
      distributionData.secondaryContactPhone ||
      distributionData.secondaryContactPhoneExtension
    ) {
      setWithAdditionalContact(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepForward(saveAndContinue), [
    saveAndContinue,
  ]);

  useEffect(() => {
    wizardContext.setSaveDraftButtonHandler(saveDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveDraft]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setCanMoveStepBackward(saveAndBack), [
    saveAndBack,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => wizardContext.setPreviewData(distributionData), [
    distributionData,
  ]);

  return (
    <div className={styles.stepContainer}>
      <Kite status="success" shouldShow={hasSavedDraft} ttl={8000}>
        Draft saved
      </Kite>
      {distributionData.isOnHold && (
        <OnHoldReasonsList reasons={onHoldReasons} />
      )}
      <div className={styles.stepTitle}>
        <Typography size="xl2" color="black" weight="bold">
          Create Release
        </Typography>
      </div>
      <Paper>
        <div>
          {formErrorSummaryValue.length > 0 && (
            <div className={styles.errorMessage}>
              Error saving draft:
              <ul>
                {formErrorSummaryValue.map((err, i) => (
                  <li key={i}>{err}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <form>
          <div className={styles.columnContainer}>
            <div className={styles.leftColumn}>
              {isLoading ? <Loader /> : null}
              <div>
                <TextInputField
                  label={'Headline'}
                  id={'headline'}
                  placeholder={''}
                  onChangeValue={updateDataFieldByValue}
                  value={distributionData.headline}
                  issues={frontEndFormErrorsValue?.headlineErrors || []}
                  validate={SubmitDistributionValidator.validateHeadline}
                  tooltip={
                    'To boost the search visibility of your news release, the first 170 characters of your headline will be used in the URL on PRWeb.com. Once the news release is distributed, the URL cannot be changed. The most effective length for a headline is 55-75 characters.  Make sure your headline contains a subject, an active verb and clearly states your news announcement.'
                  }
                />
              </div>

              <div>
                <TextAreaField
                  id={'summary'}
                  label={'Summary (30 characters minimum)'}
                  placeholder={'Enter your release summary'}
                  onChangeValue={updateDataFieldByValue}
                  value={distributionData.summary}
                  issues={frontEndFormErrorsValue?.summaryErrors || []}
                  validate={SubmitDistributionValidator.validateSummary}
                  tooltip={
                    'We recommend your summary be one to two sentences long.  Use the summary paragraph to your advantage - make it enticing enough to compel readers to read on.'
                  }
                />
              </div>

              <div className={styles.columnContainerInForm}>
                {showVideoUrlField && (
                  <div className={styles.columnInFormLeft}>
                    <Paper>
                      <VideoUrl
                        initialValue={distributionData.videoUrl}
                        initialIssues={
                          frontEndFormErrorsValue?.videoUrlErrors || []
                        }
                        onChangeValue={updateDataFieldByValue}
                      ></VideoUrl>
                    </Paper>
                  </div>
                )}
                {showFeaturedImagePanel && (
                  <div
                    className={
                      showVideoUrlField ? styles.columnInFormRight : ''
                    }
                  >
                    <Paper>
                      <FeaturedImage
                        config={config}
                        newsImage={distributionData.newsImage}
                        removeImage={removeNewsImage}
                        addImage={addNewsImage}
                      ></FeaturedImage>
                    </Paper>
                  </div>
                )}
              </div>

              <div>
                <TextInputField
                  label={'Dateline: City, State'}
                  id={'cityState'}
                  placeholder={''}
                  onChangeValue={updateDataFieldByValue}
                  value={distributionData.cityState}
                  issues={frontEndFormErrorsValue?.cityStateErrors || []}
                  validate={SubmitDistributionValidator.validateCityState}
                />
              </div>

              <div>
                <PRWebRichTextEditor
                  id={'prwRte'}
                  onChange={updateBodyField}
                  allowLinks={true}
                  initialValue={distributionData.body && distributionData.body}
                  issues={frontEndFormErrorsValue?.bodyErrors || []}
                  validate={SubmitDistributionValidator.validateBody}
                  label={'Release Body'}
                  tooltip={
                    "Remember to spell check your news release before you submit. We recommend 1-3 unique hyperlinks including a link to your company's website."
                  }
                />
              </div>

              {showPullQuote && (
                <div>
                  <TextAreaField
                    id={'pulloutQuote'}
                    label={'Pull Quote'}
                    placeholder={'Enter your pull quote.'}
                    onChangeValue={updateDataFieldByValue}
                    value={distributionData.pulloutQuote}
                    issues={frontEndFormErrorsValue?.pulloutQuoteErrors || []}
                    validate={SubmitDistributionValidator.validatePulloutQuote}
                    tooltip={
                      'We recommend adding a concise quote that highlights a key point in your news release.'
                    }
                  />
                </div>
              )}
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.contactPanelContainer}>
                <PrimaryContactPanel
                  distributionData={distributionData}
                  saveContactData={saveContact}
                  validation={primaryContactValidation || null}
                />
              </div>
              <div
                id={'secondaryContactLabel'}
                className={styles.secondaryContactToggle}
              >
                <Typography size="lg" weight="bold">
                  ADD ADDITIONAL CONTACT
                </Typography>
                <Toggle
                  checked={withAdditionalContact}
                  onChange={toggleAdditionalContact}
                />
              </div>
              {withAdditionalContact && (
                <div className={styles.contactPanelContainer}>
                  <SecondaryContactPanel
                    distributionData={distributionData}
                    saveContactData={saveContact}
                    validation={secondaryContactValidation || null}
                  />
                </div>
              )}
              <div className={styles.mediaContainer}>
                <div>
                  {attachmentsErrorSummaryValue.length > 0 && (
                    <div className={styles.errorMessage}>
                      Error adding attachment:
                      <ul>
                        {attachmentsErrorSummaryValue.map((err, i) => (
                          <li key={i}>{err}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <Attachments
                  config={config}
                  attachments={distributionData.attachments}
                  removeAttachment={removeAttachment}
                  addAttachment={addAttachment}
                />
              </div>
              <div className={styles.mediaContainer}>
                <SocialMediaContainer
                  config={config}
                  socialMediaHandler={socialMediaHandler}
                >
                  {{
                    twitter: (
                      <TwitterToggle
                        buttonState={SocialMediaState.Selectable}
                        isTwitterSelected={twitterSelected}
                      />
                    ),
                    linkedin: (
                      <LinkedinToggle
                        buttonState={SocialMediaState.Selectable}
                        isLinkedinSelected={linkedinSelected}
                      />
                    ),
                    facebook: (
                      <FacebookToggle
                        buttonState={SocialMediaState.Selectable}
                        isFacebookSelected={facebookSelected}
                      />
                    ),
                  }}
                </SocialMediaContainer>
              </div>
            </div>
          </div>
        </form>
      </Paper>
      <ExitWizardPrompt />
    </div>
  );
};

export default CreateReleaseStep;
