import React from 'react';

import { Typography } from '@cision/rover-ui';

import SocialMediaContainer, {
  SocialMediaState,
  TwitterToggle,
  LinkedinToggle,
  FacebookToggle,
} from './../../components/social-media-container';
import styles from './social.module.css';

interface Props {
  config: PRWebConfig;
}

const Social: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <div className={styles.rightPageColumn} id={'social-container'}>
        <Typography color="black" weight="bold" size="xl">
          Connect your social accounts to your PRWeb releases
        </Typography>
        <div className={styles.connectContainer}>
          <SocialMediaContainer config={props.config}>
            {{
              twitter: (
                <TwitterToggle buttonState={SocialMediaState.Removable} />
              ),
              linkedin: (
                <LinkedinToggle buttonState={SocialMediaState.Removable} />
              ),
              facebook: (
                <FacebookToggle buttonState={SocialMediaState.Removable} />
              ),
            }}
          </SocialMediaContainer>
        </div>
      </div>
    </div>
  );
};

export default Social;
