import React, { useEffect, useState } from 'react';

import { Typography, Button } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import qs from 'qs';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ReactComponent as DownArrow } from '../../assets/angle-down-solid.svg';
import { ReactComponent as UpArrow } from '../../assets/angle-up-solid.svg';
import InputWithButton from '../../components/input-with-button';

import styles from './bundles.module.css';

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

interface UserBundle {
  bundleOrderId: number;
  userAccountId: number;
  amount: number;
  orderNumber: number;
  createdDate: Date;
  oktaLogin: string;
}

interface SubmissionError {
  response: {
    status: number;
    statusText: string;
  };
  message: string;
}

const Bundles: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const urlArgs = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const searchFieldQueryParam = urlArgs?.search ? urlArgs.search : '';

  const [validationMessage, setValidationMessage] = useState('');
  const [searchField, setSearchField] = useState(searchFieldQueryParam);
  const [bundleList, setBundleList] = useState([] as UserBundle[]);

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState('bundleOrderId');
  const [sortDirection, setSortDirection] = useState('ASC');

  const prwebApi = props.config.prwebApi.url;
  const limit = 25;

  const getSearchResults = async (): Promise<void> => {
    await searchBundles(1, sortBy, sortDirection, searchField);
  };

  const searchBundles = async (
    pageNumber: number,
    sortBy: string,
    sortDirection: string,
    searchToken?: string,
  ) => {
    const bundleSearchUrl = `${prwebApi}/admin/bundleorder/search`;
    const bundleParams = {
      params: {
        searchText: searchToken?.trim() || '',
        pageNumber: pageNumber.toString(),
        limit: limit.toString(),
        sortBy,
        sortDirection,
      },
    };
    history.push({
      pathname: '/admin/bundles',
      search: `?search=${searchToken}`,
    });
    await axios
      .get(bundleSearchUrl, bundleParams)
      .then(response => {
        if (response && response.status >= 200 && response.status < 300) {
          setBundleList(response.data.userBundleOrders);
          setTotalRecords(response.data.rowCount);
          setCurrentPageIndex(pageNumber);
          setSortBy(sortBy);
          setSortDirection(sortDirection);
        }
      })
      .catch(error => {
        if ('message' in error) {
          setValidationMessage(error.message);
        }
        console.error('Error retrieving data: ', error);
      });
  };

  const goToBundleId = (bundleId: number) => () =>
    history.push(`bundles/${bundleId}`);

  const getNextBundles = async (): Promise<void> => {
    await searchBundles(
      currentPageIndex + 1,
      sortBy,
      sortDirection,
      searchField,
    );
  };

  const getPreviousBundles = async (): Promise<void> => {
    if (currentPageIndex > 1) {
      await searchBundles(
        currentPageIndex - 1,
        sortBy,
        sortDirection,
        searchField,
      );
    }
  };

  const Next = () => {
    const showNextButton = currentPageIndex * limit < totalRecords;
    return (
      <div>
        {showNextButton && (
          <Button level="primary" onClick={getNextBundles}>
            Next
          </Button>
        )}
      </div>
    );
  };

  const Previous = () => {
    const showPrevButton = currentPageIndex > 1;
    return (
      <div>
        {showPrevButton && (
          <Button level="primary" onClick={getPreviousBundles}>
            Previous
          </Button>
        )}
      </div>
    );
  };

  const sortCoupons = (sortBy: string, sortDirection: string) => async () => {
    await searchBundles(1, sortBy, sortDirection, searchField);
  };

  const getSortColumn = (localSortBy: string, columnLabel: string) => {
    let localSortDirection = 'ASC';
    if (localSortBy === sortBy) {
      if (sortDirection === 'ASC') localSortDirection = 'DESC';
    }

    return (
      <label
        onClick={sortCoupons(localSortBy, localSortDirection)}
        className={styles.mousePointer}
      >
        {columnLabel}
        {localSortBy === sortBy && (
          <>
            {sortDirection === 'ASC' ? (
              <UpArrow
                onClick={sortCoupons(localSortBy, localSortDirection)}
                className={styles.arrowIcon}
              />
            ) : (
              <DownArrow
                onClick={sortCoupons(localSortBy, localSortDirection)}
                className={styles.arrowIcon}
              />
            )}
          </>
        )}
      </label>
    );
  };

  useEffect(() => {
    if (searchFieldQueryParam !== '') {
      setSearchField(searchFieldQueryParam);
      searchBundles(1, sortBy, sortDirection, searchFieldQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography color="black" weight="bold" size="xl" tag="h3">
        Bundle Order List
      </Typography>
      <div className={styles.bundleSearchContainer}>
        <InputWithButton
          id={'bundleSearchField'}
          buttonId={'bundleSearchButton'}
          onChangeValue={setSearchField}
          issues={[]}
          initialValue={searchField}
          onSubmitHandler={getSearchResults}
        />
        <table>
          <thead>
            <tr>
              <th>{getSortColumn('bundleOrderId', 'Bundle Id')}</th>
              <th>{getSortColumn('oktaLogin', 'Login Username')}</th>
              <th>{getSortColumn('amount', 'Amount')}</th>
              <th>{getSortColumn('createdDate', 'Created Date')}</th>
            </tr>
          </thead>
          <tbody>
            {bundleList?.length > 0 &&
              bundleList.map((bundle: UserBundle, index) => {
                return (
                  <tr key={index}>
                    <td>{bundle.bundleOrderId}</td>
                    <td>
                      <Button
                        level="link"
                        onClick={goToBundleId(bundle.bundleOrderId)}
                      >
                        {bundle.oktaLogin}
                      </Button>
                    </td>
                    <td>
                      {Number(bundle.amount).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                    <td>{moment(bundle.createdDate).format('M/D/YYYY')}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className={styles.navigationContainer}>
          <Previous />
          <Next />
        </div>

        {validationMessage.length > 0 && (
          <div className={styles.errorMsg}>{validationMessage}</div>
        )}
      </div>
    </div>
  );
};

export default Bundles;
