import React, { useState, useEffect } from 'react';

import { Button, Badge, Typography, Loader, TabMenu } from '@cision/rover-ui';
import axios from 'axios';
import moment from 'moment-timezone';
import qs from 'qs';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import { ReactComponent as DownArrow } from '../../assets/angle-down-solid.svg';
import { ReactComponent as UpArrow } from '../../assets/angle-up-solid.svg';
import { ReactComponent as ClipboardSvg } from '../../assets/clipboard.svg';
import InputWithButton from '../../components/input-with-button';
import { COUPON_STATUS, COUPON_STATUS_EXPIRED } from '../../constants';

import styles from './coupons.module.css';

interface ListProps extends RouteComponentProps {
  config: PRWebConfig;
}

interface UserCouponResponse {
  userCoupons: UserCoupon[];
  rowCount: number;
}
interface UserCoupon {
  oktaLogin?: string;
  lastName?: string;
  firstName?: string;
  id?: number;
  userAccountId: number;
  couponCode: string;
  amount: number;
  originalAmount: number;
  expirationDate: string;
  notes?: string;
  status: COUPON_STATUS;
}

interface UniversalCoupon {
  universalCouponId: number;
  couponCode: string;
  amount: number;
  percent: number;
  isFixed: boolean;
  createdDate: string;
  startDate: string;
  expirationDate: string;
  notes: string;
}

const formatUSD = (value: any) => {
  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

const formatPercent = (value: any) => {
  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
  });
};

const UniqueCoupons: React.FC<ListProps> = (props: ListProps) => {
  const history = useHistory();
  const [listState, setListState] = useState([] as UserCoupon[]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState('expirationDate');
  const [sortDirection, setSortDirection] = useState('ASC');

  const [listIsLoading, setListIsLoading] = useState(false);
  const [searchFieldState, setSearchFieldState] = useState('');

  const urlArgs = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const searchFieldQueryParam = urlArgs?.uniqueCoupons
    ? urlArgs.uniqueCoupons
    : '';

  const PRWEB_API_BASE_URL = props.config.prwebApi.url;
  const limit = 25;

  const initList = async (
    pageNumber: number,
    sortBy: string,
    sortDirection: string,
    searchString?: string,
  ): Promise<void> => {
    setListIsLoading(true);

    let result = {} as UserCouponResponse;

    try {
      if (searchString && searchString === '') {
        setListState([]);
      } else {
        result = await getCoupons(
          pageNumber,
          sortBy,
          sortDirection,
          searchString,
        );
        setListState(result.userCoupons);
        setTotalRecords(result.rowCount);
      }
      setCurrentPageIndex(pageNumber);
      setSortBy(sortBy);
      setSortDirection(sortDirection);
    } finally {
      console.log(result);
      setListIsLoading(false);
    }
  };

  const sortCoupons = (sortBy: string, sortDirection: string) => async () => {
    await initList(1, sortBy, sortDirection, searchFieldState);
  };

  const getCoupons = async (
    pageNumber: number,
    sortBy: string,
    sortDirection: string,
    searchToken?: string,
  ): Promise<UserCouponResponse> => {
    const params = new URLSearchParams({
      searchText: searchToken?.trim() || '',
      pageNumber: pageNumber.toString(),
      limit: limit.toString(),
      sortBy,
      sortDirection,
    });

    const couponSearchUrl = `${PRWEB_API_BASE_URL}/admin/coupons/search`;
    try {
      const response = await axios.get<UserCouponResponse>(couponSearchUrl, {
        params,
      });
      if (response.data) {
        return response.data;
      }
      return {} as UserCouponResponse;
    } catch (e) {
      console.error('something went wrong with get coupons endpoint call', e);
      return {} as UserCouponResponse;
    }
  };

  const getSearchResults = async (): Promise<void> => {
    await initList(1, sortBy, sortDirection, searchFieldState);
    const searchParams = new URLSearchParams(urlArgs);
    searchParams.set('uniqueCoupons', searchFieldState);
    history.push({
      pathname: '/admin/coupons',
      search: `?${searchParams.toString()}`,
    });
  };

  const clipboardCouponCode = (elem: React.MouseEvent) => {
    const icon = elem.target as HTMLElement;
    const span = icon.parentElement?.querySelector('span') as HTMLElement;
    const selection = window.getSelection();
    const range = document.createRange();
    if (span && selection) {
      range.selectNodeContents(span);
      selection.removeAllRanges();
      selection.addRange(range);
      if ('clipboard' in navigator) {
        navigator.clipboard.writeText(span.innerText).then(
          () => {},
          () => {
            document.execCommand('copy');
          },
        );
      } else {
        // Backward compatibility for Internet Explorer
        document.execCommand('copy');
      }
    }
  };

  const goToCouponId = (id: number | undefined) => () =>
    history.push(`coupons/edit/${id}`);

  const getStatusElement = (date: string, status: COUPON_STATUS) => {
    if (status === COUPON_STATUS.REVOKED) {
      return (
        <Badge variant="danger">
          {COUPON_STATUS.REVOKED.toString().toUpperCase()}
        </Badge>
      );
    }

    if (status === COUPON_STATUS.USED) {
      return (
        <Badge variant="success">
          {COUPON_STATUS.USED.toString().toUpperCase()}
        </Badge>
      );
    }

    const currentDate = moment.utc();
    const expirationDate = moment.utc(date);

    if (expirationDate.isBefore(currentDate)) {
      return <Badge variant="warning">{COUPON_STATUS_EXPIRED}</Badge>;
    }
  };

  const getNextCoupons = async (): Promise<void> => {
    await initList(
      currentPageIndex + 1,
      sortBy,
      sortDirection,
      searchFieldState,
    );
  };
  const getPreviousCoupons = async (): Promise<void> => {
    if (currentPageIndex > 1) {
      await initList(
        currentPageIndex - 1,
        sortBy,
        sortDirection,
        searchFieldState,
      );
    }
  };

  const Next = () => {
    const showNextButton = currentPageIndex * limit < totalRecords;
    return (
      <div>
        {showNextButton && (
          <Button level="primary" onClick={getNextCoupons}>
            Next
          </Button>
        )}
      </div>
    );
  };
  const Previous = () => {
    const showPrevButton = currentPageIndex > 1;
    return (
      <div>
        {showPrevButton && (
          <Button level="primary" onClick={getPreviousCoupons}>
            Previous
          </Button>
        )}
      </div>
    );
  };

  const getSortColumn = (localSortBy: string, columnLabel: string) => {
    let localSortDirection = 'ASC';
    if (localSortBy === sortBy) {
      if (sortDirection === 'ASC') localSortDirection = 'DESC';
    }

    return (
      <>
        <label
          onClick={sortCoupons(localSortBy, localSortDirection)}
          className={styles.mousePointer}
        >
          {columnLabel}
          {localSortBy === sortBy && (
            <>
              {sortDirection === 'ASC' ? (
                <UpArrow
                  onClick={sortCoupons(localSortBy, localSortDirection)}
                  className={styles.arrowIcon}
                />
              ) : (
                <DownArrow
                  onClick={sortCoupons(localSortBy, localSortDirection)}
                  className={styles.arrowIcon}
                />
              )}
            </>
          )}
        </label>
      </>
    );
  };

  useEffect(() => {
    if (searchFieldQueryParam !== '') {
      setSearchFieldState(searchFieldQueryParam);
      initList(1, sortBy, sortDirection, searchFieldQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    ((listIsLoading || !listState) && <Loader />) || (
      <div>
        <InputWithButton
          id={'couponSearchField'}
          buttonId={'couponSearchButton'}
          onChangeValue={setSearchFieldState}
          issues={[]}
          initialValue={searchFieldState}
          onSubmitHandler={getSearchResults}
        />
        <table>
          <thead>
            <tr>
              <th>{getSortColumn('oktaLogin', 'Login Username')}</th>
              <th>{getSortColumn('couponCode', 'Code')}</th>
              <th>{getSortColumn('originalAmount', 'Original Amount')}</th>
              <th>{getSortColumn('amount', 'Amount Remaining')}</th>
              <th>{getSortColumn('expirationDate', 'Expiration (UTC)')}</th>
              <th>{getSortColumn('lastName', 'Last, First')}</th>
              <th>{getSortColumn('status', 'Status')}</th>
            </tr>
          </thead>
          <tbody>
            {listState.map(coupon => (
              <tr key={coupon.id}>
                <td>
                  <Button level="link" onClick={goToCouponId(coupon.id)}>
                    {coupon.oktaLogin}
                  </Button>
                </td>
                <td>
                  <span>{coupon.couponCode}</span>
                  <ClipboardSvg
                    className={styles.clipboard}
                    onClick={clipboardCouponCode}
                  />
                </td>
                <td>
                  <span>{formatUSD(coupon.originalAmount)}</span>
                </td>
                <td>
                  <span>{formatUSD(coupon.amount)}</span>
                </td>
                <td>
                  <span>
                    {moment.utc(coupon.expirationDate).format('M/D/YYYY')}
                  </span>
                </td>
                <td>
                  <span>
                    {coupon.lastName
                      ? `${coupon.lastName}
                      ${coupon.firstName ? ', ' : ''}${coupon.firstName}`
                      : coupon.firstName}
                  </span>
                </td>
                <td>
                  {getStatusElement(coupon.expirationDate, coupon.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.navigationContainer}>
          <Previous />
          <Next />
        </div>

        {listState && listState.length === 0 ? (
          <div className={styles.navigationContainer}>
            {`Please enter some search criteria`}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  );
};

const UniversalCoupons: React.FC<ListProps> = (props: ListProps) => {
  const history = useHistory();
  const prwebApi = props.config.prwebApi.url;

  const [couponList, setCouponList] = useState([] as UniversalCoupon[]);
  const [searchFieldState, setSearchFieldState] = useState('');

  const urlArgs = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const searchFieldQueryParam = urlArgs?.universalCoupons
    ? urlArgs.universalCoupons
    : '';

  const goToCouponId = (id: number | undefined) => () =>
    history.push(`universalcoupons/edit/${id}`);

  const getCoupons = async (searchToken?: string): Promise<void> => {
    const params = new URLSearchParams({
      searchText: searchToken?.trim() || '',
    });
    const universalCouponSearchUrl = `${prwebApi}/admin/universalcoupons/search`;
    return await axios
      .get(universalCouponSearchUrl, { params })
      .then(response => {
        if (
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data)
        ) {
          setCouponList(response.data);
        }
      })
      .catch(error => {
        setCouponList([]);
      });
  };

  const getSearchResults = async () => {
    await getCoupons(searchFieldState);
    const searchParams = new URLSearchParams(urlArgs);
    searchParams.set('universalCoupons', searchFieldState);
    history.push({
      pathname: '/admin/coupons',
      search: `?${searchParams.toString()}`,
    });
  };

  useEffect(() => {
    const init = async () => {
      if (searchFieldQueryParam !== '') {
        setSearchFieldState(searchFieldQueryParam);
        await getCoupons(searchFieldQueryParam);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InputWithButton
        id={'universalCouponSearchField'}
        buttonId={'universalCouponSearchButton'}
        onChangeValue={setSearchFieldState}
        issues={[]}
        initialValue={searchFieldState}
        onSubmitHandler={getSearchResults}
      />
      {couponList && (
        <table>
          <thead>
            <tr>
              <th>Coupon Code</th>
              <th>Amount</th>
              <th>Created Date (UTC)</th>
              <th>Start Date (UTC)</th>
              <th>Expiration Date (UTC)</th>
            </tr>
          </thead>
          <tbody>
            {couponList.map((item: UniversalCoupon, key: number) => {
              return (
                <tr key={key}>
                  <td>
                    <Button
                      onClick={goToCouponId(item.universalCouponId)}
                      level="link"
                    >
                      {item.couponCode}
                    </Button>
                  </td>
                  <td>
                    {item.isFixed
                      ? formatUSD(item.amount)
                      : formatPercent(item.percent)}
                  </td>
                  <td>
                    {item.createdDate
                      ? moment.utc(item.createdDate).format('M/D/YYYY')
                      : ''}
                  </td>
                  <td>
                    {item.startDate
                      ? moment.utc(item.startDate).format('M/D/YYYY')
                      : ''}
                  </td>
                  <td>
                    {item.expirationDate
                      ? moment.utc(item.expirationDate).format('M/D/YYYY')
                      : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

interface Props extends RouteComponentProps {
  config: PRWebConfig;
}

const Coupons: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('Unique Coupon');

  const goToCreateCoupon = () => {
    if (activeTab === 'Unique Coupon') {
      history.push('coupons/create');
    } else if (activeTab === 'Universal Coupon') {
      history.push('universalcoupons/create');
    }
  };

  const ChangeTab = (tab: string) => () => {
    setActiveTab(tab);
  };

  return (
    <div>
      <br />
      <TabMenu>
        <TabMenu.Item
          active={activeTab === 'Unique Coupon'}
          onClick={ChangeTab('Unique Coupon')}
        >
          <Typography color="black" weight="bold" size="xl">
            Unique Coupons&nbsp;
          </Typography>
        </TabMenu.Item>
        <TabMenu.Item
          active={activeTab === 'Universal Coupon'}
          onClick={ChangeTab('Universal Coupon')}
        >
          <Typography color="black" weight="bold" size="xl">
            Universal Coupons&nbsp;
          </Typography>
        </TabMenu.Item>
        <TabMenu.Item>
          <Button
            level="link"
            onClick={goToCreateCoupon}
            style={{ padding: '2px' }}
          >
            <Typography color="inherit" weight="bold" size="xl">
              {'Add new ' + activeTab}
            </Typography>
          </Button>
        </TabMenu.Item>
      </TabMenu>

      <div
        className={styles.listContainer}
        style={{
          display: activeTab === 'Unique Coupon' ? 'block' : 'none',
        }}
      >
        <UniqueCoupons {...props} />
      </div>
      <div
        className={styles.listContainer}
        style={{
          display: activeTab === 'Universal Coupon' ? 'block' : 'none',
        }}
      >
        <UniversalCoupons {...props} />
      </div>
    </div>
  );
};

export default Coupons;
